import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  height: 100vh;
`;

export const InnerWrapper = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  width: 100vw;
`;
