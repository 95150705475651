import Arrow from "@assets/icons/chevron-bottom.svg";
import { InputLabel } from "@material-ui/core";
import { FormLabel, ListSubheader, SelectChangeEvent } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import classNames from "classnames";
import { FormikTouched } from "formik";
import styled from "styled-components";
import "./MuiSelect.scss";
import { ReactElement } from "react";

export interface UsualMenuItem {
  value?: string | number | readonly string[];
  title?: string;
  subheader?: string;
}

type Props<Value> = {
  label?: string;
  id?: string;
  value?: Value;
  list: UsualMenuItem[];
  handleChange: (event: SelectChangeEvent<Value>) => void;
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;
  error?: string | Array<string>;
  placeholder?: string;
  size?: "m";
  touched?: boolean;
  required?: boolean;
  onTouched?: FormikTouched<Value>;
  onClose?: () => void;
  maxHeight?: number;
};

export function MuiSelect<Value extends string>(props: Props<Value>): ReactElement {
  const {
    handleChange,
    list,
    error,
    id,
    label,
    value,
    placeholder,
    touched,
    required,
    onClose,
    onBlur,
    maxHeight = 300,
  } = props;
  const isError = touched && error !== undefined;

  return (
    <FormControl fullWidth className="mui-base-form-control" error={isError}>
      {label !== undefined && (
        <InputLabel required={required} className="mui-base-label" id={id}>
          {label}
        </InputLabel>
      )}
      <Select<Value>
        displayEmpty
        IconComponent={Arrow as any}
        className={classNames("mui-base-select", isError && "error")}
        variant="outlined"
        id={id}
        onClose={onClose}
        name={id}
        value={value}
        onBlur={onBlur}
        onChange={handleChange}
        defaultValue={value}
        renderValue={selected => {
          if (selected === undefined) {
            return (
              <FormLabel className={classNames("mui-base-placeholder", isError && "withError")}>
                {placeholder}
              </FormLabel>
            );
          }

          return <>{getLabelById(selected, list) ?? ""}</>;
        }}
        MenuProps={{
          style: {
            maxHeight: maxHeight !== undefined ? `${maxHeight}px` : "auto",
          },
          PopoverClasses: { paper: "mui-base-paper" },
        }}
      >
        {/* <MenuItem value={undefined}>
          <em>None</em>
        </MenuItem> */}
        {list.map(({ title, value, subheader }) => {
          if (subheader !== undefined) {
            return <ListSubheader className="mui-base-subheader">{subheader}</ListSubheader>;
          }
          return (
            <MenuItem className="mui-base-item" value={value}>
              {title}
            </MenuItem>
          );
        })}
      </Select>
      {isError ? <StyledError>{Array.isArray(error) ? error.join(", ") : error}</StyledError> : null}
    </FormControl>
  );
}

const StyledError = styled(FormHelperText)`
  position: absolute;
  left: 0;
  bottom: -5px;
  z-index: 0;
`;

function getLabelById(value: string, arr: UsualMenuItem[]): string | undefined {
  for (let index = 0; index < arr.length; index++) {
    if (arr[index].value === value) {
      return arr[index].title;
    }
  }
  return;
}
