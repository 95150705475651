import classNames from "classnames/bind";
import styles from "./CustomTabs.module.scss";
import { ReactElement } from "react";

const cln = classNames.bind(styles);

export interface CustomTabType<T> {
  label: string;
  value: T;
  content?: ReactElement;
  icon?: ReactElement;
  minWidth?: number;
}

type Props<T extends string> = {
  tabs: CustomTabType<T>[];
  currentTab: T;
  onChange: (value: T) => void;
};

export function CustomTabs<T extends string>(props: Props<T>) {
  const { tabs, onChange, currentTab } = props;

  return (
    <div className={cln("tabs-wrapper")}>
      {tabs.map(tab => (
        <CustomTab {...tab} selected={currentTab === tab.value} onChange={onChange} />
      ))}
    </div>
  );
}

function CustomTab<T extends string>({
  selected,
  onChange,
  ...tab
}: CustomTabType<T> & {
  selected: boolean;
  onChange: (value: T) => void;
}) {
  return (
    <div
      className={cln("tab-wrapper")}
      style={{ minWidth: tab.minWidth ? `${tab.minWidth}px` : undefined }}
      onClick={() => (!selected ? onChange(tab.value) : null)}
    >
      <h5 className={cln("tab-label", selected && "selected")}>{tab.label}</h5>
      {selected ? <div className={cln("selected-row")} /> : null}
    </div>
  );
}
