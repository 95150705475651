import { z } from "zod";

export const IconColorZodSchema = z.object({
  background: z.string(),
  letter: z.string(),
});
export const AccountTypeZodSchema = z.object({
  id: z.string(),
  alias: z.string(),
  name: z.string(),
});
export const AccountShortViewZodSchema = z.object({
  name:  z.string(),
  balance:  z.string(),
  accountType:  z.string(),
  budget:  z.string(),
  iconColor: IconColorZodSchema.optional(),
});

export const AccountZodSchema = z.object({
  id: z.string().uuid(),
  name: z.string().min(1, "Минимальная длина 1 символ").max(64, "Максимальная длина 64 символа"),
  balance: z.string(),
  currentBalance: z.number().optional(),
  budget: z.string(),
  accountType: z.string(),
  iconColor: IconColorZodSchema.optional(),
  createdAt: z.string().optional(),
  updatedAt: z.string().optional(),
});
