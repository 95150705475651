import styled from 'styled-components'
import { Paper, Checkbox as CheckboxMui, Button as MuiButton } from '@material-ui/core'

export const Cell = styled.div`
  padding: 12px;
  padding-left: 12px;
  min-width: 170px;
  height: 24px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const CheckableCell = styled(Cell)`
  min-width: 50px;
  display: flex;
  align-items: center;
`;

export const TableHeader = styled(Paper)`
  margin-top: 20px !important;

  &.MuiPaper-root {
    display: flex;
    padding: 0 12px;
    color: ${p => p.theme.colors.contentSecondary};
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    box-shadow: none;
    border-bottom: 1px solid ${p => p.theme.colors.backgroundSecondary};
  }
`;

export const Section = styled.section`
  width: 100%;
`;

export const SubCategory = styled(Paper)`
  &.MuiPaper-root {
    display: flex;
    padding: 0 12px;
    color: ${p => p.theme.colors.contentMain};
    box-shadow: none;
    font-size: 16px;
    font-weight: normal;
    line-height: 24px;
    text-align: left;
    background-color: ${p => p.theme.colors.white};
    border-bottom: 1px solid ${p => p.theme.colors.backgroundSecondary};

    &:hover {
      background-color: ${p => p.theme.colors.light};

      .edit {
        visibility: visible;
      }
    }
  }
`;

export const Checkbox = styled(CheckboxMui)`
  &&.Mui-checked {
    color: ${p => p.theme.colors.main};
  }
  &.MuiCheckbox-root {
    color: ${p => p.theme.colors.backgroundSecondary};
  }
`;

export const EditCell = styled.div`
  visibility: hidden;
  padding: 12px;
  width: 82px;
  height: 24px;
`;

export const Divider = styled.div`
  width: 100%;
`;

export const Button = styled(MuiButton)`
  &.MuiButtonBase-root {
    display: flex;
    align-items: center;
  }
  && {
    color: ${p => p.theme.colors.main};
    padding: 0;

    svg {
      path {
        fill: ${p => p.theme.colors.main};
      }
    }

    &:disabled {
      color: ${p => p.theme.colors.contentSecondary};
      font-weight: 400;
      svg {
        path {
          fill: ${p => p.theme.colors.contentSecondary};
        }
      }
    }
  }
`;
