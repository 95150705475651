import { Button, Link as DocLink, FormControlLabel as MuiLabel, TextField as MuiTextField } from "@material-ui/core"
import { Link as RouterLink } from "react-router-dom"
import styled from "styled-components"

export const Title = styled.h1`
  letter-spacing: 0.5px;
  margin-bottom: 40px;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 450px;
`

export const Wrapper = styled.div`
  background-color: ${p => p.theme.colors.white};
  border-radius: 8px;
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;
`

export const TextField = styled(MuiTextField)`
  &.MuiFormControl-root {
    margin-bottom: 35px;
    width: 100%;
    position: relative;
  }
  .MuiFormLabel-root.Mui-focused {
    color: ${p => p.theme.colors.main};
  }
  .MuiInput-underline:after {
    border-color: ${p => p.theme.colors.main};
  }
  .MuiFormHelperText-root.Mui-error {
    position: absolute;
    bottom: -20px;
  }
`

export const Policy = styled.p`
  text-align: center;
  margin-bottom: 24px;
  width: 120%;
`

export const Submit = styled(Button)`
  &.MuiButton-root {
    padding: 16px 0;
    background-color: ${p => p.theme.colors.main};
    color: ${p => p.theme.colors.white};
    font-weight: bold;
    text-transform: none;
    width: 100%;

    &:hover,
    &:focus,
    &:active {
      background-color: ${p => p.theme.colors.dark};
    }

    &:disabled {
      background-color: ${p => p.theme.colors.backgroundMain};
      color: ${p => p.theme.colors.contentSecondary};
    }
  }
`

export const Link = styled(RouterLink)`
  && {
    color: ${p => p.theme.colors.main};
    cursor: pointer;
    text-decoration: none;
    &:hover,
    &:focus {
      text-decoration: none;
      color: ${p => p.theme.colors.dark};
    }
  }
`

export const DocumentLink = styled(DocLink)`
  && {
    color: ${p => p.theme.colors.main};
    cursor: pointer;

    &:hover,
    &:focus {
      text-decoration: none;
      color: ${p => p.theme.colors.dark};
    }
  }
`

export const Box = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 24px;
  justify-content: space-between;
  width: 100%;
`

export const FormControlLabel = styled(MuiLabel)`
  & .MuiIconButton-root {
    padding-top: 0;
    padding-bottom: 0;
  }
  .MuiCheckbox-colorSecondary.Mui-checked {
    color: ${p => p.theme.colors.main};
  }
  .MuiCheckbox-root {
    color: ${p => p.theme.colors.backgroundSecondary};
  }
`

export const Text = styled.p`
  margin-top: auto;
`

export const ChangePasswordTitle = styled.p`
  text-align: center;
`

export const ChangePasswordText = styled.p`
  margin-top: 300px;
`
