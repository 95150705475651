import ArticleIcon from "@assets/icons/article.svg"
import MessageIcon from "@assets/icons/message.svg"
import UserIcon from "@assets/icons/user.svg"
import WarningIcon from "@assets/icons/warning.svg"
import { MenuByTitle, RoleNames, setActiveMenu, userData } from "@common/store"
import { useDispatch, useSelector } from "react-redux"
import { ComponentProps, NavMenuTitle } from "../../routes"
import { NewRouteItem } from "./RouteItem"
import { ReactElement } from "react";

interface RouteItemType extends ComponentProps {
  availableRoles: RoleNames[]
}

export function StaffMenuList(): ReactElement {
  const user = useSelector(userData)
  const userRole = user?.roleName
  const dispatch = useDispatch()

  if (user && userRole === "client") {
    return <></>
  }

  const routeList: RouteItemType[] = [
    {
      href: "/users",
      icon: <UserIcon />,
      title: "Пользователи",
      availableRoles: [RoleNames.manager, RoleNames.administrator],
    },
    {
      href: "/articles",
      icon: <ArticleIcon />,
      title: "Статьи",
      availableRoles: [RoleNames.manager, RoleNames.administrator],
    },
    {
      href: "/notifications",
      icon: <MessageIcon />,
      title: "Оповещения",
      availableRoles: [RoleNames.manager, RoleNames.administrator],
    },
    {
      href: "/managers_requests",
      icon: <WarningIcon />,
      title: "Пользователи",
      availableRoles: [RoleNames.administrator],
    },
  ]

  function onMenuItemClick(title: NavMenuTitle) {
    dispatch(setActiveMenu(MenuByTitle[title]))
  }

  return (
    <>
      {routeList.map(({ availableRoles, ...rest }) =>
        userRole !== undefined && availableRoles.includes(userRole) ? (
          <NewRouteItem {...rest} onClick={() => onMenuItemClick(rest.title)} />
        ) : null,
      )}
    </>
  )
}
