import { Loading } from "@common/components"
import { lazyLoad } from "@common/utils"

export const AccountsDetailPageAsync = lazyLoad(
  () => import("./AccountsDetailPage"),
  module => module.AccountsDetailPage,
  {
    fallback: <Loading />,
  },
)
