import { SelectComplexOption, SelectOptionWithAmount } from "@common/components";
import type { CacheAccount, Category, CurrentBudget, Subcategory } from "@common/models";
import { createSelector } from "reselect";
import { AccountType, PeriodicType, UserData } from "./reducers";
import type { RootState } from "./store";

export const authSelector = (state: RootState): boolean => state.auth.isAuth;
export const tokenSelector = (state: RootState) => state.auth.token;
export const changePasswordSelector = (state: RootState) => state.auth.isPasswordChange;

export const accounts = (state: RootState): CacheAccount[] => state.accounts.list;
export const accountsTypes = (state: RootState): AccountType[] => state.accounts.types;
export const accountsToEdit = (state: RootState) => state.accounts.chosen;
export const currentAccount = (state: RootState) => state.accounts.currentAccount;

export const categoriesList = (state: RootState): Category[] => state.categories.list;
export const categoriesDate = (state: RootState) => state.categories.date;
export const categoriesLoading = (state: RootState) => state.categories.loading;
export const categoriesError = (state: RootState) => state.categories.error;

export const transactionsList = (state: RootState) => state.transactions.list;
export const transactionsApproved = (state: RootState) => state.transactions.approvedList;
export const transactionsUnapproved = (state: RootState) => state.transactions.unapprovedList;
export const transactionsCount = (state: RootState) => state.transactions.count;
export const transactionsFutureList = (state: RootState) => state.transactions.futureList;
export const transactionsToEdit = (state: RootState) => state.transactions.chosen;
export const transactionsPeriodicTypes = (state: RootState): PeriodicType[] => state.transactions?.periodicTypes;
export const transactionsLoading = (state: RootState) => state.transactions.loading;
export const transactionsStackActions = (state: RootState) => state.transactions.stackActions;

export const ratesSelector = (state: RootState) => state.subscription.rates;

export const budgetsListSelector = (state: RootState) => state.budgets.items;
export const isBudgetsListEmptySelector = (state: RootState) => state.budgets.isBudgetsListEmpty;
export const budgetByDateSelector = (state: RootState) => state.budgets.budgetByDate;
export const currentBudgetSelector = (state: RootState): CurrentBudget | undefined => state.budgets.currentBudget;
export const budgetLoadingSelector = (state: RootState) => state.budgets.loading;
export const currentBudgetDateSelector = (state: RootState) => state.budgets.date;

/* TODO: Доработать валидацию старого пароля когда разберемся со структурой ендпоинтов */
export const settingsUserErrorOldPasswordSelector = (state: RootState) => state.settingsUser.error.oldPassword;
/* END-TODO */
export const settingsUserErrorPasswordSelector = (state: RootState) => state.settingsUser.error.password;

export const contractorsList = (state: RootState) => state.contractors.list;
export const newContractorSelector = (state: RootState) => state.contractors.newContractor;

export const subcategoriesList = (state: RootState) => state.subcategories.list;
export const subcategoriesLoading = (state: RootState) => state.subcategories.loading;

export const reportsNetWorth = (state: RootState) => state.reports.netWorth;

export const reportsNetWorthSum = createSelector(reportsNetWorth, (items: any) =>
  items.reduce(
    (acc, { obligation, availableCapital }) => {
      if (obligation !== "") {
        acc.obligation = parseFloat(acc.obligation) + parseFloat(obligation);
        acc.availableCapital = parseFloat(acc.availableCapital) + parseFloat(availableCapital);
      }
      return acc;
    },
    { obligation: 0, availableCapital: 0 },
  ),
);
export const reportsNetWorthDate = state => state.reports.netWorthDate;
export const reportsIncomesAndExpenses = state => state.reports.incomesAndExpenses;
export const reportsIncomesAndExpensesDate = state => state.reports.incomesAndExpensesDate;
export const incomesAndExpensesCSV = state => state.reports.csv;
export const reportsLoading = state => state.reports.loading;
export const reportsError = state => state.reports.error;

export const userData = (state: RootState): UserData | null => state.user.data;
export const userLoading = state => state.user.loading;
export const userError = state => state.user.error;

export const usersClients = state => state.users.clients;
export const usersManagers = state => state.users.managers;
export const usersClientsCount = state => state.users.clientsCount;
export const usersManagersCount = state => state.users.managersCount;
export const usersLoading = state => state.users.loading;
export const usersError = state => state.users.error;
export const currentUser = state => state.users.currentUser;

export const popupType = (state: RootState) => state.popup.type;
export const popupMessage = (state: RootState): string | null => state.popup.message;

export const notificatinsList = state => state.notifications.list;

export const mailingsList = state => state.mailings.list;

export const requestsList = state => state.requests.list;
export const requestsLoading = state => state.requests.loading;
export const requestsError = state => state.requests.error;

export const currentPurpose = state => state.purpose.currentPurpose;
export const purposesList = state => state.purpose.list;
export const expensesList = (state: RootState) => state.expenses.list;
export const datesExpensesList = (state: RootState) => state.expenses.datesExpenses;

export const articlesList = state => state.articles.list;
export const articlesLoading = state => state.articles.loading;
export const articlesError = state => state.articles.error;

export const selectCategoriesOptions = (state: RootState): SelectComplexOption<SelectOptionWithAmount>[] =>
  state.categories.list?.map(({ name, subcategories }) => ({
    options:
      subcategories?.map(subcategory => ({
        label: subcategory?.name,
        value: subcategory?.id,
        amount: subcategory?.availableFunds ?? "",
      })) ?? [],
    label: name,
  }));

export const selectPeriodicTypesOptions = createSelector(transactionsPeriodicTypes, periodicTypes =>
  periodicTypes.map(({ id, name }) => ({
    value: id,
    label: name,
  })),
);

export const accountsList = createSelector(accounts, currentBudgetSelector, (accounts, account) =>
  accounts.filter(({ budget }) => budget === account?.id),
);

export const accountsListOptions = createSelector(accounts, accountsTypes =>
  accountsTypes.map(({ id, name }) => ({
    value: id,
    label: name,
  })),
);

//history
export const pastActionSelector = (state: RootState) => state.actionsHistory.past?.[0];
export const lastActionSelector = (state: RootState) => state.actionsHistory.present?.[0];
export const nextActionSelector = (state: RootState) => state.actionsHistory.future?.[0];

//get by id
export const categoryById = (state, id) => {
  return state.categories.list.find(category => category.id === id);
};

export const subcategoryById = (state: RootState, id: string): Subcategory | undefined => {
  return state.subcategories.list.find(subcategory => subcategory.id === id);
};
