import type { Subcategory } from "@common/models"
import { createSlice, PayloadAction, Slice, SliceCaseReducers } from "@reduxjs/toolkit"

interface SubcategoriesState {
  list: Subcategory[]
  loading: boolean
  error: string | null
}

const INIT_STATE: SubcategoriesState = {
  loading: false,
  error: null,
  list: [],
}

interface SubcategoriesActions extends SliceCaseReducers<SubcategoriesState> {
  setSubcategoriesList: (state: SubcategoriesState, action: PayloadAction<Subcategory[]>) => void
  setSubcategoriesLoading: (state: SubcategoriesState, action: PayloadAction<boolean>) => void
  setSubcategoriesError: (state: SubcategoriesState, action: PayloadAction<string | null>) => void
}

const subcategoriesSlice: Slice<SubcategoriesState, SubcategoriesActions, "subcategories"> = createSlice({
  name: "subcategories",
  initialState: INIT_STATE,
  reducers: {
    setSubcategoriesList: (state, action: PayloadAction<Subcategory[]>) => {
      state.list = action.payload
      state.loading = false
      state.error = null
    },
    setSubcategoriesLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    setSubcategoriesError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload
    },
  },
  extraReducers: {},
})

export const { setSubcategoriesList, setSubcategoriesLoading, setSubcategoriesError } = subcategoriesSlice.actions
export const subcategoriesReducer = subcategoriesSlice.reducer
