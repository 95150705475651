import { API, APIResponse } from "@common/api"

import { createAsyncThunk } from "@reduxjs/toolkit"
import { UserData, setBudgetsListEmpty, setNotification, setUserData, setUserError, setUserLoading } from "../reducers"

export const fetchUserDataThunk = createAsyncThunk<void, void>("FETCH_USER_DATA", async (_, thunkAPI) => {
  thunkAPI.dispatch(setUserLoading(true))
  thunkAPI.dispatch(setUserError(null))

  try {
    const { data } = await API.get<UserData>("/users/me/")
    thunkAPI.dispatch(setUserData(data))
  } catch (error) {
    thunkAPI.dispatch(setNotification({ type: "error", message: (error as any).message }))
    thunkAPI.dispatch(setUserError((error as any).message))
  }
})()

export const setDontShowThunk = createAsyncThunk<void, void>("SET_DONT_SHOW", async (_, thunkAPI) => {
  thunkAPI.dispatch(setUserLoading(true))
  thunkAPI.dispatch(setUserError(null))

  try {
    const { data } = await API.patch<UserData>("/users/me/", { uiSettings: { dontShow: true } })

    thunkAPI.dispatch(setUserData(data))
  } catch (error) {
    thunkAPI.dispatch(setNotification({ type: "error", message: (error as any).message }))
    thunkAPI.dispatch(setUserError((error as any).message))
  }
})()
export const resumeTrial = password => async dispatch => {
  try {
    await API.put("/users/me/resume_trial/", { password })

    // dispatch(clearStateAction())
    dispatch(setBudgetsListEmpty(true))
    dispatch(setNotification({ type: "success", message: "Пробный период успешно восстановлен" }))
  } catch (e) {
    const {
      response: {
        data: { password },
      },
    } = e as APIResponse<{ password: string[] }>
    dispatch(setNotification({ type: "error", message: password.pop() ?? "Произошла ошибка" }))
  }
}
