import Image from '@editorjs/image'
import Heading from '@editorjs/header'
import Video from 'editorjs-youtube-embed'

export const EDITOR_JS_TOOLS = {
  image: {
    class: Image,
    config: {
      endpoints: {
        byFile: `${import.meta.env.VITE_APP_URI}admin/posts/upload/`,
      },
      additionalRequestHeaders: {
        Authorization: `JWT ${localStorage.getItem('session')}`,
      },
    },
  },
  header: Heading,
  video: Video,
};
