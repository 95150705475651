import { API } from "@common/api";
import type { Category } from "@common/models";
import { normalizeError, stringifyDate } from "@common/utils";
import { AsyncThunk, createAsyncThunk } from "@reduxjs/toolkit";
import {
  clearCategoriesWithError,
  setCategoriesError,
  setCategoriesList,
  setCategoriesLoading,
  setNotification,
} from "../reducers";
import { categoryById, currentBudgetDateSelector, currentBudgetSelector } from "../selectors";
import { RootState } from "../store";
import { addRecordThunk, IUndoActionEnum, resetHistory, updateRecordsInHistory } from "./actionsHistory";

export type getCategoriesParamsType = {
  budget: string;
  isSystem?: boolean;
};
// isSystem парамметр для получения категории Доступно на распределение

export const getCategoriesThunk: AsyncThunk<
  Category[] | undefined,
  getCategoriesParamsType,
  {
    state: RootState;
  }
> = createAsyncThunk("GET_CATEGORIES", async ({ budget: budgetId, isSystem }, { dispatch }) => {
  dispatch(setCategoriesLoading(true));
  dispatch(setCategoriesError(null));

  try {
    const params = { budget: budgetId };

    if (isSystem) {
      params["is_system"] = true;
    }

    const { data } = await API.get<Category[]>(`/categories/`, { params });
    dispatch(setCategoriesList(data));
    return data;
  } catch (err) {
    const { message } = normalizeError(err);
    dispatch(setNotification({ type: "error", message: message }));
  } finally {
    dispatch(setCategoriesLoading(false));
  }
});

export const getAllCategoriesThunk = createAsyncThunk<Category[] | undefined, void>(
  "GET_ALL_CATEGORIES",
  async (_, thunkAPI) => {
    thunkAPI.dispatch(setCategoriesLoading(true));
    thunkAPI.dispatch(setCategoriesError(null));

    try {
      const { data } = await API.get<Category[]>(`/categories/`);
      return data;
    } catch (err) {
      const { message } = normalizeError(err);
      thunkAPI.dispatch(setNotification({ type: "error", message: message }));
      thunkAPI.dispatch(clearCategoriesWithError(message));
    } finally {
      thunkAPI.dispatch(setCategoriesLoading(false));
    }
  },
);

type GetCategoriesByDateParams = {
  budgetId: string;
  month: number;
  year: number;
};
export const getCategoriesByDateThunk = createAsyncThunk<Category[] | undefined, GetCategoriesByDateParams>(
  "GET_CATEGORIES_BY_DATE",
  async ({ year, month, budgetId }, thunkAPI) => {
    thunkAPI.dispatch(setCategoriesLoading(true));
    thunkAPI.dispatch(setCategoriesError(null));

    try {
      const { data } = await API.get<Category[]>(`/categories/${stringifyDate(month)}/${year}/`, {
        params: { budget: budgetId },
      });

      thunkAPI.dispatch(setCategoriesList(data));
      return data;
    } catch (err) {
      const { message } = normalizeError(err);
      thunkAPI.dispatch(setNotification({ type: "error", message: message }));
      thunkAPI.dispatch(clearCategoriesWithError(message));
    } finally {
      thunkAPI.dispatch(setCategoriesLoading(false));
    }
  },
);
export const getCategoriesByDate = (budget, month, year, month_to = null, year_to = null) => async dispatch => {
  dispatch(setCategoriesLoading(true));
  dispatch(setCategoriesError(null));

  try {
    const allCategories = await API.get<Category[]>(`/categories/`, { params: { budget } });
    const byDateCategories = await API.get<Category[]>(`/categories/${stringifyDate(month)}/${year}/`, {
      params: { budget, month_to, year_to },
    });

    // const categoriesList = allCategories.data.reduce((acc, category) => {
    //   const categoryByDate = byDateCategories.data.find(({ id }) => id === category.id);

    //   if (categoryByDate) acc.push({ ...category, ...categoryByDate });

    //   return acc;
    // }, []);
    const categoriesList = byDateCategories?.data?.length ? byDateCategories?.data : allCategories.data;
    dispatch(setCategoriesList(categoriesList));
  } catch (err) {
    const { message } = normalizeError(err);
    dispatch(setNotification({ type: "error", message: message }));
    dispatch(clearCategoriesWithError(message));
  } finally {
    dispatch(setCategoriesLoading(false));
  }
};

export const addCategory = ({ name, saveInHistory = true, oldId = "", fromUser = true }) => async (
  dispatch,
  getState,
) => {
  dispatch(setCategoriesLoading(true));
  dispatch(setCategoriesError(null));

  const currentBudget = currentBudgetSelector(getState());
  const { month, year } = currentBudgetDateSelector(getState());

  const bodyFormData = new FormData();

  bodyFormData.append("name", name);
  if (currentBudget?.id !== undefined) {
    bodyFormData.append("budget", currentBudget.id);
  }

  try {
    const res = await API.post(`/categories/`, bodyFormData);
    await dispatch(getCategoriesByDate(currentBudget?.id, month, year));
    if (saveInHistory) {
      await dispatch(
        addRecordThunk({
          actionType: IUndoActionEnum.ADD_CATEGORY,
          id: res.data.id,
          value: {
            name,
          },
        }),
      );
    }
    if (fromUser) {
      await dispatch(resetHistory());
    }
    await dispatch(
      updateRecordsInHistory({
        id: res.data.id,
        oldId,
        type: "category",
      }),
    );

    dispatch(setNotification({ type: "success", message: "Категория добавлена успешно" }));
  } catch (err) {
    const { message } = normalizeError(err);
    dispatch(setNotification({ type: "error", message: message }));
    dispatch(setCategoriesError(message));
  } finally {
    dispatch(setCategoriesLoading(false));
  }
};

export const addCategoryThunk = createAsyncThunk<
  void,
  {
    name: string;
    saveInHistory?: boolean;
    oldId?: string;
    fromUser?: boolean;
  },
  { state: RootState }
>("ADD_CATEGORY", async ({ name, saveInHistory = true, oldId = "", fromUser = true }, thunkAPI) => {
  thunkAPI.dispatch(setCategoriesLoading(true));
  thunkAPI.dispatch(setCategoriesError(null));

  const currentBudget = currentBudgetSelector(thunkAPI.getState());
  const { month, year } = currentBudgetDateSelector(thunkAPI.getState());

  const bodyFormData = new FormData();

  bodyFormData.append("name", name);
  if (currentBudget?.id !== undefined) {
    bodyFormData.append("budget", currentBudget.id);
  }

  try {
    const res = await API.post(`/categories/`, bodyFormData);
    await thunkAPI.dispatch(getCategoriesByDate(currentBudget?.id, month, year));
    if (saveInHistory) {
      await thunkAPI.dispatch(
        addRecordThunk({
          actionType: IUndoActionEnum.ADD_CATEGORY,
          id: res.data.id,
          value: {
            name,
          },
        }),
      );
    }
    if (fromUser) {
      await thunkAPI.dispatch(resetHistory());
    }
    await thunkAPI.dispatch(
      updateRecordsInHistory({
        id: res.data.id,
        oldId,
        type: "category",
      }),
    );

    thunkAPI.dispatch(setNotification({ type: "success", message: "Категория добавлена успешно" }));
  } catch (error) {
    thunkAPI.dispatch(setNotification({ type: "error", message: (error as any)?.response?.data.error }));
    thunkAPI.dispatch(setCategoriesError((error as any)?.message));
  } finally {
    thunkAPI.dispatch(setCategoriesLoading(false));
  }
});

export const editCategoryThunk = createAsyncThunk<
  void,
  {
    id: string;
    name?: string;
    isHidden: boolean;
    saveInHistory?: boolean;
    fromUser?: boolean;
  },
  { state: RootState }
>("EDIT_CATEGORY", async ({ id: categoryId, name = "", isHidden, saveInHistory = true, fromUser = true }, thunkAPI) => {
  thunkAPI.dispatch(setCategoriesLoading(true));
  thunkAPI.dispatch(setCategoriesError(null));

  const currentBudget = currentBudgetSelector(thunkAPI.getState());
  const { month, year } = currentBudgetDateSelector(thunkAPI.getState());
  const currentCategory = categoryById(thunkAPI.getState(), categoryId);

  const bodyFormData = new FormData();

  name && bodyFormData.append("name", name);
  if (currentBudget?.id !== undefined) {
    bodyFormData.append("budget", currentBudget?.id);
  }
  bodyFormData.append("isHidden", isHidden.toString());

  try {
    await API.patch(`/categories/${categoryId}/`, bodyFormData);
    await thunkAPI.dispatch(getCategoriesByDate(currentBudget?.id, month, year));
    if (saveInHistory) {
      await thunkAPI.dispatch(
        addRecordThunk({
          actionType: IUndoActionEnum.EDIT_CATEGORY,
          id: currentCategory?.id,
          props: {
            isHidden: currentCategory?.isHidden,
            name: currentCategory?.name,
          },
          value: {
            isHidden,
            name,
          },
        }),
      );
    }
    if (fromUser) {
      await thunkAPI.dispatch(resetHistory());
    }

    thunkAPI.dispatch(setNotification({ type: "success", message: "Категория успешно изменена" }));
  } catch (error) {
    thunkAPI.dispatch(setNotification({ type: "error", message: (error as any).response.data.error }));
    thunkAPI.dispatch(setCategoriesError((error as any).response.data.error));
  } finally {
    thunkAPI.dispatch(setCategoriesLoading(false));
  }
});

export const editCategory = ({ id: categoryId, name = "", isHidden, saveInHistory = true, fromUser = true }) => async (
  dispatch,
  getState,
) => {
  dispatch(setCategoriesLoading(true));
  dispatch(setCategoriesError(null));

  const currentBudget = currentBudgetSelector(getState());
  const { month, year } = currentBudgetDateSelector(getState());
  const currentCategory = categoryById(getState(), categoryId);

  const bodyFormData = new FormData();

  name && bodyFormData.append("name", name);
  if (currentBudget?.id !== undefined) {
    bodyFormData.append("budget", currentBudget.id);
  }
  bodyFormData.append("isHidden", isHidden);

  try {
    await API.patch(`/categories/${categoryId}/`, bodyFormData);
    await dispatch(getCategoriesByDate(currentBudget?.id, month, year));
    if (saveInHistory) {
      await dispatch(
        addRecordThunk({
          actionType: IUndoActionEnum.EDIT_CATEGORY,
          id: currentCategory?.id,
          props: {
            isHidden: currentCategory?.isHidden,
            name: currentCategory?.name,
          },
          value: {
            isHidden,
            name,
          },
        }),
      );
    }
    if (fromUser) {
      await dispatch(resetHistory());
    }

    dispatch(setNotification({ type: "success", message: "Категория успешно изменена" }));
  } catch (error) {
    dispatch(setNotification({ type: "error", message: (error as any).response.data.error }));
    dispatch(setCategoriesError((error as any).response.data.error));
  } finally {
    dispatch(setCategoriesLoading(false));
  }
};

export const deleteCategoryThunk = createAsyncThunk<
  void,
  {
    categoryId: string;
    saveInHistory?: boolean;
    fromUser?: boolean;
  },
  { state: RootState }
>("DELETE_CATEGORY", async ({ categoryId, saveInHistory = true, fromUser = true }, { dispatch, getState }) => {
  const {
    budgets: {
      date: { year, month },
      currentBudget,
    },
  } = getState();
  dispatch(setCategoriesLoading(true));
  dispatch(setCategoriesError(null));
  const currentSubcategory = categoryById(getState(), categoryId);

  try {
    await API.delete(`/categories/${categoryId}/`);
    await dispatch(getCategoriesByDate(currentBudget?.id, month, year));
    if (saveInHistory) {
      await dispatch(
        addRecordThunk({
          actionType: IUndoActionEnum.DELETE_CATEGORY,
          id: currentSubcategory?.id,
          props: {
            prevValue: {
              name: currentSubcategory?.name,
            },
          },
          value: "",
        }),
      );
    }
    if (fromUser) {
      await dispatch(resetHistory());
    }
    dispatch(setNotification({ type: "success", message: "Категория успешно удалена" }));
  } catch (error) {
    dispatch(setNotification({ type: "error", message: "Ошибка удаления категории" }));
    dispatch(clearCategoriesWithError((error as any).message));
  } finally {
    dispatch(setCategoriesLoading(false));
  }
});
export const deleteMultipleCategoriesThunk = createAsyncThunk<
  void,
  {
    categoryIds: string[];
    saveInHistory?: boolean;
    fromUser?: boolean;
  },
  { state: RootState }
>("DELETE_CATEGORY", async ({ categoryIds, saveInHistory = true, fromUser = true }, { dispatch, getState }) => {
  const {
    budgets: {
      date: { year, month },
      currentBudget,
    },
  } = getState();
  dispatch(setCategoriesLoading(true));
  dispatch(setCategoriesError(null));

  try {
    await API.post(`/categories-multiple/`, { ids: categoryIds });
    await dispatch(getCategoriesByDate(currentBudget?.id, month, year));
    if (fromUser) {
      await dispatch(resetHistory());
    }
    dispatch(setNotification({ type: "success", message: "Категории успешно удалены" }));
  } catch (error) {
    dispatch(setNotification({ type: "error", message: "Ошибка удаления категорий" }));
    dispatch(clearCategoriesWithError((error as any).message));
  } finally {
    dispatch(setCategoriesLoading(false));
  }
});
export const deleteCategory = ({ categoryId, saveInHistory = true, fromUser = true }) => async (dispatch, getState) => {
  const {
    budgets: {
      date: { year, month },
      currentBudget: { id },
    },
  } = getState();
  dispatch(setCategoriesLoading(true));
  dispatch(setCategoriesError(null));
  const currentSubcategory = categoryById(getState(), categoryId);

  try {
    await API.delete(`/categories/${categoryId}/`);
    await dispatch(getCategoriesByDate(id, month, year));
    if (saveInHistory) {
      await dispatch(
        addRecordThunk({
          actionType: IUndoActionEnum.DELETE_CATEGORY,
          id: currentSubcategory?.id,
          props: {
            prevValue: {
              name: currentSubcategory?.name,
            },
          },
          value: "",
        }),
      );
    }
    if (fromUser) {
      await dispatch(resetHistory());
    }
    dispatch(setNotification({ type: "success", message: "Категория успешно удалена" }));
  } catch (error) {
    dispatch(setNotification({ type: "error", message: "Ошибка удаления категории" }));
    dispatch(clearCategoriesWithError((error as any).message));
  } finally {
    dispatch(setCategoriesLoading(false));
  }
};
