import { Loading } from "@common/components"
import { lazyLoad } from "@common/utils"

export const AccountsPageAsync = lazyLoad(
  () => import("./AccountsPage"),
  module => module.AccountsPage,
  {
    fallback: <Loading />,
  },
)
