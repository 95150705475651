import CheckedIcon from "@assets/icons/checked_icon.svg";
import UncheckedIcon from "@assets/icons/unchecked_icon_16.svg";
import { stopPropagation } from "@common/utils";
import { Checkbox as MuiCheckbox } from "@material-ui/core";
import { SwitchBaseProps } from "@material-ui/core/internal/SwitchBase";
import styled from "styled-components";
import { generateUniqueID } from "web-vitals/dist/lib/generateUniqueID";

interface Props {
  label?: string;
  checked?: boolean;
  onClick?: () => void;
  onChange: SwitchBaseProps["onChange"];
  small?: boolean;
  classNames?: string;
}

export function Checkbox(props: Props) {
  const { classNames, label, checked, onChange, onClick, small } = props;

  const id = generateUniqueID();
  return (
    <CheckboxLabel htmlFor={id} className={classNames}>
      <StyledCheckbox
        checked={checked}
        onChange={onChange}
        onClick={stopPropagation(onClick)}
        id={id}
        name={id}
        icon={<UncheckedIcon />}
        checkedIcon={<CheckedIcon />}
        small={small}
      />
      {label !== undefined && <CheckboxText>{label}</CheckboxText>}
    </CheckboxLabel>
  );
}

const StyledCheckbox = styled(MuiCheckbox)<{ small?: boolean }>`
  &&.Mui-checked {
    color: ${p => p.theme.colors.main};
  }

  &.MuiCheckbox-root {
    color: ${p => p.theme.colors.placeholder};
    padding: 0;
    width: 20px;
    min-width: 20px;
    height: 20px;

    @media (max-width: 1420px) {
      width: 16px;
      min-width: 16px;
      height: 16px;
    }

    input {
      width: 20px;
      min-width: 20px;
      height: 20px;

      @media (max-width: 1420px) {
        width: 16px;
        min-width: 16px;
        height: 16px;
      }
    }

    &.PrivateSwitchBase-input-4 {
      width: 20px;
      min-width: 20px;

      ${p =>
        p?.small &&
        `
        width: 16px;
        min-width: 16px;
      `}
    }

    &.PrivateSwitchBase-input-8 {
      width: 20px;
      min-width: 20px;
      height: 20px;

      ${p =>
        p?.small &&
        `
        width: 16px;
        min-width: 16px;
        height: 16px;
      `}
    }

    &.PrivateSwitchBase-input-5 {
      padding: 0;
    }
  }

  &.MuiCheckbox-colorSecondary.Mui-checked:hover,
  &.MuiIconButton-colorSecondary:hover {
    background-color: transparent;
  }
`;

export const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  width: fit-content;

  .PrivateSwitchBase-root-1 {
    padding: 0;
  }
`;
export const CheckboxText = styled.span`
  margin-left: 8px;
  font-family: Manrope, Inter, sans-serif;
  color: ${p => p.theme.colors.placeholder};
`;
