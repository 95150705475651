import React from 'react'
import { Icon } from "../dropdown/DropdownStyles"
import { ComponentProps } from '../../routes'
import { CommonListItem, ExternalLink } from "./styles"
import styled from "styled-components"

export const NewRouteItem = ({ href, icon, title, onClick, active }: ComponentProps) => {

  return (
    <CommonListItem>
      <ExternalLink href={href} $active={active} onClick={onClick}>
        <StyledIcon><Icon>{icon}</Icon></StyledIcon>
        <span>{title}</span>
      </ExternalLink>
    </CommonListItem>
  );
};
const StyledIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center; 
    
  svg {
    width: 24px;
    height: 24px;
  }  
`
