export const monthsTitles = {
  '1': 'Январь',
  '2': 'Февраль',
  '3': 'Март',
  '4': 'Апрель',
  '5': 'Май',
  '6': 'Июнь',
  '7': 'Июль',
  '8': 'Август',
  '9': 'Сентябрь',
  '10': 'Октябрь',
  '11': 'Ноябрь',
  '12': 'Декабрь',
};

export const monthsTitleFrom = {
  '1': 'Января',
  '2': 'Февраля',
  '3': 'Марта',
  '4': 'Апреля',
  '5': 'Майя',
  '6': 'Июня',
  '7': 'Июля',
  '8': 'Августа',
  '9': 'Сентября',
  '10': 'Октября',
  '11': 'Ноября',
  '12': 'Декабря',
}

type FormatMode = 'regular' | 'short';

export function formatDate(year: number | null, month: number | null, mode: FormatMode = 'regular'): string {
  const monthTitle = monthsTitles[month || 1];
  const hasYear = year ? year : '';

  return mode === 'short' ? `${monthTitle.slice(0, 3)} ${hasYear}` : `${monthTitle} ${hasYear}`;
}

export function formatMonth(month: number | null): string {
  return monthsTitles[month || 1];
}

export function formatMonthFrom(month: number | null): string {
  return monthsTitleFrom[month || 1];
}


export function getIntervalTitle(startDate, endDate): string {
  if (endDate.month) {
    const start = formatDate(startDate.year, startDate.month, 'short');
    const end = formatDate(endDate.year, endDate.month, 'short');

    return `${start} - ${end}`;
  }

  return formatDate(startDate.year, startDate.month);
}

export function stringifyDate(monthOrDay: number): string {
  return monthOrDay < 10 ? `0${monthOrDay}` : monthOrDay.toString();
}

export const getHumanReadableMonth = (date: Date) => date.getMonth() + 1;


