import { CREDIT } from "@assets/index";
import { useAsyncDispatch, useTypedSelector, useUnwrapAsyncDispatch } from "@common/hooks";
import { createContractorThunk, getContractorsThunk } from "@common/services";
import {
  addPeriodicTypes,
  contractorsList,
  currentBudgetSelector,
  getCategoriesThunk,
  getPeriodicTypesThunk,
  getSubcategoriesByBudgetThunk,
  newContractorSelector,
  selectCategoriesOptions,
  selectPeriodicTypesOptions,
  setNewContractor,
} from "@common/store";
import ru from "date-fns/locale/ru";
import arrayMutators from "final-form-arrays";
import { ReactNode, useEffect, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Field, Form as FinalForm, FormRenderProps } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import { useDispatch, useSelector } from "react-redux";
import { ModalForm } from "../../Modals";
import { SplitFormRow } from "./SplitFormRow";
import { ContractorOptionsType, getContractorOptions, OptionType } from "./helpers";
import {
  Box,
  Button,
  Calendar,
  Cancel,
  Cell,
  CreatableSelect,
  Select,
  Submit,
  Text,
  TextField,
  Wrapper,
} from "./styles";

registerLocale("ru", ru);

let counter: number = 0;
const keys: number[] = [];

export const EditForm = ({ onClose, onEdit, initialValues }) => {
  const [open, setOpen] = useState(false);
  const [addedContractor, setAddedContractor] = useState<OptionType | null>(null);
  const [contractorOptions, setContractorOptions] = useState<ContractorOptionsType[]>([]);

  const categoryOptions = useSelector(selectCategoriesOptions);
  const periodicOptions = useSelector(selectPeriodicTypesOptions);
  const contractors = useSelector(contractorsList);
  const currentBudget = useSelector(currentBudgetSelector);
  const newContractor = useSelector(newContractorSelector);

  const currentAccount = useTypedSelector(state => state.accounts.currentAccount);
  const currentAccountName = currentAccount?.name;
  const currentAccountId = currentAccount?.id;

  const dispatch = useDispatch();
  const asyncDispatch = useAsyncDispatch();
  const asyncUnwrapDispatch = useUnwrapAsyncDispatch();

  useEffect(() => {
    setContractorOptions(getContractorOptions({ contractors, currentAccountId }));
  }, [contractors, currentAccountId]);

  useEffect(() => {
    if (!newContractor) {
      return setAddedContractor(null);
    }

    if (contractorOptions.length) {
      setAddedContractor(contractorOptions[0].options.find(option => option.value === newContractor.id) || null);
      dispatch(setNewContractor(null));
    }
  }, [newContractor, contractorOptions, dispatch]);

  useEffect(() => {
    if (currentBudget?.id !== undefined) {
      asyncDispatch(getCategoriesThunk({ budget: currentBudget.id, isSystem: true }));
      asyncDispatch(getSubcategoriesByBudgetThunk({ budgetId: currentBudget.id }));
      asyncDispatch(getContractorsThunk({ budget: currentBudget.id }));
    }
    asyncUnwrapDispatch(getPeriodicTypesThunk).then(data => dispatch(addPeriodicTypes(data)));
  }, [dispatch, asyncDispatch, asyncUnwrapDispatch, currentBudget]);

  const handleCreateContactor = (inputValue: any) => {
    if (currentBudget?.id === undefined) return;
    asyncDispatch(
      createContractorThunk({
        name: inputValue,
        budget: currentBudget.id,
        templates: [],
      }),
    );
  };

  const handleCreateSubcategory = () => setOpen(true);

  const onEnterPress = event => {
    if (event.key === "Enter") {
      event.preventDefault();

      const form = event.target.form;
      const inputElemnts = [...form].filter(el => el.id);
      const index = inputElemnts.indexOf(event.target);
      const nextInput = inputElemnts[index + 1]?.disabled ? inputElemnts[index + 2] : inputElemnts[index + 1];
      nextInput && nextInput.focus();
    }
  };

  const validate = values => {
    const errors: any = {};

    if (!values.outgoing && !values.incoming) {
      errors.outgoing = "Required";
      errors.incoming = "Required";
    }

    return errors;
  };

  const renderForm = ({
    handleSubmit,
    submitting,
    valid,
    values,

    form: {
      mutators: { push },
    },
  }) => {
    const disabled = submitting || !valid;
    const disabledSubcategory = contractors.some(
      el => el.contractorType === "Переводы" && values.contractor?.label === el.name,
    );

    const isTransfer = contractors.some(el => el.contractorType === "Переводы" && values.contractor?.label === el.name);

    if (addedContractor) {
      values["contractor"] = addedContractor;
    }

    const transactionType: "outgoing" | "incoming" | null = (() => {
      if (!!values.incoming || values.splitTransactions?.some(trc => !!trc?.incoming)) {
        return "incoming";
      } else if (!!values.outgoing || values.splitTransactions?.some(trc => !!trc?.outgoing)) {
        return "outgoing";
      }

      return null;
    })();

    return (
      <>
        <form onSubmit={handleSubmit} onKeyDown={onEnterPress} id="transactions-form">
          <Wrapper>
            <Cell>
              <Field name="date" validate={value => !value && "Required"}>
                {({ input: { value, onChange, name } }) => (
                  <Calendar
                    id={name}
                    selected={value}
                    onChange={onChange}
                    dateFormat="dd.MM.yyyy"
                    name={name}
                    autoComplete="off"
                    locale="ru"
                    value={value}
                    showPopperArrow={false}
                  />
                )}
              </Field>
            </Cell>
            <Cell>
              <Field name="periodicity" validate={value => !value && "Required"}>
                {({ input: { value, onChange, name } }) => (
                  <Select
                    id={name}
                    name={name}
                    value={value}
                    onChange={onChange}
                    options={periodicOptions}
                    classNamePrefix="select"
                    placeholder="Повтор"
                    className="select"
                    // components={{ DropdownIndicator: BottomIcon }}
                  />
                )}
              </Field>
            </Cell>
            <Cell>
              <Field name="contractor">
                {({ input: { value, onChange, name } }) => (
                  <>
                    {currentAccountName?.includes(CREDIT) ? (
                      <Select
                        id={name}
                        name={name}
                        value={value}
                        onChange={onChange}
                        options={[contractorOptions[1]]}
                        classNamePrefix="select"
                        placeholder="Контрагент"
                        className="select"
                        isSearchable
                        // components={{ DropdownIndicator: DropdownIndicator(BottomIcon) }}
                      />
                    ) : (
                      <CreatableSelect
                        id={name}
                        name={name}
                        value={value}
                        onChange={onChange}
                        options={contractorOptions}
                        classNamePrefix="select"
                        placeholder="Контрагент"
                        className="select"
                        isSearchable
                        onCreateOption={handleCreateContactor}
                        formatCreateLabel={(text: string) => <Text>Создать «{text}»</Text>}
                        // components={{ DropdownIndicator: DropdownIndicator(BottomIcon) }}
                        $error={false}
                      />
                    )}
                  </>
                )}
              </Field>
            </Cell>
            <Cell>
              <Field name="subcategory" validate={value => (disabledSubcategory ? undefined : !value && "Required")}>
                {({ input: { value, onChange, name } }) => (
                  <CreatableSelect
                    id={name}
                    name={name}
                    value={value}
                    onChange={e => {
                      onChange(e);
                      setContractorOptions(
                        getContractorOptions({ contractors, currentAccountId, currentSubcategoryId: (e as any).value }),
                      );
                    }}
                    options={categoryOptions}
                    classNamePrefix="select"
                    placeholder="Подкатегория"
                    className="select"
                    isSearchable
                    onCreateOption={handleCreateSubcategory}
                    formatCreateLabel={(text: string) => <Text>Создать «{text}»</Text>}
                    // components={{ DropdownIndicator: DropdownIndicator(BottomIcon) }}
                    isDisabled={disabledSubcategory}
                    $error={false}
                  />
                )}
              </Field>
            </Cell>
            <Cell>
              <Field name="comment">
                {({ input: { value, onChange, name }, meta: { error, pristine } }) => (
                  <TextField
                    name={name}
                    value={value}
                    onChange={onChange}
                    id="comment"
                    label="Описание"
                    type="text"
                    autoComplete="off"
                    error={!!error && !pristine}
                  />
                )}
              </Field>
            </Cell>
            <Cell>
              {/*<Field name="outgoing">*/}
              {/*  {({ input: { value, onChange, name } }) => (*/}
              {/*    <CalculatorInput name={name} value={value} onChange={onChange} disabled={!!values.incoming}/>*/}
              {/*  )}*/}
              {/*</Field>*/}
            </Cell>
            <Cell>
              {/*<Field name="incoming">*/}
              {/*  {({ input: { value, onChange, name } }) => (*/}
              {/*    <CalculatorInput name={name} value={value} onChange={onChange} disabled={!!values.outgoing}/>*/}
              {/*  )}*/}
              {/*</Field>*/}
            </Cell>
          </Wrapper>
          <FieldArray name="splitTransactions">
            {({ fields }) =>
              fields.map((name, index) => {
                return (
                  <SplitFormRow
                    key={keys[index] || name}
                    index={index}
                    fields={fields}
                    remove={() => queueMicrotask(() => keys.splice(index, 1))}
                    handleCreateSubcategory={handleCreateSubcategory}
                    values={values}
                    name={name}
                    {...{
                      transactionType,
                      setContractorOptions,
                      contractors,
                      currentAccountId,
                      isTransfer,
                      categoryOptions,
                    }}
                  />
                );
              })
            }
          </FieldArray>

          <Box>
            <Button
              onClick={() => {
                push("splitTransactions", undefined);
                queueMicrotask(() => keys.push(counter++));
              }}
            >
              Добавить разделение +
            </Button>
            <Submit type="submit" disabled={disabled} id="form-btn-save">
              Сохранить
            </Submit>
            <Cancel onClick={() => onClose()} id="form-btn-cancel">
              Отмена
            </Cancel>
          </Box>
        </form>
        <ModalForm open={open} setOpen={setOpen} mode={"createSubcategory"} />
      </>
    );
  };
  return (
    <FinalForm
      onSubmit={onEdit}
      validate={validate}
      mutators={{
        ...arrayMutators,
      }}
      render={(renderForm as unknown) as (props: FormRenderProps<any, any>) => ReactNode}
      initialValues={initialValues}
    />
  );
};
