import { CacheAccount } from "@common/models";
import { type RootState, setAccountsList } from "@common/store";
import { BudgetTransport } from "@common/transports";
import { normalizeError, reorder } from "@common/utils";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { DropResult } from "react-beautiful-dnd";
import { API, setLayoutAccountsList } from "..";

export const LayoutService = {
  loadAccounts: createAsyncThunk<void, void>("LayoutLoadAccounts", async (_, { dispatch, rejectWithValue }) => {
    try {
      const { data } = await API.get<CacheAccount[]>(`/accounts/`);
      dispatch(setLayoutAccountsList(data));
    } catch (err) {
      const { message } = normalizeError(err);
      return rejectWithValue(message);
    }
  }),

  sortOrderCacheAccounts: createAsyncThunk<
    void,
    {
      result: DropResult
    },
    { state: RootState }
  >("LayoutSortOrderCacheAccounts", async ({ result }, { dispatch, rejectWithValue, getState }) => {
    if (!result.destination) {
      return;
    }

    const accounts = getState().layout.accountsList;
    const accountId = result.draggableId;
    const destination = result.destination.index;
    const source = result.source.index;
    const afterAccountId = accounts[result.destination.index - 1].id;

    try {
      const sortedAccounts = reorder(accounts, source, destination);
      dispatch(setAccountsList(sortedAccounts));

      await API.post<void>("/sort-order/cache_account", {
        cache_account_id: accountId,
        after_id: afterAccountId,
      })
        .then(() => dispatch(setAccountsList(accounts)));

    } catch (err) {
      const { message } = normalizeError(err);
      return rejectWithValue(message);
    }
  }),

  createBudget: createAsyncThunk<
    void,
    {
      name: string
    },
    { state: RootState }
  >("LayoutCreateBudget", async ({ name }, { dispatch, rejectWithValue, getState }) => {
    try {
      await BudgetTransport.createBudget(name);
    } catch (err) {
      const { message } = normalizeError(err);
      return rejectWithValue(message);
    }
  }),
};
