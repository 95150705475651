import { AnyAction, Reducer } from "redux";
import {
  IUndoActionEnum,
  goBackInHistory,
  goForwardInHistory,
  resetFutureHistory,
  setRecord,
  updateRecords,
} from "../actions/actionsHistory";
import { findAndReplaceId } from "../helpers";

export type Content = any;

interface Record {
  actionType: IUndoActionEnum;
  id?: string;
  props?: {
    prevValue?: any;
    isHidden?: any;
    name?: any;
    category?: any;
  };
  value?: any;
}

interface IState {
  past: Record[];
  present: Record[];
  future: Record[];
}

const initialState = {
  past: [],
  present: [],
  future: [],
};

export const actionsHistoryReducer: Reducer = (state: IState = initialState, action: AnyAction) => {
  const { type, payload } = action;
  let futureTmp = [...state.future];
  let pastTmp = [...state.past];
  let presentTmp = [...state.present];

  switch (type) {
    case setRecord.toString():
      return { ...state, past: [...state.past, ...state.present], present: [payload] };
    case resetFutureHistory.toString():
      return { ...state, future: [] };
    case updateRecords.toString():
      return {
        ...state,
        future: findAndReplaceId(futureTmp, payload.oldId, payload.id, payload?.type),
        present: findAndReplaceId(presentTmp, payload.oldId, payload.id, payload?.type),
        past: findAndReplaceId(pastTmp, payload.oldId, payload.id, payload?.type),
      };
    case goBackInHistory.toString():
      futureTmp.unshift(...state.present);
      pastTmp.pop();
      if (state.past.length > 0) {
        presentTmp = [state.past[state.past.length - 1]];
      } else {
        presentTmp = [];
      }

      return { ...state, future: futureTmp, present: presentTmp, past: pastTmp };
    case goForwardInHistory.toString():
      futureTmp = [...state.future];
      pastTmp = [...state.past];
      presentTmp = [...state.present];
      futureTmp.shift();

      return { ...state, future: futureTmp, past: pastTmp, present: presentTmp };
    default:
      return state;
  }
};
