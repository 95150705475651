import { Loading } from "@common/components"
import { lazyLoad } from "@common/utils"

export const ManagersRequestsPage = lazyLoad(
  () => import("./index"),
  module => module.ManagersRequestsPage,
  {
    fallback: <Loading />,
  },
)
