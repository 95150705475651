import * as React from "react";
import { NavSubtitle } from "../../styles";
import { StyledMenuList } from "./styles";
import { ClientsMenuList } from "./ClientsMenuList";
import { StaffMenuList } from "./StaffMenuList";
import { ReactElement } from "react";

export function MenuList(): ReactElement {
  return (
    <>
      <NavSubtitle>Меню</NavSubtitle>
      <StyledMenuList>
        <ClientsMenuList />
        <StaffMenuList />
      </StyledMenuList>
    </>
  );
}
