import { useAsyncDispatch } from "@common/hooks"
import { createPassword, settingsUserErrorPasswordSelector, updateEmailFailureAction, userData } from "@common/store"
import { FC } from "react"
import { useSelector } from "react-redux"
import { ModalPassword } from "../ModalPassword/ModalPassword"

export const FirstSocialAuthModal: FC = () => {
  const asyncDispatch = useAsyncDispatch()
  const user = useSelector(userData)

  const isAutogeneratedPassword = Boolean(user?.isAutogeneratedPassword)
  const passwordError = useSelector(settingsUserErrorPasswordSelector)
  const resetEmailFailure = () => {
    asyncDispatch(updateEmailFailureAction({}))
  }

  const savePassword = password => {
    asyncDispatch(createPassword({ password }))
  }

  return (
    <ModalPassword
      isOpen={isAutogeneratedPassword}
      passwordError={passwordError}
      resetEmailFailure={resetEmailFailure}
      onSubmit={savePassword}
      note="Для дальнейшего пользования необходимо задать пароль"
    />
  )
}
