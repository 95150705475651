import { UniqueIdentifier } from "@dnd-kit/core"
import { arrayMove } from "@dnd-kit/sortable"
import { getAvailable } from "./getAvailable"
import { FlattenedItem } from "./types"

export function getTreeItemMaxDepth<T>(
  items: Array<FlattenedItem<T>>,
  activeIds: Array<UniqueIdentifier>,
  overItemIndex: number,
  maxDeep?: number,
): number {
  const activeItemIndex: number = items.findIndex(({ id }) => activeIds.includes(id))
  const newItems: Array<FlattenedItem<T>> = arrayMove(items, activeItemIndex, overItemIndex)
  const available: number = getAvailable(items, activeIds, maxDeep)
  const previousItem: FlattenedItem<T> = newItems[overItemIndex - 1]
  const isFirstItem: boolean = previousItem === undefined
  const previousItemDepthAvailable: number = previousItem?.depth !== undefined ? available - previousItem.depth - 1 : 0

  if (isFirstItem) {
    return 0
  }
  if (previousItemDepthAvailable < 0) {
    return available
  }
  return available - previousItemDepthAvailable
}
