import { createSlice, PayloadAction, Slice } from "@reduxjs/toolkit";

export type NotificationType = 'success' | 'error' | 'info';

export interface PopupState {
  type: NotificationType;
  message: string | null;
}

const INIT_STATE: PopupState = {
  type: 'success',
  message: null,
};

const popupSlice: Slice<PopupState> = createSlice({
  name: "popup",
  initialState: INIT_STATE,
  reducers: {
    setNotification(state, action: PayloadAction<PopupState>) {
      state.type = action.payload.type
      state.message = action.payload.message
    },
  },
})

export const { setNotification } = popupSlice.actions
export const popupReducer = popupSlice.reducer
