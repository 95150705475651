import { Loading } from "@common/components"
import { lazyLoad } from "@common/utils"

export const ChooseBudgetPage = lazyLoad(
  () => import("./index"),
  module => module.ChooseBudgetPage,
  {
    fallback: <Loading />,
  },
)
