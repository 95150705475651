import { validateEmail, validatePassword } from "@common/utils"
import { IconButton, InputAdornment } from "@material-ui/core"
import { Visibility, VisibilityOff } from "@material-ui/icons"
import { ReactElement, useState } from "react";
import { Field, FormRenderProps } from "react-final-form"
import { DocumentLink, Form, Policy, Submit, TextField } from "../common/styles"

type SignUpFormType = {
  email: string
  password: string
  confirm: string
}

export function SignUpForm(props: FormRenderProps<SignUpFormType>): ReactElement {
  const { handleSubmit, submitting, valid } = props

  const [fieldType, setFieldType] = useState<"password" | "text">("password")

  const disabled = submitting || !valid

  return (
    <Form onSubmit={handleSubmit}>
      <Field name="email" validate={validateEmail}>
        {({ input, meta }) => (
          <TextField
            name={input.name}
            value={input.value}
            onChange={input.onChange}
            id="email"
            label="E-mail"
            type="email"
            autoComplete="current-email"
            error={!!meta.error && !meta.pristine}
            helperText={meta.pristine ? "" : meta.error}
          />
        )}
      </Field>
      <Field name="password" validate={validatePassword}>
        {({ input, meta }) => (
          <TextField
            name={input.name}
            value={input.value}
            onChange={input.onChange}
            id="password"
            label="Пароль"
            type={fieldType}
            autoComplete="current-password"
            error={!!meta.error && !meta.pristine}
            helperText={meta.pristine ? "" : meta.error}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    edge="end"
                    onClick={() => setFieldType(prevState => (prevState === "password" ? "text" : "password"))}
                  >
                    {fieldType === "text" ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}
      </Field>
      <Field name="confirm" validate={validatePassword}>
        {({ input, meta }) => (
          <TextField
            name={input.name}
            value={input.value}
            onChange={input.onChange}
            id="confirm"
            label="Повторите пароль"
            type={fieldType}
            error={!!meta.error && !meta.pristine}
            helperText={meta.pristine ? "" : meta.error}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    edge="end"
                    onClick={() => setFieldType(prevState => (prevState === "password" ? "text" : "password"))}
                  >
                    {fieldType === "text" ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}
      </Field>
      <Policy>
        {"Создавая аккаунт, вы принимаете "}
        <DocumentLink href="https://store.savinger.ru/landing/privacy_policy.pdf" target="__blanc">
          {"Политику конфиденциальности "}
        </DocumentLink>
        {"и "}
        <DocumentLink href="https://store.savinger.ru/landing/terms_of_use.pdf" target="__blanc">
          {"Пользовательское соглашение"}
        </DocumentLink>
      </Policy>
      <Submit type="submit" disabled={disabled}>
        Регистрация
      </Submit>
    </Form>
  )
}
