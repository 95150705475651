import styled from 'styled-components'
import { CSVLink as Link } from 'react-csv'

export const ExportLink = styled(Link)`
  display: flex;
  text-decoration: none;
  color: ${p => p.theme.colors.main};
  margin-right: 24px;
  align-items: center;
`;
