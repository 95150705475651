import styled, { DefaultTheme } from 'styled-components';

import { ButtonProps, Variant } from './Button';

const getColorByVariant = (variant: Variant, { colors }: DefaultTheme): string | undefined =>
  variant && { primary: colors.white, secondary: colors.main }[variant];

const getBackgroundByVariant = (variant: Variant, { colors }: DefaultTheme): string | undefined =>
  variant && { primary: colors.main, secondary: colors.light }[variant];

export const Source = styled.button<ButtonProps>`
  box-sizing: border-box;
  padding: 12px 16px;
  border-radius: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  border: none;
  cursor: pointer;
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
  color: ${({ theme, variant }) => getColorByVariant(variant, theme)};
  background-color: ${({ theme, variant }) => getBackgroundByVariant(variant, theme)};
`;
