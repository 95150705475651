import CalculatorIcon from "@assets/icons/calculator.svg";
import NewBudgetIcon from "@assets/icons/newbudget.svg";
import RefreshIcon from "@assets/icons/refresh.svg";
import { CreateBudgetModal } from "@budget/view/ChooseBudget/Modals";
import { useAsyncDispatch, useFlag, useTypedSelector } from "@common/hooks";
import { getOnlyBudgetsListThunk, LayoutService } from "@common/services";
import { currentBudgetSelector, NAV_BAR_MENU, setActiveMenu } from "@common/store";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { Dropdown } from "../dropdown/Dropdown";
import { MenuListItem } from "./styles";
import { ReactElement } from "react";

const regex = /^\/budgets\/$/;

export function BudgetComponent(): ReactElement {
  const dispatch = useDispatch();
  const location = useLocation();
  const pathname = location.pathname;
  const disabled = regex.test(pathname);
  const [open, turnOn, close] = useFlag(false);
  const currentBudget = useSelector(currentBudgetSelector);
  const asyncDispatch = useAsyncDispatch();

  const activeMenu = useTypedSelector(state => state.system.activeMenu);
  const isActiveBudget = activeMenu === NAV_BAR_MENU.BUDGET;

  function onCreateBudget({ name }: { name: string }): void {
    asyncDispatch(LayoutService.createBudget({ name: name }))
      .then(() => asyncDispatch(getOnlyBudgetsListThunk))
      .then(close);
  }

  const openBudget = () => dispatch(setActiveMenu(isActiveBudget ? NAV_BAR_MENU.NOT_SELECTED : NAV_BAR_MENU.BUDGET));

  return (
    <MenuListItem>
      <Dropdown
        active={isActiveBudget}
        mainItem={{
          title: currentBudget?.name ?? "Бюджет",
          Icon: (
            <StyledIcon>
              <CalculatorIcon />
            </StyledIcon>
          ),
          disabled: disabled,
          link: "/",
          id: undefined,
          onClick: openBudget,
        }}
        common
        items={[
          {
            title: "Сменить бюджет",
            Icon: (
              <StyledIcon>
                <RefreshIcon />
              </StyledIcon>
            ),
            link: "/budgets/",
            id: undefined,
          },
          {
            title: "Новый бюджет",
            Icon: (
              <StyledIcon>
                <NewBudgetIcon />
              </StyledIcon>
            ),
            onClick: turnOn,
            id: undefined,
          },
        ]}
      />
      <CreateBudgetModal open={open} close={close} onSubmit={onCreateBudget} onCancel={close} />
    </MenuListItem>
  );
}

const StyledIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 24px;
    height: 24px;
  }
`;
