import { DragDropContext, Draggable, Droppable, OnDragEndResponder } from "react-beautiful-dnd"
import { ReactElement } from "react";

interface ItemWithId {
  id: string | undefined
}

interface SortableListProps<T extends ItemWithId> {
  list: T[]
  onDragEnd: OnDragEndResponder
  render: (item: T, index?: number) => ReactElement
}

export function SortableList<T extends ItemWithId>(props: SortableListProps<T>) {
  const { list, onDragEnd, render } = props

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {provided => (
          <div ref={provided.innerRef}>
            {list.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id ?? ""} index={index}>
                {provided => (
                  <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                    {render(item, index)}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  )
}
