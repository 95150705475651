import authService from "@auth/service/auth-service";
import { useAsyncDispatch } from "@common/hooks";
import { validateEmail } from "@common/utils";
import { Field, Form as FinalForm, FormRenderProps } from "react-final-form";
import {
  ChangePasswordText,
  ChangePasswordTitle,
  Container,
  Form,
  Link,
  Submit,
  TextField,
  Title,
  Wrapper,
} from "../common/styles";

export const ChangePassword = () => {
  const asyncDispatch = useAsyncDispatch()

  const onSubmit = ({ email }) => asyncDispatch(authService.sendLinkChangePassword({ email }))

  return (
    <Wrapper>
      <Container>
        <Title>Смена пароля</Title>
        <ChangePasswordTitle>
          Укажите адрес электронной почты, на который будут отправлены указания по изменению пароля
        </ChangePasswordTitle>
        <FinalForm onSubmit={onSubmit} render={renderForm} />
        <ChangePasswordText>
          Или вернитесь к странице <Link to="/auth/login">Входа</Link>
        </ChangePasswordText>
      </Container>
    </Wrapper>
  )
}

function renderForm({ handleSubmit, submitting, valid }: FormRenderProps<{ email: string }>) {
  const disabled = submitting || !valid

  return (
    <Form onSubmit={handleSubmit} style={{ width: "100%" }}>
      <Field name="email" validate={validateEmail}>
        {({ input, meta }) => (
          <TextField
            name={input.name}
            value={input.value}
            onChange={input.onChange}
            id="email"
            label="E-mail"
            type="email"
            autoComplete="current-email"
            error={!!meta.error && !meta.pristine}
            helperText={meta.pristine ? "" : meta.error}
          />
        )}
      </Field>
      <Submit type="submit" disabled={disabled}>
        Изменить
      </Submit>
    </Form>
  )
}
