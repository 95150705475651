import type { IMailing } from "@common/types"
import { createSlice, PayloadAction, Slice, SliceCaseReducers } from "@reduxjs/toolkit"

const initialState: StateTypes = {
  loading: false,
  error: null,
  list: [],
}

interface StateTypes {
  list: Array<IMailing>
  loading: boolean
  error: string | null
}

interface MailingsActions extends SliceCaseReducers<StateTypes> {
  setMailingsSingle: (state: StateTypes, action: PayloadAction<IMailing>) => void
  setMailingsList: (state: StateTypes, action: PayloadAction<IMailing[]>) => void
  setMailingsLoading: (state: StateTypes, action: PayloadAction<boolean>) => void
  setMailingsError: (state: StateTypes, action: PayloadAction<string | null>) => void
}

const mailingsSlice: Slice<StateTypes, MailingsActions, "mailings"> = createSlice({
  name: "mailings",
  initialState: initialState,
  reducers: {
    setMailingsSingle: (state: StateTypes, action: PayloadAction<IMailing>) => {
      state.list = state.list.map(el => {
        if (el.id === action.payload.id) {
          return action.payload
        }
        return el
      })
    },
    setMailingsList: (state: StateTypes, action: PayloadAction<IMailing[]>) => {
      state.list = action.payload.sort((a, b) => (a.id as any) - (b.id as any))
    },
    setMailingsLoading: (state: StateTypes, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    setMailingsError: (state: StateTypes, action: PayloadAction<string | null>) => {
      state.error = action.payload
    },
  },
  extraReducers: {},
})

export const { setMailingsList, setMailingsLoading, setMailingsError, setMailingsSingle } = mailingsSlice.actions

export const mailingsReducer = mailingsSlice.reducer
