import { setContractorsError, setContractorsList, setContractorsLoading, setNotification, type Transaction, type TransactionShortView } from "@common/store"
import { ContractorTransport, TransactionTransport } from "@common/transports"
import { normalizeError } from "@common/utils"
import { createAsyncThunk } from "@reduxjs/toolkit"

export const getTransactionsThunk = createAsyncThunk<void, { budget: string }>(
  "GET_CONTRACTORS",
  async ({ budget }, { dispatch }) => {
    dispatch(setContractorsLoading(true))
    try {
      const contractors = await ContractorTransport.getContractors(budget)
      dispatch(setContractorsList(contractors))
    } catch (err) {
      const error = normalizeError(err)
      dispatch(setNotification({ type: "error", message: error.message }))
      dispatch(setContractorsError(error.message))
    } finally {
      dispatch(setContractorsLoading(false))
    }
  },
)

export const createTransactionThunk = createAsyncThunk<Transaction | undefined, TransactionShortView>(
  "CREATE_TRANSACTION",
  async (dto, { dispatch }) => {
    dispatch(setContractorsLoading(true))
    try {
      return await TransactionTransport.create(dto)
    } catch (err) {
      const error = normalizeError(err)
      dispatch(setNotification({ type: "error", message: error.message }))
      dispatch(setContractorsError(error.message))
    } finally {
      dispatch(setContractorsLoading(false))
    }
  },
)
