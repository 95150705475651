import { Budget, BudgetByDate, CurrentBudget } from "@common/models";
import { createSlice, PayloadAction, Slice, SliceCaseReducers } from "@reduxjs/toolkit";
import { getHumanReadableMonth } from "../../utils";

export interface CreatedBudget extends Budget {
  available: number;
}

export interface DateType {
  day: number | null;
  month: number | null;
  year: number | null;
}

interface BudgetsState {
  items: Budget[];
  currentBudget: CurrentBudget | undefined;
  isBudgetsListEmpty: boolean | null;
  budgetByDate: BudgetByDate;
  date: DateType;
  loading: boolean;
  error: string | null;
}

const date = new Date();
const defaultDate = {
  day: date.getDate(),
  month: getHumanReadableMonth(date),
  year: date.getFullYear(),
};

const initialState: BudgetsState = {
  items: [],
  isBudgetsListEmpty: null,
  currentBudget: undefined,
  budgetByDate: {
    id: null,
    name: null,
    cashReceipts: null,
    previousOverrun: null,
    planned: null,
    futureSpending: null,
    lastMonthBudget: null,
    lastMonthSpending: null,
    avgBudgeted: null,
    avgSpending: null,
    notEnoughFunds: null,
  },
  date: defaultDate,
  loading: false,
  error: null,
};

interface BudgetsActions extends SliceCaseReducers<BudgetsState> {
  setBudgetsLoading: (state: BudgetsState, action: PayloadAction<boolean>) => void;
  setBudgetsError: (state: BudgetsState, action: PayloadAction<string | null>) => void;
  setBudgetsList: (state: BudgetsState, action: PayloadAction<Budget[]>) => void;
  setCurrentBudgetDate: (state: BudgetsState, action: PayloadAction<DateType>) => void;
  setBudgetByDate: (state: BudgetsState, action: PayloadAction<BudgetByDate>) => void;
  setCurrentBudget: (state: BudgetsState, action: PayloadAction<CurrentBudget | undefined>) => void;
  setResetCurrentBudget: (state: BudgetsState) => void;
  setBudgetsListEmpty: (state: BudgetsState, action: PayloadAction<boolean | null>) => void;
}

const budgetsSlice: Slice<BudgetsState, BudgetsActions, "budgets"> = createSlice({
  name: "budgets",
  initialState: initialState,
  reducers: {
    setBudgetsLoading: (state, action: PayloadAction<boolean>): void => {
      state.loading = action.payload;
    },
    setBudgetsError: (state, action: PayloadAction<string | null>): void => {
      state.error = action.payload;
    },
    setBudgetsList: (state, action: PayloadAction<Budget[]>): void => {
      state.items = action.payload;
    },
    setCurrentBudgetDate: (state, action: PayloadAction<DateType>): void => {
      state.date = action.payload;
    },
    setBudgetByDate: (state, action: PayloadAction<BudgetByDate>): void => {
      state.budgetByDate = action.payload;
    },
    setCurrentBudget: (state, action: PayloadAction<CurrentBudget | undefined>): void => {
      state.currentBudget = action.payload;
    },
    setResetCurrentBudget: (state): void => {
      state.currentBudget = initialState.currentBudget;
      state.budgetByDate = initialState.budgetByDate;
      state.date = defaultDate;
    },
    setBudgetsListEmpty: (state, action: PayloadAction<boolean | null>): void => {
      state.isBudgetsListEmpty = action.payload;
    },
  },
  extraReducers: {},
});

export const {
  setBudgetsLoading,
  setBudgetsError,
  setBudgetsList,
  setBudgetsListEmpty,
  setCurrentBudget,
  setCurrentBudgetDate,
  setResetCurrentBudget,
  setBudgetByDate,
} = budgetsSlice.actions;

export const budgetsReducer = budgetsSlice.reducer;
