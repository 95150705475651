import React from 'react'
import styled from 'styled-components'
import { Chip as MuiChip } from '@material-ui/core'
import CloseIcon from '@assets/icons/close.svg'

export const Chip = ({ ...props }) => {
  return (
    <StyledChip
      deleteIcon={
        <DeleteIconWrapper>
          <CloseIcon />
        </DeleteIconWrapper>
      }
      {...props}
    />
  );
};

export const StyledChip = styled(MuiChip)`
  &.MuiChip-root {
    background-color: ${p => p.theme.colors.light} !important;
    height: 40px;
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    padding: 0 8px;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
  }

  .MuiChip-label {
    color: ${p => p.theme.colors.dark};
  }
`;

const DeleteIconWrapper = styled.div`
  height: 24px;
  width: 24px;
`;
