import styled, { DefaultTheme } from "styled-components";
import { ReactElement } from "react";

export type TotalColorType = keyof Pick<DefaultTheme["colors"], "foundationPositive" | "contentSecondary" | "negative">;

type TotalColorsType = {
  [key: string]: TotalColorType;
};

export const totalColors: TotalColorsType = {
  "1": "foundationPositive",
  "0": "contentSecondary",
  "-1": "negative",
};

export type SummaryType = {
  title: string;
  titleText: string;
  sum: string;
};

type Props = {
  summaries: SummaryType[];
  classNamesWrapper?: string;
};

export function HeaderStatistics(props: Props): ReactElement {
  const { summaries, classNamesWrapper } = props;

  return (
    <StatisticFlexContainer className={classNamesWrapper}>
      <Summary count={summaries.length}>
        {summaries.map(({ title, titleText, sum }) => (
          <ItemWrapper>
            <SummaryItemContainer>
              <ItemTitle title={title}>{titleText}</ItemTitle>
              <Sum>{sum}</Sum>
            </SummaryItemContainer>
          </ItemWrapper>
        ))}
      </Summary>
    </StatisticFlexContainer>
  );
}

const Summary = styled.ul<{ count: number }>`
  background-color: ${p => p.theme.colors.white};
  border-radius: 4px;
  padding: 14px 0 11px;
  flex-grow: 1;

  display: grid;

  grid-template-columns: repeat(${p => p.count - 1}, auto) 1fr;
  grid-auto-flow: column;
  grid-auto-rows: 1fr;

  width: 100%;
`;

const StatisticFlexContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 12px;
  flex-wrap: nowrap;

  width: 100%;
`;

const SummaryItemContainer = styled.div`
  width: fit-content;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  min-width: 171px;

  @media (max-width: 1180px) {
    min-width: 100px;
  }
`;

const ItemWrapper = styled.li`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding: 0 28px;

  &:not(:last-child) {
    border-right: 1px solid ${p => p.theme.colors.backgroundSecondary};
  }

  @media (max-width: 1180px) {
    padding: 0 16px;
  }
`;

const ItemTitle = styled.a`
  font-style: normal;
  font-family: Manrope, Inter, sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: ${p => p.theme.colors.contentSecondary};

  text-overflow: ellipsis;
  overflow: hidden;
  display: block;

  white-space: nowrap;
  margin-bottom: 4px;

  @media (max-width: 1450px) {
    font-size: 12px;
  }
`;

const Sum = styled.b`
  font-size: 14px;
  font-style: normal;
  font-family: Manrope, Inter, sans-serif;
  color: ${p => p.theme.colors.darkText};
  font-weight: 600;
  line-height: 22px;
  word-wrap: break-word;
`;
