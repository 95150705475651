import React, { ReactElement } from "react";
import { DropDownItem } from "./DropDownItem";
import {
  DropdownMainItemWrapper,
  DropdownWrapper,
  ExpandIcon,
  ItemsContainer,
  MainDropdownItemTitle,
  MainItemContainer,
  StyledExpandMoreIcon,
  UserNav,
} from "./DropdownStyles";
import { OnDragEndResponder } from "react-beautiful-dnd";
import { ListItemIcon } from "@material-ui/core";
import { SortableList } from "../../../ui";

type DropdownProps = {
  mainItem: DropdownNavItem;
  items: DropdownNavItem[];
  onDragEnd?: OnDragEndResponder;
  isComplex?: boolean;
  withScrollContainer?: boolean;
  OtherItem?: ReactElement;
  active: boolean;
  common?: boolean;
};

export type DropdownNavItem = {
  id: string | undefined;
  title?: string;
  Icon?: ReactElement;
  node?: string;
  onClick?: () => void;
  disabled?: boolean;
  active?: boolean;
  link?: string;
};

export const Dropdown = (props: DropdownProps) => {
  const { mainItem, items, onDragEnd, isComplex, withScrollContainer, OtherItem, active, common } = props;

  return (
    <DropdownWrapper>
      <DropdownMainItemWrapper to={mainItem?.link ?? ""} onClick={mainItem?.onClick} $active={active}>
        <MainItemContainer>
          <ListItemIcon>{mainItem?.Icon}</ListItemIcon>
          <MainDropdownItemTitle>{mainItem?.title}</MainDropdownItemTitle>
        </MainItemContainer>
        {!mainItem?.disabled && (
          <ExpandIcon>
            <StyledExpandMoreIcon open={active} />
          </ExpandIcon>
        )}
      </DropdownMainItemWrapper>
      {!mainItem?.disabled && active && onDragEnd === undefined && (
        <ItemsContainer $withScroll={withScrollContainer}>
          <UserNav className="UserNav" $common={common}>
            {items?.map(item => (
              <DropDownItem item={item} key={item.title} />
            ))}
            {OtherItem !== undefined && OtherItem}
          </UserNav>
        </ItemsContainer>
      )}
      {!mainItem?.disabled && active && onDragEnd !== undefined && (
        <ItemsContainer $withScroll={withScrollContainer}>
          <UserNav className="UserNav">
            <SortableList
              list={items}
              onDragEnd={onDragEnd}
              render={item => <DropDownItem item={item} isComplex={isComplex} key={item?.id} />}
            />
            {OtherItem !== undefined && OtherItem}
          </UserNav>
        </ItemsContainer>
      )}
    </DropdownWrapper>
  );
};
