import Icon from "@assets/icons/subscribeBanner.svg";
import { useTypedSelector } from "@common/hooks";
import { OneRowTooltip as Tooltip } from "../../../ui";
import { ExitButton, FooterWrapper, StyledEmail, SubscriptionBanner } from "./styles";

export function Footer() {
  const user = useTypedSelector(state => state.user.data)

  return (
    <FooterWrapper>
      {user?.endOfSubscription === null ||
        (user?.endOfSubscription === undefined && <SubscriptionBanner to="/subscription" children={<Icon />} />)}
      <ExitButton to="/auth/login">Выход</ExitButton>
      <Tooltip placement="top" title={user?.email}>
        <StyledEmail>{user?.email}</StyledEmail>
      </Tooltip>
    </FooterWrapper>
  )
}
