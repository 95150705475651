import { EditPurposeInitialValuesType } from "@budget/view/table/modals/PurposeModals/EditPurposeModal";
import { TreeItem } from "@common/components/ui";
import type {
  Category,
  CategoryWithTag,
  CurrentBudget,
  DateType,
  Subcategory,
  SubcategoryWithTag,
} from "@common/models";
import { getHumanReadableMonth } from "@common/utils";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type CreateSubcategoryType = {
  name: string | undefined;
  category: string | undefined;
};

type TransferModalType = {
  subcategory: Subcategory | undefined;
  expenseId: string | undefined;
  amount: string | undefined;
};

const date = new Date();
const defaultDate = {
  day: date.getDate(),
  month: getHumanReadableMonth(date),
  year: date.getFullYear(),
};

type BudgetStoreType = {
  modals: {
    createSubcategory: {
      values: CreateSubcategoryType;
      open: boolean;
    };
    transfer: {
      values: TransferModalType;
      open: boolean;
    };
    spendingSubcategory: {
      values: { subcategory: Subcategory | undefined };
      open: boolean;
    };
    createCategory: {
      values: {
        name: string | undefined;
      };
      open: boolean;
    };
    editCategory: {
      values: {
        name?: string;
        isHidden?: boolean;
        categoryId?: string;
      };
      open: boolean;
    };
    editSubcategory: {
      values: {
        category?: string;
        name?: string;
        isHidden?: boolean;
        subcategoryId?: string;
      };
      open: boolean;
    };
    addPurpose: {
      name: string | undefined;
      open: boolean;
    };
    deleteCategory: {
      open: boolean;
    };
    viewPurpose: {
      values: {
        id: string | undefined;
        subcategoryId?: string;
        name: string | undefined;
        progress: number | undefined;
      };
      open: boolean;
    };
    deletePurpose: {
      values: {
        name: string | undefined;
        id: string | undefined;
      };
      open: boolean;
    };
    editPurpose: {
      values: EditPurposeInitialValuesType;
      open: boolean;
    };
  };
  categories: Category[];
  hiddenCategories: TreeItem<SubcategoryWithTag | CategoryWithTag>[];
  tableTree: TreeItem<CategoryWithTag | SubcategoryWithTag>[];
  selectedCategoryIds: string[];
  selectedSubCategoryIds: string[];
  currentBudget: CurrentBudget | undefined;
  currentDate: DateType;
};

const initialState: BudgetStoreType = {
  modals: {
    createSubcategory: {
      values: {
        name: undefined,
        category: undefined,
      },
      open: false,
    },
    transfer: {
      values: {
        expenseId: undefined,
        subcategory: undefined,
        amount: undefined,
      },
      open: false,
    },
    spendingSubcategory: {
      values: {
        subcategory: undefined,
      },
      open: false,
    },
    createCategory: {
      values: {
        name: undefined,
      },
      open: false,
    },
    editCategory: {
      values: {
        name: undefined,
        isHidden: undefined,
        categoryId: undefined,
      },
      open: false,
    },
    editSubcategory: {
      values: {
        category: undefined,
        name: undefined,
        isHidden: undefined,
        subcategoryId: undefined,
      },
      open: false,
    },
    addPurpose: {
      name: undefined,
      open: false,
    },
    deleteCategory: {
      open: false,
    },
    viewPurpose: {
      values: {
        id: undefined,
        name: undefined,
        progress: undefined,
        subcategoryId: undefined,
      },
      open: false,
    },
    deletePurpose: {
      values: {
        name: undefined,
        id: undefined,
      },
      open: false,
    },
    editPurpose: {
      values: {
        amount: undefined,
        purposeType: undefined,
        date: undefined,
        paymentMode: undefined,
      },
      open: false,
    },
  },
  categories: [],
  hiddenCategories: [],
  tableTree: [],
  selectedCategoryIds: [],
  selectedSubCategoryIds: [],
  currentBudget: undefined,
  currentDate: defaultDate,
};

const budgetSlice = createSlice({
  name: "budget",
  initialState: initialState,
  reducers: {
    setTableCategoryItems: (state, action: PayloadAction<Category[]>) => {
      state.categories = action.payload;
    },
    setHiddenCategories: (state, action: PayloadAction<TreeItem<SubcategoryWithTag | CategoryWithTag>[]>) => {
      state.hiddenCategories = action.payload;
    },
    setCurrentBudget: (state, action: PayloadAction<CurrentBudget | undefined>) => {
      state.currentBudget = action.payload;
    },
    setTree: (state, action: PayloadAction<TreeItem<SubcategoryWithTag | CategoryWithTag>[]>) => {
      state.tableTree = action.payload;
    },
    toggleExpandedCategoryRow: (state, action: PayloadAction<{ categoryId: string }>) => {
      state.tableTree = state.tableTree.map(node => {
        if (node.id === action.payload.categoryId) {
          return { ...node, collapsed: !node.collapsed };
        }
        return node;
      });
    },
    toggleExpandedHiddenCategoryRow: (state, action: PayloadAction<{ categoryId: string }>) => {
      state.hiddenCategories = state.hiddenCategories.map(node => {
        if (node.id === action.payload.categoryId) {
          return { ...node, collapsed: !node.collapsed };
        }
        return node;
      });
    },
    toggleAllCategoryExpanded: (state, action: PayloadAction<boolean>) => {
      state.tableTree = state.tableTree.map(node => ({ ...node, collapsed: action.payload }));
    },
    setCreateSubcategoryModalOpen: (state, action: PayloadAction<boolean>) => {
      state.modals.createSubcategory.open = action.payload;
    },
    setCreateSubcategoryState: (state, action: PayloadAction<CreateSubcategoryType>) => {
      state.modals.createSubcategory.values = action.payload;
    },
    setTransferModalOpen: (state, action: PayloadAction<boolean>) => {
      state.modals.transfer.open = action.payload;
    },
    setTransferModalState: (state, action: PayloadAction<TransferModalType>) => {
      state.modals.transfer.values = action.payload;
    },
    setSpendingModalOpen: (state, action: PayloadAction<boolean>) => {
      state.modals.spendingSubcategory.open = action.payload;
    },
    setSpendingModalState: (
      state,
      action: PayloadAction<{
        subcategory: Subcategory | undefined;
      }>,
    ) => {
      state.modals.spendingSubcategory.values = action.payload;
    },
    setCreateCategoryModalOpen: (state, action: PayloadAction<boolean>) => {
      state.modals.createCategory.open = action.payload;
    },
    setCreateCategoryModalState: (state, action: PayloadAction<{ name: string | undefined }>) => {
      state.modals.createCategory.values = action.payload;
    },
    setEditCategoryModalOpen: (state, action: PayloadAction<boolean>) => {
      state.modals.editCategory.open = action.payload;
    },
    setEditCategoryModalState: (
      state,
      action: PayloadAction<{
        name?: string;
        isHidden?: boolean;
        categoryId?: string;
      }>,
    ) => {
      state.modals.editCategory.values = action.payload;
    },
    setEditSubcategoryModalOpen: (state, action: PayloadAction<boolean>) => {
      state.modals.editSubcategory.open = action.payload;
    },
    setEditSubcategoryModalState: (
      state,
      action: PayloadAction<{
        category?: string;
        name?: string;
        isHidden?: boolean;
        subcategoryId?: string;
      }>,
    ) => {
      state.modals.editSubcategory.values = action.payload;
    },
    setAddPurposeModalOpen: (state, action: PayloadAction<boolean>) => {
      state.modals.addPurpose.open = action.payload;
    },
    setAddPurposeModalState: (state, action: PayloadAction<string | undefined>) => {
      state.modals.addPurpose.name = action.payload;
    },
    setDeleteCategoryModalOpen: (state, action: PayloadAction<boolean>) => {
      state.modals.deleteCategory.open = action.payload;
    },
    toggleSelectedCategoryIds: (
      state,
      action: PayloadAction<{
        category: string;
      }>,
    ) => {
      const selectedIds = state.selectedCategoryIds;

      if (state.selectedCategoryIds.includes(action.payload.category)) {
        state.selectedCategoryIds = selectedIds.filter(id => id !== action.payload.category);
      } else {
        state.selectedCategoryIds = [...selectedIds, action.payload.category];
      }
    },
    toggleSelectedSubcategoryIds: (
      state,
      action: PayloadAction<{
        subcategory: string;
      }>,
    ) => {
      const selectedIds = state.selectedSubCategoryIds;
      if (state.selectedSubCategoryIds.includes(action.payload.subcategory)) {
        state.selectedSubCategoryIds = selectedIds.filter(id => id !== action.payload.subcategory);
      } else {
        state.selectedSubCategoryIds = [...selectedIds, action.payload.subcategory];
      }
    },
    setViewPurposeModalOpen: (state, action: PayloadAction<boolean>) => {
      state.modals.viewPurpose.open = action.payload;
    },
    setViewPurposeModalState: (
      state,
      action: PayloadAction<{
        progress: number | undefined;
        name: string | undefined;
        id: string | undefined;
      }>,
    ) => {
      state.modals.viewPurpose.values = action.payload;
    },
    setDeletePurposeModalOpen: (state, action: PayloadAction<boolean>) => {
      state.modals.deletePurpose.open = action.payload;
    },
    setDeletePurposeModalState: (
      state,
      action: PayloadAction<{
        name: string | undefined;
        id: string | undefined;
      }>,
    ) => {
      state.modals.deletePurpose.values = action.payload;
    },
    setEditPurposeState: (state, action: PayloadAction<EditPurposeInitialValuesType>) => {
      state.modals.editPurpose.values = action.payload;
    },
    setEditPurposeOpen: (state, action: PayloadAction<boolean>) => {
      state.modals.editPurpose.open = action.payload;
    },
  },
  extraReducers: {},
});

export const {
  toggleAllCategoryExpanded,
  toggleExpandedCategoryRow,
  toggleExpandedHiddenCategoryRow,
  setHiddenCategories,
  setTree,
  setTableCategoryItems,
  setCreateSubcategoryModalOpen,
  setCreateSubcategoryState,
  toggleSelectedSubcategoryIds,
  toggleSelectedCategoryIds,
  setTransferModalState,
  setTransferModalOpen,
  setSpendingModalOpen,
  setSpendingModalState,
  setCreateCategoryModalOpen,
  setCreateCategoryModalState,
  setEditCategoryModalOpen,
  setEditCategoryModalState,
  setEditSubcategoryModalOpen,
  setEditSubcategoryModalState,
  setAddPurposeModalOpen,
  setDeleteCategoryModalOpen,
  setViewPurposeModalOpen,
  setViewPurposeModalState,
  setDeletePurposeModalOpen,
  setDeletePurposeModalState,
  setAddPurposeModalState,
  setEditPurposeOpen,
  setEditPurposeState,
  setCurrentBudget,
} = budgetSlice.actions;
export const budgetReducer = budgetSlice.reducer;
