import GoogleIcon from "@assets/icons/google.svg"
import MailIcon from "@assets/icons/mail.svg"
import VkIcon from "@assets/icons/vk.svg"
import YandexIcon from "@assets/icons/ya.svg"
import { useAsyncDispatch } from "@common/hooks"
import { authSocialAction } from "@common/store"
import { IconButton as MuiIconButton } from "@material-ui/core"
import styled from "styled-components"

enum AuthSocialId {
  VK = "vk-oauth2",
  GOOGLE = "google-oauth2",
  YANDEX = "yaru",
  MAIL = "mailru",
}

export const Social = () => {
  const asyncDispatch = useAsyncDispatch()

  const onChooseSocial = event => {
    event.stopPropagation()
    const target = event.target.closest("li")
    if (!target) return
    const social = target.id
    if (social) {
      localStorage.setItem("social", social)
      asyncDispatch(authSocialAction({ social }))
    }
  }

  return (
    <Wrapper>
      <Title>или войдите через соцсеть</Title>
      <List onClick={event => onChooseSocial(event)}>
        <Item id={AuthSocialId.VK}>
          <Link>
            <VkIcon />
          </Link>
        </Item>
        <Item id={AuthSocialId.GOOGLE}>
          <Link>
            <GoogleIcon />
          </Link>
        </Item>
        <Item id={AuthSocialId.YANDEX}>
          <Link>
            <YandexIcon />
          </Link>
        </Item>
        <Item id={AuthSocialId.MAIL}>
          <Link>
            <MailIcon />
          </Link>
        </Item>
      </List>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding-top: 40px;
`

const List = styled.ul`
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
  margin-top: 24px;
`

const Item = styled.li`
  margin-right: 24px;
`

const Title = styled.p`
  text-align: center;
`

const Link = styled(MuiIconButton)`
  && {
    border: 1px solid ${p => p.theme.colors.backgroundSecondary};
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
  }

  &:hover path,
  &:active path {
    fill: ${p => p.theme.colors.main};
  }
`
