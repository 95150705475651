import styled from 'styled-components'
import {
  Accordion as MuiAccordion,
  AccordionSummary,
  AccordionDetails,
  IconButton as MuiIconButton,
  Paper,
  Checkbox as MuiCheckbox,
} from '@material-ui/core'

export const Section = styled.section({
  overflow: 'auto',
  height: '60hv',
  position: 'relative',
});

export const Cell = styled.div`
  min-width: 156px;
  width: 156px;
  padding: 4px 12px;
  line-height: 24px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  height: 48px;
  display: flex;
  & .MuiFormControlLabel-root {
    margin: unset;
  }

  & .MuiSelect-outlined.MuiSelect-outlined {
    width: 144px;
  }

  &:nth-child(5) {
    width: 180px;
    min-width: 180px;
  }

  &:nth-child(1) {
    width: 160px;
    min-width: 160px;
  }

  &:nth-child(2) {
    width: 180px;
    min-width: 180px;
  }
  &:nth-child(3) {
    width: 180px;
    min-width: 180px;
  }
  &:nth-child(4) {
    width: 180px;
    min-width: 180px;
  }

  &:nth-child(6) {
    width: 120px;
    min-width: 120px;
  }

  &:nth-child(7) {
    width: 120px;
    min-width: 120px;
  }

  &.minify {
    min-width: 70px;
    width: 70px;
  }
`;

export const SortButton = styled.button<{ selected?: boolean }>`
  padding: 0;
  border: 0;
  background: none;
  margin-left: 10px;
  margin-top: 5px;
  svg {
    fill: ${({ selected }) => (selected ? '#13BDCE' : 'grey')};
  }
  :hover {
    cursor: pointer;
  }
`;

export const CellText = styled.span`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const MainCategoryTitle = styled.div`
  padding: 12px;
  display: flex;
  align-items: center;
`;

export const TableHeader = styled(Paper)`
  &.MuiPaper-root {
    display: flex;
    position: sticky;
    top: 0;
    align-items: center;
    padding: 0 12px 0 20px;
    color: ${p => p.theme.colors.contentSecondary};
    border-bottom: 1px ${p => p.theme.colors.backgroundSecondary} solid;
    border-radius: 0;
    font-weight: 600;
    z-index: 10;
    background: ${p => p.theme.colors.white};
  }
`;

export const SubCategory = styled(Paper)`
  &.MuiPaper-root {
    display: flex;
    align-items: center;
    padding: 0 12px 0 20px;
    color: ${p => p.theme.colors.contentMain};
    box-shadow: none;
    border-bottom: 1px ${p => p.theme.colors.backgroundSecondary} solid;
    background-color: ${p => p.theme.colors.white};
  }
`;

export const Checkbox = styled(MuiCheckbox)`
  &&.Mui-checked {
    color: ${p => p.theme.colors.main};
  }
  &.MuiCheckbox-root {
    color: ${p => p.theme.colors.backgroundSecondary};
  }
`;

export const Accordion = styled(MuiAccordion)`
  &.MuiAccordion-root.Mui-expanded {
    margin: 0;
  }
`;

export const Title = styled.span`
  margin-left: 8px;
`;

export const MainCategory = styled(AccordionSummary)`
  font-weight: 600;
  & .MuiAccordionSummary-content {
    display: flex;
    align-items: center;
    margin: 0;
  }
  & .MuiAccordionSummary-content.Mui-expanded {
    margin: 0;
  }
  &.MuiAccordionSummary-root {
    display: flex;
    align-items: center;
    padding: 0 24px 0 12px;
    height: 64px;
    border-bottom: 1px solid ${p => p.theme.colors.backgroundSecondary};
  }
  & .MuiAccordion-root.Mui-expanded {
    margin: 0;
  }
`;

export const Collapse = styled(AccordionDetails)`
  &.MuiAccordionDetails-root {
    flex-direction: column;
    padding: 0;
  }
`;

export const IconButton = styled(MuiIconButton)`
  &.MuiButtonBase-root.Mui-disabled {
    svg {
      fill: ${p => p.theme.colors.contentSecondary};
    }
  }
`;

export const TableBody = styled.div`
  height: 60vh;
`;
