import AnalyticsIcon from "@assets/icons/analytics.svg";
import ArticleIcon from "@assets/icons/article.svg";
import BillsIcon from "@assets/icons/bills.svg";
import CalculatorIcon from "@assets/icons/calculator.svg";
import ContractorsIcon from "@assets/icons/counterparties.svg";
import GlobeIcon from "@assets/icons/globe.svg";
import MessageIcon from "@assets/icons/message.svg";
import PremiumIcon from "@assets/icons/premium.svg";
import SettingsIcon from "@assets/icons/settings.svg";
import UserIcon from "@assets/icons/user.svg";
import WarningIcon from "@assets/icons/warning.svg";
import { LANDING_LINK } from "@assets/index";
import * as React from "react";
import { RoleNames } from "../../store";

export interface ComponentProps {
  href: string
  title: NavMenuTitle
  icon: React.ReactNode
  note?: string
  navBarCollapsed?: boolean
  onClick?: () => void
  active?: boolean
}

export type NavMenuTitle =
  | "Aналитика"
  | "Контрагенты"
  | "Настройки"
  | "Подписка"
  | "Перейти на сайт"
  | "Бюджет"
  | "Счета"
  | "Пользователи"
  | "Статьи"
  | "Оповещения"
  | "Запросы менеджеров"

interface RouteData {
  href: string
  title: NavMenuTitle
  icon: React.ReactNode
  availableForRoles: RoleNames[]
}

export const newAppRoutes: RouteData[] = [
  {
    href: "/analytics",
    title: "Aналитика",
    icon: <AnalyticsIcon />,
    availableForRoles: [RoleNames.client],
  },
  {
    href: "/contractors",
    title: "Контрагенты",
    icon: <ContractorsIcon />,
    availableForRoles: [RoleNames.client],
  },
  {
    href: "/settings",
    title: "Настройки",
    icon: <SettingsIcon />,
    availableForRoles: [RoleNames.client],
  },
  {
    href: "/subscription",
    title: "Подписка",
    icon: <PremiumIcon />,
    availableForRoles: [RoleNames.client],
  },
]

export const otherRoutes: RouteData[] = [
  {
    href: LANDING_LINK,
    title: "Перейти на сайт",
    icon: <GlobeIcon />,
    availableForRoles: [RoleNames.client],
  },
]

export const appRoutes: RouteData[] = [
  {
    href: "/",
    title: "Бюджет",
    icon: <CalculatorIcon />,
    // Component: Dropdown,
    availableForRoles: [RoleNames.client],
  },
  {
    href: "/accounts",
    title: "Счета",
    icon: <BillsIcon />,
    // Component: Dropdown,
    availableForRoles: [RoleNames.client],
  },
  {
    href: "/analytics",
    title: "Aналитика",
    icon: <AnalyticsIcon />,
    // Component: RouteItem,
    availableForRoles: [RoleNames.client],
  },
  {
    href: "/contractors",
    title: "Контрагенты",
    icon: <ContractorsIcon />,
    // Component: RouteItem,
    availableForRoles: [RoleNames.client],
  },
  {
    href: "/users",
    title: "Пользователи",
    icon: <UserIcon />,
    // Component: RouteItem,
    availableForRoles: [RoleNames.administrator, RoleNames.manager],
  },
  {
    href: "/articles",
    title: "Статьи",
    icon: <ArticleIcon />,
    // Component: RouteItem,
    availableForRoles: [RoleNames.administrator, RoleNames.manager],
  },
  {
    href: "/notifications",
    title: "Оповещения",
    icon: <MessageIcon />,
    // Component: RouteItem,
    availableForRoles: [RoleNames.administrator, RoleNames.manager],
  },
  {
    href: "/managers_requests",
    title: "Запросы менеджеров",
    icon: <WarningIcon />,
    // Component: RouteItem,
    availableForRoles: [RoleNames.administrator],
  },
]

export const clientRoutes: RouteData[] = [
  {
    href: "/settings",
    title: "Настройки",
    icon: <SettingsIcon />,
    // Component: RouteItem,
    availableForRoles: [RoleNames.client],
  },
  {
    href: "/subscription",
    title: "Подписка",
    icon: <PremiumIcon />,
    // Component: RouteItem,
    availableForRoles: [RoleNames.client],
  },
  {
    href: LANDING_LINK,
    title: "Перейти на сайт",
    icon: <GlobeIcon />,
    // Component: ExternalLinkItem,
    availableForRoles: [RoleNames.client],
  },
]
