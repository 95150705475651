import ChevronBottomAsset from "@assets/icons/chevron-bottom.svg"
import LeftIcon from "@assets/icons/left.svg"
import RightIcon from "@assets/icons/right.svg"
import { getHumanReadableMonth, getIntervalTitle, monthsTitles } from "@common/utils"
import { add, equals, not } from "ramda"
import { MouseEventHandler, memo, useMemo, useState } from "react"
import {
  Chevron,
  Container,
  Dimmer,
  Picker,
  PickerCard,
  PickerMonth,
  PickerMonthWrap,
  PickerYear,
  StartDate,
  Title,
  Trigger,
} from "./MonthPicker.style"

const yearInterval = [2015, 2021]

const date = new Date()

const defaultStartDate = { month: getHumanReadableMonth(date), year: date.getFullYear() }
const defaultEndDate = { month: getHumanReadableMonth(date), year: date.getFullYear() }

export interface IntervalItem {
  month: number | null
  year: number
}

interface MonthPickerProps {
  disabled?: boolean
  onSubmit?: Function
  useInterval?: boolean
  start?: IntervalItem
  end?: IntervalItem
  arrowIcon?: string
}

export const MonthPicker = memo(
  ({
    disabled = false,
    onSubmit,
    useInterval = false,
    start = defaultStartDate,
    end = defaultEndDate,
    arrowIcon,
  }: MonthPickerProps) => {
    const [startDate, setStartDate] = useState<IntervalItem>(start)
    const [endDate, setEndDate] = useState<IntervalItem>(end)

    const [isOpen, setIsOpen] = useState<boolean>(false)

    const title = useMemo<string>(() => getIntervalTitle(startDate, endDate), [startDate, endDate])

    const tickStartYear = (value: number): MouseEventHandler => () =>
      setStartDate({ month: 1, year: add(value, startDate.year) })

    const tickEndYear = (value: number): MouseEventHandler => () => {
      if (endDate.month && startDate.month && endDate.month < startDate.month)
        setEndDate({ ...startDate, year: add(value, endDate.year) })
      else setEndDate({ ...endDate, year: add(value, endDate.year) })
    }

    const enterStartMonth = (value: number): MouseEventHandler => () => {
      if (endDate.year === startDate.year && endDate.month && endDate.month < value) return
      setStartDate({ ...startDate, month: value })
    }

    const enterEndMonth = (value: number): MouseEventHandler => () => {
      if (endDate.year === startDate.year && startDate.month && startDate.month > value) return
      setEndDate({ ...endDate, month: value })
    }

    const handleTogglePicker = () => {
      if (isOpen && onSubmit) onSubmit({ startDate, endDate })

      setIsOpen(not)
    }

    return (
      <>
        <Container>
          <Trigger onClick={handleTogglePicker}>
            <Title disabled={disabled}>{title}</Title>
            <img src={arrowIcon?.toString() ?? ChevronBottomAsset.toString()} alt="chevron_bottom_icon" />
          </Trigger>
          {isOpen && (
            <PickerCard useInterval={useInterval}>
              <Picker>
                <PickerYear>
                  <Chevron onClick={tickStartYear(-1)} disabled={startDate.year === yearInterval[0]}>
                    <LeftIcon />
                  </Chevron>
                  <StartDate>{startDate?.year}</StartDate>
                  <Chevron
                    onClick={tickStartYear(1)}
                    disabled={startDate.year === yearInterval[1] || startDate.year === endDate.year}
                  >
                    <RightIcon />
                  </Chevron>
                </PickerYear>
                {Object.keys(monthsTitles).map(id => (
                  <PickerMonth
                    key={id}
                    active={equals(Number(id), startDate?.month)}
                    onClick={enterStartMonth(Number(id))}
                  >
                    <PickerMonthWrap>{monthsTitles[id]}</PickerMonthWrap>
                  </PickerMonth>
                ))}
              </Picker>
              {useInterval && (
                <Picker>
                  <PickerYear>
                    <Chevron onClick={tickEndYear(-1)} disabled={endDate.year === startDate.year}>
                      <LeftIcon />
                    </Chevron>
                    <StartDate>{endDate?.year}</StartDate>
                    <Chevron onClick={tickEndYear(1)} disabled={endDate.year === yearInterval[1]}>
                      <RightIcon />
                    </Chevron>
                  </PickerYear>
                  {Object.keys(monthsTitles).map(id => (
                    <PickerMonth key={id} active={Number(id) === endDate?.month} onClick={enterEndMonth(Number(id))}>
                      <PickerMonthWrap>{monthsTitles[id]}</PickerMonthWrap>
                    </PickerMonth>
                  ))}
                </Picker>
              )}
            </PickerCard>
          )}
        </Container>
        {isOpen && <Dimmer onClick={handleTogglePicker} />}
      </>
    )
  },
)
