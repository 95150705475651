import React, { memo, useState } from 'react'
import { MenuItem } from '@material-ui/core'
import ChevronBottomAsset from '@assets/icons/chevron-bottom.svg'
import { Title, Container, StyledMaterialMenu, MenuItemContainer } from './Dropdown.style'

interface DropdownItem {
  name: string;
  id: string;
  selected: boolean;
}
interface DropdownProps {
  disabled?: boolean;
  title: string;
  items?: DropdownItem[];
  onSelect: (id: string) => void;
  isAllItemsSelected: boolean;
  onToggleAllSelected: (value: boolean) => void;
}

export const Dropdown = memo(
  ({ title, items = [], onSelect, isAllItemsSelected, onToggleAllSelected, disabled = false }: DropdownProps) => {
    const [anchorEl, setAnchorEl] = useState<Element | null>(null);

    const handleOpenMenu = event => {
      !disabled && setAnchorEl(event.currentTarget);
    };

    const handleCloseMenu = () => {
      setAnchorEl(null);
    };

    const handleToggleAll = ({ target }) => {
      onToggleAllSelected(target.checked);
    };

    return (
      <>
        <Container onClick={handleOpenMenu}>
          <Title disabled={disabled}>{title}</Title>
          <img src={ChevronBottomAsset.toString()} alt="chevron_bottom_icon" />
        </Container>
        <StyledMaterialMenu
          id="customized-menu"
          anchorEl={anchorEl}
          keepMounted
          open={!!anchorEl}
          onClose={handleCloseMenu}
        >
          <MenuItem>
            <MenuItemContainer>
              {title}
              {/*<Checkbox color="primary" checked={isAllItemsSelected} onClick={handleToggleAll} />*/}
            </MenuItemContainer>
          </MenuItem>
          {items.map(({ name, selected, id }) => (
            <MenuItem key={id} disabled={isAllItemsSelected}>
              <MenuItemContainer>
                {name}
                {/*<Checkbox color="primary" checked={selected} onClick={() => onSelect(id)} />*/}
              </MenuItemContainer>
            </MenuItem>
          ))}
        </StyledMaterialMenu>
      </>
    );
  },
);
