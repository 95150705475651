import CalendarIcon from "@assets/icons/calendar.svg";
import { hexToRgb } from "@common/utils";
import { theme } from "@main/styles";
import classNames from "classnames";
import ru from "date-fns/locale/ru";
import { useState } from "react";
import DatePicker, { ReactDatePickerCustomHeaderProps, ReactDatePickerProps, registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FieldInputProps, FieldMetaState } from "react-final-form";
import styled from "styled-components";
import { CalendarHeader } from "./CalendarHeader";
import InputMask from "react-input-mask";

registerLocale("ru", ru);

interface Props<T extends Date | undefined> extends Omit<ReactDatePickerProps, "onChange"> {
  input: FieldInputProps<T>;
  onChange?: ReactDatePickerProps["onChange"];
  label?: string;
  required?: boolean;
  meta?: FieldMetaState<T>;
}

export type CalendarPreviewMode = "yearsPicker" | "monthsPicker" | "default";

export function Calendar<T extends Date | undefined>(props: Props<T>) {
  const { input, label, required, meta } = props;

  const [mode, changeMode] = useState<CalendarPreviewMode>("default");

  return (
    <Wrapper withLabel={label !== undefined} tabIndex={2}>
      {label !== undefined && <span className={classNames("calendarLabel", required && "required")}>{label}</span>}
      <StyledCalendar
        {...props}
        name={input.name}
        selected={input.value}
        onChange={input.onChange}
        onBlur={input.onBlur}
        onFocus={input.onFocus}
        dateFormat="d MMMM yyyy"
        customInput={<CustomInput />}
        onSelect={() => changeMode("default")}
        showMonthYearPicker={mode === "monthsPicker"}
        showYearPicker={mode === "yearsPicker"}
        shouldCloseOnSelect={mode === "default"}
        autoComplete="off"
        locale="ru"
        scrollableYearDropdown
        renderMonthContent={(monthIndex: number, shortMonthText: string, fullMonthText: string) => fullMonthText}
        renderCustomHeader={(params: ReactDatePickerCustomHeaderProps) =>
          CalendarHeader({ ...params, changeMode, mode })
        }
        $error={meta?.invalid && meta?.touched}
      />
      <div className="calendar-icon-wrapper">
        <CalendarIcon />
      </div>
    </Wrapper>
  );
}

const months = [
  "января", "февраля", "марта", "апреля", "мая", "июня",
  "июля", "августа", "сентября", "октября", "ноября", "декабря",
];


function CustomInput(props) {
  const { format, onChange, ...other } = props;

  const formatDate = date => {
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();

    switch (format) {
      case "dd.MM.yyyy":
        return `${day.toString().padStart(2, "0")}.${(monthIndex + 1).toString().padStart(2, "0")}.${year}`;
      case "dd mmm yyyy":
        return `${day} ${months[monthIndex]} ${year}`;
      case "dd mmmm yyyy":
        return `${day} ${months[monthIndex]} ${year}`;
      default:
        return `${day} ${months[monthIndex]} ${year}`;
    }
  };

  return (
    <InputMask
      {...other}
      format={formatDate}
    />
  );
}


export const Wrapper = styled.div<{ withLabel?: boolean; $error?: boolean }>`
  position: relative;
  width: 100%;

  .react-datepicker-wrapper {
    width: 100%;
  }

  .calendarLabel {
    font-weight: 600;
    font-family: Manrope, Inter, sans-serif;
    font-size: 16px;
    line-height: 22px;
    color: ${p => p.theme.colors.darkText};
    padding: 0 16px;
    margin-bottom: 11px;
    display: block;
    width: fit-content;

    position: relative;

    &.required::after {
      position: absolute;
      top: 3px;
      right: 6px;

      content: "";
      display: block;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background: red;
    }
  }

  & .react-datepicker {
    border-radius: 6px;
    padding: 24px 20px;
    border: 1px solid ${p => p.theme.colors.lightnessGrey};
    width: 100%;
    box-sizing: border-box;

    ${p =>
            p.$error &&
            `
     background-color: rgba(${hexToRgb(theme.colors.red)}, 0.2);
     border: 1px solid ${p => p.theme.colors.red};
  `}
    .react-datepicker__month-container {
      padding: 0;

      .react-datepicker__header {
        padding: 0;
        position: static;

        .react-datepicker__day-names {
          display: grid;
          grid-template-columns: repeat(7, 1fr);
          justify-items: center;

          .react-datepicker__day-name {
            color: ${p => p.theme.colors.main};
            font-family: Manrope, Inter, sans-serif;
          }
        }
      }

      .react-datepicker__month {
        margin: 12px 0 0 0;

        .react-datepicker__week {
          display: grid;
          grid-template-columns: repeat(7, 1fr);
          justify-items: center;

          .react-datepicker__day {
            border-radius: 6px;
            border: 1px solid ${p => p.theme.colors.lightGrey};
            line-height: 32px;

            &:hover {
              background-color: ${p => p.theme.colors.lightnessGrey};
            }

            &.react-datepicker__day--selected {
              background-color: ${p => p.theme.colors.main};
              color: white;
            }

            &.react-datepicker__day--keyboard-selected {
              background-color: inherit;

              &:hover {
                background-color: ${p => p.theme.colors.lightnessGrey};
              }
            }

            &.react-datepicker__day--outside-month {
              opacity: 0.3;
            }
          }
        }

        &.react-datepicker__monthPicker {
          display: grid;
          grid-template-columns: repeat(4, max-content);
          justify-items: start;
          column-gap: 5px;

          .react-datepicker__month-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            column-gap: 4px;

            .react-datepicker__month-text {
              width: 100%;
              box-sizing: border-box;
              text-align: start;
              font-weight: 600;
              font-size: 16px;
              line-height: 22px;
              color: ${p => p.theme.colors.darkText};
              padding: 12px;

              &:first-letter {
                text-transform: uppercase;
              }

              &.react-datepicker__month-text--selected {
                color: ${p => p.theme.colors.white};
                background-color: ${p => p.theme.colors.main};
              }
            }
          }
        }
      }
    }

    .react-datepicker__year--container {
      .react-datepicker__year {
        min-width: 240px;
        max-height: 150px;
        overflow-y: scroll;
        overflow-x: hidden;

        .react-datepicker__year-wrapper {
          flex-wrap: nowrap;
          flex-direction: column;
          max-width: unset;
          padding: 0 5px;

          .react-datepicker__year-text {
            width: 100%;
            box-sizing: border-box;
            padding: 4px 16px;
            border-radius: 6px;
            font-size: 16px;
            line-height: 22px;
            font-weight: 500;
            color: ${p => p.theme.colors.darkText};
            border: 1px solid transparent;

            &.react-datepicker__year-text--selected {
              color: ${p => p.theme.colors.main};
              border: 1px solid ${p => p.theme.colors.lightGrey};
              background-color: ${p => p.theme.colors.lightnessGrey};
            }
          }
        }
      }
    }

    .react-datepicker__triangle {
      display: none;
    }
  }

  & .react-datepicker .react-datepicker__header {
    background-color: #fff;
    border: none;
    padding: 16px 16px 4px;
    font-size: 16px;
    line-height: 24px;
    color: ${p => p.theme.colors.contentMain};
    font-family: "Montserrat", sans-serif;
    text-transform: capitalize;
    font-weight: 600;
  }

  & .react-datepicker__current-month {
    font-size: 16px;
    line-height: 32px;
    color: ${p => p.theme.colors.contentMain};
    font-family: "Montserrat", sans-serif;
    text-transform: capitalize;
    margin-bottom: 4px;
  }

  & .react-datepicker__navigation--previous {
    left: 16px;
    top: 16px;
  }

  & .react-datepicker__navigation--next {
    right: 16px;
    top: 16px;
  }

  & .react-datepicker__day {
    width: 32px;
    height: 32px;
    text-align: center;
    vertical-align: middle;
    font-family: Manrope, Inter, sans-serif;
    font-weight: 500;
    color: ${p => p.theme.colors.darkText};
  }

  & .MuiInputLabel-outlined.MuiInputLabel-shrink {
    display: none;
  }

  .calendar-icon-wrapper {
    position: absolute;
    right: 16px;
    top: 18px;
    pointer-events: none;

    display: flex;

    ${p =>
            p.withLabel &&
            `
      top: 54px;
    `}
    svg > path {
      fill: ${p => p.theme.colors.placeholder};
    }
  }
`;

const StyledCalendar = styled(DatePicker)<{ $error?: boolean }>`
  font-family: Manrope, Inter, sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
  color: ${p => p.theme.colors.darkText};

  width: 100%;
  box-sizing: border-box;
  height: 59px;
  padding: 8px 16px;
  border: 1px solid ${p => p.theme.colors.lightBlue};
  border-radius: 6px;

  position: relative;
  background-color: rgba(${hexToRgb(theme.colors.lightBlue)}, 0.2);

  outline: none;
  cursor: pointer;

  &::placeholder {
    font-family: Manrope, Inter, sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: ${p => p.theme.colors.placeholder};
  }

  ${p =>
          p.$error &&
          `
      border: 1px solid ${theme.colors.red} !important;
  `}
`;
