import { UserData } from "./user.types";
import { createSlice, PayloadAction, Slice, SliceCaseReducers } from "@reduxjs/toolkit";

export interface Rate {
  id: string;
  name: string;
  subscriptionPeriod: number;
  cost: string;
  costByMonth: string;
  description: string;
}

export interface UserDataWithRate extends UserData {
  actualRate: Rate;
}

export interface UsersState {
  loading: boolean;
  error: string | null;
  clients: UserDataWithRate[];
  managers: UserData[];
  managersCount: number;
  clientsCount: number;
  currentUser: UserData | undefined;
}

const initialState: UsersState = {
  loading: false,
  error: null,
  clients: [],
  managers: [],
  managersCount: 0,
  clientsCount: 0,
  currentUser: undefined,
};

interface UsersActions extends SliceCaseReducers<UsersState> {
  setUsersSingle: (state: UsersState, action: PayloadAction<UserDataWithRate>) => void
  setClientsList: (state: UsersState, action: PayloadAction<UserDataWithRate[]>) => void
  setManagersList: (state: UsersState, action: PayloadAction<UserData[]>) => void
  setUsersClientsCount: (state: UsersState, action: PayloadAction<number>) => void
  setUsersManagersCount: (state: UsersState, action: PayloadAction<number>) => void
  setUsersLoading: (state: UsersState, action: PayloadAction<boolean>) => void
  setCurrentUser: (state: UsersState, action: PayloadAction<UserData | undefined>) => void
  setUsersError: (state: UsersState, action: PayloadAction<string | null>) => void
}

const usersSlice: Slice<UsersState, UsersActions, "users"> = createSlice({
  name: "users",
  initialState: initialState,
  reducers: {
    setUsersSingle: (state: UsersState, action: PayloadAction<UserDataWithRate>) => {
      state.clients = state.clients.map(el => {
        if (el.id === action.payload.id) {
          return action.payload;
        }
        return el;
      });
    },
    setClientsList: (state: UsersState, action: PayloadAction<UserDataWithRate[]>) => {
      state.clients = action.payload
    },
    setManagersList: (state: UsersState, action: PayloadAction<UserData[]>) => {
      state.managers = action.payload
    },
    setUsersClientsCount: (state: UsersState, action: PayloadAction<number>) => {
      state.clientsCount = action.payload
    },
    setUsersManagersCount: (state: UsersState, action: PayloadAction<number>) => {
      state.managersCount = action.payload
    },
    setUsersLoading: (state: UsersState, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    setCurrentUser: (state: UsersState, action: PayloadAction<UserData | undefined>) => {
      state.currentUser = action.payload
    },
    setUsersError: (state: UsersState, action: PayloadAction<string | null>) => {
      state.error = action.payload
    },
  },
  extraReducers: {},
})

export const {
  setUsersLoading,
  setCurrentUser,
  setManagersList,
  setUsersError,
  setUsersManagersCount,
  setUsersSingle,
  setUsersClientsCount,
  setClientsList,
} = usersSlice.actions

export const usersReducer = usersSlice.reducer
