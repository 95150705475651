import { UniqueIdentifier } from "@dnd-kit/core"
import { getTreeItemMaxDepth } from "./getTreeItemMaxDepth"
import { getTreeItemMinDepth } from "./getTreeItemMinDepth"
import { FlattenedItem } from "./types"

export function getTreeItemDepth<T>(
  items: Array<FlattenedItem<T>>,
  newItems: Array<FlattenedItem<T>>,
  activeIds: Array<UniqueIdentifier>,
  dragOffset: number,
  indentationWidth: number,
  overItemIndex: number,
  maxDeep?: number,
  currentId?: UniqueIdentifier,
  disableChangeDepth?: boolean,
): number {
  const activeItemDepth: number = items.find(({ id }): boolean => id === currentId)?.depth ?? 0

  if (disableChangeDepth) {
    return activeItemDepth
  }

  const minDepth: number = getTreeItemMinDepth(newItems, overItemIndex, items, activeIds, maxDeep)

  const maxDepth: number = getTreeItemMaxDepth(items, activeIds, overItemIndex, maxDeep)

  const dragDepth: number = getDragDepth(dragOffset, indentationWidth)
  let depth: number = activeItemDepth + dragDepth

  if (depth >= maxDepth) {
    depth = maxDepth
  } else if (depth < minDepth) {
    depth = minDepth
  }
  return depth
}

function getDragDepth(offset: number, indentationWidth: number): number {
  return Math.round(offset / indentationWidth)
}
