import { media } from "@main/styles"
import { Divider as MuiDivider } from "@material-ui/core"
import { Link as RouterLink } from "react-router-dom"
import styled from "styled-components"

export const Wrapper = styled.div`
  max-width: 1320px;
  width: 100%;
  margin: 0 auto 80px;

  ${media.mobile`
  box-sizing: border-box;
  padding-left: 16px;
  padding-right: 16px;
`}
`

export const Header = styled(Wrapper)`
  padding-top: 24px;
  padding-bottom: 24px;
  margin: 0 auto;
`

export const Title = styled.div<{ indentRight?: string }>`
  font-style: normal;
  font-weight: bold;
  font-size: ${p => p.theme.text.fontExtraLarge};
  line-height: 60px;
  margin-top: 24px;
  margin-bottom: 40px;
  ${p => p.indentRight && `margin-right: ${p.indentRight}`};
  h1 {
    margin: 0;
  }
`

export const StatusWrapper = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: ${p => p.theme.text.fontLarge};
  line-height: 44px;
  color: ${p => p.theme.colors.contentMain};
  margin-bottom: 20px;
`

export const Status = styled.span<{ active: boolean }>`
  color: ${p => (p.active ? p.theme.colors.main : p.theme.colors.negative)};
`

export const StatusNote = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: ${p => p.theme.text.fontSmall};
  line-height: 24px;
  color: ${p => p.theme.colors.contentMain};
  margin-bottom: 24px;
`

export const Divider = styled(MuiDivider)`
  &.MuiDivider-root {
    margin: 40px 0;
  }
`

export const Link = styled(RouterLink)`
  text-decoration: none;
  color: ${p => p.theme.colors.white};
  display: flex;
  align-items: center;
  white-space: nowrap;
`

export const HeaderWrapper = styled.div`
  display: flex;
  background-color: ${p => p.theme.colors.main};
`
