import { Chip as ChipComponent } from "@common/components/ui/Chip"
import { InputLabel as MuiInputLabel } from "@material-ui/core"
import styled from "styled-components"

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const Top = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 59px;

  > .MuiTextField-root:first-child {
    flex: 2;
  }

  > .MuiFormControl-root:last-child {
    flex: 1;
    margin-left: 40px;
  }
`

export const Bottom = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding-top: 40px;
  padding-bottom: 59px;

  > .MuiFormControl-root:first-child {
    flex: 0 1 50%;
    margin-right: 20px;
  }

  > .MuiAutocomplete-root:last-child {
    flex: 1;
    margin-left: 20px;

    .MuiButtonBase-root {
      transition: transform 0.3s ease;
    }

    .Mui-focused svg path {
      fill: ${p => p.theme.colors.dark};
    }
  }
`

export const ActionsWrapper = styled.div`
  display: inline-block;
  flex: 1;
`

export const InputLabel = styled(MuiInputLabel)`
  &.Mui-focused {
    color: ${p => p.theme.colors.dark} !important;
  }
`

export const SelectedWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  grid-gap: 1rem;
  padding-bottom: 80px;
`

export const LoadingWrapper = styled.div`
  width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Chip = styled(ChipComponent)`
  max-width: 350px;
`
