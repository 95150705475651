import { media } from "@main/styles";
import styled from "styled-components";

export const HeaderTitleWithSettingsMenuWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 16px;
`;
export const HeaderTitleWithSettingsMenuTitleWrapper = styled.div`
  max-width: 522px;

  h1 {
    margin-bottom: 0;
    overflow: hidden;
    font-size: 24px;
    line-height: 1.5;
    color: ${p => p.theme.colors.darkText};
    text-overflow: ellipsis;
    font-family: Manrope, Inter, sans-serif;

    @media (max-width: 1100px) {
      font-size: 18px;
    }
  }

  ${media.medium`
    max-width: 495px;
  `}
`;

export const HeaderTitleWithSettingsMenuControls = styled.div`
  display: flex;
  align-items: center;
`;
