type Release = () => void
type Listener = (event: KeyboardEvent) => void
type ListenerName = "onEscape" | "onTab" | "onUp" | "onDown" | "onEnter"

const keyboardKeyToListenerName: Record<string, ListenerName> = {
  Escape: "onEscape",
  Esc: "onEscape",
  Tab: "onTab",
  ArrowUp: "onUp",
  ArrowDown: "onDown",
  Enter: "onEnter",
}

const activeListeners: Record<ListenerName, Array<Listener>> = {
  onEscape: [],
  onTab: [],
  onUp: [],
  onDown: [],
  onEnter: [],
}

export function attachKeyboardListener(listeners: Partial<Record<ListenerName, Listener>>): Release {
  if (!hasActiveListeners()) {
    attach()
  }
  (Object.keys(listeners) as Array<ListenerName>)
    .forEach((listenerName) => {
      const listener = listeners[listenerName]
      if (!listener) {
        return
      }
      const currentListeners = activeListeners[listenerName]
      if (currentListeners) {
        currentListeners.push(listener)
      }
    })
  return () => {
    releaseKeyboardListener(listeners)
  }
}

function releaseKeyboardListener(listeners: Partial<Record<ListenerName, Listener>>) {
  (Object.keys(listeners) as Array<ListenerName>)
    .forEach((listenerName) => {
      const listener = listeners[listenerName]
      const currentEventListeners = activeListeners[listenerName]
      if (currentEventListeners) {
        const index = currentEventListeners.findIndex(l => l === listener)
        if (index !== -1) {
          currentEventListeners.splice(index, 1)
        }
      }
    })
  if (!hasActiveListeners()) {
    detach()
  }
}

function attach() {
  document.addEventListener("keydown", handleDocumentKeyDown, true)
}

function detach() {
  document.removeEventListener("keydown", handleDocumentKeyDown, false)
}

function hasActiveListeners() {
  return Object.values(activeListeners)
    .some(listeners => listeners.length > 0)
}

function handleDocumentKeyDown(event: KeyboardEvent) {
  const listenerName = keyboardKeyToListenerName[event.key]
  if (!listenerName) {
    return
  }
  const listeners = activeListeners[listenerName]
  const length = listeners.length
  if (!length) {
    return
  }
  event.stopPropagation()
  const handler = activeListeners[listenerName][length - 1]
  handler(event)
}
