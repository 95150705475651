import styled from 'styled-components'

interface ButtonProps {
  disabled?: boolean;
}

export const Wrapper = styled.div`
  max-width: 1320px;
  width: 100%;
  margin: 0 auto;
  padding-top: 40px;
`;

export const Button = styled.div<ButtonProps>`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme, disabled }) => (disabled ? theme.colors.contentSecondary : theme.colors.main)};
  margin-right: 30px;

  & svg {
    margin-right: 10px;

    path {
      fill: ${({ disabled, theme }) => (disabled ? theme.colors.contentSecondary : theme.colors.main)};
    }
  }
`;

export const TopBarContainer = styled.div`
  display: flex;
`;

export const LoadingWrapper = styled.div`
  width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
