import { UniqueIdentifier } from "@dnd-kit/core"
import { FlattenedItem } from "./types"

export function getTreeItemParentId<T>(
  depth: number,
  newItems: Array<FlattenedItem<T>>,
  overItemIndex: number,
  activeIds?: Array<UniqueIdentifier>,
): UniqueIdentifier | undefined {
  const firstActiveItem: FlattenedItem<T> | undefined = newItems.find(item => item.id === activeIds?.[0])
  const previousFromOverItem: FlattenedItem<T> | undefined = newItems[overItemIndex - 1]
  const itemBeforeFirstFromActive: FlattenedItem<T> | undefined =
    activeIds !== undefined && firstActiveItem !== undefined && activeIds?.includes(previousFromOverItem?.id)
      ? newItems[newItems.indexOf(firstActiveItem) - 1]
      : undefined
  const previousItem: FlattenedItem<T> | undefined = itemBeforeFirstFromActive ?? previousFromOverItem

  if (depth === 0 || !previousItem) {
    return undefined
  }

  if (depth === previousItem.depth) {
    return previousItem.parentId
  }

  if (depth > previousItem.depth) {
    return previousItem.id
  }

  return newItems
    .slice(0, overItemIndex)
    .reverse()
    .find((item): boolean => item.depth === depth)?.parentId
}
