import { UniqueIdentifier } from "@dnd-kit/core"
import { FlattenedItem, TreeItem } from "./types"

export function getAvailable<T>(
  items: Array<FlattenedItem<T>>,
  activeIds: Array<UniqueIdentifier>,
  maxDeep?: number,
): number {
  let available: number | undefined = undefined
  const activeItems: Array<FlattenedItem<T>> = items.filter(({ id }) => activeIds.includes(id))

  for (const activeItem of activeItems) {
    const initialMaxDeep: number = maxDeep ?? calculateMaxTreeDepth(items, 0)
    const fromParentToChildDepth: number = getFromParentToChildDepth(activeItem)
    const temp: number = initialMaxDeep - fromParentToChildDepth
    if (available === undefined) {
      available = temp
    }
    if (temp < available) {
      available = temp
    }
  }

  if (available === undefined) {
    return 0
  }
  return available
}

function getFromParentToChildDepth<T>(activeItem: FlattenedItem<T>): number {
  if (activeItem.children.length === 0) {
    return 0
  }
  return calculateMaxTreeDepth(activeItem.children, activeItem.depth + 1) - activeItem.depth
}

function calculateMaxTreeDepth<T>(array: Array<TreeItem<T>>, previousDepth?: number): number {
  let depth = 0
  if (previousDepth !== undefined && previousDepth > depth) {
    depth = previousDepth
  }
  for (const item of array) {
    if (item.children.length !== 0) {
      return calculateMaxTreeDepth(item.children, depth + 1)
    }
  }
  return depth
}
