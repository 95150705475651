import styled from 'styled-components'
import { Paper } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'

export const Cell = styled.div`
  padding: 12px;
  font-weight: 600;
`;

export const TableHeader = styled(Paper)`
  &.MuiPaper-root {
    display: grid;
    grid-template-columns: 1fr 224px 48px;
    padding: 0 12px;
    color: ${p => p.theme.colors.contentSecondary};
    box-shadow: none;
    border-radius: 4px 4px 0 0;
    border-bottom: 1px solid ${p => p.theme.colors.backgroundSecondary};
  }
`;

export const Section = styled.section`
  width: 984px;
  margin-right: 24px;
  border-radius: 8px;
`;

export const SubCategory = styled(Paper)`
  &.MuiPaper-root {
    display: grid;
    grid-template-columns: 1fr 48px;
    padding: 0 12px;
    color: ${p => p.theme.colors.contentMain};
    box-shadow: none;
    background-color: ${p => p.theme.colors.white};
    border-bottom: 1px solid ${p => p.theme.colors.backgroundSecondary};
  }
  &.MuiPaper-rounded {
    border-radius: 0;
    &:last-child {
      border-radius: 0 0 4px 4px;
    }
  }
`;

export const Link = styled(RouterLink)`
  text-decoration: none;
  color: ${p => p.theme.colors.contentMain};
  display: grid;
  grid-template-columns: 1fr 224px;
`;
