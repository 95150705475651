import styled from 'styled-components'
import { TextField as MuiTextField, Button } from '@material-ui/core'

export const Text = styled.p`
  margin-bottom: 56px;
  max-width: 360px;
`;

export const  TextField = styled(MuiTextField)`
  &.MuiFormControl-root {
    width: 100%;
    margin-bottom: 36px;
    color: ${p => p.theme.colors.placeholder};

    .MuiInputLabel-formControl {
      display: block;
      padding: 0 16px;
      height: 22px;
      font-size: 16px;
      line-height: 22px;
      position: static;
      font-weight: 600;
      font-family: Manrope, Inter, sans-serif;
      transform: none;
      color: ${p => p.theme.colors.darkText}
    }

    .MuiInput-formControl {
      margin-top: 0;

      .MuiInputBase-input {
        font-size: 16px;
        line-height: 22px;

        padding: 0 16px;
        height: 60px;

        color: ${p => p.theme.colors.darkText};
        border-radius: 6px;
        border: 1px solid ${p => p.theme.colors.lightGrey};

        &::placeholder {
          color: ${({ theme }) => theme.colors.placeholder} !important;
          font-size: 16px;
          line-height: 22px;
          font-family: Manrope, Inter, sans-serif;
          opacity: 1 !important;
        }
      }
    }
  }

  .MuiInput-underline:after,
  .MuiInput-underline:before {
    display: none;
  }
`;

export const Submit = styled(Button)`
  &.MuiButton-root {
    padding: 8px 16px;
    background-color: ${p => p.theme.colors.main};
    color: ${p => p.theme.colors.white};
    font-weight: 500;
    font-family: Manrope, Inter, sans-serif;
    text-align: center;
    text-transform: none;
    border-radius: 6px;
    border: 1px solid transparent;
    width: 100%;
    
    &:hover,
    &:focus,
    &:active {
      background-color: ${p => p.theme.colors.dark};
    }

    &:disabled {
      background-color: ${p => p.theme.colors.backgroundMain};
      color: ${p => p.theme.colors.contentSecondary};
    }
  }
`;

export const Cancel = styled(Button)`
  &.MuiButton-root {
    padding: 8px 16px;
    background-color: ${p => p.theme.colors.white};
    color: ${p => p.theme.colors.lightGrey};
    font-weight: 500;
    font-family: Manrope, Inter, sans-serif;
    text-align: center;
    text-transform: none;
    border-radius: 6px;
    border: 1px solid ${p => p.theme.colors.lightGrey};
    width: 100%;
  }
`;
export const FormFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 16px;
`;
export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

