import type { AccountShortView, CacheAccount } from "@common/models";
import {
  setAccountsError,
  setAccountsList,
  setAccountsLoading,
  setAccountsTypesLoaded,
  setCurrentAccount,
  setNotification,
} from "@common/store";
import { AccountTransport } from "@common/transports";
import { normalizeError } from "@common/utils";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { API, LayoutService } from "..";

export const getAccountsListThunk = createAsyncThunk<Array<CacheAccount>, void>(
  "GET_ACCOUNTS_LIST",
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setAccountsError(null));
    dispatch(setAccountsLoading(true));

    try {
      const { data } = await API.get<CacheAccount[]>(`/accounts/`);
      dispatch(setAccountsList(data));
      return data;
    } catch (err) {
      const { message } = normalizeError(err);
      dispatch(setAccountsError(message));
      return rejectWithValue(message);
    }
  },
)();

export const getAccountsTypesThunk = createAsyncThunk<void, void>("GET_ACCOUNTS_TYPES", async (_, thunk) => {
  thunk.dispatch(setAccountsError(null));
  thunk.dispatch(setAccountsLoading(true));
  try {
    const accountTypes = await AccountTransport.getAccountTypes();
    thunk.dispatch(setAccountsTypesLoaded(accountTypes));
  } catch (err) {
    const error = normalizeError(err);
    thunk.dispatch(setAccountsError(error.message));
  }
})();

export const addAccountToListThunk = createAsyncThunk<void, AccountShortView>("ADD_ACCOUNT_TO_LiST", (args, thunk) => {
  AccountTransport.createAccount(args)
    .then(() => {
      thunk.dispatch(getAccountsTypesThunk);
      thunk.dispatch(LayoutService.loadAccounts())
      thunk.dispatch(setNotification({ type: "success", message: "Счёт успешно добавлен!" }));
    })
    .catch(error => {
      thunk.dispatch(setNotification({ type: "error", message: error.response.data.error }));
      setAccountsError(error.message);
    });
});

export const editAccountThunk = createAsyncThunk<void, AccountShortView & { id: string }>(
  "EDIT_ACCOUNT",
  async ({ id, ...dto }, { dispatch, rejectWithValue }) => {
    try {
      AccountTransport.updateAccount(id, dto).then(() => {
        dispatch(getAccountsListThunk);
        dispatch(getAccountsTypesThunk);
        const account = dispatch(getAccountInfoThunk({ id }));
        return account
      });
    } catch (err) {
      const { message } = normalizeError(err);
      dispatch(setAccountsError(message));
      return rejectWithValue(message);
    }
  },
);

export const deleteAccountThunk = createAsyncThunk<void, { id: string }>(
  "DELETE_ACCOUNT",
  async ({ id }, { dispatch }) => {
    try {
      AccountTransport.deleteAccount(id).then(async () => {
        const accounts = await dispatch(getAccountsListThunk).unwrap();
        dispatch(getAccountsTypesThunk);
        if (accounts?.length !== 0 && accounts !== undefined) {
          dispatch(setCurrentAccount(accounts[0]));
        }
      });
    } catch (err) {
      const error = normalizeError(err);
      dispatch(setAccountsError(error.message));
    }
  },
);

export const getAccountInfoThunk = createAsyncThunk<CacheAccount | undefined, { id: string }>(
  "GET_ACCOUNT_INFO",
  async ({ id }) => {
    try {
      return AccountTransport.getAccount(id);
    } catch (err) {
      const error = normalizeError(err);
      setAccountsError(error.message);
      throw error;
    }
  },
);
