import { API } from "@common/api"
import type { IExpense } from "@common/store"

export type ExpensesTransportType = {
  getExpenses: (budgetId: string, month: number, year: number) => Promise<IExpense[]>
}
export const ExpensesTransport: ExpensesTransportType = {
  getExpenses: async (budgetId: string, month: number, year: number): Promise<IExpense[]> => {
    const { data } = await API.get<IExpense[]>(`/expenses/`, { params: { budget: budgetId, month, year } })
    return data
  },
}
