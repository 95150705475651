import { createSlice, PayloadAction, Slice, SliceCaseReducers } from "@reduxjs/toolkit";

export enum PurposeEnum {
  FIXED_SPEND = 'Фиксированная сумма трат',
  PURPOSEFUL_ACCUMULATION = 'Целенаправленное накопление',
  MONEY_BOX = 'Копилка',
  CREDIT_PURPOSE = 'Кредитная цель',
}

export enum PurposePaymentMode {
  TO_DATE = 'toDate',
  ONE_MONTH = 'oneMonth',
}

export const purposePaymentMode = {
  [PurposePaymentMode.ONE_MONTH]: 'На месяц',
  [PurposePaymentMode.TO_DATE]: 'К дате',
};

export interface IPurpose {
  id: string;
  name: string;
  purposeType: PurposeEnum | null;
  subcategory: string;
  amount: string;
  fromDate: string;
  toDate: string;
  message: string;
  invested: string;
  left: string;
  context?: {
    paymentMode: PurposePaymentMode;
  };
}

interface IPurposeState {
  loading: boolean;
  error: string | null;
  list: IPurpose[];
  currentPurpose: IPurpose | null;
}

const initialState: IPurposeState = {
  loading: false,
  error: null,
  list: [],
  currentPurpose: {
    id: '',
    name: '',
    purposeType: null,
    subcategory: '',
    amount: '',
    fromDate: '',
    toDate: '',
    message: '',
    invested: '',
    left: '',
  },
};

interface PurposeActions extends SliceCaseReducers<IPurposeState> {
  setPurposeData: (state: IPurposeState, action: PayloadAction<IPurpose | null>) => void
  setPurposeLoading: (state: IPurposeState, action: PayloadAction<boolean>) => void
  setPurposeError: (state: IPurposeState, action: PayloadAction<string | null>) => void
  clearPurposeData: (state: IPurposeState) => void
  setPurposesData: (state: IPurposeState, action: PayloadAction<IPurpose[]>) => void
  setPurposesLoading: (state: IPurposeState, action: PayloadAction<boolean>) => void
  setPurposesError: (state: IPurposeState, action: PayloadAction<string | null>) => void
}

const purposeSlice: Slice<IPurposeState, PurposeActions, "purpose"> = createSlice({
  name: "purpose",
  initialState: initialState,
  reducers: {
    setPurposeData: (state: IPurposeState, action: PayloadAction<IPurpose | null>) => {
      state.currentPurpose = action.payload
      state.error = null
    },
    setPurposeLoading: (state: IPurposeState, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    setPurposeError: (state: IPurposeState, action: PayloadAction<string | null>) => {
      state.error = action.payload
      state.currentPurpose = null
    },
    clearPurposeData: (state: IPurposeState) => {
      state.currentPurpose = null
    },
    setPurposesData: (state: IPurposeState, action: PayloadAction<IPurpose[]>) => {
      state.list = action.payload
    },
    setPurposesLoading: (state: IPurposeState, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    setPurposesError: (state: IPurposeState, action: PayloadAction<string | null>) => {
      state.error = action.payload
    },
  },
  extraReducers: {},
})

export const {
  setPurposeData,
  setPurposeError,
  setPurposesData,
  clearPurposeData,
  setPurposesError,
  setPurposesLoading,
  setPurposeLoading,
} = purposeSlice.actions

export const purposeReducer = purposeSlice.reducer
