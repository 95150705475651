import "reset-css";
import "./colors.css";
import { createGlobalStyle } from "styled-components";
import MontserratBold from "./fonts/Montserrat-Bold.ttf";
import MontserratRegular from "./fonts/Montserrat-Regular.ttf";
import MontserratSemiBold from "./fonts/Montserrat-SemiBold.ttf";

import InterBold from "./fonts/Inter-Bold.ttf";
import InterExtraBold from "./fonts/Inter-ExtraBold.ttf";
import InterLight from "./fonts/Inter-Light.ttf";
import InterMedium from "./fonts/Inter-Medium.ttf";
import InterRegular from "./fonts/Inter-Regular.ttf";
import InterSemiBold from "./fonts/Inter-SemiBold.ttf";

import ManropeBold from "./fonts/Manrope-Bold.ttf";
import ManropeExtraBold from "./fonts/Manrope-ExtraBold.ttf";
import ManropeLight from "./fonts/Manrope-Light.ttf";
import ManropeMedium from "./fonts/Manrope-Medium.ttf";
import ManropeRegular from "./fonts/Manrope-Regular.ttf";
import ManropeSemiBold from "./fonts/Manrope-SemiBold.ttf";

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Montserrat';
    src: url(${MontserratRegular});
    font-style: normal;
    font-weight: 400;
  }

  @font-face {
    font-family: 'Montserrat';
    src: url(${MontserratSemiBold});
    font-style: normal;
    font-weight: 600;
  }

  @font-face {
    font-family: 'Montserrat';
    src: url(${MontserratBold});
    font-style: normal;
    font-weight: 700;
  }
  
  @font-face {
    font-family: Inter;
    src: url(${InterLight});
    font-style: normal;
    font-weight: 300;
  }

  @font-face {
    font-family: Inter;
    src: url(${InterRegular});
    font-style: normal;
    font-weight: 400;
  }

  @font-face {
    font-family: Inter;
    src: url(${InterMedium});
    font-style: normal;
    font-weight: 500;
  }

  @font-face {
    font-family: Inter;
    src: url(${InterSemiBold});
    font-style: normal;
    font-weight: 600;
  }

  @font-face {
    font-family: Inter;
    src: url(${InterBold});
    font-style: normal;
    font-weight: 700;
  }

  @font-face {
    font-family: Inter;
    src: url(${InterExtraBold});
    font-style: normal;
    font-weight: 800;
  }
  
    @font-face {
    font-family: Manrope;
    src: url(${ManropeLight});
    font-style: normal;
    font-weight: 300;
  }

  @font-face {
    font-family: Manrope;
    src: url(${ManropeRegular});
    font-style: normal;
    font-weight: 400;
  }

  @font-face {
    font-family: Manrope;
    src: url(${ManropeMedium});
    font-style: normal;
    font-weight: 500;
  }

  @font-face {
    font-family: Manrope;
    src: url(${ManropeSemiBold});
    font-style: normal;
    font-weight: 600;
  }

  @font-face {
    font-family: Manrope;
    src: url(${ManropeBold});
    font-style: normal;
    font-weight: 700;
  }

  @font-face {
    font-family: Manrope;
    src: url(${ManropeExtraBold});
    font-style: normal;
    font-weight: 800;
  }

  html,
  body {
    height: 100%;
    width: 100%;
    background-color: ${p => p.theme.colors.backgroundMain};
    color: ${p => p.theme.colors.contentMain};
    font-size: ${p => p.theme.text.fontSmall};
    line-height: ${p => p.theme.text.lineHeight};
  }

  body {
    font-family: 'Montserrat', sans-serif;
    overflow-x: hidden;
  }

  body.fontLoaded {
    font-family: 'Montserrat', sans-serif;
  }

  #menu-category {
    z-index: 13000;
  }

  p,
  label {
    margin: 0;
  }

  input, select, button {
    font-family: inherit;
    font-size: inherit;
  }

  h1 {
    font-weight: 700;
    font-size: 48px;
    line-height: 60px;
    margin-bottom: 40px;
  }

  h2 {
    font-weight: 700;
    font-size: 22px;
    line-height: 32px;
    margin-bottom: 40px;
  }

  h3 {
    font-weight: bold;
    font-size: 22px;
    line-height: 32px;
  }

  h4 {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
  }

  button span {
    text-transform: uppercase;
    font-weight: 400;
  }

  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background-color: #f4f4f4;
    border-radius: 4px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${p => p.theme.colors.main};
    border-radius: 4px;
  }

  @keyframes bounce {
    0% {
      height: 42.5px;
      width: 96.5px;
    }
    30% {
      height: 47px;
      width: 100px;
      opacity: 1
    }
    40% {
      height: 47.5px;
      width: 105px;
      opacity: 0.3;
    }
    100% {
      height: 42.5px;
      width: 96.5px;
      opacity: 0.6;
    }
  }

  @keyframes bounce-save-btn {
    0% {
      height: 42.5px;
      width: 122.5px;
    }
    30% {
      height: 47px;
      width: 135px;
      opacity: 1
    }
    40% {
      height: 47.5px;
      width: 140px;
      opacity: 0.3;
    }
    100% {
      height: 42.5px;
      width: 122.5px;
      opacity: 0.6;
    }
  }
`;
