import styled from "styled-components"
import { Link } from "react-router-dom"

export const SubscriptionBanner = styled(Link)`
  display: block;
  margin-bottom: 18px;
  text-decoration: none;
  border-radius: 5px;
  overflow: hidden;
  width: 100%;
`
export const ExitButton = styled(Link)`
  color: ${p => p.theme.colors.red};
  display: block;
  text-decoration: none;
  text-align: start;
  font-size: 16px;
  line-height: 25px;
  font-family: Manrope, Inter, sans-sarif;
`

export const StyledEmail = styled.p`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: start;
  font-family: Manrope, Inter, sans-sarif;
`;

export const FooterWrapper = styled.div`
  max-width: 180px;
  width: 100%;
  margin: 0 auto;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-top: 16px;
  border-top: 1px solid #E5E6E9;
`
