import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import { Tab } from "@mui/material";
import * as React from "react";
import { ReactElement } from "react";
import styled from "styled-components";

export interface TabType<T> {
  label: string;
  value: T;
  content?: ReactElement;
  icon?: ReactElement;
}

type Props<T extends string> = {
  tabs: TabType<T>[];
  onChange: (value: T) => void;
  currentTab?: string;
};

export function Tabs<T extends string>(props: Props<T>) {
  const { currentTab, tabs, onChange } = props;

  if (currentTab === undefined) {
    return <></>;
  }

  const handleChange = (event: React.ChangeEvent<{}>, newTab: T): void => {
    onChange(newTab);
  };

  return (
    <StyledTabContext value={currentTab}>
      <StyledTabList onChange={handleChange} disableTouchRipple>
        {tabs.map(({ label, value, icon }) => (
          <StyledTab label={label} icon={icon} value={value} key={value} />
        ))}
      </StyledTabList>
      <ContentWrapper>
        {tabs.map(({ content, value }) => (
          <StyledTabPanel value={value} key={value}>
            {content}
          </StyledTabPanel>
        ))}
      </ContentWrapper>
    </StyledTabContext>
  );
}

const ContentWrapper = styled.div`
  max-height: 100%;
`;
const StyledTabContext = styled(TabContext)`
  max-height: 100%;
  overflow-y: hidden;
`;

const StyledTabPanel = styled(TabPanel)`
  display: flex;
  flex-direction: column;
  position: relative;

  &.MuiTabPanel-root {
    padding: 0;
  }
`;

const StyledTabList = styled(TabList)`
  span {
    background-color: ${p => p.theme.colors.main};
  }

  background: white;

  .MuiTab-wrapper,
  .MuiTouchRipple-root {
    background-color: transparent;
    transition: none;
  }

  .MuiTouchRipple-root {
    display: none;
  }

  .MuiTabs-flexContainer {
    border-bottom: 1px solid ${p => p.theme.colors.lightnessGrey};
  }

  .MuiTouchRipple-root {
    transition: none;
  }
`;

const StyledTab = styled(Tab)`
  &.MuiButtonBase-root {
    font-family: Manrope, Inter, sans-serif;
    font-size: 12px;
    line-height: 16px;
    color: ${p => p.theme.colors.darkText};
    font-weight: 800;
    width: fit-content;
    padding: 16px 10px;
    min-width: initial !important;
    position: relative;
    overflow: initial;
    min-height: initial !important;
    background: white !important;
  }
`;
