import { createSlice, PayloadAction, Slice, SliceCaseReducers } from "@reduxjs/toolkit"

export interface IlistRates {
  id: string
  name: string
  subscriptionPeriod: number
  cost: string
  costByMonth: string
  description: string
}

export interface ISubscriptionState {
  loading: boolean
  error: Error | null
  rates: Array<IlistRates>
}

const initialState: ISubscriptionState = {
  loading: false,
  error: null,
  rates: [],
}

interface SubscriptionActions extends SliceCaseReducers<ISubscriptionState> {
  fetchRatesSuccessAction: (state: ISubscriptionState, action: PayloadAction<IlistRates[]>) => void
  fetchRatesFailureAction: (state: ISubscriptionState, action: PayloadAction<Error | null>) => void
  fetchRatesRequestAction: (state: ISubscriptionState, action: PayloadAction<boolean>) => void
}

const subscriptionSlice: Slice<ISubscriptionState, SubscriptionActions, "subscription"> = createSlice({
  name: "subscription",
  initialState: initialState,
  reducers: {
    fetchRatesSuccessAction: (state: ISubscriptionState, action: PayloadAction<IlistRates[]>) => {
      state.rates = action.payload
    },
    fetchRatesFailureAction: (state: ISubscriptionState, action: PayloadAction<Error | null>) => {
      state.error = action.payload
    },
    fetchRatesRequestAction: (state: ISubscriptionState, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
  },
  extraReducers: {},
})

export const { fetchRatesSuccessAction, fetchRatesRequestAction, fetchRatesFailureAction } = subscriptionSlice.actions
export const subscriptionReducer = subscriptionSlice.reducer
