import { Loading } from "@common/components"
import { lazyLoad } from "@common/utils"

export const BudgetPageAsync = lazyLoad(
  () => import("./BudgetPage"),
  module => module.BudgetPage,
  {
    fallback: <Loading />,
  },
)
