import { useTypedSelector } from "@common/hooks";
import { popupType, setNotification } from "@common/store";
import { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Popup } from "./styles";

export const GlobalPopup: FC = () => {
  const dispatch = useDispatch();

  const type = useSelector(popupType);
  const message = useTypedSelector(state => state.popup.message);

  const isOpen = message !== null;

  useEffect(() => {
    let timerId;

    if (message) {
      timerId = setTimeout(() => dispatch(setNotification({ type: "success", message: null })), 3000);
    }

    return () => clearTimeout(timerId);
  }, [message, dispatch]);

  return (
    <Popup type={type} isOpen={isOpen}>
      <p>{message}</p>
    </Popup>
  );
};
