import { LOGIN } from "@assets/constants/routing"
import { Link as RouterLink } from "react-router-dom"
import styled from "styled-components"

export const LoginLink = () => (
  <p>
    Уже есть аккаунт? <Link to={LOGIN}>Войдите</Link>
  </p>
)

const Link = styled(RouterLink)`
  text-decoration: none;
  color: ${p => p.theme.colors.main};

  &:hover,
  &:focus {
    color: ${p => p.theme.colors.dark};
  }
`
