import { API } from "@common/api"
import type { Article, ArticleShortView } from "@common/models"
import { z } from "zod"
import { ArticleZodSchema } from "./articles.zod"

export type ArticlesTransportType = {
  getArticles: () => Promise<Article[]>
  createArticle: (article: ArticleShortView) => Promise<Article>
  editArticle: (id: string, article: ArticleShortView) => Promise<Article>
  deleteArticle: (id: string) => Promise<void>
}
export const ArticlesTransport: ArticlesTransportType = {
  getArticles: async (): Promise<Article[]> => {
    const { data } = await API.get<Article[]>("/admin/posts/")
    return z.array(ArticleZodSchema).parse(data)
  },
  createArticle: async (article: ArticleShortView): Promise<Article> => {
    const { data } = await API.post<Article>("/admin/posts/", article)
    return ArticleZodSchema.parse(data)
  },
  editArticle: async (id: string, article: ArticleShortView): Promise<Article> => {
    const { data } = await API.put<Article>(`/admin/posts/${id}/`, article)
    return ArticleZodSchema.parse(data)
  },
  deleteArticle: async (id: string): Promise<void> => {
    return await API.delete(`/admin/posts/${id}/`)
  },
}
