import styled, { css } from 'styled-components'
import {
  Button as MuiButton,
  List,
  ListItemIcon,
  ListItemText as MuiListItemText,
  Typography,
} from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'
import ExpandMoreIcon from '@assets/icons/expandmore.svg'
import CloseIcon from '@assets/icons/arrow-back-white.svg'

interface IconProps {
  open?: boolean;
}

const navBarFullWidth = '220px'
const containerSpacing = '15px'

const textEllipsis = css`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const linkStyles = css`
  text-decoration: none;
  color: ${p => p.theme.colors.main};
  white-space: nowrap;
`;

const iconDefaultStyle = css`
  svg > path {
    transition: all 0.15s ease-in-out;
    fill: ${p => p.theme.colors.purple};
  }
`;

const iconActiveStyle = css`
  svg > path {
    transition: all 0.15s ease-in-out;
    fill: ${p => p.theme.colors.main};
  }
`;
export const Link = styled(RouterLink)`
  ${linkStyles}
`;

export const ExternalLink = styled.a`
  ${linkStyles}
`;

export const Item = styled(Link)<{ $active?: boolean }>`
  display: flex;
  align-items: center;

  margin-bottom: 30px;

  transition: all 0.15s ease-in-out;

  &:hover {
    > span {
      transition: all 0.15s ease-in-out;
      color: ${p => p.theme.colors.main};
    }

    ${iconActiveStyle};
  }

  ${({$active}) => $active && `
  > span {
    transition: all 0.15s ease-in-out;
    color: #13BDCE;
  }
  > div > svg > path {
    transition: all 0.15s ease-in-out;
    fill: #13BDCE;
  }
  `}
`;

export const Sidebar = styled.aside`
  width: ${navBarFullWidth};
  background-color: ${p => p.theme.colors.white};
  box-shadow: 2px 0 15px 0 rgba(222, 222, 222, 0.14), 0 2px 15px 0 rgba(222, 222, 222, 0.08);
  height: 100vh;
  padding: 30px 8px 20px ${containerSpacing};
  color: ${p => p.theme.colors.purple};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  font-weight: 600;
  box-sizing: border-box;
  position: fixed;
  z-index: 99;
  overflow-y: hidden;
  transition: all 0.15s;

  & .MuiListItem-root {
    min-width: ${navBarFullWidth} !important;
  }

  & .MuiButton-label {
    display: flex;
    justify-content: space-between;
  }

  & .MuiList-root {
    padding: 0;
  }

  & .MuiPopover-root {
    z-index: 13000;
  }

  & .MuiTypography-root {
    display: flex;

    font-family: ${({ theme }) => theme.fontFamily.main};
  }
}`;

export const DropdownWrapper = styled.li`
  width: 100%;
  min-width: calc(${navBarFullWidth} - (2 * ${containerSpacing}));
`;

export const Body = styled.div`
  width: 100%;
  border-left: 1px solid ${p => p.theme.colors.lightnessGrey};

  svg {
    opacity: 0.6;
  }
`;

export const ExpandIcon = styled.div`
  width: 16px;
  height: 16px;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;

  ${iconDefaultStyle};
`;

export const Icon = styled(ListItemIcon)`
  min-width: 24px !important;
  display: flex;
  align-items: center;
  margin-right: 8px;

  ${iconDefaultStyle};
`;

export const UserNav = styled(List)<{ $active?: boolean }>`
  margin-top: auto;

  ${p => p.$active &&
          `
        overflow: hidden;
      `
  }
  li + li {
    margin-bottom: 32px;
  }
`;

export const NavSubtitle = styled(Typography)`
  &.MuiTypography-root {
    font-family: Manrope, Inter, sans-serif;
    font-size: 16px;
    line-height: 1;
    font-weight: 600;
    color: ${p => p.theme.colors.purple};

    margin-bottom: 28px;
  }
`;

export const LogoOpened = styled(Link)`
  display: block;
  margin: 0 auto 85px;
  text-align: start;
`;


export const StyledCloseBtn = styled(CloseIcon)<{ $active?: boolean }>`
  zoom: 80%;
  cursor: pointer;
  position: absolute;
  top: -30px;
  right: 5px;
  width: 30px;
  height: 30px;
  z-index: 101;
  padding: 8px;
  transition-duration: 0.8s;
  ${p =>
          p.$active &&
          `
  border: solid 1px white;
  border-radius: 4px;
  right: -25px;
  transition-duration: 0.8s;
  transform: rotate(180deg);
  background: #13bdce;
  `}
`;

export const Button = styled(MuiButton)`
  &.MuiButton-root {
    color: inherit;
    padding: 0;
    transition: all 0.15s ease-in-out;

    &:hover {
      background: transparent;

      > span {
        transition: all 0.15s ease-in-out;
        color: ${p => p.theme.colors.main};
      }

      ${iconActiveStyle};
    }
  }

  & .MuiTypography-root {
    text-transform: none;
  }
`;

export const StyledExpandMoreIcon = styled(ExpandMoreIcon)<IconProps>`
  fill: ${p => p.theme.colors.main};
  transform: rotate(${({ open }) => (open ? '0deg' : '180deg')});
  transition: transform 0.15s ease;
`;

const listItem = css`
  color: ${p => p.theme.colors.purple};
  fill: ${p => p.theme.colors.purple};
  transition: all 0.15s ease-in-out;
`;

export const ListItem = styled.li<{ $active?: boolean }>`
  a, path, span {
    ${listItem};

    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  &:hover {
    a, path, span {
      color: ${p => p.theme.colors.main};
      fill: ${p => p.theme.colors.main};
      transition: all 0.15s ease-in-out;
    }
  }
`;

export const Wrapper = styled.div`
  padding-left: 2px;
  max-width: 100%;

  .UserNav {
    .MuiTypography-root {
      font-weight: normal;
      display: block;
      text-align: left;
      ${textEllipsis};
    }
  }
`;

export const ListItemText = styled(MuiListItemText)<any>`
  ${props => {
    if (props.direction === 'row') {
      return css`
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;
      `;
    }
  }}
  & .MuiTypography-colorTextSecondary {
    color: ${p => (p.isValueNegative ? p.theme.colors.negative : p.theme.colors.main)};
  }
`;

export const BudgetTitle = styled.span`
  max-width: 165px;
  ${textEllipsis};
  color: ${p => p.theme.colors.purple};
`;

export const Blure = styled.div`
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9;
`;
export const StikerCheck = styled.div`
  position: fixed;
  margin-left: 0;
  top: 50px;
  color: #333333;
  z-index: 10;

  svg {
    opacity: 1;
  }

  .MuiButton-label {
    display: inline;
  }
`;

export const StikerBudget = styled.div`
  position: fixed;
  margin-left: -430px;
  top: 0;
  color: #333333;
  z-index: 10;

  svg {
    opacity: 1;
  }

  h1 {
    font-weight: 700;
    font-size: 18px;
    line-height: 32px;
    margin-bottom: 40px;
  }

  & p {
    font-size: 16px;
    line-height: 1.3;
  }

  .MuiButton-label {
    display: inline;
  }

  .MuiButtonBase-root {
    margin-top: 0;
  }

  .MuiButton-root {
    margin-top: 0 !important;
  }

  form {
    max-height: 320px;
  }

  > div {
    max-height: 370px;
  }
`;

