import styled from 'styled-components'

export const Title = styled.div<{ $indentBottom?: string }>`
  ${p => (p.$indentBottom ? `margin-bottom: ${p.$indentBottom}` : '40px')};
  font-style: normal;
  font-weight: bold;
  font-size: ${p => p.theme.text.fontLarge};
  line-height: 44px;
  color: ${p => p.theme.colors.contentMain};
`;

export const Bottom = styled.div`
  display: flex;
  align-items: center;
  margin: 0 -27px;
`;

export const Text = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: ${p => p.theme.text.fontSmall};
  line-height: 24px;
  color: ${p => p.theme.colors.contentMain};
`;

export const BottomColumn = styled.div`
  width: 418px;
  margin: 0 27px;
`;
