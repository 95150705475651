import React, { useMemo, useRef } from 'react'
import { Field } from 'react-final-form'
import CloseIcon from '@assets/icons/close.svg'
import 'react-datepicker/dist/react-datepicker.css'
import { Cell, Select, SplitCrossIconButton, TextField, Wrapper } from './styles';

export const SplitFormRow = ({
  setContractorOptions,
  contractors,
  currentAccountId,
  isTransfer,
  categoryOptions,
  handleCreateSubcategory,
  transactionType,
  values,
  name,
  index,
  fields,
  remove,
}) => {
  const subReference = useRef<HTMLInputElement | null>(null),
    descReference = useRef<HTMLInputElement | null>(null);

  const allowedCategories = useMemo(() => {
    if (!values.subcategory || !values.splitTransactions) {
      return categoryOptions;
    }

    return categoryOptions.map(({ options, ...rest }) => ({
      ...rest,
      options: options.filter(
        opt =>
          opt &&
          opt.value !== values.subcategory.value &&
          !values.splitTransactions.some(transaction => transaction?.subcategory?.value === opt.value),
      ),
    }));
  }, [categoryOptions, values.subcategory, values.splitTransactions]);
  return (
    <>
      <SplitCrossIconButton
        onClick={() => {
          fields.remove(index);
          remove();
        }}
      >
        <CloseIcon />
      </SplitCrossIconButton>
      <Wrapper>
        <Cell $hidden>
          <Field name="date">
            {({ input: { value, name } }) => (
              <></>
              // <Calendar id={name} selected={value} value={value} name={name} />
            )}
          </Field>
        </Cell>
        <Cell $hidden>
          <Field name="periodicity">
            {({ input: { value, name } }) => (
              <Select
                isDisabled
                id={name}
                name={name}
                value={value}
                classNamePrefix="select"
                placeholder="Повтор"
                className="select"
                // components={{ DropdownIndicator: DropdownIndicator(BottomIcon) }}
              />
            )}
          </Field>
        </Cell>
        <Cell $hidden>
          <Field name="contractor">
            {({ input: { value, name } }) => (
              <Select
                isDisabled
                id={name}
                name={name}
                value={value}
                classNamePrefix="select"
                placeholder="Контрагент"
                className="select"
                isSearchable
                isClearable
                components={{
                  // DropdownIndicator: DropdownIndicator(BottomIcon),
                  // ClearIndicator: ClearIndicator(CloseIcon),
                }}
              />
            )}
          </Field>
        </Cell>
        <Cell>
          {/*<Field name={`${name}.subcategory`} validate={value => !value && 'Required'}>*/}
          {/*  {({ input: { value, onChange, name }, meta: { error, touched } }) => (*/}
          {/*    <CreatableSelect*/}
          {/*      id={name}*/}
          {/*      name={name}*/}
          {/*      value={value}*/}
          {/*      onChange={e => {*/}
          {/*        onChange(e);*/}
          {/*        descReference && descReference.current?.focus();*/}
          {/*        // setContractorOptions(*/}
          {/*        //   // getContractorOptions({ contractors, currentAccountId, currentSubcategoryId: e?.value }),*/}
          {/*        // );*/}
          {/*      }}*/}
          {/*      ref={subReference as any}*/}
          {/*      options={allowedCategories}*/}
          {/*      classNamePrefix="select"*/}
          {/*      placeholder="Подкатегория"*/}
          {/*      className="select"*/}
          {/*      isSearchable*/}
          {/*      onCreateOption={(text: string) => handleCreateSubcategory(text)}*/}
          {/*      formatCreateLabel={(text: string) => <Text>Создать «{text}»</Text>}*/}
          {/*      components={{ DropdownIndicator: DropdownIndicator(BottomIcon) }}*/}
          {/*      isDisabled={isTransfer}*/}
          {/*    />*/}
          {/*  )}*/}
          {/*</Field>*/}
        </Cell>
        <Cell>
          <Field name={`${name}.comment`}>
            {({ input: { value, onChange, name }, meta: { error, pristine } }) => (
              <TextField
                name={name}
                value={value}
                onChange={onChange}
                inputRef={descReference}
                id={name}
                label="Введите описание"
                type="text"
                variant="outlined"
                autoComplete="off"
                error={!!error && !pristine}
              />
            )}
          </Field>
        </Cell>
        {/*<Cell>*/}
        {/*  <Field name={`${name}.amount`} validate={value => !value && transactionType === 'outgoing' && 'Required'}>*/}
        {/*    {({ input: { value, onChange, name }, meta: { error, touched } }) => (*/}
        {/*      <CalculatorInput*/}
        {/*        name={name}*/}
        {/*        value={value}*/}
        {/*        onChange={onChange}*/}
        {/*        disabled={transactionType === 'incoming'}*/}
        {/*        id={name}*/}
        {/*        $error={!!error && touched}*/}
        {/*      />*/}
        {/*    )}*/}
        {/*  </Field>*/}
        {/*</Cell>*/}
        {/*<Cell>*/}
        {/*  <Field name={`${name}.amount`} validate={value => !value && transactionType === 'incoming' && 'Required'}>*/}
        {/*    {({ input: { value, onChange, name }, meta: { error, touched } }) => (*/}
        {/*      <CalculatorInput*/}
        {/*        name={name}*/}
        {/*        value={value}*/}
        {/*        onChange={onChange}*/}
        {/*        disabled={transactionType === 'outgoing' || isTransfer}*/}
        {/*        id={name}*/}
        {/*        $error={!!error && touched}*/}
        {/*      />*/}
        {/*    )}*/}
        {/*  </Field>*/}
        {/*</Cell>*/}
        <Cell />
      </Wrapper>
    </>
  );
};
