import { InputText } from "./InputText";
import React, { ReactElement } from "react";
import SearchIcon from "@assets/icons/search_20.svg";
import styled from "styled-components";

type Props = {
  value: string | undefined;
  placeholder?: string;
  maxWidth?: number;
  onChange?: (str?: string) => void;
};

export function Search(props: Props): ReactElement {
  const { value, onChange, placeholder, maxWidth } = props;

  return (
    <SearchWrapper maxWidth={maxWidth}>
      <SearchIconContainer>
        <SearchIcon />
      </SearchIconContainer>
      <StyledInput
        value={value}
        onChange={e => onChange?.(e.target.value)}
        type="text"
        autoComplete="off"
        placeholder={placeholder}
        withMainBorder
        autoFocus
      />
    </SearchWrapper>
  );
}

const SearchWrapper = styled.div<{ maxWidth?: number }>`
  position: relative;
  background: var(--color-white-100);
  border-radius: 5px;
  width: 100%;
  ${p =>
    p.maxWidth !== undefined &&
    `
      max-width: ${p.maxWidth}px; 
    `}
`;
const SearchIconContainer = styled.div`
  position: absolute;
  top: 15px;
  left: 12px;
`;

const StyledInput = styled(InputText)`
  input {
    border: 0 !important;
    padding-left: 44px !important;
    width: 100%;
    height: 44px !important;

    @media (max-width: 1100px) {
      font-size: 12px !important;
      padding: 4px 4px 4px 44px !important;
    }

    &::placeholder {
      @media (max-width: 1100px) {
        font-size: 12px !important;
      }
    }
  }
`;
