import { watchWindowResize } from "@common/core"
import { useResizeWatcher, useVirtualBackground } from "@common/hooks"
import { offset, useFloating } from "@floating-ui/react-dom"
import { Placement, shift } from "@floating-ui/react-dom-interactions"
import { PropsWithChildren, RefCallback, memo, useCallback, useEffect, useImperativeHandle, useRef } from "react"
import styled from "styled-components"
import { Portal } from "../Portal/Portal"
import { attachKeyboardListener } from "./attachKeyboardListener"

export type Popover = {
  reference: (node: Element | null) => void
  update: () => void
}

export type PopoverProps = PropsWithChildren<{
  popoverRef: RefCallback<Popover>
  isShow: boolean
  withBackdrop?: boolean
  closeOnBackdrop?: boolean
  closeOnEscape?: boolean
  onClose?: () => void
  crossAxis?: number
  mainAxis?: number
  className?: string
  classNameBody?: string
  placement?: Placement
}>

export const Popover = memo(function Popover(props: PropsWithChildren<PopoverProps>) {
  const {
    popoverRef,
    isShow,
    withBackdrop,
    closeOnBackdrop,
    closeOnEscape,
    onClose,
    crossAxis,
    mainAxis,
    children,
    className,
    classNameBody,
    placement,
  } = props

  const ref = useRef<HTMLDivElement | null>(null)
  const {
    update,
    refs: { setFloating, setReference },
    strategy,
    x,
    y,
  } = useFloating({
    strategy: "absolute",
    placement: placement ?? "bottom",
    middleware: [
      offset({
        crossAxis: crossAxis ?? 0,
        mainAxis: mainAxis ?? 10,
      }),
      shift(),
    ],
  })

  const { setWatcherRef } = useResizeWatcher(update)

  const floatingRef = useCallback(
    (element: HTMLDivElement | null) => {
      ref.current = element
      setWatcherRef(element)
      setFloating(element)
    },
    [setWatcherRef, setFloating],
  )

  const onCloseCb = useCallback(() => {
    if (onClose) {
      onClose()
    }
  }, [onClose])

  useVirtualBackground(isShow && withBackdrop === true && closeOnBackdrop === true, ref, onCloseCb)

  useEffect(() => (isShow && closeOnEscape === true ? attachKeyboardListener({ onEscape: onCloseCb }) : undefined), [
    isShow,
    closeOnEscape,
    onCloseCb,
  ])

  useEffect(() => (isShow ? watchWindowResize(update) : undefined), [isShow, update])

  useImperativeHandle(
    popoverRef,
    () => ({
      reference: setReference,
      update: update,
    }),
    [setReference, update],
  )

  const popoverBodyClasses = "portal-popover-body"

  if (!isShow) {
    return null
  }
  return (
    <Portal>
      {isShow && withBackdrop === true && <StyledBackdrop />}
      <StyledPopover
        ref={floatingRef}
        style={{
          position: strategy,
          top: y ?? 0,
          left: x ?? 0,
        }}
      >
        {children}
      </StyledPopover>
    </Portal>
  )
})

const StyledPopover = styled.div`
  z-index: 21474;
  max-width: 100%;
  max-height: 100%;
  border: none;
  border-radius: 8px;
  background-color: white;
  opacity: 1;
`

const StyledBackdrop = styled.div`
  position: fixed;
  left: -100vw;
  right: -100vw;
  top: -100vw;
  bottom: -100vw;
  z-index: 21474;
  user-select: none;
  cursor: default;
  opacity: 1;
`
