import styled from 'styled-components'
import { TextField as MuiTextField } from '@material-ui/core'

export const Wrapper = styled.div`
  width: 360px;
`;

export const Title = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: ${p => p.theme.text.fontMedium};
  line-height: 32px;
  color: ${p => p.theme.colors.contentMain};
  margin-bottom: 40px;
`;

export const TextField = styled(MuiTextField)`
  &.MuiFormControl-root {
    margin-bottom: 35px;
    width: 100%;
    position: relative;
  }
  .MuiFormLabel-root.Mui-focused {
    color: ${p => p.theme.colors.main};
  }
  .MuiInput-underline:after {
    border-color: ${p => p.theme.colors.main};
  }
  .MuiFormHelperText-root.Mui-error {
    position: absolute;
    bottom: -20px;
  }
`;
