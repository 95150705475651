import { Modal } from "@common/components/deprecated/Modal"
import { useAsyncDispatch } from "@common/hooks"
import { editAccountThunk } from "@common/services"
import { createSubcategories, currentAccount } from "@common/store"
import { Form as FinalForm } from "react-final-form"
import { useSelector } from "react-redux"
import { CreateSubcategory } from "./CreateSubcategory"
import { RecalculateAccount } from "./RecalculateAccount"

export const ModalForm = ({ open, setOpen, mode, value = "", setActiveSubcategory = _ => {} }) => {
  const asyncDispatch = useAsyncDispatch()
  const account = useSelector(currentAccount)

  const onCreateSubcategory = ({ name, category }) => {
    asyncDispatch(async dispatch => {
      const response = await dispatch(
        createSubcategories({
          name,
          category,
        }),
      )
      setActiveSubcategory(response)
    })
    setOpen(false)
  }

  const onRecalculate = ({ balance }) => {
    if (account === undefined) return
    asyncDispatch(
      editAccountThunk({
        balance,
        budget: account.budget,
        name: account.name,
        accountType: account.accountType,
        id: account.id,
      }),
    )
    setOpen(false)
  }
  return (
    <Modal open={open} setOpen={setOpen}>
      {
        {
          createSubcategory: (
            <FinalForm onSubmit={onCreateSubcategory} render={CreateSubcategory} initialValues={{ name: value }} />
          ),
          recalculation: (
            <FinalForm
              onSubmit={onRecalculate}
              render={e => RecalculateAccount({ ...e, onClick: () => setOpen(false) })}
            />
          ),
          transactionBill: <></>, // TODO пока нет бэка
        }[mode]
      }
    </Modal>
  )
}
