import ArrowIcon from "@assets/icons/chevron-bottom.svg"
import { getHumanReadableMonth, monthsTitles } from "@common/utils"
import { theme } from "@main/styles"
import { ReactDatePickerCustomHeaderProps } from "react-datepicker"
import styled from "styled-components"
import { CalendarPreviewMode } from "./Calendar"

interface Props extends ReactDatePickerCustomHeaderProps {
  mode: CalendarPreviewMode

  changeMode(mode: CalendarPreviewMode): void
}

export function CalendarHeader(props: any): any {
  const {
    monthDate,
    increaseMonth,
    decreaseMonth,
    changeYear,
    changeMonth,
    nextMonthButtonDisabled,
    prevMonthButtonDisabled,
    customHeaderCount,
    date,
    increaseYear,
    decreaseYear,
    changeMode,
    mode,
  } = props

  function onLeftArrowClick(mode: CalendarPreviewMode, nextYear: () => void, nextMonth: () => void) {
    if (mode === "default") {
      return nextMonth()
    }
    return nextYear()
  }

  function onRightArrowClick(mode: CalendarPreviewMode, prevYear: () => void, prevMonth: () => void): void {
    if (mode === "default") {
      return prevMonth()
    }
    return prevYear()
  }

  return (
    <HeaderContainer>
      <ArrowContainer
        onClick={() => onLeftArrowClick(mode, decreaseYear, decreaseMonth)}
        disabled={prevMonthButtonDisabled}
      >
        <ArrowIcon />
      </ArrowContainer>
      <CurrentDateContainer>
        <Text onClick={() => changeMode("monthsPicker")}>{monthsTitles[getHumanReadableMonth(date)]}</Text>
        <Text onClick={() => changeMode("yearsPicker")}>{date.getFullYear()}</Text>
      </CurrentDateContainer>
      <ArrowContainer
        onClick={() => onRightArrowClick(mode, increaseYear, increaseMonth)}
        disabled={nextMonthButtonDisabled}
        right
      >
        <ArrowIcon />
      </ArrowContainer>
    </HeaderContainer>
  )
}

const CurrentDateContainer = styled.div`
  padding: 0 16px;

  span + span {
    padding-left: 4px;
  }
`

const Text = styled.span`
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  color: ${p => p.theme.colors.main};
  font-family: Manrope, Inter, sans-serif;
  cursor: pointer;
  border-bottom: 1px solid transparent;
  transition: all 0.15s ease-in-out;

  &:hover {
    border-bottom: 1px solid ${p => p.theme.colors.lightGrey};
    transition: all 0.15s ease-in-out;
  }
`
const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 22px;
  padding: 0 12px 16px 12px;

  border-bottom: 1px solid ${p => p.theme.colors.lightnessGrey};
`

const ArrowContainer = styled.div<{ right?: boolean; disabled?: boolean }>`
  transform: rotate(90deg);
  cursor: pointer;

  ${p =>
    p.right &&
    `
   transform: rotate(-90deg);
  `}

  ${p =>
    p.disabled &&
    `
    cursor: not-allowed;
    
    svg > path {
      fill: ${theme.colors.placeholder};
    }
  `}
`
