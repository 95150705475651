import { StatusCode } from "@assets/index"
import { API } from "@common/api"
import {
  deleteUserFailureAction,
  deleteUserRequestAction,
  setIsAutogeneratedPassword,
  setNotification,
  updateEmailFailureAction,
  updateEmailRequestAction,
  updatePasswordFailureAction,
  updatePasswordRequestAction,
} from "@common/store"

export const deleteUser = () => async dispatch => {
  dispatch(deleteUserFailureAction(null))
  dispatch(deleteUserRequestAction(true))

  try {
    await API.delete("/users/me/")
    dispatch(deleteUserRequestAction(false))
  } catch (error) {
    dispatch(deleteUserFailureAction((error as any).message))
  }
}

export const createPassword = ({ password }) => async dispatch => {
  dispatch(updatePasswordFailureAction(null))
  dispatch(updatePasswordRequestAction(true))

  try {
    await API.post("/users/social_user_set_own_password/", { password })

    dispatch(setIsAutogeneratedPassword())
    dispatch(setNotification({ type: "success", message: "Пароль успешно задан" }))
  } catch (error) {
    dispatch(setNotification({ type: "error", message: (error as any).response.data.error }))
    dispatch(updatePasswordFailureAction((error as any).response.data))
  } finally {
    dispatch(updatePasswordRequestAction(false))
  }
}

export const updatePassword = ({ oldPassword, newPassword }) => async dispatch => {
  dispatch(updatePasswordFailureAction(null))
  dispatch(updatePasswordRequestAction(true))

  const bodyFormData = new FormData()
  bodyFormData.append("oldPassword", oldPassword)
  bodyFormData.append("newPassword", newPassword)

  try {
    await API.put("/users/me/password/", bodyFormData)

    dispatch(setNotification({ type: "success", message: "Пароль успешно изменен" }))
  } catch (error) {
    const message =
      (error as any).response.status === StatusCode.BAD_REQUEST
        ? "Текущий пароль введён неверно"
        : (error as any).message
    dispatch(setNotification({ type: "error", message: message }))
    dispatch(updatePasswordFailureAction((error as any).response.data))
  } finally {
    dispatch(updatePasswordRequestAction(false))
  }
}

export const updateEmail = ({ email, password }) => async dispatch => {
  dispatch(updateEmailFailureAction({}))
  dispatch(updateEmailRequestAction(true))

  const bodyFormData = new FormData()
  bodyFormData.append("email", email)
  bodyFormData.append("password", password)

  try {
    await API.put("/users/me/emal_change/", bodyFormData)

    dispatch(setNotification({ type: "success", message: "Email успешно изменен" }))
  } catch (error) {
    dispatch(updateEmailFailureAction((error as any).response.data))
  } finally {
    dispatch(updateEmailRequestAction(false))
  }
}
