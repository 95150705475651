import background from "@assets/background/background-auth.svg"
import image from "@assets/background/mockup-1.png"
import Logo from "@assets/icons/logo.svg"
import { media } from "@main/styles"
import styled from "styled-components"

export const AppStore = () => {
  return (
    <App>
      <Wrapper>
        <Logo />
        <Img src={image} alt="app-store" width="256" height="517" />
        <Link href="#">Доступно в AppStore и GooglePlay</Link>
      </Wrapper>
    </App>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${media.medium`
    width: 294px;
    margin: 0 auto;
    padding: 74px 0 64px;
  `}
`

const App = styled.div`
  margin-right: 24px;
  border-radius: 8px;
  background-color: ${props => props.theme.colors.main};
  background-image: url(${background.toString()});
  background-position: center;
  background-repeat: no-repeat;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  max-width: 600px;
  max-height: 800px;
`

const Link = styled.a`
  color: ${p => p.theme.colors.white};
  text-decoration: none;
  width: 294px;
`

const Img = styled.img`
  margin: 36px 0 46px;
  filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.15));
`
