import NumberOneIcon from "@assets/icons/number-one.svg"
import NumberSixIcon from "@assets/icons/number-six.svg"
import NumberTwelveIcon from "@assets/icons/number-twelve.svg"
import { media } from "@main/styles"
import styled, { css } from "styled-components"

export const Wrapper = styled.div``

export const Title = styled.div<{ indentRight?: string }>`
  font-style: normal;
  font-weight: bold;
  font-size: ${p => p.theme.text.fontExtraLarge};
  line-height: 60px;
  margin-top: 24px;
  margin-bottom: 40px;
  ${p => p.indentRight && `margin-right: ${p.indentRight}`};
  h1 {
    margin: 0;
  }
`

export const Container = styled.div`
  display: flex;
  margin: 0 -12px 40px -12px;

  ${media.mobile`
flex-direction: column;
margin: -12px 0;
`}
`

export const Column = styled.div`
  margin: 0 12px;
  width: 424px;
  * {
    box-sizing: border-box;
  }

  ${media.mobile`
  width: 100%;
  margin: 12px 0;
  `}
`

const styledIconWrap = css`
  position: relative;
  z-index: 5;
`

export const NumberOneIconWrap = styled(NumberOneIcon)`
  ${styledIconWrap}
`

export const NumberTwelveIconWrap = styled(NumberTwelveIcon)`
  ${styledIconWrap}
`

export const NumberSixIconWrap = styled(NumberSixIcon)`
  ${styledIconWrap}
`

export const Bottom = styled.div`
  display: flex;
  align-items: center;
  margin: 0 -27px;
  padding-top: 40px;
  margin-top: 47px;
  border-top: 1px solid ${p => p.theme.colors.backgroundSecondary};
`

export const BottomColumn = styled.div`
  width: 418px;
  margin: 0 27px;
`

export const Top = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 40px;
`

export const RateTitle = styled(Title)`
  margin-top: 0;
  margin-bottom: 0;
  font-size: ${p => p.theme.text.fontLarge};
  line-height: 44px;
`

export const IconWrapper = styled.div`
  min-height: 40px;
  min-width: 40px;
`
