import { PaymentType } from '@budget/view/table/modals/PurposeModals/CreatePurposeModal';
import { API } from '@common/api';
import {
  clearPurposeData,
  currentBudgetSelector,
  getCategoriesByDate,
  getCategoriesByDateThunk,
  getSubcategoriesByDateThunk,
  type IPurpose,
  type RootState,
  setNotification,
  setPurposeData,
  setPurposeError,
  setPurposeLoading,
  setPurposesData,
  setPurposesError,
  setPurposesLoading,
} from '@common/store';
import { stringifyDate } from '@common/utils';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const getPurposeThunk = createAsyncThunk<IPurpose[] | undefined, {
  subcategory: string,
}, { state: RootState }>(
  "CREATE_PURPOSE",
  async ({ subcategory }, { dispatch }) => {
    dispatch(setPurposeLoading(true));
    dispatch(setPurposeError(null));
    try {
      const { data } = await API.get<IPurpose[]>('/purposies/', {
        params: {
          subcategory,
        },
      });
      return data
    } catch (error) {
      dispatch(setNotification({ type: 'error', message: (error as any).message }));
      dispatch(setPurposeError((error as any).message));
    } finally {
      dispatch(setPurposeLoading(false));
    }
  },
);
export const getPurpose = ({ ...values }) => async dispatch => {
  dispatch(setPurposeLoading(true));
  dispatch(setPurposeError(null));
  try {
    const { data } = await API.get('/purposies/', { params: values });

    dispatch(setPurposeData(data[0]));
  } catch (error) {
    dispatch(setNotification({ type: 'error', message: (error as any).message }));
    dispatch(setPurposeError((error as any).message));
  } finally {
    dispatch(setPurposeLoading(false));
  }
};

export const getPurposes = id => async dispatch => {
  dispatch(setPurposesLoading(true));
  dispatch(setPurposesError(null));
  try {
    const { data } = await API.get(`/purposies/?budget=${id}`);

    dispatch(setPurposesData(data));
  } catch (error) {
    dispatch(setNotification({ type: 'error', message: (error as any).message }));
    dispatch(setPurposesError((error as any).message));
  } finally {
    dispatch(setPurposesLoading(false));
  }
};

export const createPurposeThunk = createAsyncThunk<void, {
  name: string,
  purposeType: string,
  subcategory: string,
  amount: string,
  fromDate: string,
  toDate: string | null,
  context: {
    paymentMode: PaymentType,
  },
}, { state: RootState }>(
  "CREATE_PURPOSE",
  async (dto, { dispatch, getState }) => {
    const {
      budgets: {
        date: { year, month, day },
      },
    } = getState();
    dispatch(setPurposeLoading(true));
    dispatch(setPurposeError(null));

    const currentBudget = currentBudgetSelector(getState());

    try {
      await API.post('/purposies/', dto);
      if (month !== null && day !== null) {
        void dispatch(getPurpose({
          subcategory: dto.subcategory,
          data: `${year}-${stringifyDate(month)}-${stringifyDate(day)}`,
        }));
      }

      void dispatch(getCategoriesByDate(currentBudget?.id, month, year));
      if (currentBudget?.id !== undefined && month !== null && year !== null) {
        dispatch(getSubcategoriesByDateThunk({ budgetId: currentBudget.id, month, year }));
      }

      dispatch(setNotification({ type: 'success', message: 'Цель добавлена' }));
    } catch (error) {
      dispatch(setNotification({ type: 'error', message: (error as any).response.data.error }));
      dispatch(setPurposeError(error as string));
    } finally {
      dispatch(setPurposeLoading(false));
    }
  },
);

export const createPurpose = ({ ...values }) => async (dispatch, getState) => {
  const {
    budgets: {
      date: { year, month, day },
    },
  } = getState();
  dispatch(setPurposeLoading(true));
  dispatch(setPurposeError(null));

  const currentBudget = currentBudgetSelector(getState());

  try {
    await API.post('/purposies/', { ...values });
    dispatch(
      getPurpose({ subcategory: values.subcategory, data: `${year}-${stringifyDate(month)}-${stringifyDate(day)}` }),
    );

    dispatch(getCategoriesByDate(currentBudget?.id, month, year));
    if (currentBudget?.id !== undefined && month !== null && year !== null) {
      dispatch(getSubcategoriesByDateThunk({ budgetId: currentBudget.id, month, year }));
    }

    dispatch(setNotification({ type: 'success', message: 'Цель добавлена' }));
  } catch (error) {
    dispatch(setNotification({ type: 'error', message: (error as any).response.data.error }));
    dispatch(setPurposeError(error as string));
  } finally {
    dispatch(setPurposeLoading(false));
  }
};

export const editPurposeThunk = createAsyncThunk<void, {
  id: string,
  data: {
    name: string,
    purposeType: string,
    subcategory: string,
    amount: string,
    fromDate: string,
    toDate: string | null,
    context: {
      paymentMode: string,
    },
  }
}, { state: RootState }>(
  "EDIT_PURPOSE",
  async ({ id, data }, { dispatch, getState }) => {
    const {
      budgets: {
        date: { year, month },
      },
    } = getState();
    dispatch(setPurposeLoading(true));
    dispatch(setPurposeError(null));

    const currentBudget = currentBudgetSelector(getState());

    try {
      await API.patch(`/purposies/${id}/`, { ...data });
      dispatch(getPurposeThunk({ subcategory: data.subcategory }))

      if (month !== null && year !== null && currentBudget?.id !== undefined) {
        dispatch(getCategoriesByDateThunk({ budgetId: currentBudget.id, month, year }));
        dispatch(getSubcategoriesByDateThunk({ budgetId: currentBudget.id, month, year }));
      }

      dispatch(setNotification({ type: 'success', message: 'Цель изменена' }));
    } catch (error) {
      dispatch(setNotification({ type: 'error', message: (error as any).response.data.error }));
      dispatch(setPurposeError(error as string));
    } finally {
      dispatch(setPurposeLoading(false));
    }
  },
);
export const editPurpose = ({ data, id }) => async (dispatch, getState) => {
  const {
    budgets: {
      date: { year, month, day },
    },
  } = getState();
  dispatch(setPurposeLoading(true));
  dispatch(setPurposeError(null));

  const currentBudget = currentBudgetSelector(getState());

  try {
    await API.patch(`/purposies/${id}/`, { ...data });
    dispatch(
      getPurpose({ subcategory: data.subcategory, data: `${year}-${stringifyDate(month)}-${stringifyDate(day)}` }),
    );

    dispatch(getCategoriesByDate(currentBudget?.id, month, year));

    if (currentBudget?.id !== undefined) {
      dispatch(getSubcategoriesByDateThunk({ budgetId: currentBudget.id, month, year }));
    }

    dispatch(setNotification({ type: 'success', message: 'Цель изменена' }));
  } catch (error) {
    dispatch(setNotification({ type: 'error', message: (error as any).response.data.error }));
    dispatch(setPurposeError(error as string));
  } finally {
    dispatch(setPurposeLoading(false));
  }
};

export const deletePurposeThunk = createAsyncThunk<void, {
  id: string,
}, { state: RootState }>(
  "DELETE_PURPOSE",
  async ({ id }, { dispatch, getState }) => {
    const {
      budgets: {
        date: { year, month },
      },
    } = getState();
    dispatch(setPurposeLoading(true));
    dispatch(setPurposeError(null));

    const currentBudget = currentBudgetSelector(getState());

    try {
      await API.delete(`/purposies/${id}/`);

      dispatch(getCategoriesByDate(currentBudget?.id, month, year));

      if (currentBudget?.id !== undefined && month !== null && year !== null) {
        dispatch(getSubcategoriesByDateThunk({ budgetId: currentBudget?.id, month, year }));
      }

      dispatch(setNotification({ type: 'success', message: 'Цель удалена' }));
      dispatch(clearPurposeData());
    } catch (error) {
      dispatch(setNotification({ type: 'error', message: (error as any).message }));
      dispatch(setPurposeError((error as any).message));
    } finally {
      dispatch(setPurposeLoading(false));
    }
  },
);

export const deletePurpose = id => async (dispatch, getState) => {
  const {
    budgets: {
      date: { year, month },
    },
  } = getState();
  dispatch(setPurposeLoading(true));
  dispatch(setPurposeError(null));

  const currentBudget= currentBudgetSelector(getState());

  try {
    await API.delete(`/purposies/${id}/`);

    dispatch(getCategoriesByDate(currentBudget?.id, month, year));

    if (currentBudget?.id !== undefined) {
      dispatch(getSubcategoriesByDateThunk({ budgetId: currentBudget.id, month, year }));
    }

    dispatch(setNotification({ type: 'success', message: 'Цель удалена' }));
    dispatch(clearPurposeData());
  } catch (error) {
    dispatch(setNotification({ type: 'error', message: (error as any).message }));
    dispatch(setPurposeError((error as any).message));
  } finally {
    dispatch(setPurposeLoading(false));
  }
};
