import styled from "styled-components"
import { InputLabel, MenuItem } from "@material-ui/core"

export const StyledLabel = styled(InputLabel)`
  &.MuiFormLabel-root {
    font-size: 16px;
    line-height: 22px;
    font-family: Manrope, Inter, sans-serif;
    font-weight: 600;
    color: ${p => p.theme.colors.darkText};

    margin-bottom: 11px;
    padding: 0 16px;
  }
`

export const StyledMenuItem = styled(MenuItem)`
  &.MuiListItem-root{
    font-size: 16px;
    line-height: 22px;
    font-family: Manrope, Inter, sans-serif;
    font-weight: 700;
    color: ${p => p.theme.colors.darkText};

    padding: 12px 16px;
  }
`
