import { IconButton, InputAdornment } from "@material-ui/core"
import { Visibility, VisibilityOff } from "@material-ui/icons"
import { FC } from "react"

export type PasswordEndAdornmentType = "text" | "password"

interface IPasswordEndAdornment {
  fieldType: PasswordEndAdornmentType
  onClick: () => void
}

export const PasswordEndAdornment: FC<IPasswordEndAdornment> = ({ fieldType, onClick }) => {
  return (
    <InputAdornment position="end">
      <IconButton aria-label="toggle password visibility" edge="end" onClick={onClick}>
        {fieldType === "text" ? <VisibilityOff /> : <Visibility />}
      </IconButton>
    </InputAdornment>
  )
}
