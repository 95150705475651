import styled from 'styled-components'
import { IconButton } from '@material-ui/core'

type TableCellType = 'income' | 'expense';

interface TableCellProps {
  type?: TableCellType;
}

function getTableCellColor(theme, type: TableCellType | undefined): string {
  if (type === 'income') {
    return theme.colors.main;
  }

  if (type === 'expense') {
    return theme.colors.negative;
  }

  return 'black';
}

export const PageContainer = styled.div`
  width: 100%;
  height: 100%;
`;

export const TableContainer = styled.div`
  width: 100%;
  height: calc(100% - 122px);
  border-top: 1px solid ${({ theme }) => theme.colors.backgroundSecondary};
  overflow-x: auto;
`;

export const ActionsContainer = styled.div`
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TopBarContainer = styled.div`
  padding: 48px 24px 24px 24px;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TableHead = styled.div`
  width: max-content;
  min-width: 100%;
  height: 50px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.backgroundSecondary};
  display: flex;
  justify-content: space-between;
  padding: 0 12px;
  box-sizing: border-box;
`;

export const TableBody = styled.div`
  width: 100%;
  height: calc(100% - 100px);
`;

export const TableFooter = styled.div`
  width: 100%;
  height: 50px;
  border-top: 1px solid ${({ theme }) => theme.colors.backgroundSecondary};
  display: flex;
  justify-content: space-between;
  padding: 0 12px;
  box-sizing: border-box;
`;

export const TableLine = styled.div`
  width: max-content;
  min-width: 100%;
  height: 50px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.backgroundSecondary};
  display: flex;
  justify-content: space-between;
  padding: 0 12px;
  box-sizing: border-box;
`;

export const TableCell = styled.div<TableCellProps>`
  height: 100%;
  display: flex;
  align-items: center;
  color: ${({ theme, type }) => getTableCellColor(theme, type)};
`;

export const CellText = styled.a`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Expander = styled(TableCell)`
  justify-content: center;
  min-width: 50px;
`;

export const Divider = styled(TableCell)`
  width: 100%;
`;

export const Title = styled(TableCell)`
  min-width: 350px;
  padding-left: 12px;
`;

export const Regular = styled(TableCell)`
  min-width: 180px;
  max-width: 180px;
  padding-left: 12px;
  overflow: hidden;
`;

export const BoldTitle = styled(Title)`
  font-weight: 600;
`;

export const BoldRegular = styled(Regular)`
  font-weight: 600;
`;

export const RegularMeta = styled(BoldRegular)`
  color: ${({ theme }) => theme.colors.contentSecondary};
`;

export const TitleMeta = styled(BoldTitle)`
  color: ${({ theme }) => theme.colors.contentSecondary};
`;

export const Content = styled.div<{ $height: number }>`
  max-height: ${({ $height }) => `${$height}px`};
  transition: max-height 0.3s ease-in-out;
  overflow: hidden;
  width: max-content;
  min-width: 100%;
`;

export const Button = styled(IconButton)<{ $isOpen: boolean }>`
  transition: transform 0.3s ease;
  ${({ $isOpen }) => $isOpen && 'transform: rotate(180deg)'}
`;

export const ExportLink = styled.a`
  display: flex;
  text-decoration: none;
  color: ${p => p.theme.colors.main};
  margin-right: 24px;
  align-items: center;
`;
