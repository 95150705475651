import React, { Dispatch, SetStateAction, ReactNode, FC } from 'react'
import { Backdrop, Fade } from '@material-ui/core'
import { Paper, Card, Close, Wrapper } from './styles'
import CloseIcon from '@assets/icons/close.svg'

interface IModal {
  open: boolean;
  setOpen?: Dispatch<SetStateAction<boolean>>;
  children: ReactNode;
  mod?: 'clean';
  onClose?: () => void;
}

export const Modal: FC<IModal> = ({ open, setOpen, children, mod, onClose }) => {
  const handleClose = () => {
    if (onClose) onClose();
    else setOpen?.(false);
  };

  return (
    <Paper
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Wrapper>
          <Card raised $mod={mod}>
            {children}
          </Card>
          {setOpen && (
            <Close onClick={handleClose}>
              <CloseIcon />
            </Close>
          )}
        </Wrapper>
      </Fade>
    </Paper>
  );
};
