import styled from "styled-components";
import { ReactElement } from "react";

type Props = {
  onClick: () => void
  label: string
  Icon: ReactElement
  $disabled?: boolean
  hideText?: boolean
}

export function ButtonComponent(props: Props) {
  const { onClick, Icon, label, $disabled, hideText } = props
  return (
    <ButtonWrapper onClick={onClick} $disabled={$disabled}>
      {Icon}
      {!hideText && <Label>{label}</Label>}
    </ButtonWrapper>
  )
}

const ButtonWrapper = styled.button<{ $disabled?: boolean }>`
  display: flex;
  align-items: center;
  column-gap: 8px;
  border: 0;
  background: white;
  cursor: pointer;
  padding: 0;

  svg > path {
    fill: child;
  }

  ${p => p.$disabled && `
    cursor: initial;
    svg > path {
      fill: #61698A !important;
    }
  `}
  
  &:hover {
    svg > path {
      ${p => !p.$disabled && `
        cursor: pointer;
        fill: #13BDCE;
      `}
    }

    span {
      ${p => !p.$disabled && `
        color: #13BDCE;
      `}
    }
  }
`;

const Label = styled.span`
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  font-family: Manrope, Inter, sans-serif;
  color: ${p => p.theme.colors.lightText};
  text-transform: lowercase;

  &:first-letter {
    text-transform: uppercase;
  }

  @media (max-width: 1200px) {
    font-size: 12px;
    line-height: 14px;
  }
`;
