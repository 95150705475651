import { scheduleNow, scheduleRaf } from "@common/core"
import { useCallback, useRef } from "react"
import useResizeObserver from "use-resize-observer"
import { useDebounce } from "./useDebounce"
import { useScheduler } from "./useScheduler"

export function useResizeWatcher(
  callback: (element: HTMLElement) => void,
  debounce?: number,
  skipFirstResize: boolean = true,
): {
  setWatcherRef: (element: HTMLElement | null) => void
} {
  const firstResizeRef = useRef(true)
  const ref = useRef<HTMLElement | null>(null)

  const runCallback = useCallback(() => {
    if (ref.current) {
      callback(ref.current)
    }
  }, [callback])

  const runCallbackRaf = useScheduler(scheduleRaf, runCallback)
  const runCallbackDebounced = useDebounce(debounce ?? 0, scheduleNow)

  const { ref: resizeRef } = useResizeObserver<HTMLElement>({
    onResize: () => {
      if (firstResizeRef.current) {
        firstResizeRef.current = false
        if (skipFirstResize) {
          return
        }
      }
      if (debounce !== undefined) {
        runCallbackDebounced(runCallbackRaf)
      } else {
        runCallbackRaf()
      }
    },
  })

  const setWatcherRef = useCallback(
    (element: HTMLElement | null) => {
      ref.current = element
      resizeRef(element)
    },
    [resizeRef],
  )

  return {
    setWatcherRef,
  }
}
