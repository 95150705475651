import { API } from "@common/api";
import { Category, GetCategoriesQuery } from "@common/models";
import { stringifyDate } from "@common/utils";

export type CategoryTransportType = {
  getCategories: (query?: GetCategoriesQuery) => Promise<Category[]>;
  getBudgetCategoriesByDate: (budgetId: string, month: number, year: number) => Promise<Category[]>;
};
export const CategoryTransport: CategoryTransportType = {
  getCategories: async (query?: GetCategoriesQuery): Promise<Category[]> => {
    const { data } = await API.get<Category[]>("/categories/", { params: query });
    return data;
  },
  getBudgetCategoriesByDate: async (budgetId: string, month: number, year: number): Promise<Category[]> => {
    const { data } = await API.get<Category[]>(`/categories/${stringifyDate(month)}/${year}/`, {
      params: { budget: budgetId },
    });
    return data;
  },
};
