import { Select } from "@common/components/ui";
import { useAsyncDispatch } from "@common/hooks";
import { categoriesList, currentBudgetSelector, getCategoriesThunk } from "@common/store";
import { InputLabel, MenuItem } from "@material-ui/core";
import { useEffect } from "react";
import { Field } from "react-final-form";
import { useSelector } from "react-redux";
import { Form, FormControl, ModalField, Submit } from "./styles";

export const CreateSubcategory = ({ handleSubmit, submitting, pristine, valid }) => {
  const asyncDispatch = useAsyncDispatch();

  const categories = useSelector(categoriesList);
  const currentBudget = useSelector(currentBudgetSelector);

  const disabled = submitting || !valid || pristine;

  useEffect(() => {
    if (currentBudget?.id === undefined) {
      return;
    }
    asyncDispatch(getCategoriesThunk({ budget: currentBudget?.id }));
  }, [asyncDispatch, currentBudget]);

  return (
    <Form onSubmit={handleSubmit}>
      <h2>Создание подкатегории</h2>
      <Field name="name" validate={value => !value && "Required"}>
        {({ input }) => (
          <ModalField
            name={input.name}
            value={input.value}
            onChange={input.onChange}
            id="name"
            label="Наименование"
            type="text"
          />
        )}
      </Field>
      <Field name="category" validate={value => !value && "Required"}>
        {({ input: { value, onChange, name } }) => (
          <FormControl>
            <InputLabel htmlFor="category">Категория</InputLabel>
            <Select name={name} labelId="category" value={value} onChange={onChange}>
              {!!categories.length &&
                categories.map(({ id, name }) => (
                  <MenuItem key={id} value={id}>
                    {name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        )}
      </Field>
      <Submit type="submit" disabled={disabled}>
        Сохранить
      </Submit>
    </Form>
  );
};
