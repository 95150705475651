import { API_V2 } from "@common/api";
import { AccountShortView, CacheAccount } from "@common/models";
import { AccountType } from "@common/store";

export type AccountServiceType = {
  getAccounts: () => Promise<CacheAccount[]>;
  getAccountTypes: () => Promise<AccountType[]>;
  createAccount: (account: AccountShortView) => Promise<CacheAccount>;
  updateAccount: (id: string, account: AccountShortView) => Promise<CacheAccount>;
  deleteAccount: (id: string) => Promise<void>;
  getAccount: (id: string) => Promise<CacheAccount>;
};
export const AccountTransport: AccountServiceType = {
  getAccounts: async (): Promise<CacheAccount[]> => {
    const { data } = await API_V2.get<CacheAccount[]>(`/accounts/`);
    // const { data } = await API.get<CacheAccount[]>(`/accounts/`);
    return data;
  },
  getAccountTypes: async (): Promise<AccountType[]> => {
    const { data } = await API_V2.get<AccountType[]>(`/accounts/types/`);
    // const { data } = await API.get<AccountType[]>(`/accounts/types/`);
    return data;
  },
  createAccount: async (account: AccountShortView): Promise<CacheAccount> => {
    const { data } = await API_V2.post<CacheAccount>(`/accounts/`, account);
    // const { data } = await API.post<CacheAccount>(`/accounts/`, account);
    return data;
  },
  updateAccount: async (id: string, account: AccountShortView): Promise<CacheAccount> => {
    const { data } = await API_V2.put<CacheAccount>(`/accounts/${id}/`, account);
    // const { data } = await API.put<CacheAccount>(`/accounts/${id}/`, account);
    return data;
  },
  deleteAccount: async (id: string): Promise<void> => {
    return API_V2.delete(`/accounts/${id}/`);
    // return API.delete(`/accounts/${id}/`);
  },
  getAccount: async (id: string): Promise<CacheAccount> => {
    const { data } = await API_V2.get<CacheAccount>(`/accounts/${id}/`);
    // const { data } = await API.get<CacheAccount>(`/accounts/${id}/`);
    return data
  },
};
