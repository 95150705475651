import { useUnwrapAsyncDispatch } from "@common/hooks"
import { getAccountInfoThunk } from "@common/services"
import { accountsListOptions, currentAccount, setCurrentAccount } from "@common/store"
import { useState } from "react"
import { Field } from "react-final-form"
import { useDispatch, useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
import { CreatableSelect } from "../Table/Row/styles"
import { Background, Balance, Button, Edit, Form, ModalSum, Submit, Title, Wrapper } from "./styles"

export const RecalculateAccount = ({ handleSubmit, submitting, valid, onClick }) => {
  const dispatch = useDispatch()
  const account = useSelector(currentAccount)
  const [editMode, setEditMode] = useState(false)
  const disabled = submitting || !valid
  const walletOptions = useSelector(accountsListOptions)
  const asyncDispatch = useUnwrapAsyncDispatch()
  const { pathname } = useLocation()
  const enableSelectWallet = pathname.includes("transactions")
  const getWalletById = async e => {
    await asyncDispatch(getAccountInfoThunk({ id: e.value })).then(data => dispatch(setCurrentAccount(data)))
  }

  return (
    <Form onSubmit={handleSubmit}>
      <h2>Перерасчет</h2>
      {!editMode ? (
        <>
          {enableSelectWallet && (
            <>
              <h4>Выберите счет</h4>
              <CreatableSelect
                onChange={e => void getWalletById(e)}
                options={walletOptions}
                classNamePrefix="select"
                placeholder="Счет"
                className="select"
                $error={false}
              />
            </>
          )}
          {account?.currentBalance && (
            <>
              <Background>
                <Title>Текущий баланс по счету:</Title>

                <Balance>{account.currentBalance} ₽</Balance>
              </Background>
              <Wrapper>
                <Button onClick={onClick}>Подтвердить</Button>
                <Edit variant="outlined" onClick={() => setEditMode(true)}>
                  Изменить
                </Edit>
              </Wrapper>
            </>
          )}
        </>
      ) : (
        <>
          <Background>
            <Title>Укажите баланс счета</Title>
            <Field name="balance" validate={value => !value && "Required"}>
              {({ input: { value, onChange, name }, meta: { error, pristine } }) => (
                // TODO валидация формы и маска
                <ModalSum
                  name={name}
                  value={value}
                  onChange={onChange}
                  id={name}
                  type="number"
                  error={!!error && !pristine}
                  autoComplete="off"
                  label="Сумма"
                />
              )}
            </Field>
          </Background>
          <Submit type="submit" disabled={disabled}>
            Сохранить
          </Submit>
        </>
      )}
    </Form>
  )
}
