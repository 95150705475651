import styled from 'styled-components'
import { Paper } from '@material-ui/core'

export const Cell = styled.div`
  padding: 24px 0;
  &:first-child {
    font-weight: 600;
    padding-right: 24px;
  }
  &:nth-child(2) {
    font-weight: 600;
    padding-right: 24px;
  }
  &:last-child {
    margin-left: auto;
  }
`;

export const Section = styled.section`
  width: 100%;

  .MuiPaper-root:first-child > div {
    padding-top: 0;
  }
`;

export const SubCategory = styled(Paper)`
  &.MuiPaper-root {
    display: grid;
    grid-template-columns: 1fr 1fr 1.5fr 184px;
    color: ${p => p.theme.colors.contentMain};
    box-shadow: none;
    font-size: 16px;
    font-weight: normal;
    line-height: 24px;
    text-align: left;
    background-color: ${p => p.theme.colors.white};
    border-top: 1px solid ${p => p.theme.colors.backgroundSecondary};

    &:first-child {
      border-top: none;
    }
  }
`;

export const NoDataMessage = styled.div`
  padding-bottom: 40px;
`;
