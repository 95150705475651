import { API } from "@common/api"
import {
  setNotification,
  setNotificationsError,
  setNotificationSingle,
  setNotificationsList,
  setNotificationsLoading,
} from "@common/store"

export const getNotifications = () => async dispatch => {
  dispatch(setNotificationsLoading(true))
  try {
    const { data } = await API.get("/admin/notifications/")
    dispatch(setNotificationsList(data))
  } catch (error) {
    dispatch(setNotification({ type: "error", message: (error as any).message }))
    dispatch(setNotificationsError((error as any).message))
  } finally {
    dispatch(setNotificationsLoading(false))
  }
}

export const updateNotification = ({
  id,
  title,
  body,
}: {
  id: number
  title: string
  body: string
}) => async dispatch => {
  dispatch(setNotificationsLoading(true))
  try {
    const { data } = await API.patch(`/admin/notifications/${id}/`, { title, body })

    dispatch(setNotificationSingle(data))
  } catch (error) {
    dispatch(setNotification({ type: "error", message: (error as any).message }))
    dispatch(setNotificationsError((error as any).message))
  } finally {
    dispatch(setNotificationsLoading(false))
  }
}
