import categoriesService from '@budget/services/categories.service';
import { setTableCategoryItems } from '@budget/store';
import { type RootState } from '@common/store';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const updateCurrentMonthTable = createAsyncThunk<void, void, { state: RootState }>(
  "UPDATE_CURRENT_MONTH_TABLE",
  async (_, { dispatch, getState }) => {
    const currentBudget = getState().budgets.currentBudget
    const currentDate = getState().budgets.date
    if (currentBudget?.id !== undefined && currentDate.month !== null && currentDate.year !== null) {
      dispatch(categoriesService.loadBudgetCategoriesByDate({
        budgetId: currentBudget.id,
        month: currentDate.month,
        year: currentDate.year,
      }))
        .unwrap()
        .then((response) => {
          if (response === undefined) return
          dispatch(setTableCategoryItems([ ...response ]))
        });
    }
  },
)();
