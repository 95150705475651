import { CacheAccount } from "@common/models"
import { createSlice, PayloadAction, Slice, SliceCaseReducers } from "@reduxjs/toolkit"

interface LayoutState {
  accountsList: CacheAccount[]
}

const initialState: LayoutState = {
  accountsList: [],
}

interface LayoutActions extends SliceCaseReducers<LayoutState> {
  setLayoutAccountsList: (state: LayoutState, action: PayloadAction<CacheAccount[]>) => void
}

const layoutSlice: Slice<LayoutState, LayoutActions, "layout"> = createSlice({
  name: "layout",
  initialState: initialState,
  reducers: {
    setLayoutAccountsList: (state, action: PayloadAction<CacheAccount[]>): void => {
      state.accountsList = action.payload
    },
  },
})

export const { setLayoutAccountsList } = layoutSlice.actions

export const layoutReducer = layoutSlice.reducer
