import {
  setBudgetByDate,
  setBudgetsError,
  setBudgetsList,
  setBudgetsListEmpty,
  setBudgetsLoading,
  setCurrentBudget,
  setNotification,
} from "@common/store";
import { BudgetTransport } from "@common/transports";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { localStorageService } from "@common/services/localStorage.service";

export const getBudgetsListThunk = createAsyncThunk<void, void>("GET_BUDGETS_LIST", async (_, { dispatch }) => {
  dispatch(setBudgetsLoading(true));

  try {
    const budgets = await BudgetTransport.getBudgets();

    dispatch(setBudgetsList(budgets));
    dispatch(setBudgetsListEmpty(!budgets.length));
  } catch (error) {
    dispatch(setNotification({ type: "error", message: (error as any).message }));
    dispatch(setBudgetsError((error as any).message));
  } finally {
    dispatch(setBudgetsLoading(false));
  }
})();

export const getBudgetThunk = createAsyncThunk<void, { id: string }>("GET_Budget", async ({ id }, { dispatch }) => {
  dispatch(setBudgetsLoading(true));

  try {
    const currentBudget = await BudgetTransport.getBudgetById(id);
    dispatch(setCurrentBudget(currentBudget));
    localStorageService.update("currentBudgetId", id);
  } catch (error) {
    dispatch(setNotification({ type: "error", message: (error as any).message }));
    dispatch(setBudgetsError((error as any).message));
    throw new Error((error as any)?.message ?? "Error current budget load");
  } finally {
    dispatch(setBudgetsLoading(false));
  }
});

type getBudgetByDateParams = {
  budgetId: string;
  month: number;
  year: number;
};

export const getBudgetByDateThunk = createAsyncThunk<void, getBudgetByDateParams>(
  "GET_BUDGET_BY_DATE",
  async ({ budgetId, month, year }, thunkAPI) => {
    thunkAPI.dispatch(setBudgetsLoading(true));

    try {
      const budget = await BudgetTransport.getBudgetByDate(budgetId, year, month);
      thunkAPI.dispatch(setBudgetByDate(budget));
    } catch (error) {
      thunkAPI.dispatch(setNotification({ type: "error", message: (error as any).message }));
      thunkAPI.dispatch(setBudgetsError((error as any).message));
    } finally {
      thunkAPI.dispatch(setBudgetsLoading(false));
    }
  },
);

/// страница создать/выбрать бюджет

export const getOnlyBudgetsListThunk = createAsyncThunk<void, void>("GET_ONLY_BUDGETS_LIST", async (_, thunkAPI) => {
  thunkAPI.dispatch(setBudgetsLoading(true));

  try {
    const budget = await BudgetTransport.getBudgets();
    thunkAPI.dispatch(setBudgetsList(budget));
    thunkAPI.dispatch(setBudgetsListEmpty(!budget.length));
  } catch (error) {
    thunkAPI.dispatch(setNotification({ type: "error", message: (error as any).message }));
    thunkAPI.dispatch(setBudgetsError((error as any).message));
  } finally {
    thunkAPI.dispatch(setBudgetsLoading(false));
  }
})();

export type CreateBudgetType = {
  name: string;
};

export const addBudgetToListThunk = createAsyncThunk<void, CreateBudgetType>(
  "ADD_BUDGET_TO_LIST",
  async ({ name }, thunk) => {
    thunk.dispatch(setBudgetsLoading(true));

    try {
      await BudgetTransport.createBudget(name);
    } catch (error) {
      thunk.dispatch(setNotification({ type: "error", message: (error as any).response.data.error }));
      thunk.dispatch(setBudgetsError((error as any).response.data.error));
    } finally {
      thunk.dispatch(setBudgetsLoading(false));
    }
  },
);

export const deleteBudgetThunk = createAsyncThunk<void, { id: string }>("DELETE_BUDGET", async ({ id }, thunkAPI) => {
  thunkAPI.dispatch(setBudgetsLoading(true));

  try {
    await BudgetTransport.deleteBudget(id);
  } catch (error) {
    thunkAPI.dispatch(setNotification({ type: "error", message: (error as any).message }));
    thunkAPI.dispatch(setBudgetsError((error as any).message));
  } finally {
    thunkAPI.dispatch(setBudgetsLoading(false));
  }
});

export const resetBudgetThunk = createAsyncThunk<void, { id: string }>("RESET_BUDGET", async ({ id }, { dispatch }) => {
  dispatch(setBudgetsLoading(true));

  try {
    const budget = await BudgetTransport.resetBudget(id);
    dispatch(getOnlyBudgetsListThunk)
      .then(() => dispatch(setCurrentBudget(budget)))
      .then(() => dispatch(setNotification({ type: "success", message: "Бюджет успешно сброшен" })));
  } catch (error) {
    dispatch(setNotification({ type: "error", message: (error as any).message }));
    dispatch(setBudgetsError((error as any).message));
  } finally {
    dispatch(setBudgetsLoading(false));
  }
});

export const editBudgetThunk = createAsyncThunk<void, { id: string; name: string | undefined }>(
  "EDIT_BUDGET",
  async ({ id, name }, thunkAPI) => {
    thunkAPI.dispatch(setBudgetsLoading(true));

    try {
      await BudgetTransport.updateBudget({ id, name });
    } catch (error) {
      thunkAPI.dispatch(setNotification({ type: "error", message: (error as any).response.data.error }));
      thunkAPI.dispatch(setBudgetsError(error as string));
    } finally {
      thunkAPI.dispatch(setBudgetsLoading(false));
    }
  },
);
