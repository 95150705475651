import RemoveIcon from "@assets/icons/close.svg"
import SearchIcon from "@assets/icons/search.svg"
import { media } from "@main/styles"
import { IconButton, Button as MuiButton, Tabs as MuiTabs, Paper, TextField } from "@material-ui/core"
import styled from "styled-components"

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  justify-content: space-between;
`

export const More = styled(IconButton)`
  &.MuiButtonBase-root {
    background-color: ${p => p.theme.colors.white};
    border-radius: 50%;
    margin-left: 24px;
    padding: 8px;
  }
`

export const Back = styled(IconButton)`
  &.MuiButtonBase-root {
    margin-right: 16px;
    padding: 8px;
  }
`

export const Title = styled.div`
  display: flex;
  align-items: center;

  h1 {
    margin-bottom: 0;
  }
`

export const Tabs = styled(MuiTabs)`
  & .MuiTabs-flexContainer {
    border-bottom: 2px ${p => p.theme.colors.light} solid;
  }
  & .MuiButtonBase-root {
    padding: 16px 24px;
  }

  & .MuiTabs-indicator {
    background: ${p => p.theme.colors.main};
  }

  & .MuiTab-wrapper {
    text-transform: none;
    font-weight: 600;
    color: ${p => p.theme.colors.contentMain};
  }

  & .Mui-selected .MuiTab-wrapper {
    color: ${p => p.theme.colors.main};
  }

  & .PrivateTabIndicator-colorPrimary-2 {
    background-color: ${p => p.theme.colors.main};
  }
`

export const Options = styled(Paper)`
  position: absolute;
  width: 456px;
  right: 0;
  top: 52px;
  padding: 8px 0;
  z-index: 10;
`

export const Box = styled.div`
  position: relative;
  z-index: 100;
`

export const SearchField = styled(TextField)`
  width: 360px;
  & .MuiInputBase-input.MuiInput-input {
    padding: 8px 16px;
    font-family: inherit;
    line-height: inherit;
    font-size: inherit;
    background-color: ${p => p.theme.colors.white};
    border-radius: 4px;
  }
  & .MuiInput-underline:before {
    border: none;
  }
  .MuiFormLabel-root.Mui-focused {
    color: ${p => p.theme.colors.main};
  }
  .MuiInput-underline:after {
    border-color: ${p => p.theme.colors.main};
  }
  .MuiFormHelperText-root.Mui-error {
    position: absolute;
    bottom: -20px;
  }
  & .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: none;
  }
`

export const Item = styled.li`
  padding: 8px 16px;
  cursor: pointer;
  &:hover,
  &:focus {
    background-color: ${p => p.theme.colors.backgroundMain};
  }
`

export const Icon = styled(SearchIcon)`
  position: absolute;
  right: 10px;
  bottom: 6px;
`

export const ButtonRemove = styled(RemoveIcon)`
  cursor: pointer;
  position: absolute;
  right: 40px;
  bottom: 6px;
`

export const List = styled.ul`
  display: flex;
`

export const Label = styled.span`
  padding-left: 10px;
  text-transform: none;
`

export const NonRequiredPart = styled.span`
  text-transform: none;
  ${media.medium`
    display: none;
  `}
`

export const InputLabel = styled.label`
  padding-left: 10px;
  text-transform: none;
`

export const ManageList = styled.ul`
  display: flex;
`

export const ManageItem = styled.li`
  margin-right: 26px;
  display: flex;
  align-items: center;
`

export const Button = styled(MuiButton)`
  && {
    color: ${p => p.theme.colors.main};
    padding: 0;

    svg {
      path {
        fill: ${p => p.theme.colors.main};
      }
    }

    &:disabled {
      color: ${p => p.theme.colors.contentSecondary};
      font-weight: 400;
      svg {
        path {
          fill: ${p => p.theme.colors.contentSecondary};
        }
      }
    }
  }
`

export const Container = styled.div<{ justifyContent: string }>`
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent || "end"};
  padding: 40px 24px 24px;
  border-bottom: 1px ${p => p.theme.colors.backgroundSecondary} solid;
`

export const Controls = styled.div<{ $bigIndent?: boolean }>`
  display: flex;
  align-items: center;
  margin: -8px;
  padding-left: ${p => (p.$bigIndent ? "16px" : "24px")};
`
