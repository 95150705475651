import styled from 'styled-components'
import { IconButton } from '@material-ui/core';

export const Container = styled.div`
  height: 40px;
  width: 288px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${({ theme }) => theme.colors.backgroundSecondary};
  border-radius: 4px;
  padding: 2px 8px;
  box-sizing: border-box;
  margin-right: 20px;
`;

export const Input = styled.input`
  border: none;
  outline: none;

  &::placeholder {
    color: ${({ theme }) => theme.colors.contentSecondary};
  }
`;

export const Button = styled(IconButton)`
  span {
    svg {
      path {
        fill: ${({ theme }) => theme.colors.main};
      }
    }
  }
`;
