import React, { ButtonHTMLAttributes, DetailedHTMLProps, FC, ReactElement } from 'react';
import { Button, Link } from './styles';

export enum ButtonVariants {
  primary = 'primary',
  outlined = 'outlined',
}

interface ButtonMainProps extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  href?: string;
  children: ReactElement | string;
  type?: 'button' | 'submit';
  disabled?: boolean;
  onClick?: () => void;
  fullWidth?: boolean;
  size?: string;
  indentTop?: string;
  styleLink?: boolean;
  padX?: number;
  variant?: ButtonVariants;
}

export const ButtonMain: FC<ButtonMainProps> = ({
  href,
  children,
  type = 'button',
  disabled = false,
  onClick,
  fullWidth = false,
  size,
  indentTop,
  styleLink,
  padX,
  variant = ButtonVariants.primary,
}) => {
  let button: null | ReactElement = null;

  if (href) {
    button = <Link href={href}>{children}</Link>;
  } else {
    button = (
      <Button
        type={type}
        disabled={disabled}
        onClick={onClick}
        fullWidth={fullWidth}
        size={size}
        padX={padX}
        $indentTop={indentTop}
        $styleLink={styleLink}
        variant={variant}
      >
        {children}
      </Button>
    );
  }

  return button;
};
