import ExpandMoreIcon from "@assets/icons/expandmore.svg";
import { media, sizes } from "@main/styles";
import { List, ListItemIcon } from "@material-ui/core";
import { ComponentType } from "react";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

interface IconProps {
  open?: boolean;
}

const textEllipsis = css`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

// global
export const DropdownWrapper = styled.div`
  width: 100%;

  ${media.medium(`
  
  `)};

  @media (max-width: "${sizes.mobile}px") {
    ${css``};
  }
`;
export const MainItemContainer = styled.span`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 8px;
`;
export const MainDropdownItemTitle = styled.span`
  max-width: 165px;
  font-size: 14px;
  line-height: 21px;
  font-family: Manrope, Inter, sans-serif;
  font-weight: 400;

  color: ${p => p.theme.colors.purple};
  ${textEllipsis};
`;
export const UserNav = styled(List)<{ $active?: boolean; $common?: boolean }>`
  margin-top: auto;

  ${p =>
    p.$active &&
    `
        overflow: hidden;
      `}
  ${p =>
    p.$common !== true &&
    `
      li + li {
        margin-top: 20px;
      }
    `}
  & .MuiListItem-root {
    padding: 5px 15px;
  }
`;

// item
const iconActiveStyle = css`
  svg > path {
    transition: all 0.15s ease-in-out;
    fill: ${p => p.theme.colors.main};
  }
`;

const iconDefaultStyle = css`
  svg > path {
    transition: all 0.15s ease-in-out;
    fill: ${p => p.theme.colors.purple};
  }
`;

export const DropdownLinkItem = styled(Link)<{ $bold?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  column-gap: 8px;
  cursor: pointer;
  text-decoration: none;
  width: 100%;

  &:hover {
    color: ${p => p.theme.colors.main};
    font-weight: 500;
  }

  a,
  span {
    color: ${p => p.theme.colors.purple};
    font-family: Manrope, Inter, sans-sarif;
    font-size: 14px;
    line-height: 1.5;
    font-weight: 400;
    display: inline-block;

    ${p =>
      p?.$bold &&
      `
      font-weight: 600;
    `}
    ${textEllipsis};
  }

  svg > path {
    fill: ${p => p.theme.colors.purple};
  }

  &:hover {
    svg > path {
      fill: ${p => p.theme.colors.main};
    }

    a,
    span {
      color: ${p => p.theme.colors.main};
    }
  }
`;

export const PriceText = styled.p`
  color: ${p => p.theme.colors.green};
  font-family: Manrope, Inter, sans-sarif;
  font-size: 12px;
  font-weight: 500;
`;

export const HeaderItemContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
  width: 100%;
`;

export const IconWrapper = styled.div`
  min-width: 24px;

  display: flex;
  align-items: center;
`;

export const ItemTitle = styled.span`
  ${textEllipsis};
  font-family: Manrope, Inter, sans-serif;
`;

export const DropdownTextItem = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  column-gap: 8px;

  span {
    ${textEllipsis};

    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: ${p => p.theme.colors.purple};
  }

  svg > path {
    fill: ${p => p.theme.colors.main};
  }

  &:hover {
    span {
      color: ${p => p.theme.colors.main};
    }
  }
`;
export const ListItem = styled.li<{ $active?: boolean }>`
  padding: 5px 0 5px 15px;

  &:first-child {
    margin-bottom: 32px;
  }
`;

export const DropdownMainItemWrapper = styled(Link)<{ $active?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  margin-bottom: 30px;

  transition: all 0.15s ease-in-out;

  ${iconDefaultStyle}
  .MuiListItemIcon-root {
    min-width: 24px;
  }

  &:hover {
    span {
      transition: all 0.15s ease-in-out;
      color: ${p => p.theme.colors.main};
    }

    ${iconActiveStyle};
  }

  ${p =>
    p.$active &&
    `
  span {
    transition: all 0.15s ease-in-out;
    color: #13BDCE;
    font-weight: 500;
  }
  div > svg > path {
    transition: all 0.15s ease-in-out;
    fill: #13BDCE;
  }
  `}
`;

export const ExpandIcon = styled.div`
  width: 16px;
  height: 16px;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-grow: 1;

  ${iconDefaultStyle};
`;

export const StyledExpandMoreIcon = styled((ExpandMoreIcon as unknown) as ComponentType<unknown>)<IconProps>`
  fill: ${p => p.theme.colors.main};
  transform: rotate(${({ open }) => (open ? "0deg" : "180deg")});
  transition: transform 0.15s ease;
`;
export const ItemsContainer = styled.div<{ $withScroll?: boolean }>`
  width: 100%;
  border-left: 1px solid ${p => p.theme.colors.lightnessGrey};

  ${p =>
    p?.$withScroll &&
    `
    max-height: 140px;
    overflow-y: scroll;
  `}
  svg {
    opacity: 0.6;
  }
`;

export const Icon = styled(ListItemIcon)`
  min-width: 24px !important;
  display: flex;
  align-items: center;
  margin-right: 8px;

  ${iconDefaultStyle};
`;
