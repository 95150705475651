import { API } from "@common/api";
import type { Subcategory } from "@common/models";
import { getBudgetThunk, getExpensesByBudgetThunk } from "@common/services";
import { currentBudgetDateSelector, currentBudgetSelector, subcategoryById } from "@common/store";
import { stringifyDate } from "@common/utils";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { getCategoriesByDate, getCategoriesByDateThunk } from ".";
import { setNotification, setSubcategoriesError, setSubcategoriesList, setSubcategoriesLoading } from "../reducers";
import { RootState } from "../store";
import { addRecordThunk, IUndoActionEnum, resetHistory, updateRecordsInHistory } from "./actionsHistory";

type SubcategoriesByIntervalType = {
  budget: string | undefined;
  category: string;
  from_month: number;
  from_year: number;
  to_month: number;
  to_year: number;
};

export const getSubcategoriesByBudgetThunk = createAsyncThunk<
  void,
  {
    budgetId: string;
  }
>("GET_SUBCATEGORIES", async ({ budgetId }, thunkAPI) => {
  thunkAPI.dispatch(setSubcategoriesLoading(true));

  try {
    const { data } = await API.get<Subcategory[]>("/subcategories/", { params: { budget: budgetId } });

    thunkAPI.dispatch(setSubcategoriesList(data));
  } catch (error) {
    thunkAPI.dispatch(setNotification({ type: "error", message: (error as any).message }));
    thunkAPI.dispatch(setSubcategoriesError((error as any).message));
  } finally {
    thunkAPI.dispatch(setSubcategoriesLoading(false));
  }
});

export const getSubcategoriesByDateThunk = createAsyncThunk<
  void,
  {
    budgetId: string;
    month: number;
    year: number;
  }
>("GET_SUBCATEGORIES_BY_DATE", async ({ budgetId, month, year }, thunkAPI) => {
  thunkAPI.dispatch(setSubcategoriesLoading(true));
  const params = { budget: budgetId };

  try {
    const { data } = await API.get<Subcategory[]>(`/subcategories/${stringifyDate(month)}/${year}/`, { params });

    thunkAPI.dispatch(setSubcategoriesList(data));
  } catch (error) {
    thunkAPI.dispatch(setNotification({ type: "error", message: (error as any).message }));
    thunkAPI.dispatch(setSubcategoriesError((error as any).message));
  } finally {
    thunkAPI.dispatch(setSubcategoriesLoading(false));
  }
});

export const getSubCategoriesByCategoryId = (year: number, month: number, category: string) => async dispatch => {
  dispatch(setSubcategoriesLoading(true));

  try {
    const { data } = await API.get(`/subcategories/${stringifyDate(month)}/${year}/`, { params: { category } });

    dispatch(setSubcategoriesList(data));
  } catch (error) {
    dispatch(setNotification({ type: "error", message: (error as any).message }));
    dispatch(setSubcategoriesError((error as any).message));
  } finally {
    dispatch(setSubcategoriesLoading(false));
  }
};

export const getSubcategoriesByInterval = ({
  budget,
  category,
  from_month,
  from_year,
  to_month,
  to_year,
}: SubcategoriesByIntervalType) => async dispatch => {
  dispatch(setSubcategoriesLoading(true));

  try {
    const { data } = await API.get(`/subcategories/${stringifyDate(from_month)}/${from_year}/`, {
      params: { budget, category, to_month, to_year },
    });

    dispatch(setSubcategoriesList(data));
  } catch (error) {
    dispatch(setNotification({ type: "error", message: (error as any).message }));
    dispatch(setSubcategoriesError((error as any).message));
  } finally {
    dispatch(setSubcategoriesLoading(false));
  }
};

export const createSubcategoriesThunk = createAsyncThunk<
  void,
  { category: string; name: string },
  {
    state: RootState;
  }
>("CREATE_SUBCATEGORIES", async (createSubcategoryDto, thunkAPI) => {
  thunkAPI.dispatch(setSubcategoriesLoading(true));
  const { month, year } = currentBudgetDateSelector(thunkAPI.getState());

  const currentBudget = currentBudgetSelector(thunkAPI.getState());

  try {
    const response = await API.post(`/subcategories/`, createSubcategoryDto);
    if (currentBudget?.id !== undefined && year !== null && month !== null) {
      thunkAPI.dispatch(getCategoriesByDateThunk({ budgetId: currentBudget.id, month, year }));
    }
    return response.data;
  } catch (error) {
    thunkAPI.dispatch(setNotification({ type: "error", message: (error as any).response.data.error }));
    thunkAPI.dispatch(setSubcategoriesError(error as string));
  } finally {
    thunkAPI.dispatch(setSubcategoriesLoading(false));
  }
});

export const createSubcategories = ({ ...values }) => async (dispatch, getState) => {
  dispatch(setSubcategoriesLoading(true));

  const currentBudget = currentBudgetSelector(getState());

  try {
    const response = await API.post(`/subcategories/`, values);
    if (currentBudget?.id !== undefined) {
      dispatch(getSubcategoriesByBudgetThunk({ budgetId: currentBudget?.id }));
    }
    return response.data;
  } catch (error) {
    dispatch(setNotification({ type: "error", message: (error as any).response.data.error }));
    dispatch(setSubcategoriesError(error as string));
  } finally {
    dispatch(setSubcategoriesLoading(false));
  }
};

export const addSubcategoryThunk = createAsyncThunk<
  void,
  {
    name: string;
    categoryId: string;
    oldId?: string;
    saveInHistory?: boolean;
    fromUser?: boolean;
  },
  {
    state: RootState;
  }
>(
  "ADD_SUBCATEGORY",
  async ({ name, categoryId, saveInHistory = true, oldId = "", fromUser = true }, { dispatch, getState }) => {
    dispatch(setSubcategoriesLoading(true));

    const currentBudget = currentBudgetSelector(getState());
    const { month, year } = currentBudgetDateSelector(getState());

    const bodyFormData = new FormData();
    bodyFormData.append("name", name);
    bodyFormData.append("category", categoryId);

    try {
      const res = await API.post(`/subcategories/`, bodyFormData);
      if (currentBudget?.id !== undefined && month !== null && year !== null) {
        await dispatch(getSubcategoriesByDateThunk({ budgetId: currentBudget?.id, month, year }));
      }
      if (saveInHistory) {
        await dispatch(
          addRecordThunk({
            actionType: IUndoActionEnum.ADD_SUBCATEGORY,
            id: res.data.id,
            props: {
              category: categoryId,
            },
            value: { name },
          }),
        );
      }
      if (fromUser) {
        await dispatch(resetHistory());
      }
      await dispatch(
        updateRecordsInHistory({
          id: res.data.id,
          oldId,
          type: "subcategory",
        }),
      );

      dispatch(setNotification({ type: "success", message: "Подкатегория добавлена успешно" }));
    } catch (error) {
      dispatch(setNotification({ type: "error", message: (error as any).response.data.error }));
      dispatch(setSubcategoriesError(error as string));
    } finally {
      dispatch(setSubcategoriesLoading(false));
    }
  },
);

export const addSubcategory = ({ name, categoryId, saveInHistory = true, oldId = "", fromUser = true }) => async (
  dispatch,
  getState,
) => {
  dispatch(setSubcategoriesLoading(true));

  const currentBudget = currentBudgetSelector(getState());
  const { month, year } = currentBudgetDateSelector(getState());

  const bodyFormData = new FormData();
  bodyFormData.append("name", name);
  bodyFormData.append("category", categoryId);

  try {
    const res = await API.post(`/subcategories/`, bodyFormData);
    if (currentBudget?.id !== undefined && month !== null && year !== null) {
      await dispatch(getSubcategoriesByDateThunk({ budgetId: currentBudget.id, month, year }));
    }
    if (saveInHistory) {
      await dispatch(
        addRecordThunk({
          actionType: IUndoActionEnum.ADD_SUBCATEGORY,
          id: res.data.id,
          props: {
            category: categoryId,
          },
          value: { name },
        }),
      );
    }
    if (fromUser) {
      await dispatch(resetHistory());
    }
    await dispatch(
      updateRecordsInHistory({
        id: res.data.id,
        oldId,
        type: "subcategory",
      }),
    );

    dispatch(setNotification({ type: "success", message: "Подкатегория добавлена успешно" }));
  } catch (error) {
    dispatch(setNotification({ type: "error", message: (error as any).response.data.error }));
    dispatch(setSubcategoriesError(error as string));
  } finally {
    dispatch(setSubcategoriesLoading(false));
  }
};

export const editSubcategoryThunk = createAsyncThunk<
  void,
  {
    name?: string;
    category?: string;
    id: string;
    isHidden: boolean;
    notification?: boolean;
    saveInHistory?: boolean;
    fromUser?: boolean;
  },
  {
    state: RootState;
  }
>(
  "EDIT_SUBCATEGORY",
  async ({ name, id, category, fromUser = true, saveInHistory = true, isHidden, notification = true }, thunkAPI) => {
    thunkAPI.dispatch(setSubcategoriesLoading(true));

    // const { id: budgetId } = currentBudgetSelector(thunkAPI.getState())
    // const { month, year } = currentBudgetDateSelector(thunkAPI.getState())
    // const currentSubcategory = subcategoryById(thunkAPI.getState(), id)
    // const bodyFormData = new FormData()
    // name && bodyFormData.append("name", name)
    // category && bodyFormData.append("category", category)
    // bodyFormData.append("isHidden", isHidden.toString())

    try {
      await API.put(`/subcategories/${id}/`, {
        isHidden: isHidden,
        category: category,
        name: name,
      });

      // if (budgetId !== null && month !== null && year !== null) {
      //   await thunkAPI.dispatch(getSubcategoriesByDateThunk({ budgetId, month, year }))
      //   await thunkAPI.dispatch(getExpensesByBudgetThunk({ budgetId, month, year }))
      //   await thunkAPI.dispatch(getCategoriesByDate(budgetId, month, year))
      // }
      // if (saveInHistory) {
      //   await thunkAPI.dispatch(
      //     addRecordThunk({
      //       actionType: IUndoActionEnum.EDIT_SUBCATEGORY,
      //       id: currentSubcategory?.id,
      //       props: {
      //         isHidden: currentSubcategory?.isHidden,
      //         category: currentSubcategory?.category,
      //         name: currentSubcategory?.name,
      //       },
      //       value: {
      //         isHidden,
      //         category,
      //         name,
      //       },
      //     }),
      //   )
      // }
      if (fromUser) {
        await thunkAPI.dispatch(resetHistory());
      }

      notification && thunkAPI.dispatch(setNotification({ type: "success", message: "Подкатегория успешно изменена" }));
    } catch (error) {
      thunkAPI.dispatch(setNotification({ type: "error", message: (error as any).response.data.error }));
      thunkAPI.dispatch(setSubcategoriesError(error as string));
    } finally {
      thunkAPI.dispatch(setSubcategoriesLoading(false));
    }
  },
);

export const editSubcategory = ({
  name = "",
  category,
  id: subcategoryId,
  isHidden,
  notification = true,
  saveInHistory = true,
  fromUser = true,
}) => async (dispatch, getState) => {
  dispatch(setSubcategoriesLoading(true));
  const currentBudget = currentBudgetSelector(getState());
  const { month, year } = currentBudgetDateSelector(getState());
  const currentSubcategory = subcategoryById(getState(), subcategoryId);
  const bodyFormData = new FormData();
  name && bodyFormData.append("name", name);
  bodyFormData.append("category", category);
  bodyFormData.append("isHidden", isHidden);

  try {
    await API.patch(`/subcategories/${subcategoryId}/`, bodyFormData);
    if (currentBudget?.id !== undefined && month !== null && year !== null) {
      await dispatch(getSubcategoriesByDateThunk({ budgetId: currentBudget?.id, month, year }));
      await dispatch(getExpensesByBudgetThunk({ budgetId: currentBudget?.id, month, year }));
    }
    await dispatch(getCategoriesByDate(currentBudget?.id, month, year));
    if (saveInHistory) {
      await dispatch(
        addRecordThunk({
          actionType: IUndoActionEnum.EDIT_SUBCATEGORY,
          id: currentSubcategory?.id,
          props: {
            isHidden: currentSubcategory?.isHidden,
            category: currentSubcategory?.category,
            name: currentSubcategory?.name,
          },
          value: {
            isHidden,
            category,
            name,
          },
        }),
      );
    }
    if (fromUser) {
      await dispatch(resetHistory());
    }

    notification && dispatch(setNotification({ type: "success", message: "Подкатегория успешно изменена" }));
  } catch (error) {
    dispatch(setNotification({ type: "error", message: (error as any).response.data.error }));
    dispatch(setSubcategoriesError(error as string));
  } finally {
    dispatch(setSubcategoriesLoading(false));
  }
};

export const deleteSubcategoryAndMove = ({
  subcategoryId,
  subcategoryToMove,
  saveInHistory = true,
  fromUser = true,
}) => async (dispatch, getState) => {
  dispatch(setSubcategoriesLoading(true));

  const currentBudget = currentBudgetSelector(getState());
  const { month, year } = currentBudgetDateSelector(getState());
  const currentSubcategory = subcategoryById(getState(), subcategoryId);

  const bodyFormData = new FormData();
  bodyFormData.append("toSubcategory", subcategoryToMove);

  try {
    await API.post(`/subcategories/${subcategoryId}/move/`, bodyFormData);
    if (currentBudget?.id) {
      await dispatch(getBudgetThunk({ id: currentBudget?.id }));
      if (month !== null && year !== null) {
        await dispatch(getSubcategoriesByDateThunk({ budgetId: currentBudget.id, month, year }));
      }
    }
    if (currentBudget?.id && month !== null && year !== null) {
      await dispatch(getSubcategoriesByDateThunk({ budgetId: currentBudget?.id, month, year }));
    }
    await dispatch(getCategoriesByDate(currentBudget?.id, month, year));
    if (saveInHistory) {
      await dispatch(
        addRecordThunk({
          actionType: IUndoActionEnum.DELETE_SUBCATEGORY_AND_MOVE,
          id: currentSubcategory?.id,
          props: {
            category: currentSubcategory?.category,
            prevValue: {
              name: currentSubcategory?.name,
              isHidden: currentSubcategory?.isHidden,
              availableFunds: currentSubcategory?.availableFunds,
              budgetFunds: currentSubcategory?.budgetFunds,
              spentFunds: currentSubcategory?.spentFunds,
            },
          },
          value: "",
        }),
      );
    }
    if (fromUser) {
      await dispatch(resetHistory());
    }

    dispatch(setNotification({ type: "success", message: "Подкатегория успешно удалена" }));
  } catch (error) {
    dispatch(setNotification({ type: "error", message: (error as any).message }));
    dispatch(setSubcategoriesError((error as any).message));
  } finally {
    dispatch(setSubcategoriesLoading(false));
  }
};

export const deleteSubcategoryThunk = createAsyncThunk<
  void,
  {
    subcategoryId: string;
    saveInHistory?: boolean;
    fromUser?: boolean;
  },
  {
    state: RootState;
  }
>("DELETE_SUBCATEGORY", async ({ subcategoryId, saveInHistory = true, fromUser = true }, thunkAPI) => {
  thunkAPI.dispatch(setSubcategoriesLoading(true));

  const currentBudget = currentBudgetSelector(thunkAPI.getState());
  const { month, year } = currentBudgetDateSelector(thunkAPI.getState());
  const currentSubcategory = subcategoryById(thunkAPI.getState(), subcategoryId);

  try {
    await API.delete(`/subcategories/${subcategoryId}/`);
    await thunkAPI.dispatch(getCategoriesByDate(currentBudget?.id, month, year));
    if (currentBudget?.id !== undefined && month !== null && year !== null) {
      await thunkAPI.dispatch(getSubcategoriesByDateThunk({ budgetId: currentBudget.id, month, year }));
    }
    if (saveInHistory) {
      await thunkAPI.dispatch(
        addRecordThunk({
          actionType: IUndoActionEnum.DELETE_SUBCATEGORY,
          id: currentSubcategory?.id,
          props: {
            category: currentSubcategory?.category,
            prevValue: {
              name: currentSubcategory?.name,
              isHidden: currentSubcategory?.isHidden,
              availableFunds: currentSubcategory?.availableFunds,
              budgetFunds: currentSubcategory?.budgetFunds,
              spentFunds: currentSubcategory?.spentFunds,
            },
          },
          value: "",
        }),
      );
    }
    if (fromUser) {
      await thunkAPI.dispatch(resetHistory());
    }

    thunkAPI.dispatch(setNotification({ type: "success", message: "Подкатегория успешно удалена" }));
  } catch (error) {
    thunkAPI.dispatch(setNotification({ type: "error", message: (error as any).message }));
    thunkAPI.dispatch(setSubcategoriesError((error as any).message));
  } finally {
    thunkAPI.dispatch(setSubcategoriesLoading(false));
  }
});
export const deleteSubcategory = ({ subcategoryId, saveInHistory = true, fromUser = true }) => async (
  dispatch,
  getState,
) => {
  dispatch(setSubcategoriesLoading(true));

  const currentBudget = currentBudgetSelector(getState());
  const { month, year } = currentBudgetDateSelector(getState());
  const currentSubcategory = subcategoryById(getState(), subcategoryId);

  try {
    await API.delete(`/subcategories/${subcategoryId}/`);
    await dispatch(getCategoriesByDate(currentBudget?.id, month, year));
    if (currentBudget?.id !== undefined && month !== null && year !== null) {
      await dispatch(getSubcategoriesByDateThunk({ budgetId: currentBudget?.id, month, year }));
    }
    if (saveInHistory) {
      await dispatch(
        addRecordThunk({
          actionType: IUndoActionEnum.DELETE_SUBCATEGORY,
          id: currentSubcategory?.id,
          props: {
            category: currentSubcategory?.category,
            prevValue: {
              name: currentSubcategory?.name,
              isHidden: currentSubcategory?.isHidden,
              availableFunds: currentSubcategory?.availableFunds,
              budgetFunds: currentSubcategory?.budgetFunds,
              spentFunds: currentSubcategory?.spentFunds,
            },
          },
          value: "",
        }),
      );
    }
    if (fromUser) {
      await dispatch(resetHistory());
    }

    dispatch(setNotification({ type: "success", message: "Подкатегория успешно удалена" }));
  } catch (error) {
    dispatch(setNotification({ type: "error", message: (error as any).message }));
    dispatch(setSubcategoriesError((error as any).message));
  } finally {
    dispatch(setSubcategoriesLoading(false));
  }
};

export const deleteMultipleSubcategoriesThunk = createAsyncThunk<
  void,
  {
    subcategoryIds: string[];
    saveInHistory?: boolean;
    fromUser?: boolean;
  },
  {
    state: RootState;
  }
>("DELETE_SUBCATEGORIES", async ({ subcategoryIds, saveInHistory = true, fromUser = true }, thunkAPI) => {
  thunkAPI.dispatch(setSubcategoriesLoading(true));

  const currentBudget = currentBudgetSelector(thunkAPI.getState());
  const { month, year } = currentBudgetDateSelector(thunkAPI.getState());
  try {
    await API.post(`/subcategories-multiple/`, { ids: subcategoryIds });
    await thunkAPI.dispatch(getCategoriesByDate(currentBudget?.id, month, year));
    if (currentBudget?.id !== undefined && month !== null && year !== null) {
      await thunkAPI.dispatch(getSubcategoriesByDateThunk({ budgetId: currentBudget?.id, month, year }));
    }
    if (fromUser) {
      await thunkAPI.dispatch(resetHistory());
    }

    thunkAPI.dispatch(setNotification({ type: "success", message: "Подкатегории успешно удалены" }));
  } catch (error) {
    thunkAPI.dispatch(setNotification({ type: "error", message: (error as any).message }));
    thunkAPI.dispatch(setSubcategoriesError((error as any).message));
  } finally {
    thunkAPI.dispatch(setSubcategoriesLoading(false));
  }
});

export const transferFundsSubcategory = ({ subcategoryId, toSubcategory, amount, month, year }) => async (
  dispatch,
  getState,
) => {
  dispatch(setSubcategoriesError(null));
  dispatch(setSubcategoriesLoading(true));

  const currentBudget = currentBudgetSelector(getState());

  const bodyFormData = new FormData();

  bodyFormData.append("toSubcategory", toSubcategory);
  bodyFormData.append("amount", amount);
  bodyFormData.append("month", month);
  bodyFormData.append("year", year);

  try {
    await API.post(`/subcategories/${subcategoryId}/transfer_funds/`, bodyFormData);

    dispatch(setNotification({ type: "success", message: "Средства успешно перенесены" }));

    dispatch(getCategoriesByDate(currentBudget?.id, month, year));
    if (currentBudget?.id !== undefined) {
      dispatch(getSubcategoriesByDateThunk({ budgetId: currentBudget.id, month, year }));
      dispatch(getExpensesByBudgetThunk({ budgetId: currentBudget.id, month, year }));
    }
  } catch (error) {
    dispatch(setNotification({ type: "error", message: (error as any).response.data.amount[0] }));
    dispatch(setSubcategoriesError((error as any).message));
  } finally {
    dispatch(setSubcategoriesLoading(false));
  }
};
