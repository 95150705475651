import { AuthGuard, RoleGuard } from "@common/guards";
import { useAsyncDispatch, useRefreshToken } from "@common/hooks";
import { fetchSocialAction } from "@common/store";
import { ReactElement, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { ROUTERS, RouteType } from "@main/providers/routes";

export function Routing(): ReactElement {
  const asyncDispatch = useAsyncDispatch();

  useRefreshToken();

  useEffect(() => {
    const url = new URL(window.location.href);
    const code = url.searchParams.get("code");
    const state = url.searchParams.get("state");
    const social = localStorage.getItem("social");

    if (code && state) {
      void asyncDispatch(fetchSocialAction({ code, state, social }));
    }

    return () => localStorage.removeItem("social");
  }, [asyncDispatch]);

  return (
    <Routes>
      {ROUTERS.map(route => (
        <Route path={route.path} element={RenderElement(route)} key={route.path} />
      ))}
    </Routes>
  );
}

function RenderElement(route: RouteType): ReactElement {
  if (route.roleGuard) {
    return <RoleGuard>{route.element}</RoleGuard>;
  }
  if (route.auth) {
    return <AuthGuard>{route.element}</AuthGuard>;
  }

  return route.element;
}
