import { API } from "@common/api"
import { UserData } from "@common/store"

import { createAsyncThunk } from "@reduxjs/toolkit"

export const loadUser = createAsyncThunk<UserData, void>("users/load", async _ => {
  const { data } = await API.get<UserData>("/users/me/")
  return data
})

export const setNoShowUser = createAsyncThunk<UserData, void>("users/noShow", async _ => {
  const { data } = await API.patch<UserData>("/users/me/", { uiSettings: { dontShow: true } })
  return data
})

export const setTrialPeriod = createAsyncThunk<void, { password: string }>("users/noShow", async ({ password }) => {
  const { data } = await API.put<void>("/users/me/resume_trial/", { password })
  return data
})
