import { TransactionInfiniteResult } from "@account-detail/model";
import type { AccountShortView, CacheAccount } from "@common/models";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";

const STACK_SIZE = 3;

type Stack = {
  type: "return" | "cancel";
  id: string | string[];
  cancelFn: () => Promise<unknown>;
  returnFn: () => Promise<unknown>;
};

type AccountDetailType = {
  modals: {
    editAccount: {
      values: Partial<AccountShortView>;
      open: boolean;
    };
    deleteAccount: {
      values: Partial<AccountShortView> & Partial<{ id: string }>;
      open: boolean;
    };
    addTransaction: {
      open: boolean;
    };
  };
  search?: string;
  stack: Stack[];
  selectedTransaction: string[];
  currentAccount?: CacheAccount;
  transactionInfiniteList: TransactionInfiniteResult | undefined;
  selectedPlanedNotApprovedTransaction: Set<string>;
  selectedPlanedFutureTransaction: Set<string>;
};
const initialState: AccountDetailType = {
  modals: {
    editAccount: {
      open: false,
      values: {},
    },
    deleteAccount: {
      open: false,
      values: {},
    },
    addTransaction: {
      open: false,
    },
  },
  stack: [],
  search: undefined,
  selectedTransaction: [],
  currentAccount: undefined,
  transactionInfiniteList: undefined,
  selectedPlanedNotApprovedTransaction: new Set([]),
  selectedPlanedFutureTransaction: new Set([]),
};
const accountDetailSlice = createSlice({
  name: "accountDetails",
  initialState: initialState,
  reducers: {
    setEditAccountState: (state, action: PayloadAction<Partial<AccountShortView>>) => {
      state.modals.editAccount.values = action.payload;
    },
    setEditAccountOpen: (state, action: PayloadAction<boolean>) => {
      state.modals.editAccount.open = action.payload;
    },
    setDeleteAccountState: (state, action: PayloadAction<Partial<AccountShortView> & Partial<{ id: string }>>) => {
      state.modals.deleteAccount.values = action.payload;
    },
    setDeleteAccountOpen: (state, action: PayloadAction<boolean>) => {
      state.modals.deleteAccount.open = action.payload;
    },
    toggleSelectedTransactionIds: (
      state,
      action: PayloadAction<{
        transaction: string;
      }>,
    ) => {
      const selectedIds = state.selectedTransaction;

      if (state.selectedTransaction.includes(action.payload.transaction)) {
        state.selectedTransaction = selectedIds.filter(id => id !== action.payload.transaction);
      } else {
        state.selectedTransaction = [...selectedIds, action.payload.transaction];
      }
    },

    clearSelectedTransactionIds: state => {
      state.selectedTransaction = [];
    },

    setAddTransferModalOpen: (state, action: PayloadAction<boolean>) => {
      state.modals.addTransaction.open = action.payload;
    },

    setCurrentCacheAccount: (state, action: PayloadAction<CacheAccount>) => {
      state.currentAccount = action.payload;
    },

    accountSearch: (state, action: PayloadAction<string | undefined>) => {
      state.search = action.payload;
    },

    toAddToStack: (state, action: PayloadAction<Stack>) => {
      if (state.stack.length === STACK_SIZE) {
        state.stack.splice(0, 1);
      }
      state.stack = [...state.stack, action.payload];
    },

    clearStack: (state) => {
      state.stack = [];
    },

    updateReturnTransactionsStack(state) {
      const returnIndex: number = findLastElementByType(state.stack, { type: "return" });
      if (returnIndex === -1) return;

      const stack = state.stack[returnIndex];
      state.stack.splice(returnIndex, 1);
      state.stack = [...state.stack, { ...stack, type: "cancel" }];
    },

    updateCancelTransactionsStack(state) {
      const cancelIndex: number = findLastElementByType(state.stack, { type: "cancel" });
      if (cancelIndex === -1) return;

      const stack = state.stack[cancelIndex];
      state.stack.splice(cancelIndex, 1);
      state.stack = [...state.stack, { ...stack, type: "return" }];
    },

    setTransactionList: (
      state,
      action: PayloadAction<(TransactionInfiniteResult & { addMore?: boolean }) | undefined>,
    ) => {
      if (state.transactionInfiniteList !== undefined && action.payload?.addMore) {
        state.transactionInfiniteList.next = action.payload.next;
        state.transactionInfiniteList.previous = action.payload.previous;
        state.transactionInfiniteList.results = state.transactionInfiniteList?.results?.concat(
          action.payload?.results ?? [],
        );
      } else {
        state.transactionInfiniteList = action.payload;
      }
    },
    toggleSelectedPlanedNotApprovedTransactions: (
      state,
      action: PayloadAction<{
        transactionId: string;
      }>,
    ) => {
      if (state.selectedPlanedNotApprovedTransaction.has(action.payload.transactionId)) {
        state.selectedPlanedNotApprovedTransaction.delete(action.payload.transactionId);
        return;
      }
      state.selectedPlanedNotApprovedTransaction.add(action.payload.transactionId);
    },
    toggleSelectedPlanedNotApprovedTransactionSection: (
      state,
      action: PayloadAction<{
        transactionIds: string[];
        select: boolean;
      }>,
    ) => {
      action.payload.transactionIds.forEach(id =>
        action.payload.select
          ? state.selectedPlanedNotApprovedTransaction.add(id)
          : state.selectedPlanedNotApprovedTransaction.delete(id),
      );
    },
    toggleSelectedPlanedFutureTransactions: (
      state,
      action: PayloadAction<{
        transactionId: string;
      }>,
    ) => {
      if (state.selectedPlanedFutureTransaction.has(action.payload.transactionId)) {
        state.selectedPlanedFutureTransaction.delete(action.payload.transactionId);
        return;
      }
      state.selectedPlanedFutureTransaction.add(action.payload.transactionId);
    },
    toggleSelectedPlanedFutureTransactionSection: (
      state,
      action: PayloadAction<{
        transactionIds: string[];
        select: boolean;
      }>,
    ) => {
      action.payload.transactionIds.forEach(id =>
        action.payload.select
          ? state.selectedPlanedFutureTransaction.add(id)
          : state.selectedPlanedFutureTransaction.delete(id),
      );
    },
  },
  extraReducers: {},
});

export const {
  setEditAccountState,
  setEditAccountOpen,
  setDeleteAccountOpen,
  setDeleteAccountState,
  toggleSelectedTransactionIds,
  setAddTransferModalOpen,
  setCurrentCacheAccount,
  setTransactionList,
  toggleSelectedPlanedNotApprovedTransactionSection,
  toggleSelectedPlanedNotApprovedTransactions,
  toggleSelectedPlanedFutureTransactionSection,
  toggleSelectedPlanedFutureTransactions,
  accountSearch,
  toAddToStack,
  updateReturnTransactionsStack,
  updateCancelTransactionsStack,
  clearSelectedTransactionIds,
  clearStack,
} = accountDetailSlice.actions;

export const accountDetailReducer = accountDetailSlice.reducer;

export function findLastElementByType(stacks: Stack[], { type }: { type: "return" | "cancel" }): number {
  return _.findLastIndex(stacks, stack => stack.type === type);
}
