import { valueToPrice } from "@common/utils";
import type { DropdownNavItem } from "./Dropdown";
import {
  DropdownLinkItem,
  DropdownTextItem,
  HeaderItemContainer,
  IconWrapper,
  ItemTitle,
  ListItem,
  PriceText,
} from "./DropdownStyles";

type Props = {
  item: DropdownNavItem;
  isComplex?: boolean;
};

export function DropDownItem({ item, isComplex }: Props) {
  const { link, title, Icon, onClick, node } = item;

  function onItemClick() {
    if (onClick === undefined) {
      return;
    }
    onClick();
  }

  return (
    <ListItem>
      {link !== undefined && (
        <DropdownLinkItem to={link} $bold={!!isComplex}>
          <HeaderItemContainer>
            {Icon !== undefined && <IconWrapper>{Icon}</IconWrapper>}
            <ItemTitle>{title}</ItemTitle>
          </HeaderItemContainer>
          {node !== undefined && <PriceText>{valueToPrice(node, "P")}</PriceText>}
        </DropdownLinkItem>
      )}
      {link === undefined && (
        <DropdownTextItem onClick={onItemClick}>
          {Icon !== undefined && <IconWrapper>{Icon}</IconWrapper>}
          <ItemTitle>{title}</ItemTitle>
        </DropdownTextItem>
      )}
    </ListItem>
  );
}
