import { customAssoc } from '@common/utils'
import { curry, map, pipe, prop, toString } from 'ramda';

export const calcExpensePercents = curry((common: number, balance: string | number): number => {
  const expense = Number(balance);

  return isNaN(expense) ? 0 : Math.round(expense / (common / 100));
});

export function parseSpentFunds<T>(array: T[]) {
  const getAbsoluteSpentFunds = pipe(prop<string, T>('spentFunds'), Number, Math.abs, toString);

  return map<T, T>(customAssoc('spentFunds', getAbsoluteSpentFunds), array);
}
