import styled, { css } from 'styled-components'

interface TitleProps {
  disabled?: boolean;
}

interface MonthProps {
  active?: boolean;
}

interface PickerCardProps {
  useInterval: boolean;
}

export const MenuItemContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Container = styled.div`
  position: relative;
`;

export const Trigger = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const PickerMonthWrap = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: ${({ theme }) => theme.text.fontSmall};
  line-height: 24px;
  color: ${({ theme }) => theme.colors.contentMain};
`;

export const Title = styled.div<TitleProps>`
  font-size: 16px;
  line-height: 24px;
  margin-right: 8px;
  color: ${({ disabled, theme }) => (disabled ? theme.colors.contentSecondary : theme.colors.black)};
`;

export const PickerCard = styled.div<PickerCardProps>`
  width: ${({ useInterval }) => (useInterval ? 300 : 150)}px;
  position: absolute;
  z-index: 1000;
  top: 40px;
  left: 0;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  height: 215px;
  overflow-y: scroll;
  border-radius: 8px;
  border: none;
  box-shadow: 0 16px 64px rgb(0 0 0 / 20%);
`;

export const Picker = styled.div`
  width: 100%;
`;

export const PickerYear = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 22px;
  height: 56px;
`;

const stylePickerMonth = css`
  background-color: ${({ theme }) => theme.colors.white};
  ${PickerMonthWrap} {
    color: ${({ theme }) => theme.colors.contentMain};
  }
`;
const stylePickerMonthActive = css`
  background-color: ${({ theme }) => theme.colors.backgroundMain};
  transition: 0.1s;
  ${PickerMonthWrap} {
    color: ${({ theme }) => theme.colors.main};
  }
`;

export const PickerMonth = styled.div<MonthProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  font-size: 16px;
  cursor: pointer;
  ${({ active }) => (active ? stylePickerMonthActive : stylePickerMonth)}
  &:hover {
    ${stylePickerMonthActive}
  }
`;

export const StartDate = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: ${({ theme }) => theme.text.fontSmall};
  line-height: 24px;
`;

export const Chevron = styled.button`
  font-size: 22px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.transparent};
  border: none;
  cursor: pointer;
  &:disabled {
    cursor: auto;
    svg {
      opacity: 0.4;
    }
  }
`;

export const Dimmer = styled.div`
  z-index: 900;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
`;
