import styled from 'styled-components'

export const Wrapper = styled.div`
  max-width: 1320px;
  width: 100%;
  margin: 0 auto;
  padding-top: 40px;
`;

export const MainContainer = styled.div`
  max-width: 1320px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
`;

export const LoaderContainer = styled.div`
  width: 100%;
  height: 840px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PageContainer = styled.div`
  width: 100%;
  height: 100%;
`;

export const TopBarContainer = styled.div`
  padding: 40px 24px 24px 24px;
  width: 100%;
  min-height: 40px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
`;

export const Action = styled.span`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.main};
`;

export const TableContainer = styled.div`
  width: 100%;
  height: calc(100% - 110px);
  border-top: 1px solid ${({ theme }) => theme.colors.backgroundSecondary};
`;

export const TableHead = styled.div`
  width: 100%;
  height: 50px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.backgroundSecondary};
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 0 0 12px;
`;

export const TableLine = styled.div`
  width: 100%;
  height: 50px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.backgroundSecondary};
  display: flex;
  justify-content: space-between;
  padding: 0 0 0 12px;
  box-sizing: border-box;

  &:hover {
    background-color: ${({ theme }) => theme.colors.light};
  }
`;

export const TableBody = styled.div`
  width: 100%;
  height: calc(100% - 100px);
  overflow-y: scroll;
  overflow-x: hidden;

  ${/* sc-selector */ TableLine}:last-child {
    border-bottom: none;
  }
`;

export const TableCell = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

export const Regular = styled(TableCell)`
  min-width: 345px;
  padding-left: 12px;
`;

export const ShortRegular = styled(Regular)`
  min-width: 150px;
`;

export const BoldRegular = styled(Regular)`
  font-weight: 600;
`;

export const RegularMeta = styled(BoldRegular)`
  color: ${({ theme }) => theme.colors.contentSecondary};
`;

export const ShortRegularMeta = styled(RegularMeta)`
  min-width: 150px;
`;

export const Divider = styled(TableCell)`
  width: 100%;
`;
