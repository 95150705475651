import { API } from "@common/api";
import {
  changeExpenseLoading,
  changeExpenseSuccess,
  createExpenseLoading,
  createExpenseSuccess,
  currentBudgetDateSelector,
  currentBudgetSelector,
  getCategoriesByDate,
  getDatesHasExpensesError,
  getDatesHasExpensesLoading,
  getDatesHasExpensesSuccess,
  getExpensesError,
  getExpensesLoading,
  getExpensesSuccess,
  getSubcategoriesByDateThunk,
  IExpense,
  setNotification,
} from "@common/store";
import { ExpensesTransport } from "@common/transports";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { addRecordThunk, IUndoActionEnum, resetHistory } from "../store/actions/actionsHistory";
import { RootState } from "../store/store";
import { getBudgetByDateThunk, getBudgetThunk } from "./index";

export const getExpensesByBudgetThunk = createAsyncThunk<void, { budgetId: string; month: number; year: number }>(
  "GET_EXPENSES",
  async ({ budgetId, year, month }, thunkAPI) => {
    thunkAPI.dispatch(getExpensesLoading(true));

    try {
      const expenses = await ExpensesTransport.getExpenses(budgetId, month, year);
      thunkAPI.dispatch(getExpensesSuccess(expenses));
    } catch (error) {
      thunkAPI.dispatch(setNotification({ type: "error", message: (error as any).message }));
      thunkAPI.dispatch(getExpensesError((error as any).message));
    } finally {
      thunkAPI.dispatch(getExpensesLoading(false));
    }
  },
);

export const getDatesHasExpensesThunk = createAsyncThunk<void, { budgetId: string }>(
  "GET_DATES_HAS_EXPENSES",
  async ({ budgetId }, thunkAPI) => {
    thunkAPI.dispatch(getDatesHasExpensesLoading(true));

    try {
      const { data } = await API.get(`/budgets/${budgetId}/dateinfo/`);

      thunkAPI.dispatch(getDatesHasExpensesSuccess(data));
    } catch (error) {
      thunkAPI.dispatch(getDatesHasExpensesError((error as any).message));
    } finally {
      thunkAPI.dispatch(getDatesHasExpensesLoading(false));
    }
  },
);

export const createExpense = ({ subcategoryId, amount, month, year, saveInHistory = true, fromUser = true }) => async (
  dispatch,
  getState,
) => {
  dispatch(createExpenseLoading(true));

  const currentBudget = currentBudgetSelector(getState());

  const bodyFormData = new FormData();

  bodyFormData.append("subcategory", subcategoryId);
  bodyFormData.append("amount", amount);
  bodyFormData.append("month", month);
  bodyFormData.append("year", year);

  try {
    if (amount < 0) {
      dispatch(setNotification({ type: "error", message: "Бюджетируемая сумма не может быть отрицательной" }));
      return;
    }

    const { data } = await API.post(`/expenses/`, bodyFormData);
    if (saveInHistory) {
      dispatch(
        addRecordThunk({
          actionType: IUndoActionEnum.EDIT_SUBCATEGORY_FOUNDS,
          id: data.id,
          props: {
            subcategory: subcategoryId,
            prevValue: {
              amount: 0,
              month: data.month,
              year: data.year,
            },
          },
          value: {
            amount: data.amount,
            month: data.month,
            year: data.year,
          },
        }),
      );
    }
    if (fromUser) {
      await dispatch(resetHistory());
    }

    dispatch(setNotification({ type: "success", message: "Бюджетируемая сумма успешно добавлена" }));

    dispatch(createExpenseSuccess(data));

    // TODO временно, пока не сделаем нормальную систему обновления
    dispatch(getCategoriesByDate(currentBudget?.id, month, year));
    if (currentBudget?.id !== undefined) {
      dispatch(getSubcategoriesByDateThunk({ budgetId: currentBudget?.id, month, year }));
      dispatch(getExpensesByBudgetThunk({ budgetId: currentBudget?.id, month, year }));
      dispatch(getBudgetThunk({ id: currentBudget?.id }));
      dispatch(getBudgetByDateThunk({ budgetId: currentBudget?.id, month, year }));
    }
  } catch (error) {
    dispatch(setNotification({ type: "error", message: "Ошибка добавления бюджетируемой суммы" }));
  } finally {
    dispatch(createExpenseLoading(false));
  }
};

export const changeExpenseThunk = createAsyncThunk<
  void,
  {
    expenseId?: string;
    subcategoryId: string;
    amount: string;
    saveInHistory?: boolean;
    fromUser?: boolean;
    move?: boolean;
  },
  { state: RootState }
>(
  "CHANGE_EXPENSE",
  async ({ fromUser = true, amount, move = false, subcategoryId, saveInHistory = false, expenseId }, thunkAPI) => {
    thunkAPI.dispatch(changeExpenseLoading(true));
    const currentBudget = currentBudgetSelector(thunkAPI.getState());
    const { month, year } = currentBudgetDateSelector(thunkAPI.getState());
    const { data: expenses } = await API.get<IExpense[]>(`/expenses/`, {
      params: { budget: currentBudget?.id, month, year },
    });
    const [currentExpenses] = expenses.filter(exp => expenseId !== undefined && exp.id === expenseId);
    const bodyFormData = new FormData();

    bodyFormData.append("subcategory", subcategoryId);
    bodyFormData.append("amount", amount);
    if (month !== null) {
      bodyFormData.append("month", month.toString());
    }
    if (year !== null) {
      bodyFormData.append("year", year.toString());
    }
    try {
      if (Number(amount) < 0) {
        thunkAPI.dispatch(
          setNotification({
            type: "error",
            message: "Бюджетируемая сумма не может быть отрицательной",
          }),
        );
        return;
      }

      const { data } = await (async () => {
        if (expenseId !== null && expenseId !== undefined) {
          return await API.patch(`/expenses/${expenseId}/`, bodyFormData);
        }

        if (move) {
          return await API.patch(`/expenses/move/`, bodyFormData);
        }

        return await API.post(`/expenses/`, bodyFormData);
      })();

      if (saveInHistory) {
        thunkAPI.dispatch(
          addRecordThunk({
            actionType: IUndoActionEnum.EDIT_SUBCATEGORY_FOUNDS,
            id: data.id,
            props: {
              subcategory: subcategoryId,
              prevValue: {
                amount: currentExpenses.amount,
                month: currentExpenses.month,
                year: currentExpenses.year,
              },
            },
            value: {
              amount: data.amount,
              month: data.month,
              year: data.year,
            },
          }),
        );
      }
      if (fromUser) {
        await thunkAPI.dispatch(resetHistory());
      }

      thunkAPI.dispatch(changeExpenseSuccess(data));

      thunkAPI.dispatch(setNotification({ type: "success", message: "Бюджетируемая сумма успешно изменена" }));
      // TODO временно, пока не сделаем нормальную систему обновления
      if (currentBudget?.id !== undefined && month !== null && year !== null) {
        thunkAPI.dispatch(getBudgetThunk({ id: currentBudget?.id }));
        thunkAPI.dispatch(getSubcategoriesByDateThunk({ budgetId: currentBudget?.id, month, year }));
        thunkAPI.dispatch(getExpensesByBudgetThunk({ budgetId: currentBudget?.id, month, year }));
      }
      thunkAPI.dispatch(getCategoriesByDate(currentBudget?.id, month, year));
      if (currentBudget?.id !== undefined && month !== null && year !== null) {
        thunkAPI.dispatch(getBudgetByDateThunk({ budgetId: currentBudget?.id, month, year }));
      }
    } catch (error) {
      thunkAPI.dispatch(setNotification({ type: "error", message: "Ошибка изменение бюджетируемой суммы" }));
    } finally {
      thunkAPI.dispatch(changeExpenseLoading(false));
    }
  },
);

export const changeExpense = ({
  expenseId,
  subcategoryId,
  amount,
  month,
  year,
  saveInHistory = true,
  fromUser = true,
  move = false,
}) => async (dispatch, getState) => {
  dispatch(changeExpenseLoading(true));

  const currentBudget = currentBudgetSelector(getState());

  const bodyFormData = new FormData();

  bodyFormData.append("subcategory", subcategoryId);
  bodyFormData.append("amount", amount);
  bodyFormData.append("month", month);
  bodyFormData.append("year", year);

  try {
    if (amount < 0) {
      dispatch(setNotification({ type: "error", message: "Бюджетируемая сумма не может быть отрицательной" }));
      return;
    }

    const { data } = await (async () => {
      if (!!expenseId) {
        return await API.patch(`/expenses/${expenseId}/`, bodyFormData);
      }

      if (move) {
        return await API.patch(`/expenses/move/`, bodyFormData);
      }

      return await API.post(`/expenses/`, bodyFormData);
    })();
    if (saveInHistory) {
      dispatch(
        addRecordThunk({
          actionType: IUndoActionEnum.EDIT_SUBCATEGORY_FOUNDS,
          id: data.id,
          props: {
            subcategory: subcategoryId,
            prevValue: {
              amount: data.amount,
              month: data.month,
              year: data.year,
            },
          },
          value: {
            amount: data.amount,
            month: data.month,
            year: data.year,
          },
        }),
      );
    }
    if (fromUser) {
      await dispatch(resetHistory());
    }

    dispatch(changeExpenseSuccess(data));

    dispatch(setNotification({ type: "success", message: "Бюджетируемая сумма успешно изменена" }));
    // TODO временно, пока не сделаем нормальную систему обновления
    if (currentBudget?.id !== undefined) {
      dispatch(getBudgetThunk({ id: currentBudget?.id }));
      dispatch(getSubcategoriesByDateThunk({ budgetId: currentBudget?.id, month, year }));
      dispatch(getExpensesByBudgetThunk({ budgetId: currentBudget?.id, month, year }));
    }
    dispatch(getCategoriesByDate(currentBudget?.id, month, year));
    if (currentBudget?.id !== undefined) {
      dispatch(getBudgetByDateThunk({ budgetId: currentBudget?.id, month, year }));
    }
  } catch (error) {
    dispatch(setNotification({ type: "error", message: "Ошибка изменение бюджетируемой суммы" }));
  } finally {
    dispatch(changeExpenseLoading(false));
  }
};

export const resetAndSpreadAvailable = (ids, mode) => async (dispatch, getState) => {
  dispatch(getExpensesLoading(true));
  const successMessage = mode === "spread" ? "распределены" : "обнулены";

  const currentBudget = currentBudgetSelector(getState());
  const currentDate = currentBudgetDateSelector(getState());

  try {
    await API.post("/expenses/reset/", { expensesIds: ids });
    dispatch(setNotification({ type: "success", message: `Доступные средства ${successMessage}` }));
    dispatch(getCategoriesByDate(currentBudget?.id, currentDate.month, currentDate.year));
    if (currentBudget?.id !== undefined && currentDate.year !== null && currentDate.month !== null) {
      dispatch(
        getSubcategoriesByDateThunk({ budgetId: currentBudget.id, month: currentDate.month, year: currentDate.year }),
      );
      dispatch(getBudgetByDateThunk({ budgetId: currentBudget.id, month: currentDate.month, year: currentDate.year }));
      dispatch(getBudgetThunk({ id: currentBudget.id }));
    }
  } catch (error) {
    dispatch(setNotification({ type: "error", message: (error as any).message }));
  } finally {
    dispatch(getExpensesLoading(false));
  }
};
