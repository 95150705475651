import { media } from "@main/styles"
import styled from "styled-components"

export const ButtonLink = styled.button`
  font-style: normal;
  font-weight: normal;
  font-size: ${p => p.theme.text.fontSmall};
  line-height: 24px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 10px 12px;
  transition: background 0.2s;
  color: ${p => p.theme.colors.main};
  &:hover {
    color: ${p => p.theme.colors.dark};
  }
  &:active {
    color: ${p => p.theme.colors.dark};
  }
`

export const Wrapper = styled.div<{ indentBottom: string }>`
  background: ${p => p.theme.colors.white};
  border-radius: 8px;
  width: 984px;
  ${p => p.indentBottom && `margin-bottom: ${p.indentBottom};`};
  ${media.medium`
   max-width: 1146px;
   width: 100%;
  `}
`

export const Title = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: ${p => p.theme.text.fontLarge};
  line-height: 44px;
`

export const Top = styled.div`
  padding: 40px;
  display: flex;
  align-items: center;
`

export const Bottom = styled.div``

export const Column = styled.div<{ grow?: string }>`
  flex-grow: ${p => p.grow || "0"};
`
