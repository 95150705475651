import { TextField as UiTextField } from "@common/components"
import { TextField as MuiTextField } from "@material-ui/core"
import styled from "styled-components"

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 360px;
  box-sizing: border-box;
`

export const Title = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: ${p => p.theme.text.fontMedium};
  line-height: 32px;
  color: ${p => p.theme.colors.contentMain};
  margin-bottom: 40px;
`

export const Section = styled.div`
  margin-bottom: 30px;

  b {
    font-weight: bold;
  }
`

export const Divider = styled.div`
  height: 150px;
`

export const TextField = styled(MuiTextField)`
  &.MuiFormControl-root {
    margin-bottom: 35px;
    width: 100%;
    position: relative;
  }
  .MuiFormLabel-root.Mui-focused {
    color: ${p => p.theme.colors.main};
  }
  .MuiInput-underline:after {
    border-color: ${p => p.theme.colors.main};
  }
  .MuiFormHelperText-root.Mui-error {
    position: absolute;
    top: 45px;
  }
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const Field = styled.div<{ indentBottom?: string }>`
  width: 100%;
  height: 50px;
  margin-bottom: ${p => (p.indentBottom ? p.indentBottom : "16px")};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const FieldValue = styled.span`
  height: 25px;
  font-weight: 600;
  line-height: 24px;
`

export const FieldLabel = styled(FieldValue)`
  color: ${({ theme }) => theme.colors.contentSecondary};
`

export const DateField = styled(UiTextField)`
  && {
    background-position: 100% 4px;
    margin-bottom: 50px;
  }
`
