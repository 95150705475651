import { SerializedError } from "@reduxjs/toolkit"

export interface UserState {
  loading: boolean
  error: SerializedError | null
  data: UserData | null
}

export enum RoleNames {
  client = "client",
  manager = "manager",
  administrator = "administrator",
}

type UserUiSettings = {
  dontShow?: boolean
}

export interface UserData {
  id: string
  email: string
  password: string
  referralId: string
  endOfSubscription: string
  autoRenewal: boolean
  actualRateName: string
  actualRateId: string
  roleId: string
  roleName: RoleNames
  createdAt: string
  lastLogin: string
  lastResumeDate: string | null
  moderationRequestCount: number
  isAutogeneratedPassword?: boolean
  uiSettings: UserUiSettings
}
