import { accountDetailReducer } from "@account-detail/store";
import { authReducer } from "@auth/store";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storageSession from "reduxjs-toolkit-persist/lib/storage/session";
import { layoutReducer } from "..";
import {
  accountsReducer,
  articlesReducer,
  budgetsReducer,
  categoriesReducer,
  contractorsReducer,
  expensesReducer,
  mailingsReducer,
  notificationsReducer,
  popupReducer,
  purposeReducer,
  reportsReducer,
  requestsReducer,
  settingsUserReducer,
  subcategoriesReducer,
  subscriptionReducer,
  systemReducer,
  transactionsReducer,
  uiReducer,
  userReducer,
  usersReducer,
} from "./reducers";
import { actionsHistoryReducer } from "./reducers/actionsHistory";
import { analyticsReducer } from "../../unit/analytics/store";

const persistConfig = {
  key: "root",
  storage: storageSession,
  whitelist: ["system", "auth", "budgets"],
}

export const rootReducer = combineReducers({
  accounts: accountsReducer,
  auth: authReducer,
  budgets: budgetsReducer,
  transactions: transactionsReducer,
  categories: categoriesReducer,
  subscription: subscriptionReducer,
  settingsUser: settingsUserReducer,
  contractors: contractorsReducer,
  subcategories: subcategoriesReducer,
  popup: popupReducer,
  reports: reportsReducer,
  user: userReducer,
  purpose: purposeReducer,
  expenses: expensesReducer,
  notifications: notificationsReducer,
  mailings: mailingsReducer,
  users: usersReducer,
  requests: requestsReducer,
  articles: articlesReducer,
  actionsHistory: actionsHistoryReducer,
  system: systemReducer,
  accountDetails: accountDetailReducer,
  ui: uiReducer,
  layout: layoutReducer,
  analytics: analyticsReducer,
})

export const persistedRootReducer = persistReducer(persistConfig, rootReducer)

export type RootState = ReturnType<typeof rootReducer>
