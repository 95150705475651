import { setTree, toggleAllCategoryExpanded } from "@budget/store"
import { API } from "@common/api"
import { TreeItem } from "@common/components/ui"
import { Category, CategoryWithTag, SubcategoryWithTag } from "@common/models"
import { RootState } from "@common/store"
import { SubcategoryTransport } from "@common/transports"
import { UniqueIdentifier } from "@dnd-kit/core"
import { KeyboardReturnTwoTone } from "@material-ui/icons"
import { createAsyncThunk } from "@reduxjs/toolkit"
import { indexOf } from "ramda"

type UpdateCategoryOrderParams = {
  treeNodes: TreeItem<SubcategoryWithTag | CategoryWithTag>[]
  activeNode: TreeItem<SubcategoryWithTag | CategoryWithTag>
  updateTree: () => void
}

type UpdateSubcategoryCategoryOrderParams = {
  updatedTreeNodes: TreeItem<SubcategoryWithTag | CategoryWithTag>[]
  prevTreeNodes: TreeItem<SubcategoryWithTag | CategoryWithTag>[]
  activeId?: UniqueIdentifier
  updateTree: () => void
}

type UpdateSubcategoryCategoryOrderParamsFromEqualParent = {
  activeNode: TreeItem<SubcategoryWithTag | CategoryWithTag>
  activeParent: TreeItem<SubcategoryWithTag | CategoryWithTag>
  activeIndex: number
  updateTree: () => void
}

type UpdateSubcategoryCategoryOrderParamsFromDifferentParent = UpdateSubcategoryCategoryOrderParamsFromEqualParent

class CategoriesTableService {
  public transformCategoriesToTree(categories: Category[]): TreeItem<SubcategoryWithTag | CategoryWithTag>[] {
    return categories.map(category => ({
      id: category.id,
      parentId: undefined,
      data: { ...category, _tag: "category" },
      collapsed: true,
      children:
        category.subcategories?.map(subcategory => ({
          id: subcategory.id,
          parentId: subcategory.category,
          collapsed: false,
          data: { ...subcategory, _tag: "subcategory" },
          children: [],
        })) ?? [],
    }))
  }

  public expandRowIfCategory = createAsyncThunk<
    void,
    {
      activeId?: UniqueIdentifier
    },
    { state: RootState }
  >("budget/expandRowCategory", async ({ activeId }, { getState, dispatch }) => {
    const nodes = getState().ui.budget.tableTree
    for (let categoryIndex = 0; categoryIndex < nodes.length; categoryIndex++) {
      if (nodes[categoryIndex].id === activeId) {
        dispatch(toggleAllCategoryExpanded(true))
      }
    }
  })

  public sortTree = createAsyncThunk<
    void,
    {
      treeNodes: TreeItem<SubcategoryWithTag | CategoryWithTag>[]
      activeId?: UniqueIdentifier
    },
    { state: RootState }
  >("budget/sort-tree", async ({ treeNodes, activeId }, { dispatch, getState }) => {
    try {
      const activeCategoryNode = this.getNode(treeNodes, activeId)

      if (activeCategoryNode?.id === undefined) {
        return
      }

      if (activeCategoryNode?.parentId === undefined) {
        return await this.updateCategoryOrder({
          treeNodes,
          activeNode: activeCategoryNode,
          updateTree: () => dispatch(setTree(treeNodes)),
        })
      }

      await this.updateSubcategoryOrder({
        updatedTreeNodes: treeNodes,
        prevTreeNodes: getState().ui.budget.tableTree,
        activeId,
        updateTree: () => dispatch(setTree(treeNodes)),
      })
    } catch (error) {}
  })

  private async updateCategoryOrder(params: UpdateCategoryOrderParams): Promise<undefined> {
    const { activeNode, treeNodes, updateTree } = params
    const nodeIndex = indexOf(activeNode, treeNodes)

    if (nodeIndex === -1) {
      return
    }

    if (nodeIndex === 0) {
      await API.post("/sort-order/category", {
        category_id: activeNode.id.toString(),
        after_id: undefined,
      })
      updateTree()
      return
    }
    const afterNode = treeNodes[nodeIndex - 1]

    if (afterNode === undefined) {
      return
    }
    await API.post("/sort-order/category", {
      category_id: activeNode.id.toString(),
      after_id: afterNode.id.toString(),
    })
    updateTree()
    return
  }

  private async updateSubcategoryOrder(params: UpdateSubcategoryCategoryOrderParams): Promise<undefined> {
    const { prevTreeNodes, updatedTreeNodes, updateTree, activeId } = params

    const activeParent = this.getNodeParent(updatedTreeNodes, activeId)

    if (activeParent === undefined) {
      return
    }

    const activeNode = this.getNode(activeParent.children, activeId)

    if (activeNode === undefined) {
      return
    }

    const activeIndex = indexOf(activeNode, activeParent.children)

    if (activeIndex === -1) {
      return
    }

    const activeParentPrevState = this.getNodeParent(prevTreeNodes, activeId)

    if (activeParentPrevState?.id === activeParent.id) {
      await this.updateSubcategoryOrderOfEqualParent({
        activeIndex,
        activeNode,
        activeParent,
        updateTree,
      })
      return
    }

    await this.updateSubcategoryOrderOfDifferentParent({
      activeIndex,
      activeNode,
      activeParent,
      updateTree,
    })
  }

  private async updateSubcategoryOrderOfEqualParent(
    params: UpdateSubcategoryCategoryOrderParamsFromEqualParent,
  ): Promise<undefined> {
    const { activeIndex, updateTree, activeNode, activeParent } = params
    if (activeIndex === 0) {
      await SubcategoryTransport.sortOrder(activeNode.id.toString(), undefined)
      updateTree()
      KeyboardReturnTwoTone
    }

    const afterNode = activeParent.children[activeIndex - 1]

    if (afterNode === undefined) {
      return
    }

    await SubcategoryTransport.sortOrder(activeNode.id.toString(), afterNode.id.toString())
    updateTree()
    return
  }

  private async updateSubcategoryOrderOfDifferentParent(
    params: UpdateSubcategoryCategoryOrderParamsFromDifferentParent,
  ): Promise<undefined> {
    const { activeIndex, updateTree, activeNode, activeParent } = params
    if (activeIndex === 0) {
      await SubcategoryTransport.moveTo(activeNode.id.toString(), activeNode.parentId?.toString(), undefined)
      updateTree()
      return
    }

    const afterNode = activeParent.children[activeIndex - 1]

    if (afterNode === undefined) {
      return
    }

    await SubcategoryTransport.moveTo(
      activeNode.id.toString(),
      activeNode.parentId?.toString(),
      afterNode.id.toString(),
    )
    updateTree()
    return
  }

  private getNode(
    treeNodes: TreeItem<SubcategoryWithTag | CategoryWithTag>[],
    nodeId?: UniqueIdentifier,
  ): undefined | TreeItem<SubcategoryWithTag | CategoryWithTag> {
    if (nodeId === undefined) {
      return
    }

    let node: undefined | TreeItem<SubcategoryWithTag | CategoryWithTag> = undefined

    for (let categoryIndex = 0; categoryIndex < treeNodes.length; categoryIndex++) {
      if (treeNodes[categoryIndex].id === nodeId) {
        node = treeNodes[categoryIndex]
        break
      }

      const subcategories = treeNodes[categoryIndex].children

      for (let subcategoryIndex = 0; subcategoryIndex < subcategories.length; subcategoryIndex++) {
        if (subcategories[subcategoryIndex].id === nodeId) {
          node = subcategories[subcategoryIndex]
          break
        }
      }
    }

    return node
  }

  private getNodeParent(
    treeNodes: TreeItem<SubcategoryWithTag | CategoryWithTag>[],
    nodeId?: UniqueIdentifier,
  ): undefined | TreeItem<SubcategoryWithTag | CategoryWithTag> {
    let parent: TreeItem<SubcategoryWithTag | CategoryWithTag> | undefined

    for (let nodeIndex = 0; nodeIndex < treeNodes.length; nodeIndex++) {
      const subcategories = treeNodes[nodeIndex].children

      for (let subcategoryIndex = 0; subcategoryIndex < subcategories.length; subcategoryIndex++) {
        if (subcategories[subcategoryIndex].id === nodeId) {
          parent = treeNodes[nodeIndex]
          break
        }
      }
    }

    return parent
  }

  constructor() {}
}

export default new CategoriesTableService()
