import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import { MRT_RowData } from "material-react-table";
import { FC, PropsWithChildren, ReactElement, ReactNode } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import styled from "styled-components";

export interface EntityWithChildren {
  id: string;
  children: EntityWithChildren[];
}

type Props<TData extends MRT_RowData, CELL_ID extends string, TValue> = {
  data: TData;
  RowContainer: FC<PropsWithChildren<{ isBody?: boolean; isExpanded?: boolean }>>;
  TableBody: FC<
    PropsWithChildren<{
      list: TData;
      cellIds: CELL_ID[] | readonly CELL_ID[];
      RowContainer: FC<
        PropsWithChildren<{ isBody?: boolean; isExpanded?: boolean; isSubcategory?: boolean; isSelected?: boolean }>
      >;
    }>
  >;
  cellIds: CELL_ID[] | readonly CELL_ID[];
  renderHeaderCell: (cellId: CELL_ID) => ReactNode;
};

export function Table<TData extends MRT_RowData, CELL_ID extends string, TValue = unknown>(
  props: Props<TData, CELL_ID, TValue>,
): ReactElement {
  const { data, renderHeaderCell, cellIds, RowContainer, TableBody } = props;

  function checkDataWithChildren<TData extends object>(data: TData[]): EntityWithChildren[] | undefined {
    if ("children" in data?.[0] && "id" in data?.[0]) {
      return (data as unknown) as EntityWithChildren[];
    }
  }

  return (
    <DragDropContext onDragEnd={() => null}>
      <TableContainer component={StyledPaper}>
        <FixedFooter>
          <RowContainer isBody={false}>{cellIds.map(cellId => renderHeaderCell(cellId))}</RowContainer>
        </FixedFooter>
        <TableBody list={data} cellIds={cellIds} RowContainer={RowContainer} />
      </TableContainer>
    </DragDropContext>
  );
}

const FixedFooter = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
`;

const StyledPaper = styled(Paper)`
  box-shadow: none !important;

  &.MuiPaper-root {
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
`;
