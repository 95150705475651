import { useCallback } from "react";
import { AsyncThunkAction, unwrapResult } from "@reduxjs/toolkit";
import { useAsyncDispatch } from "./useAsyncDispatch";

export const useUnwrapAsyncDispatch = () => {
  const dispatch = useAsyncDispatch();
  return useCallback(
    <R extends any>(asyncThunk: AsyncThunkAction<R, any, any>): Promise<R> =>
      dispatch(asyncThunk).then(unwrapResult),
    [dispatch]
  );
}
