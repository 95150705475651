import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 360px;
`;

export const Title = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: ${p => p.theme.text.fontMedium};
  line-height: 32px;
  color: ${p => p.theme.colors.contentMain};
  margin-bottom: 24px;
`;

export const Text = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: ${p => p.theme.text.fontSmall};
  line-height: 24px;
  color: ${p => p.theme.colors.contentMain};
`;

export const PressableText = styled.span`
  cursor: pointer;
  font-style: normal;
  font-weight: normal;
  font-size: ${p => p.theme.text.fontSmall};
  line-height: 24px;
  color: ${p => p.theme.colors.main};
  &:hover {
    text-decoration: underline;
  }
`;
