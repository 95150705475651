import type { Category } from "@common/models"
import { getHumanReadableMonth } from "@common/utils"
import { PayloadAction, Slice, createSlice } from "@reduxjs/toolkit"

export interface DateFormat {
  month: number | null
  year: number | null
}

export interface CategoriesState {
  loading: boolean
  error: string | null
  date: DateFormat
  list: Category[]
}

const defaultDate = { month: getHumanReadableMonth(new Date()), year: new Date().getFullYear() }

const INIT_STATE: CategoriesState = {
  loading: false,
  error: null,
  date: defaultDate,
  list: [],
}

const categoriesSlice: Slice<CategoriesState> = createSlice({
  name: "categories",
  initialState: INIT_STATE,
  reducers: {
    setCategoriesList: (state, action: PayloadAction<Category[]>): void => {
      state.list = action.payload
      state.error = null
    },
    setCategoriesDate: (state, action: PayloadAction<DateFormat>): void => {
      state.date = action.payload
    },
    setCategoriesLoading: (state, action: PayloadAction<boolean>): void => {
      state.loading = action.payload
    },
    setCategoriesError: (state, action: PayloadAction<string | null>): void => {
      state.loading = false
      state.error = action.payload
    },
    clearCategoriesWithError: (state, action: PayloadAction<string | null>): void => {
      state.error = action.payload
    },
  },
  extraReducers: {},
})

export const {
  setCategoriesLoading,
  setCategoriesError,
  clearCategoriesWithError,
  setCategoriesDate,
  setCategoriesList,
} = categoriesSlice.actions
export const categoriesReducer = categoriesSlice.reducer
