import { z } from "zod"

export const ArticleZodSchema = z.object({
  id: z.string().uuid(),
  title: z.string(),
  section: z.string(),
  titleText: z.string().optional(),
  titleImage: z.string(),
  content: z.any().optional(),
  sortingParameter: z.number().optional(),
  publicationDate: z.string().optional(),
  isDrafts: z.boolean().optional(),
  isPublished: z.boolean().optional(),
  isRemoved: z.boolean(),
  createdAt: z.string(),
  updatedAt: z.string(),
})
