import { CacheAccount } from "@common/models"
import { createSlice, PayloadAction, Slice, SliceCaseReducers } from "@reduxjs/toolkit"

const initialState: AccountsState = {
  list: [],
  types: [],
  chosen: [],
  loading: false,
  error: null,
  currentAccount: undefined,
}

export interface AccountType {
  id: string
  alias: string
  name: string
}

interface AccountsState {
  loading: boolean
  error: string | null
  list: CacheAccount[]
  types: AccountType[]
  chosen: CacheAccount[]
  currentAccount: CacheAccount | undefined
}

interface AccountsActions extends SliceCaseReducers<AccountsState> {
  setAccountsLoading: (state: AccountsState, action: PayloadAction<boolean>) => void
  setAccountsError: (state: AccountsState, action: PayloadAction<string | null>) => void
  setAccountsList: (state: AccountsState, action: PayloadAction<CacheAccount[]>) => void
  setAccountsTypesLoaded: (state: AccountsState, action: PayloadAction<AccountType[]>) => void
  setAccountsChosen: (state: AccountsState, action: PayloadAction<string>) => void
  removeAccountChosen: (state: AccountsState, action: PayloadAction<string>) => void
  setCurrentAccount: (state: AccountsState, action: PayloadAction<CacheAccount | undefined>) => void
  addAllAccountsToChange: (state: AccountsState) => void
  removeAllAccountsToChange: (state: AccountsState) => void
}

const accountsSlice: Slice<AccountsState, AccountsActions, "accounts"> = createSlice({
  name: "accounts",
  initialState: initialState,
  reducers: {
    setAccountsLoading: (state, action: PayloadAction<boolean>): void => {
      state.loading = action.payload
    },
    setAccountsError: (state, action: PayloadAction<string | null>): void => {
      state.error = action.payload
    },
    setAccountsList: (state, action: PayloadAction<CacheAccount[]>): void => {
      state.list = action.payload
      state.loading = false
      state.chosen = []
    },
    setAccountsTypesLoaded: (state, action: PayloadAction<AccountType[]>): void => {
      state.types = action.payload
      state.loading = false
    },
    setAccountsChosen: (state, action: PayloadAction<string>): void => {
      const chosen = state.chosen
      const addedAccount = state.list?.find(item => item?.id === action.payload)
      if (addedAccount !== undefined) {
        state.chosen = chosen.concat(addedAccount)
      }
    },
    removeAccountChosen: (state, action: PayloadAction<string>): void => {
      state.chosen = state.chosen.filter(item => item.id !== action.payload)
    },
    setCurrentAccount: (state, action: PayloadAction<CacheAccount | undefined>): void => {
      state.currentAccount = action.payload
    },
    addAllAccountsToChange: (state): void => {
      state.chosen = state.list
    },
    removeAllAccountsToChange: (state): void => {
      state.chosen = []
    },
  },
  extraReducers: {},
})

export const {
  removeAccountChosen,
  setCurrentAccount,
  setAccountsChosen,
  setAccountsError,
  setAccountsList,
  setAccountsTypesLoaded,
  setAccountsLoading,
  removeAllAccountsToChange,
  addAllAccountsToChange,
} = accountsSlice.actions

export const accountsReducer = accountsSlice.reducer
