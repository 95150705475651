import { media } from "@main/styles"
import { Tab as TabMui, Tabs as TabsMui } from "@material-ui/core"
import styled from "styled-components"

export const Wrapper = styled.div`
  max-width: 1320px;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
`

export const Title = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 60px;
  margin-top: 40px;
  margin-bottom: 40px;
  h1 {
    margin: 0;
  }
  ${media.medium`
    margin-top: 24px;
  `}
`

export const ContentWrapper = styled.div`
  background: ${p => p.theme.colors.white};
  border-radius: 8px;
  width: 100%;
`

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 40px;
`

export const MailingFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px;
`

export const Tabs = styled(TabsMui)`
  .MuiTabs-flexContainer:before {
    content: "";
    width: 100%;
    height: 0;
    position: absolute;
    bottom: 0;
    border-bottom: 2px solid ${p => p.theme.colors.light};
  }
  .MuiTabs-indicator {
    background-color: ${p => p.theme.colors.dark};
  }
  .Mui-selected .MuiTab-wrapper {
    color: ${p => p.theme.colors.dark};
  }
`

export const Tab = styled(TabMui)`
  .MuiTab-wrapper {
    text-transform: none;
  }
`

export const BackButtonWrapper = styled.div`
  margin-right: 14px;
  display: inline;
`

export const LoadingWrapper = styled.div`
  width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
`
