const validateConfirmFailureMessage: string = "Введенные пароли не совпадают";

export const validateEmail = (value?: string) => {
  const _value = String(value);

  const re = /^\w+([\.\/$!#%&'*+-/=?^_`{|}~]?\w+)*@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(\w+([\.-]?\w+)*(\.\w{2,})+))$/;

  if (_value.length > 253) return "Длинна email не должна превышать 253 символа";
  if (!re.test(_value)) return "Пожалуйста, введите корректный email-адрес";
};

export const validatePassword = (value: string) => {
  if (/[А-ЯЁ]|[а-яё]/.test(value)) return "Пароль должен состоять только из латинских букв, цифр и символов";
  if (!/[A-Z]/.test(value)) return "Пароль должен включать заглавные буквы";
  if (!/[a-z]/.test(value)) return "Пароль должен включать строчные буквы";
  if (!/[0-9]/.test(value)) return "Пароль должен включать цифры";

  if (value.length < 8) return "Длина пароля должна быть не менее 8 символов";
};

export const comparePasswords = values => {
  const errors = {};
  if (values.password !== values.repeat_password) {
    errors["repeat_password"] = validateConfirmFailureMessage;
  }
  return errors;
};

interface ValuesProps {
  email: string;
  password: string;
  confirm: string;
}

export const validateConfirm = (values: ValuesProps) => {
  if (values.confirm !== values.password) {
    return {
      email: "",
      password: "",
      confirm: validateConfirmFailureMessage,
    };
  }
};

interface IValidateConfirmWithOldPassword {
  oldPassword: string;
  newPassword: string;
  confirmNewPassword: string;
}

export const validateConfirmWithOldPassword = (values: IValidateConfirmWithOldPassword) => {
  if (values.newPassword !== values.confirmNewPassword) {
    return {
      oldPassword: "",
      newPassword: "",
      confirmNewPassword: validateConfirmFailureMessage,
    };
  }
};

export const validateRequired = (value: string) => {
  if (!value) return "Поле обязательно для заполнения";
};

export function isNotEmptyString(str?: string): boolean {
  return str !== "" && str !== undefined;
}

export function isEmptyString(str?: string | null): boolean {
  return str === "" || str === undefined || str === null;
}

export function isUndefined(value: unknown): boolean {
  return value === undefined;
}

export function isNotUndefined(value: unknown): boolean {
  return value !== undefined;
}
