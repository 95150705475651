import styled, { css } from 'styled-components'
import { Modal, Card as MuiCard, IconButton } from '@material-ui/core'

export const Paper = styled(Modal)`
  && {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const styesCleanCard = css`
  padding: 0;
  box-sizing: border-box;
`;

export const Card = styled(MuiCard)<{ $mod?: 'clean' }>`
  &.MuiCard-root {
    padding: 40px;
    position: relative;
    overflow: visible;
    ${p => p.$mod === 'clean' && styesCleanCard};
  }
`;

export const Wrapper = styled.div`
  position: relative;
`;

export const Close = styled(IconButton)`
  && {
    background-color: ${p => p.theme.colors.white};
    border-radius: 50%;
    padding: 13px;
    position: absolute;
    top: -20px;
    right: -20px;
    z-index: 100;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.1);

    &:hover {
      background-color: ${p => p.theme.colors.white};
    }
  }
`;
