import styled from 'styled-components'
import { Menu } from '@material-ui/core'

interface TitleProps {
  disabled?: boolean;
}

export const StyledMaterialMenu = styled(Menu)`
  margin-top: 50px;
`;

export const MenuItemContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const Title = styled.div<TitleProps>`
  font-size: 16px;
  line-height: 24px;
  margin-right: 8px;
  color: ${({ disabled, theme }) => (disabled ? theme.colors.contentSecondary : 'black')};
`;
