import { createSlice, PayloadAction, Slice, SliceCaseReducers } from "@reduxjs/toolkit";

const initialState: StateTypes = {
  loading: false,
  error: null,
  list: [],
};

export interface INotification {
  id: string;
  name: string;
  title: string;
  body: string;
}

interface StateTypes {
  list: Array<INotification>;
  loading: boolean;
  error: string | null;
}

interface NotificationsActions extends SliceCaseReducers<StateTypes> {
  setNotificationSingle: (state: StateTypes, action: PayloadAction<INotification>) => void
  setNotificationsList: (state: StateTypes, action: PayloadAction<INotification[]>) => void
  setNotificationsLoading: (state: StateTypes, action: PayloadAction<boolean>) => void
  setNotificationsError: (state: StateTypes, action: PayloadAction<string | null>) => void
}

const notificationsSlice: Slice<StateTypes, NotificationsActions, "notifications"> = createSlice({
  name: "notifications",
  initialState: initialState,
  reducers: {
    setNotificationSingle: (state: StateTypes, action: PayloadAction<INotification>) => {
      state.list = state.list.map(el => {
        if (el.id === action.payload.id) {
          return action.payload;
        }
        return el;
      });
    },
    setNotificationsList: (state: StateTypes, action: PayloadAction<INotification[]>) => {
      state.list =  action.payload.sort((a, b) => (a.id as any) - (b.id as any))
    },
    setNotificationsLoading: (state: StateTypes, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    setNotificationsError: (state: StateTypes, action: PayloadAction<string | null>) => {
      state.error = action.payload
    }
  },
  extraReducers: {},
})

export const {
  setNotificationsError,
  setNotificationSingle,
  setNotificationsList,
  setNotificationsLoading,
} = notificationsSlice.actions

export const notificationsReducer = notificationsSlice.reducer
