import authService from "@auth/service/auth-service";
import { useAsyncDispatch } from "@common/hooks";
import { validateConfirm } from "@common/utils";
import { getParam } from "@common/utils/searchParamsParser";
import { Form as FinalForm } from "react-final-form";
import { useLocation, useNavigate } from "react-router-dom";
import { Social } from "../common/Social";
import { Container, Title, Wrapper } from "../common/styles";
import { LoginLink } from "../login/LoginLink";
import { SignUpForm } from "./SignUpForm";
import { ReactElement } from "react";

export function SignUp(): ReactElement {
  const navigate = useNavigate()
  const location = useLocation()
  const asyncDispatch = useAsyncDispatch()

  const referralId = getParam(location.search, "ref") || undefined

  const onSubmit = ({ email, password }) => {
    asyncDispatch(authService.signUpFromData({ email, password, referralId })).catch(() => navigate("/auth"))
  }

  return (
    <Wrapper>
      <Container>
        <Title>Регистрация</Title>
        <FinalForm onSubmit={onSubmit} render={SignUpForm} validate={validateConfirm} />
        <Social />
        <LoginLink />
      </Container>
    </Wrapper>
  )
}
