import { useAsyncDispatch, useTypedSelector } from "@common/hooks";
import { Field } from "react-final-form";
import { FormInputText, InputFloat, Selector, StyledMenuItem } from "../../../../../common/components/ui";
import { Form, Submit } from "./styles";
import { useEffect } from "react";
import { getAccountsTypesThunk } from "@common/services";
import styled from "styled-components";

const numberFormatSettings = {
  decimalScale: 2,
  allowNegative: true,
  isNumericString: true,
  thousandSeparator: "",
  valueType: "floatValue",
};

export const CreateForm = ({ handleSubmit, submitting, pristine, valid }) => {
  const asyncDispatch = useAsyncDispatch();
  const disabled = submitting || !valid || pristine;
  const types = useTypedSelector(state => state.accounts.types);

  useEffect(() => {
    asyncDispatch(getAccountsTypesThunk);
  }, []);

  return (
    <Form onSubmit={handleSubmit}>
      <Title>Создание счета</Title>
      <Body>
        <Field name="type" validate={value => !value && "Required"}>
          {({ input }) => (
            <Selector
              input={input}
              label="Тип счёта"
              list={types}
              renderValue={value => (
                <StyledMenuItem value={value.id} key={value.alias}>
                  {value.name}
                </StyledMenuItem>
              )}
            />
          )}
        </Field>
        <Field name="name" validate={value => !value && "Required"}>
          {({ input }) => <FormInputText input={input} label="Наименование" />}
        </Field>
        <Field name="available" validate={value => !value && "Required"}>
          {({ input }) => <InputFloat input={input} label="Текущий баланс" />}
        </Field>
      </Body>
      <Submit type="submit" disabled={disabled}>
        Сохранить
      </Submit>
    </Form>
  );
};

const Title = styled.h2`
  margin-bottom: 16px;
`;

const Body = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  margin-bottom: 20px;
`;
