import DragIcon from "@assets/icons/drag_icon.svg";
import styled from "styled-components";
import { SortTreeHandlerProps } from "../SortableTree/SortableTreeItem";

type Props = {
  sortSettings?: SortTreeHandlerProps;
};

export function DragButton(props: Props) {
  const { sortSettings } = props;

  return (
    <DragIconWrapper {...sortSettings?.listeners} ref={sortSettings?.ref}>
      <DragIcon />
    </DragIconWrapper>
  );
}

const DragIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: grab;

  svg {
    width: 24px;
    height: 24px;
  }
`;
