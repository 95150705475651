type AnyToVoidFunction = (...args: any) => void
export function watchWindowResize(
  onResize: () => void,
): AnyToVoidFunction {
  /* eslint-disable-next-line no-restricted-globals */
  self.addEventListener("resize", onResize)
  return () => {
    /* eslint-disable-next-line no-restricted-globals */
    self.removeEventListener("resize", onResize)
  }
}
