import { TextField } from "./styles"
import * as React from "react"
import { FieldInputProps } from "react-final-form"
import { ChangeEvent } from "react"

type Props<T> = {
  input: FieldInputProps<T>
  label?: string
  placeholder?: string
}
export function InputFloat<T extends string>(props: Props<T>) {
  const { input, placeholder, label } = props
  const reg = new RegExp(/^[0-9]*\.?[0-9]*$/);

  const inputRef = React.useRef<React.Ref<any>>(null);
  return (
    <TextField
      name={input.name}
      value={input.value}
      id={input.name}
      placeholder={placeholder}
      label={label}

      onChange={(event: ChangeEvent<HTMLInputElement>) => {
        let text = String(event.target.value);
        const dotePosition = text.indexOf('.');
        //@ts-ignore
        const cursorPosition = inputRef.current.selectionStart;
        if (!reg.test(text)) {
          if (dotePosition && cursorPosition <= dotePosition) {
            setTimeout(() => {
              //@ts-ignore
              inputRef?.current?.setSelectionRange(dotePosition, text.length);
            }, 0);
          }

          return;
        }

        if (cursorPosition < dotePosition) {
          if (dotePosition - cursorPosition === 0) {
            //@ts-ignore
            inputRef?.current?.setSelectionRange(text.length - 3, text.length - 2);
          }
        }
        if (!text) {
          input.onChange('');
          return;
        }
        if (text.length - dotePosition > 3) {
          return;
        }

        if (!text.includes('.')) {
          input.onChange(text + '.00');
          if (text.length <= 3) {
            setTimeout(() => {
              //@ts-ignore
              inputRef?.current?.setSelectionRange(1, 1);
            }, 0);
          }
        } else {
          input.onChange(text);
        }
      }}
      onBlur={() => {
        const dotePosition = input.value.indexOf('.');
        if (dotePosition !== -1 && input.value.length - dotePosition <= 2) {
          input.onChange(input.value + (input.value.length - dotePosition === 1 ? '00' : '0'));
        }
      }}
      type="numberformat"
      inputRef={inputRef}
      variant="standard"
    />
  )
}
