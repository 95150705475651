import { createSlice, PayloadAction, Slice, SliceCaseReducers } from "@reduxjs/toolkit";

export enum RequestActionTypes {
  deleteUser = 'delete_user',
  sendNotification = 'send_notification',
  createManager = 'create_manager',
  changeTrial = 'change_tiral_subscription',
  disableAutoRenewal = 'disable_auto_renewal',
  publishPost = 'publish_post',
  revokeReset = 'restoration_nullified_budget',
}

// TODO: Детально описать контент запроса
export type RequestContent = any;

export interface Request {
  id: string;
  initiator: string;
  initiatorEmail: string;
  action: RequestActionTypes;
  actionName: string;
  content: RequestContent;
  isAproved: boolean;
  updatedAt: string;
  createdAt: string;
}

export interface RequestsState {
  loading: boolean;
  error: string | null;
  list: Request[];
}

const initialState: RequestsState = {
  loading: false,
  error: null,
  list: [],
};

interface RequestsActions extends SliceCaseReducers<RequestsState> {
  setRequestsList: (state: RequestsState, action: PayloadAction<Request[]>) => void
  setRequestsLoading: (state: RequestsState, action: PayloadAction<boolean>) => void
  setRequestsError: (state: RequestsState, action: PayloadAction<string | null>) => void
}

const requestsSlice: Slice<RequestsState, RequestsActions, "requests"> = createSlice({
  name: "requests",
  initialState: initialState,
  reducers: {
    setRequestsList: (state: RequestsState, action: PayloadAction<Request[]>) => {
      state.list = action.payload
    },
    setRequestsLoading: (state: RequestsState, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    setRequestsError: (state: RequestsState, action: PayloadAction<string | null>) => {
      state.error = action.payload
    },
  },
  extraReducers: {},
})

export const {
  setRequestsLoading,
  setRequestsList,
  setRequestsError,
} = requestsSlice.actions

export const requestsReducer = requestsSlice.reducer
