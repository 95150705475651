import { updateCurrentMonthTable } from "@budget/api"
import { changeExpense, changeExpenseThunk } from "@common/services"
import { createAsyncThunk } from "@reduxjs/toolkit"
import { createAction } from "redux-actions"
import { lastActionSelector, nextActionSelector, subcategoryById } from "../selectors"
import { RootState } from "../store"
import { addCategory, deleteCategory, deleteCategoryThunk, editCategoryThunk } from "./categories"
import {
  addSubcategory,
  addSubcategoryThunk,
  deleteSubcategory,
  deleteSubcategoryThunk,
  editSubcategory,
  editSubcategoryThunk,
} from "./subcategories"

export enum IUndoActionEnum {
  ADD_CATEGORY = "ADD_CATEGORY",
  ADD_SUBCATEGORY = "ADD_SUBCATEGORY",
  EDIT_CATEGORY = "EDIT_CATEGORY",
  EDIT_SUBCATEGORY = "EDIT_SUBCATEGORY",
  EDIT_SUBCATEGORY_FOUNDS = "EDIT_SUBCATEGORY_FOUNDS",
  DELETE_CATEGORY = "DELETE_CATEGORY",
  DELETE_SUBCATEGORY = "DELETE_SUBCATEGORY",
  DELETE_SUBCATEGORY_AND_MOVE = "DELETE_SUBCATEGORY_AND_MOVE",
}

interface IHistoryRecord {
  actionType: IUndoActionEnum
  id?: string
  props?: {
    prevValue?: any
    isHidden?: any
    name?: any
    category?: any
    subcategory?: string
  }
  value?: any
}

export const setRecord = createAction("ACTION_ADD_RECORD)")
export const updateRecords = createAction("ACTION_UPDATE_RECORDS)")
export const goBackInHistory = createAction("ACTION_GO_BACK)")
export const goForwardInHistory = createAction("ACTION_GO_FORWARD)")
export const resetFutureHistory = createAction("RESET_FUTURE_HISTORY")

export const addRecordThunk = createAsyncThunk<void, IHistoryRecord, { state: RootState }>(
  "ADD_RECORD",
  async (data, { dispatch }) => {
    dispatch(setRecord(data))
  },
)
export const addRecord = (data: IHistoryRecord) => async dispatch => {
  dispatch(setRecord(data))
}

export const updateRecordsInHistory = data => async dispatch => {
  dispatch(updateRecords(data))
}

export const resetHistory = () => async dispatch => {
  dispatch(resetFutureHistory())
}

export const goBackThunk = createAsyncThunk<void, void, { state: RootState }>(
  "GO_BACK",
  async (_, { getState, dispatch }) => {
    const lastAction: IHistoryRecord = lastActionSelector(getState())
    switch (lastAction.actionType) {
      case IUndoActionEnum.ADD_CATEGORY:
        await dispatch(
          deleteCategoryThunk({
            categoryId: lastAction?.id ?? "",
            saveInHistory: false,
            fromUser: false,
          }),
        )
        break
      case IUndoActionEnum.ADD_SUBCATEGORY:
        await dispatch(
          deleteSubcategoryThunk({
            subcategoryId: lastAction?.id ?? "",
            saveInHistory: false,
            fromUser: false,
          }),
        )
        break
      case IUndoActionEnum.DELETE_SUBCATEGORY:
        await dispatch(
          addSubcategoryThunk({
            name: lastAction?.props?.prevValue.name,
            categoryId: lastAction?.props?.category,
            saveInHistory: false,
            oldId: lastAction.id,
            fromUser: false,
          }),
        )
        break
      case IUndoActionEnum.DELETE_SUBCATEGORY_AND_MOVE:
        await dispatch(
          addSubcategoryThunk({
            name: lastAction?.props?.prevValue.name,
            categoryId: lastAction?.props?.category,
            saveInHistory: false,
            oldId: lastAction.id,
            fromUser: false,
          }),
        )
        break
      case IUndoActionEnum.EDIT_CATEGORY:
        await dispatch(
          editCategoryThunk({
            name: lastAction?.props?.name,
            isHidden: lastAction.props?.isHidden,
            id: lastAction.id ?? "",
            saveInHistory: false,
            fromUser: false,
          }),
        )
        break
      case IUndoActionEnum.EDIT_SUBCATEGORY:
        await dispatch(
          editSubcategoryThunk({
            name: lastAction?.props?.name,
            isHidden: lastAction.props?.isHidden,
            category: lastAction.props?.category,
            id: lastAction.id ?? "",
            saveInHistory: false,
            fromUser: false,
          }),
        )
        break
      case IUndoActionEnum.EDIT_SUBCATEGORY_FOUNDS:
        const subcategory =
          lastAction.props?.subcategory !== undefined
            ? subcategoryById(getState(), lastAction.props?.subcategory)
            : undefined
        if (!subcategory?.expensesId) {
          await dispatch(
            changeExpenseThunk({
              subcategoryId: lastAction.props?.subcategory ?? "",
              amount: lastAction.props?.prevValue.amount || 0,
              saveInHistory: false,
              fromUser: false,
            }),
          )
          dispatch(updateCurrentMonthTable)
        } else {
          await dispatch(
            changeExpenseThunk({
              expenseId: lastAction.id,
              subcategoryId: lastAction.props?.subcategory ?? "",
              amount: lastAction.props?.prevValue.amount || 0,
              saveInHistory: false,
              fromUser: false,
            }),
          )
          dispatch(updateCurrentMonthTable)
        }
        break
      default:
        break
    }
    dispatch(goBackInHistory())
  },
)()

export const goForwardThunk = createAsyncThunk<void, void, { state: RootState }>(
  "GO_FORWARD",
  async (_, { getState, dispatch }) => {
    const nextAction: IHistoryRecord = nextActionSelector(getState())
    switch (nextAction.actionType) {
      case IUndoActionEnum.ADD_CATEGORY:
        await dispatch(addCategory({ name: nextAction?.value.name, oldId: nextAction.id, fromUser: false }))
        break
      case IUndoActionEnum.DELETE_CATEGORY:
        await dispatch(deleteCategory({ categoryId: nextAction?.id, fromUser: false }))
        break
      case IUndoActionEnum.ADD_SUBCATEGORY:
        await dispatch(
          addSubcategory({
            name: nextAction?.value.name,
            categoryId: nextAction?.props?.category,
            oldId: nextAction.id,
            fromUser: false,
          }),
        )
        break
      case IUndoActionEnum.DELETE_SUBCATEGORY:
        await dispatch(deleteSubcategory({ subcategoryId: nextAction?.id, fromUser: false }))
        break

      case IUndoActionEnum.EDIT_SUBCATEGORY:
        await dispatch(
          editSubcategory({
            name: nextAction?.value?.name,
            isHidden: nextAction.value?.isHidden,
            category: nextAction.value?.category,
            id: nextAction.id,
            fromUser: false,
          }),
        )
        dispatch(updateCurrentMonthTable)
        break
      case IUndoActionEnum.EDIT_SUBCATEGORY_FOUNDS:
        await dispatch(
          changeExpense({
            expenseId: nextAction.id,
            subcategoryId: nextAction.props?.subcategory,
            amount: nextAction.value?.amount || 0,
            month: nextAction.value?.month,
            year: nextAction.value?.year,
            fromUser: false,
          }),
        )
        dispatch(updateCurrentMonthTable)
        break
      default:
        break
    }
    dispatch(goForwardInHistory())
  },
)
