import styled from 'styled-components'
import {
  Checkbox as MuiCheckbox,
  FormControlLabel as MuiFormControlLabel,
  FormControl as MuiFormControl,
  TextField as MuiTextField,
  Button as MuiButton,
} from '@material-ui/core'
import addIcon from '@assets/icons/add.svg'

export const Wrapper = styled.div`
  box-sizing: border-box;
  max-width: 800px;
`;

export const Header = styled.section`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
`;

export const FieldGroup = styled.div`
  display: flex;
  align-items: center;
  margin: 35px 0;
`;

export const FormControlLabel = styled(MuiFormControlLabel)``;

export const Checkbox = styled(MuiCheckbox)`
  &&.Mui-checked {
    color: ${p => p.theme.colors.main};
  }
  &.MuiCheckbox-root {
    color: ${p => p.theme.colors.backgroundSecondary};
  }
`;

export const TextField = styled(MuiTextField)<{ width?: string }>`
  &.MuiFormControl-root {
    margin-right: 24px;
    &:last-child {
      margin-right: 0;
    }
  }
  .MuiFormLabel-root {
    font-family: Montserrat, sans-serif;

    &.Mui-focused {
      color: ${p => p.theme.colors.main};
    }
  }

  .MuiInput-underline {
    &:after {
      border-color: ${p => p.theme.colors.main};
    }

    &:before {
      border-color: ${p => p.theme.colors.backgroundSecondary};
    }
  }

  width: 100%;
`;

export const InputLabel = styled.label`
  display: inline-flex;
  align-items: center;
  height: 24px;
  position: relative;
  padding-left: 32px;
  color: ${p => p.theme.colors.main};
  cursor: pointer;

  &:before {
    position: absolute;
    content: url(${addIcon.toString()});
    top: 0;
    left: 0;
  }
`;

export const Button = styled(MuiButton)`
  &.MuiButtonBase-root {
  }
  && {
    color: ${p => p.theme.colors.main};
    padding: 0;

    svg {
      path {
        fill: ${p => p.theme.colors.main};
      }
    }

    &:disabled {
      color: ${p => p.theme.colors.contentSecondary};
      font-weight: 400;
      svg {
        path {
          fill: ${p => p.theme.colors.contentSecondary};
        }
      }
    }
  }
`;

export const InputFile = styled.input`
  display: none;
`;

export const Container = styled.div`
  margin-top: 24px;
  margin-bottom: 40px;
  width: 800px;
  height: 360px;

  border: 1px solid #e1e1e1;
  box-sizing: border-box;
  border-radius: 4px;
  overflow-y: auto;

  .ce-block__content {
    max-width: 700px;
  }

  .ce-toolbar__actions {
    right: -50px;
  }

  .ce-toolbar__plus {
    left: -65px;
  }
`;

export const P = styled.p`
  margin-top: 40px;
  max-width: 360px;

  b {
    font-weight: bold;
  }
`;

export const FormControl = styled(MuiFormControl)`
  && {
    margin-right: 24px;
  }
  .MuiFormLabel-root {
    &.Mui-focused {
      color: ${p => p.theme.colors.main};
    }
  }
  .MuiInputBase-root {
    margin-bottom: 0;
  }
  width: 100%;
`;
