import { AppStore } from "@auth/view/common/AppStore";
import { media } from "@main/styles";
import { redirect } from "react-router-dom";
import styled from "styled-components";
import { PropsWithChildren, ReactElement } from "react";

export function Redirect({ to }: { to: string }): null {
  redirect(to);
  return null;
}

export function AuthPageWrapper({ children }: PropsWithChildren): ReactElement {

  return (
    <Container>
      <Wrapper>
        <AppStore />
        <FormContainer>
          {children}
        </FormContainer>
      </Wrapper>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;

  ${media.medium`
    overflow: hidden;
  `}
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const FormContainer = styled.div`
  height: 100%;
  width: 100%;
  max-width: 600px;
  max-height: 800px;
`;
