import styled from 'styled-components'
import background from '@assets/background/growth.svg'
import { TextField as MuiTextField, Button, FormControlLabel } from '@material-ui/core'

export const PageWrapper = styled.div`
  max-width: 1320px;
  margin: 0 auto;
  overflow-y: hidden;
  padding-top: 40px;
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  /* height: calc(100% - 400px); */
`;

export const AsideWrapper = styled.aside`
  background-color: ${p => p.theme.colors.main};
  background-image: url(${background.toString()});
  background-repeat: no-repeat;
  background-position: bottom 0 right 0;
  background-size: 185px 229px;
  width: 312px;
  height: 312px;
  padding: 16px 24px;
  color: ${p => p.theme.colors.white};
  border-radius: 8px;
  box-sizing: border-box;
`;

export const Text = styled.p`
  margin-bottom: 40px;
`;

export const Sum = styled.span`
  font-size: ${p => p.theme.text.fontLarge};
  font-weight: bold;
  word-wrap: break-word;
`;

export const TextField = styled(MuiTextField)`
  &.MuiFormControl-root {
    margin-bottom: 50px;
    width: 360px;
  }
  .MuiFormLabel-root.Mui-focused {
    color: ${p => p.theme.colors.main};
  }
  .MuiInput-underline:after {
    border-color: ${p => p.theme.colors.main};
  }
`;

export const Submit = styled(Button)`
  &.MuiButton-root {
    padding: 8px 0;
    background-color: ${p => p.theme.colors.main};
    color: ${p => p.theme.colors.white};
    font-weight: bold;
    text-transform: none;

    &:hover,
    &:focus,
    &:active {
      background-color: ${p => p.theme.colors.dark};
    }

    &:disabled {
      background-color: ${p => p.theme.colors.backgroundMain};
      color: ${p => p.theme.colors.contentSecondary};
    }
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 360px;
`;

export const Label = styled(FormControlLabel)`
  margin-bottom: 40px;
`;


export const Blure = styled.div `
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9;
`;
