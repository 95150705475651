import styled from 'styled-components';
import { Checkbox as CheckboxMui } from '@material-ui/core';

interface ButtonProps {
  disabled?: boolean;
}

interface ChevronProps {
  disabled?: boolean;
}

export const Checkbox = styled(CheckboxMui)`
  &&.Mui-checked {
    color: ${p => p.theme.colors.main};
  }
  &.MuiCheckbox-root {
    color: ${p => p.theme.colors.backgroundSecondary};
  }
`;

export const PageContainer = styled.div`
  width: 100%;
  height: 100%;
`;

export const TableContainer = styled.div`
  width: 100%;
  height: calc(100% - 110px);
  border-top: 1px solid ${({ theme }) => theme.colors.backgroundSecondary};
`;

export const TopBarContainer = styled.div`
  padding: 40px 24px 24px 24px;
  width: 100%;
  min-height: 40px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
`;

export const ManagersTopBarContainer = styled(TopBarContainer)`
  justify-content: space-between;
`;

export const ManagersTopBarContainerleft = styled.div`
  display: flex;
`;

export const PaginationChevron = styled.div<ChevronProps>`
  width: 50px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
`;

export const DeleteButton = styled.div<ButtonProps>`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100px;
  color: ${({ disabled, theme }) => (disabled ? theme.colors.contentSecondary : theme.colors.main)};

  svg {
    path {
      fill: ${({ disabled, theme }) => (disabled ? theme.colors.contentSecondary : theme.colors.main)};
    }
  }
`;

export const CreateButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 200px;
  color: ${({ theme }) => theme.colors.main};
`;

export const TableHead = styled.div`
  width: 100%;
  height: 50px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.backgroundSecondary};
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 0 0 12px;
`;

export const TableBody = styled.div`
  width: 100%;
  height: calc(100% - 100px);
  overflow-y: scroll;
  overflow-x: hidden;
`;

export const TableFooter = styled.div`
  width: 100%;
  height: 50px;
  border-top: 1px solid ${({ theme }) => theme.colors.backgroundSecondary};
  display: flex;
  padding: 0 12px;
  box-sizing: border-box;
`;

export const TableLine = styled.div`
  width: 100%;
  height: 50px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.backgroundSecondary};
  display: flex;
  justify-content: space-between;
  padding: 0 0 0 12px;
  box-sizing: border-box;

  &:hover {
    background-color: ${({ theme }) => theme.colors.light};
  }
`;

export const TableCell = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

export const Divider = styled(TableCell)`
  width: 160px;
`;

export const CheckboxCell = styled(TableCell)`
  min-width: 50px;
  width: 80px;
  padding-left: 12px;
`;

export const EditCell = styled(CheckboxCell)`
  min-width: 40px;
  cursor: pointer;
`;

export const Regular = styled(TableCell)`
  min-width: 245px;
  padding-left: 12px;
`;

export const ShortRegular = styled(Regular)`
  min-width: 190px;
`;

export const BoldRegular = styled(Regular)`
  font-weight: 600;
`;

export const RegularMeta = styled(BoldRegular)`
  color: ${({ theme }) => theme.colors.contentSecondary};
`;

export const ShortRegularMeta = styled(RegularMeta)`
  min-width: 190px;
`;

export const LoaderContainer = styled.div`
  width: 100%;
  height: 840px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CellText = styled.a`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
