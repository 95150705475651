import { FieldInputProps } from "react-final-form";
import { Select } from "../Select";
import { StyledLabel } from "./styles";
import { ReactElement } from "react";

interface Props<FORM_TYPE, LIST_VALUE> {
  input: FieldInputProps<FORM_TYPE>;
  label?: string;
  placeholder?: string;
  list: LIST_VALUE[];
  renderValue: (value: LIST_VALUE) => ReactElement;
}

export function Selector<FORM_TYPE extends string, LIST_VALUE>(props: Props<FORM_TYPE, LIST_VALUE>) {
  const { input, label, list, placeholder, renderValue } = props;

  return (
    <div>
      {label !== undefined && <StyledLabel id={`${input.name}-label`}>{label}</StyledLabel>}
      <Select
        labelId={`${input.name}-label`}
        id={input.name}
        value={input.value}
        onChange={input.onChange}
        name={input.name}
        placeholder={placeholder}
      >
        {list?.map(item => renderValue(item))}
      </Select>
    </div>
  );
}
