import styled from 'styled-components'
import { Button as ButtonMui } from '@material-ui/core'
import CirclePlusIconAsset from '@assets/icons/plus-circle.svg'

export const Button = styled(ButtonMui)`
  margin-left: 20px !important;

  .MuiButton-label {
    color: ${p => p.theme.colors.dark};
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-transform: none;
  }
`;

export const CirclePlusIcon = styled(CirclePlusIconAsset)`
  path {
    fill: ${p => p.theme.colors.dark};
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const LoadingWrapper = styled.div`
  width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
