import styled from 'styled-components'
import {
  Button as MuiButton,
  FormControlLabel,
  TextField as MuiTextField,
  Link as MuiLInk,
  Checkbox as MuiCheckbox,
  FormControl as MuiFormControl,
} from '@material-ui/core'
import CreatableSelect from 'react-select/creatable'
import ruble from '@assets/icons/ruble.svg'

export const Text = styled.p`
  margin-bottom: 40px;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;

export const Submit = styled(MuiButton)`
  &.MuiButton-root {
    margin-top: 110px;
    padding: 8px 0;
    background-color: ${p => p.theme.colors.main};
    color: ${p => p.theme.colors.white};
    font-weight: bold;
    text-transform: none;

    &:hover,
    &:focus,
    &:active {
      background-color: ${p => p.theme.colors.dark};
    }

    &:disabled {
      background-color: ${p => p.theme.colors.backgroundMain};
      color: ${p => p.theme.colors.contentSecondary};
    }
  }
`;

export const Button = styled(Submit)`
  width: 100%;
  margin-bottom: 24px;
  &:not(:last-child),
  &:not(:only-child) {
    margin-right: 16px;
  }
`;

export const Edit = styled(Button)`
  &.MuiButton-root {
    background-color: transparent;
    border: 1px ${p => p.theme.colors.main} solid;
    border-radius: 4px;
    color: ${p => p.theme.colors.main};
  }
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 360px;
`;

export const Label = styled(FormControlLabel)`
  margin-bottom: 40px;
  & .MuiIconButton-root {
    padding-top: 0;
    padding-bottom: 0;
  }
`;

export const TextField = styled(MuiTextField)`
  &.MuiFormControl-root {
    position: relative;
    width: 100%;
  }
  .MuiFormLabel-root.Mui-focused {
    color: ${p => p.theme.colors.main};
  }
  .MuiInput-underline:after {
    border-color: ${p => p.theme.colors.main};
  }
  .MuiFormHelperText-root.Mui-error {
    position: absolute;
    bottom: -20px;
  }
  .MuiOutlinedInput-input {
    padding: 8px;
    height: 40px;
    box-sizing: border-box;
  }

  .MuiInputBase-root {
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
      padding: 0;
    }

    input[type='number'] {
      -moz-appearance: textfield;
    }
  }

  .MuiInputLabel-outlined {
    transform: translate(14px, 8px) scale(1);
  }
  &.MuiOutlinedInput-input {
    width: 100%;
  }
  & .MuiOutlinedInput-root {
    width: 100%;
  }
  & .PrivateNotchedOutline-legendLabelled-11 > span {
    display: none;
  }
`;

export const ModalField = styled(TextField)`
  &.MuiFormControl-root {
    margin-bottom: 40px;
  }
`;

export const ReactSelect = styled(CreatableSelect)`
  width: 100%;
  & .select__menu {
    width: 192px;
    padding: 16px;
    font-size: 12px;
    line-height: 16px;
    margin-top: 8px;
    z-index: 100;
    box-sizing: border-box;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.1);
    max-height: 150px;
    overflow-y: auto;
  }
  & .select__control {
    font-size: 12px;
    line-height: 16px;
    box-shadow: none;
    border-color: ${p => p.theme.colors.backgroundSecondary};
  }
  & .css-1okebmr-indicatorSeparator {
    display: none;
  }
  & .select__control:hover,
  & .select__control--is-focused,
  & .select__control--is-selected {
    border: 2px solid ${p => p.theme.colors.main};
    box-shadow: none;
  }
  & .select__option {
    cursor: pointer;
    background-color: transparent;
  }
  & .select__option:hover {
    background: ${p => p.theme.colors.light};
  }
  & .select__option--is-selected {
    background: ${p => p.theme.colors.light};
    color: ${p => p.theme.colors.contentMain};
  }
`;

export const ModalSelect = styled(ReactSelect)`
  margin-bottom: 36px;
  & .select__menu {
    width: 100%;
  }
`;

export const Link = styled(MuiLInk)`
  && {
    color: ${p => p.theme.colors.main};
    cursor: pointer;
    text-align: center;
    margin-top: 24px;
    &:hover,
    &:focus {
      text-decoration: none;
      color: ${p => p.theme.colors.dark};
    }
  }
`;

export const ModalSum = styled(TextField)`
  && {
    width: 312px;
    margin: 0 auto 75px;
    background-image: url(${ruble.toString()});
    background-repeat: no-repeat;
    background-position: 100% 16px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Background = styled.div`
  padding: 48px 0;
  background: ${p => p.theme.colors.backgroundMain};
  text-align: center;
  width: 360px;
  box-sizing: border-box;
  margin-bottom: 40px;
  margin-top: 10px;
`;

export const Title = styled.h3`
  margin-bottom: 24px;
  font-weight: 600;
`;

export const Balance = styled.p`
  color: ${p => p.theme.colors.foundationPositive};
  font-weight: 700;
  font-size: 22px;
  line-height: 32px;
`;

export const Checkbox = styled(MuiCheckbox)`
  &&.Mui-checked {
    color: ${p => p.theme.colors.main};
  }
  &.MuiCheckbox-root {
    color: ${p => p.theme.colors.backgroundSecondary};
  }
`;

export const FormControl = styled(MuiFormControl)`
  .MuiFormLabel-root {
    &.Mui-focused {
      color: ${p => p.theme.colors.main};
    }
  }
  width: 100%;
`;
