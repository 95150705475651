import styled from "styled-components";
import { HTMLAttributes } from "react";

type Props = HTMLAttributes<HTMLSpanElement> & {
  text?: string
  lightText?: boolean
  notWrap?: boolean
}

export function CommonCell(props: Props) {
  const { text, lightText, ...other } = props

  return <Text lightText={lightText} $active={other.onClick !== undefined} {...other}>{text}</Text>
}

const Text = styled.span<{$active?: boolean, lightText?: boolean, notWrap?: boolean}>`
  font-weight: 500;
  font-family: Manrope, Inter, sans-serif;
  font-size: 14px;
  line-height: 19px;
  color: ${p => p.theme.colors.darkText};
  max-width: 100%;
  display: inline-block;
  
  ${p => p.notWrap && `
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `}  
    
  ${p => p.lightText && `
    color: #61698A;
  `}
  
  ${p => p.$active && `
    cursor: pointer;
  `}
`
