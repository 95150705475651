import React from 'react'
import { ListItemText } from '@material-ui/core'
import { CommonListItem, ExternalLink } from './styles'
import { Icon } from "../../styles"
import { ComponentProps } from "../../routes"
import styled from "styled-components"

export const ExternalLinkComponent = ({ href, icon, title, onClick, active }: ComponentProps) => {
  return (

    <CommonListItem>
      <ExternalLink href={href} onClick={onClick} $active={active}>
        <StyledIcon><Icon>{icon}</Icon></StyledIcon>
        <ListItemText primary={title}/>
      </ExternalLink>
    </CommonListItem>
  );
};

const StyledIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center; 
    
  svg {
    width: 24px;
    height: 24px;
  }  
`
