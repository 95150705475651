import styled from "styled-components";

export function ComplexText({ description, title }: { title?: string; description: string }) {
  return (
    <>
      <ComplexBoldText>{title}</ComplexBoldText>
      <ComplexLightText small>{description}</ComplexLightText>
    </>
  );
}

export const ComplexBoldText = styled.p`
  font-size: 14px;
  line-height: 19px;
  font-weight: 600;
  font-family: Manrope, Inter, sans-serif;
  color: ${p => p.theme.colors.darkText};
  margin-bottom: 4px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

export const ComplexLightText = styled.p<{ small?: boolean }>`
  font-size: 14px;
  line-height: 19px;
  font-weight: 500;
  font-family: Manrope, Inter, sans-serif;
  color: ${p => p.theme.colors.lightText};

  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;

  ${p =>
    p.small &&
    `
      font-size: 12px;
      line-height: 16px;
      -webkit-line-clamp: 1;
    `}
`;
