import React, { useEffect } from 'react'
import { useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex: 1;
  background-color: pink;
`;

const detectMob = () => {
  const toMatch = [
    {
      platform: 'android',
      regexp: /Android/i,
    },
    {
      platform: 'ios',
      regexp: /iPhone/i,
    },
    {
      platform: 'ios',
      regexp: /iPad/i,
    },
    {
      platform: 'ios',
      regexp: /iPod/i,
    },
  ];

  const mobile = toMatch.find(toMatchItem => {
    return navigator.userAgent.match(toMatchItem.regexp);
  });

  return mobile ? mobile.platform : false;
};

export const RedirectUpdatePassword = () => {
  const params = useParams<{ token: string }>();

  const navigate = useNavigate()
  useEffect(() => {
    const { token } = params || {};

    if (!token) {
      navigate('/auth', { replace: true })
      return;
    }
    const iframeRedirect = document.getElementById('iframe-redirect');
    const mobilePlatform = detectMob();

    const reportURL = mobilePlatform
      ? `savinger://update_password/${token}`
      : `${window.origin}/auth/update_password?token=${token}`;

    if (iframeRedirect) {
      // @ts-ignore
      iframeRedirect.src =
        mobilePlatform === 'ios'
          ? 'itms-apps://?action=discover&referrer=app-store&itscg=10000&itsct=app-appstore-nav-200917/'
          : 'https://play.google.com/store/games';
    }
    window.location.href = reportURL;
  }, [ params, navigate ]);

  return (
    <>
      <iframe id="iframe-redirect"/>
      <Container/>
    </>
  );
};
