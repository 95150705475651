import styled from 'styled-components'
import { IconButton as MuiIconButton } from '@material-ui/core'

export const Wrapper = styled.div`
  padding:  12px 36px;
  border: 1px solid ${p => p.theme.colors.backgroundSecondary};
`;

export const Text = styled.span`
  font-weight: 600;
  color: ${p => p.theme.colors.contentSecondary};
`;

export const IconButton = styled(MuiIconButton)`
  &&:disabled svg {
    fill: ${p => p.theme.colors.contentSecondary};
  }
`;
