import CreateIcon from "@assets/icons/add.svg"
import EditIcon from "@assets/icons/edit.svg"
import DeleteIcon from "@assets/icons/trash.svg"
import { accountsToEdit } from "@common/store"
import { Button as MuiButton } from "@material-ui/core"
import { useSelector } from "react-redux"
import styled from "styled-components"

export const ManageButtons = ({ onClick }) => {
  const chosenRow = useSelector(accountsToEdit)

  return (
    <List>
      <Item id="create">
        <Button aria-label="create" onClick={() => onClick("create")}>
          <CreateIcon />
          <Label>Создать счет</Label>
        </Button>
      </Item>
      <Item>
        <Button aria-label="edit" disabled={chosenRow.length !== 1} onClick={() => onClick("edit")}>
          <EditIcon />
          <Label>Редактировать</Label>
        </Button>
      </Item>
      <Item>
        <Button aria-label="delete" disabled={chosenRow.length === 0} onClick={() => onClick("delete")}>
          <StyledDeleteIcon />
          <Label>Удалить</Label>
        </Button>
      </Item>
    </List>
  )
}

const List = styled.ul`
  display: flex;
  margin-bottom: 24px;
`

const Item = styled.li`
  margin-right: 26px;
`

const Label = styled.span`
  padding-left: 10px;
  text-transform: none;
`

const Button = styled(MuiButton)`
  && {
    color: ${p => p.theme.colors.main};
    &:disabled {
      color: ${p => p.theme.colors.contentSecondary};
      font-weight: 400;
      svg {
        fill: ${p => p.theme.colors.contentSecondary};
      }
    }
  }
`

export const StyledDeleteIcon = styled(DeleteIcon)`
  fill: ${p => p.theme.colors.main};
`
