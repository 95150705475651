import { RefObject, useEffect } from "react"

const containerStack: Array<HTMLElement> = []

export type UseVirtualBackdropOpts = {
  backdropClassName?: string
  noConsumeEvent?: boolean
  disableElementCheck?: boolean
  disableBackdropClassCheck?: boolean
}

export function useVirtualBackground(
  isOpen: boolean,
  containerRef: RefObject<HTMLElement> | undefined,
  onClose?: (event: Event | undefined) => void | undefined,
  opts?: UseVirtualBackdropOpts,
): void {
  const backdropClassName = opts?.backdropClassName
  const noConsumeEvent = opts?.noConsumeEvent
  const disableElementCheck = opts?.disableElementCheck
  const disableBackdropClassCheck = opts?.disableBackdropClassCheck
  const backdrop = backdropClassName ?? "backdrop"

  useEffect(() => {
    if (!isOpen) {
      return
    }
    if (containerRef === undefined) {
      return
    }
    const container = containerRef.current
    if (container == null) {
      return
    }
    containerStack.push(container)
    return () => {
      containerStack.pop()
    }
  }, [ containerRef, isOpen, backdrop ])

  useEffect(() => {
    const handleEvent = function useBackdropClickHandler(event: MouseEvent) {
      if (containerRef === undefined) {
        return
      }

      const container = containerRef.current
      const target = findEventTarget(event)

      if (!container || !target) {
        return
      }

      const isLastAtStack = containerStack.length === 0 || containerStack[containerStack.length - 1] === container
      if (!isLastAtStack) {
        return
      }

      const isTargetOutsideContainer = (disableElementCheck !== true) && !container.contains(target)
      const isTargetHasBackdropClass = (disableBackdropClassCheck !== true) && target.classList.contains(backdrop)
      if (isTargetOutsideContainer || isTargetHasBackdropClass) {
        if (noConsumeEvent !== true) {
          event.preventDefault()
          event.stopImmediatePropagation()
        }
        if (onClose) {
          onClose(event)
        }
      }
    }

    if (isOpen && onClose) {
      document.addEventListener("click", handleEvent, { capture: true })
      document.addEventListener("contextmenu", handleEvent, { capture: true })
    }
    return () => {
      document.removeEventListener("click", handleEvent, { capture: true })
      document.removeEventListener("contextmenu", handleEvent, { capture: true })
    }
  }, [ containerRef, isOpen, onClose, backdrop, noConsumeEvent, disableElementCheck, disableBackdropClassCheck ])
}

function findEventTarget(event: MouseEvent): HTMLElement | null {
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const paths: unknown = event.path || event.composedPath()
  const target = event.target as HTMLElement | null | undefined
  if (Array.isArray(paths)) {
    const path: unknown = paths[0]
    if (path instanceof HTMLElement) {
      return path
    }
  }
  return target || null
}
