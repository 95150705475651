import ChevronDownIcon from "@assets/icons/chevron-bottom.svg";
import { hexToRgb } from "@common/utils";
import { theme } from "@main/styles";
import { Select as MuiSelect } from "@material-ui/core";
import ReactSelect from "react-select";
import CreatableSelect from "react-select/creatable";
import styled from "styled-components";
import {
  createReactSelectPrefixClassName as createReactSelectPrefix,
  createReactSelectWrapperClassName as createReactSelectWrapper,
} from "./CreateReactSelect";
import { reactSelectPrefixClassName as reactSelectPrefix, reactSelectWrapperClassName } from "./ReactSelect";

// ______ Select ______

export const StyledSelect = styled(MuiSelect)`
  &.MuiInputBase-root {
    border-radius: 6px;
    height: 60px;
    position: relative;
    border: 1px solid ${p => p.theme.colors.lightBlue};
    width: 100%;

    .MuiSelect-root {
      font-family: Manrope, Inter, sans-serif;
      height: 100%;
      padding: 0 12px 0 16px;
      display: flex;
      align-items: center;
      border-radius: 6px;
      background-color: rgba(${hexToRgb(theme.colors.lightBlue)}, 0.2);
    }

    &.MuiInput-underline:after,
    &.MuiInput-underline:before {
      border: 0;
    }

    &.MuiInput-underline:hover {
      border: 1px solid ${p => p.theme.colors.lightBlue};
    }

    &.MuiInput-underline:hover:not(.Mui-disabled):before {
      border: 0;
    }

    &:hover {
      border: 1px solid ${p => p.theme.colors.lightBlue};
    }

    .MuiSelect-nativeInput {
      font-size: 16px;
      border: 0;
      transform: none;
      font-family: Manrope, Inter, sans-serif;
      color: #272c41;
      font-weight: 500;
      opacity: 1;
      left: 0;
      top: 0;
      max-width: calc(100% - (32px * 2));
      padding: 0 42px 0 16px;
      background: transparent;

      &::placeholder {
        font-family: Manrope, Inter, sans-serif;
        color: #aaaaaa;
        font-weight: 500;
      }

      &:not([value=""]) {
        opacity: 0;
      }
    }
  }

  && .MuiSelect-icon {
    transition: transform 0.15s ease-in-out;
    right: 12px;
  }
`;

export const ButtonIcon = styled(ChevronDownIcon)`
  pointer-events: none;
  position: absolute;
  right: 12px;
`;

// _______ CreateReactSelect ________

export const OptionWrapper = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 16px 4px 12px;
`;

export const OptionLabel = styled.span`
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
  font-family: Manrope, Inter, sans-serif;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: ${p => p.theme.colors.darkText};
`;
export const OptionPrice = styled.div<{ value: number }>(({ theme, value }) => ({
  color: value == 0.0 ? "#989898" : value < 0 ? theme.colors.negative : theme.colors.foundationPositive,
  whiteSpace: "nowrap",
}));

export const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 11px;
  width: 100%;
  flex: 1;
`;

export const SelectLabel = styled.label<{ $required?: Boolean }>`
  padding: 0 16px;
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
  font-family: Manrope, Inter, sans-serif;
  transform: none;
  color: ${p => p.theme.colors.darkText};
  width: fit-content;
  position: relative;

  ${p =>
    p.$required &&
    `
   &::after {
    position: absolute;
    top: 3px;
    right: 6px;

    content: "";
    display: block;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: red;
   }
  `}
`;
export const StyledChevronDownIcon = styled(ChevronDownIcon)`
  width: 57px;
`;

export const StyledCreatableSelect = styled(CreatableSelect)<{ $error?: boolean }>`
  &.${createReactSelectWrapper} {
    height: 59px;

    .${createReactSelectPrefix}__control {
      border: 1px solid ${p => p.theme.colors.lightBlue};
      background-color: rgba(${hexToRgb(theme.colors.lightBlue)}, 0.2);
      box-shadow: none;
      border-radius: 6px;

      height: 100%;
      cursor: pointer;

      ${p =>
        p.$error &&
        `
          background-color: rgba(${hexToRgb(theme.colors.red)}, 0.2) !important;
          border: 1px solid ${theme.colors.red} !important;
      `}

      .${createReactSelectPrefix}__value-container {
        padding: 2px 8px 2px 15px;

        .${createReactSelectPrefix}__placeholder {
          color: ${p => p.theme.colors.placeholder};
          font-size: 16px !important;
          line-height: 22px !important;
          font-weight: 700 !important;
          font-family: Manrope, Inter, sans-serif !important;
        }

        .${createReactSelectPrefix}__input-container {
          .${createReactSelectPrefix}__input {
            font-size: 16px !important;
            line-height: 22px !important;
            font-weight: 700 !important;
            font-family: Manrope, Inter, sans-serif !important;
            color: ${p => p.theme.colors.darkText} !important;
          }
        }
      }

      .${createReactSelectPrefix}__indicators {
        .${createReactSelectPrefix}__indicator-separator {
          display: none;
        }

        .${createReactSelectPrefix}__indicator {
        }
      }
    }

    .${createReactSelectPrefix}__menu {
      border: 1px solid ${p => p.theme.colors.lightBlue};
      box-shadow: none;

      .${createReactSelectPrefix}__menu-list {
        .${createReactSelectPrefix}__group {
          .${createReactSelectPrefix}__group-heading {
            font-size: 16px;
            line-height: 22px;
            font-weight: 600;
            font-family: Manrope, Inter, sans-serif;
            color: ${p => p.theme.colors.placeholder};
            text-transform: uppercase;

            padding: 0 24px;
            margin-bottom: 4px;
          }

          .${createReactSelectPrefix}__option {
            padding: 8px 4px;
            font-size: 14px;
            line-height: 18px;
            font-weight: 500;
            font-family: Manrope, Inter, sans-serif;
            color: ${p => p.theme.colors.darkText};

            cursor: pointer;

            &:hover {
              background-color: rgba(${hexToRgb(theme.colors.lightBlue)}, 0.2);
            }

            &.${createReactSelectPrefix}__option--is-focused, &.${createReactSelectPrefix}__option--is-selected {
              background-color: rgba(${hexToRgb(theme.colors.lightBlue)}, 0.2);
            }
          }
        }
      }
    }
  }
`;

export const StyledReactSelect = styled(ReactSelect)<{ $error?: boolean }>`
  &.${reactSelectWrapperClassName} {
    height: 59px;

    .${reactSelectPrefix}__control {
      border: 1px solid ${p => p.theme.colors.lightBlue};
      background-color: rgba(${hexToRgb(theme.colors.lightBlue)}, 0.2);
      box-shadow: none;
      border-radius: 6px;

      height: 100%;
      cursor: pointer;

      ${p =>
        p.$error &&
        `
          border: 1px solid ${theme.colors.red} !important;
      `}

      .${reactSelectPrefix}__value-container {
        padding: 2px 8px 2px 15px;

        .${reactSelectPrefix}__placeholder {
          color: ${p => p.theme.colors.placeholder};
          font-size: 16px !important;
          line-height: 22px !important;
          font-weight: 500 !important;
          font-family: Manrope, Inter, sans-serif !important;
        }

        .${reactSelectPrefix}__input-container {
          .${reactSelectPrefix}__input {
            font-size: 16px !important;
            line-height: 22px !important;
            font-weight: 700 !important;
            font-family: Manrope, Inter, sans-serif !important;
            color: ${p => p.theme.colors.darkText} !important;

            &::placeholder {
              font-family: Manrope, Inter, sans-serif !important;
              font-weight: 700 !important;
            }
          }
        }
      }

      .${reactSelectPrefix}__indicators {
        .${reactSelectPrefix}__indicator-separator {
          display: none;
        }

        .${reactSelectPrefix}__indicator {
        }
      }
    }

    .${reactSelectPrefix}__menu {
      border: 1px solid ${p => p.theme.colors.lightBlue};
      box-shadow: none;

      .${reactSelectPrefix}__menu-list {
        .${reactSelectPrefix}__option {
          padding: 12px 16px;
          font-size: 14px;
          line-height: 18px;
          font-weight: 700;
          font-family: Manrope, Inter, sans-serif;
          color: ${p => p.theme.colors.darkText};
          cursor: pointer;

          &:hover {
            background-color: rgba(${hexToRgb(theme.colors.lightBlue)}, 0.2);
          }

          &.${reactSelectPrefix}__option--is-focused, &.${reactSelectPrefix}__option--is-selected {
            background-color: rgba(${hexToRgb(theme.colors.lightBlue)}, 0.2);
          }
        }
      }
    }
  }
`;
