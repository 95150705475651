import React from 'react'
import styled from 'styled-components'
import { Tooltip as MuiTooltip } from '@material-ui/core'

export const Tooltip = styled(props => <MuiTooltip classes={{ popper: props.className }} {...props} />)`
  & .MuiTooltip-tooltip {
    font-style: normal;
    font-weight: normal;
    font-size: ${p => p.theme.text.fontSmall};
    line-height: 24px;
  }
`;

export const OneRowTooltip = styled(Tooltip)`
  & .MuiTooltip-tooltip {
    min-width: min-content;
  }
`;
