import { useFlag } from "@common/hooks";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import { ChangeEvent, KeyboardEvent, useRef, useState } from "react";
import styled from "styled-components";
import { InputText } from "../Input/InputText";
import { Popover } from "../Popover/Popover";
import { CalculatorButtonGroup, evaluateStringExpression, NumberExpressionRegExp } from "./utils";

type Props = {
  value: string | undefined
  onSave: (result: string) => void
  onOpenPanel?: () => void
  onClosePanel?: () => void
  isSubModule?: boolean
}

export function CalculatorTableInput(props: Props) {
  const { onSave, value: initialValue, onOpenPanel, onClosePanel, isSubModule } = props;
  const [isEditMode, setEditMode, turnOffMode] = useFlag(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const [value, changeValue] = useState<string | undefined>(initialValue);

  function save(closeByDefault?: boolean) {
    if (!isEditMode) {
      return;
    }

    if ((value !== undefined && NumberExpressionRegExp.test(value)) || value === undefined) {
      turnOffMode();
      onClosePanel?.();
      onSave(evaluateStringExpression(value ?? ""));
      return;
    }

    if (closeByDefault) {
      turnOffMode();
      onClosePanel?.();
    }
  }

  function handleKeyPress(e: KeyboardEvent<HTMLInputElement>) {
    const key = e.key;

    if (key === "Enter") {
      save();
    }
  }

  const popoverRef = (ref: Popover | null) => {
    ref?.reference(containerRef.current);
  };

  function openInput() {
    setEditMode();
    onOpenPanel?.();
  }

  return (
    <ClickAwayListener onClickAway={() => save(true)}>
      <div ref={containerRef} style={{ maxWidth: "100%" }}>
        {isEditMode && (
          <>
            <InputText
              value={value}
              onChange={(e: ChangeEvent<HTMLInputElement>) => changeValue(e.target.value)}
              onKeyPress={handleKeyPress}
              type="text"
              autoComplete="off"
              withMainBorder
              autoFocus
            />
            <Popover popoverRef={popoverRef} placement="bottom-start" mainAxis={4} isShow>
              <CalculatorButtonGroup
                toAddSymbol={symbol => {
                  changeValue(`${value}${symbol}`);
                }}
                currentState={value}
              />
            </Popover>
          </>
        )}
        {!isEditMode && (
          <StyledText onClick={openInput} $isSubModule={isSubModule}>
            {initialValue}
          </StyledText>
        )}
      </div>
    </ClickAwayListener>
  );
}

const StyledText = styled.span<{ $isSubModule?: boolean }>`
  font-size: 14px;
  line-height: 19px;
  color: ${p => p.theme.colors.darkText};
  font-family: Manrope, Inter, sans-serif;
  font-weight: 600;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 100%;

  ${p =>
          p.$isSubModule &&
          `
    font-weight: 400;
    color: #61698A;
  `}
`;
