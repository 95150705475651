import styled from "styled-components"
import { List } from "@material-ui/core"

export const MenuListItem = styled.li`
`;
export const CommonListItem = styled.li`
   margin-bottom: 32px; 
`;
export const DashedLine = styled.div`
  width: 100%;
  height: 1px;
  background: ${p => p.theme.colors.lightnessGrey};
`

export const ExternalLink = styled.a<{ $active?: boolean }>`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${p => p.theme.colors.purple};
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;

  span {
    font-weight: 400;
    font-size: 14px;
    font-family: Manrope, Inter, sans-serif !important;
    color: ${p => p.theme.colors.purple};
    transition: all 0.15s ease-in-out;
  }

  ${p => p.$active && `
     span {
      color: #13BDCE !important;
      font-weight: 500;
      transition: all 0.15s ease-in-out;
    }
    svg > path {
      fill: #13BDCE !important;
      transition: all 0.15s ease-in-out;
    }
  `}
  &:hover {
    span {
      color: ${p => p.theme.colors.main};
      font-weight: 500;
      transition: all 0.15s ease-in-out;
    }

    svg > path {
      fill: ${p => p.theme.colors.main};
      transition: all 0.15s ease-in-out;
    }
  }
`
export const StyledMenuList = styled(List)`
  margin-top: auto;
  width: 100%;


  li:last-child {
    margin-bottom: 24px;
  }
`;

// modals

export const Blure = styled.div`
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9;
`;

export const CreateBudgetModal = styled.div`
  position: fixed;
  margin-left: -430px;
  top: 0;
  color: #333333;
  z-index: 10;

  svg {
    opacity: 1;
  }

  h1 {
    font-weight: 700;
    font-size: 18px;
    line-height: 32px;
    margin-bottom: 40px;
  }

  & p {
    font-size: 16px;
    line-height: 1.3;
  }

  .MuiButton-label {
    display: inline;
  }

  .MuiButtonBase-root {
    margin-top: 0;
  }

  .MuiButton-root {
    margin-top: 0 !important;
  }

  form {
    max-height: 320px;
  }

  > div {
    max-height: 370px;
  }
`;
