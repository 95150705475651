export type OptionType = {
  label: string;
  value: string;
};

export type ContractorOptionsType = {
  label: 'Контрагенты' | 'Переводы';
  options: OptionType[];
};

export const getContractorOptions = ({
  contractors,
  currentAccountId,
  currentSubcategoryId = null,
}): ContractorOptionsType[] => {
  const cleanContractors: ContractorOptionsType = {
    label: 'Контрагенты',
    options: [],
  };
  const moneyTransfers: ContractorOptionsType = { label: 'Переводы', options: [] };

  for (const { contractorType, name, id, autoSubcategory, isVisible, cacheAccountId } of contractors) {
    if (contractorType === 'Переводы' && cacheAccountId !== currentAccountId) {
      moneyTransfers.options.push({ label: name, value: id });
    } else if (
      contractorType === 'Контрагенты' &&
      (autoSubcategory ? currentSubcategoryId !== autoSubcategory : true) &&
      isVisible
    ) {
      cleanContractors.options.push({ label: name, value: id });
    }
  }

  return [cleanContractors, moneyTransfers];
};

export const checkClickInForm = e => {
  const target = e.target;
  const node = target.closest('#transactions-form');
  if (node) {
    return true;
  }
  return false;
};
