import {
  ContractorEditType,
  setContractor,
  setContractorsError,
  setContractorsList,
  setContractorsLoading,
  setNewContractor,
  setNotification,
} from "@common/store"
import { ContractorTransport } from "@common/transports"
import { createAsyncThunk } from "@reduxjs/toolkit"

export const getContractorsThunk = createAsyncThunk<void, { budget: string }>(
  "GET_CONTRACTORS",
  async ({ budget }, { dispatch }) => {
    dispatch(setContractorsLoading(true))
    try {
      const contractors = await ContractorTransport.getContractors(budget)
      dispatch(setContractorsList(contractors))
    } catch (error) {
      dispatch(setNotification({ type: "error", message: (error as any).message }))
      dispatch(setContractorsError((error as any).message))
    } finally {
      dispatch(setContractorsLoading(false))
    }
  },
)

export const createContractorThunk = createAsyncThunk<void, ContractorEditType>(
  "CREATE_CONTRACTOR",
  async (dto, { dispatch }) => {
    dispatch(setContractorsLoading(true))
    try {
      const contractor = await ContractorTransport.createContractor(dto)
      await dispatch(getContractorsThunk({ budget: dto.budget }))
      dispatch(setNewContractor(contractor))
    } catch (error) {
      dispatch(setNotification({ type: "error", message: (error as any).response.data.error }))
      dispatch(setContractorsError(error as string))
    } finally {
      dispatch(setContractorsLoading(false))
    }
  },
)

export const editContractorThunk = createAsyncThunk<void, ContractorEditType & { id: string }>(
  "EDIT_CONTRACTOR",
  async (dto, { dispatch }) => {
    try {
      const contractor = await ContractorTransport.updateContractor(dto)
      dispatch(setContractor(contractor))
    } catch (error) {
      dispatch(setNotification({ type: "error", message: (error as any).response.data.error as string }))
      dispatch(setContractorsError(error as string))
    } finally {
      dispatch(setContractorsLoading(false))
    }
  },
)
export const removeContractorThunk = createAsyncThunk<void, { budget: string; id: string }>(
  "REMOVE_CONTRACTOR",
  async ({ id, budget }, { dispatch }) => {
    dispatch(setContractorsLoading(true))
    try {
      await ContractorTransport.deleteContractor(id)
      await dispatch(getContractorsThunk({ budget }))
    } catch (error) {
      dispatch(setNotification({ type: "error", message: (error as any).response.data.error as string }))
      dispatch(setContractorsError(error as string))
    } finally {
      dispatch(setContractorsLoading(false))
    }
  },
)
