import styled from 'styled-components'
import { Paper } from '@material-ui/core'

export const Cell = styled.div`
  padding: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const CellText = styled.a``;

export const TableHeader = styled(Paper)`
  margin-top: 20px !important;

  &.MuiPaper-root {
    display: grid;
    grid-template-columns: 160px 1fr 240px 240px;
    padding: 0 12px;
    color: ${p => p.theme.colors.contentSecondary};
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    box-shadow: none;
    border-top: 1px solid ${p => p.theme.colors.backgroundSecondary};
  }
`;

export const Section = styled.section`
  width: 100%;
`;

export const SubCategory = styled(Paper)`
  &.MuiPaper-root {
    display: grid;
    grid-template-columns: 160px 1fr 240px 240px;
    padding: 0 12px;
    color: ${p => p.theme.colors.contentMain};
    box-shadow: none;
    font-size: 16px;
    font-weight: normal;
    line-height: 24px;
    text-align: left;
    background-color: ${p => p.theme.colors.white};
    border-top: 1px solid ${p => p.theme.colors.backgroundSecondary};
  }
`;
