import {
  closestCorners,
  DroppableContainer,
  getFirstCollision,
  KeyboardCode,
  KeyboardCoordinateGetter,
} from "@dnd-kit/core"
import { getProjection } from "./projection"
import { SensorContext } from "./types"

const directions: Array<string> = [KeyboardCode.Down, KeyboardCode.Right, KeyboardCode.Up, KeyboardCode.Left]
const directionsHorizontal: Array<string> = [KeyboardCode.Left, KeyboardCode.Right]

export const sortableTreeKeyboardCoordinates: <T>(
  context: SensorContext<T>,
  indicator: boolean,
  indentationWidth: number,
  maxDeep?: number,
) => KeyboardCoordinateGetter = (context, indicator, indentationWidth, maxDeep) => (
  event,
  { currentCoordinates, context: { active, over, collisionRect, droppableRects, droppableContainers } },
) => {
  if (directions.includes(event.code)) {
    if (!active || !collisionRect) {
      return
    }

    event.preventDefault()

    const {
      current: { items, offset },
    } = context

    if (directionsHorizontal.includes(event.code) && over?.id) {
      const { depth, maxDepth, minDepth } = getProjection(
        items,
        [active.id],
        over.id,
        offset,
        indentationWidth,
        maxDeep,
      )

      switch (event.code) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
        case KeyboardCode.Left:
          if (depth > minDepth) {
            return {
              ...currentCoordinates,
              x: currentCoordinates.x - indentationWidth,
            }
          }
          break
        // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
        case KeyboardCode.Right:
          if (depth < maxDepth) {
            return {
              ...currentCoordinates,
              x: currentCoordinates.x + indentationWidth,
            }
          }
          break
      }

      return undefined
    }

    const containers: Array<DroppableContainer> = []

    droppableContainers.forEach(container => {
      if (container?.disabled || container.id === over?.id) {
        return
      }

      const rect = droppableRects.get(container.id)

      if (!rect) {
        return
      }

      switch (event.code) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
        case KeyboardCode.Down:
          if (collisionRect.top < rect.top) {
            containers.push(container)
          }
          break
        // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
        case KeyboardCode.Up:
          if (collisionRect.top > rect.top) {
            containers.push(container)
          }
          break
      }
    })

    const collisions = closestCorners({
      active,
      collisionRect,
      pointerCoordinates: null,
      droppableRects,
      droppableContainers: containers,
    })
    let closestId = getFirstCollision(collisions, "id")

    if (closestId === over?.id && collisions.length > 1) {
      closestId = collisions[1].id
    }

    if (closestId && over?.id) {
      const activeRect = droppableRects.get(active.id)
      const newRect = droppableRects.get(closestId)
      const newDroppable = droppableContainers.get(closestId)

      if (activeRect && newRect && newDroppable) {
        const newIndex = items.findIndex(({ id }) => id === closestId)
        const newItem = items[newIndex]
        const activeIndex = items.findIndex(({ id }) => id === active.id)
        const activeItem = items[activeIndex]

        if (newItem && activeItem) {
          const { depth } = getProjection(
            items,
            [active.id],
            closestId,
            (newItem.depth - activeItem.depth) * indentationWidth,
            indentationWidth,
            maxDeep,
          )
          const isBelow = newIndex > activeIndex
          const modifier = isBelow ? 1 : -1
          const offset = indicator ? (collisionRect.height - activeRect.height) / 2 : 0

          const newCoordinates: { x: number; y: number } = {
            x: newRect.left + depth * indentationWidth,
            y: newRect.top + modifier * offset,
          }

          return newCoordinates
        }
      }
    }
  }

  return undefined
}
