import styled, { css } from 'styled-components'
import { IconButton, FormControlLabel, Button as MuiButton } from '@material-ui/core'

const SButton = css`
  border-radius: 24px;
  padding: 8px 12px;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  justify-content: space-between;
`;

export const More = styled(IconButton)`
  &.MuiButtonBase-root {
    background-color: ${p => p.theme.colors.white};
    border-radius: 50%;
    margin-left: 24px;
    padding: 8px;
  }
`;

export const Back = styled(IconButton)`
  &.MuiButtonBase-root {
    margin-right: 16px;
    padding: 8px;
  }
`;

export const Title = styled.div`
  display: flex;
  align-items: center;

  h1 {
    margin-bottom: 0;
  }
`;

export const Button = styled(IconButton)<{ $oval?: boolean }>`
  &.MuiButtonBase-root {
    background-color: ${p => p.theme.colors.white};
    border-radius: 50%;
    margin: 8px;
    padding: 8px;
    ${p => !!p.$oval && SButton}
  }
  .MuiIconButton-label {
    font-style: normal;
    font-weight: normal;
    font-size: ${p => p.theme.text.fontSmall};
    line-height: 24px;
    color: #13bdce;
    text-transform: none;
    font-family: 'Montserrat', sans-serif;
  }
`;

export const Controls = styled.div<{ $bigIndent?: boolean }>`
  display: flex;
  align-items: center;
  margin: -8px;
  padding-left: ${p => (p.$bigIndent ? '16px' : '24px')};
`;

export const Text = styled.p`
  margin-bottom: 40px;
`;

export const Submit = styled(MuiButton)`
  &.MuiButton-root {
    padding: 8px 0;
    background-color: ${p => p.theme.colors.main};
    color: ${p => p.theme.colors.white};
    font-weight: bold;
    text-transform: none;

    &:hover,
    &:focus,
    &:active {
      background-color: ${p => p.theme.colors.dark};
    }

    &:disabled {
      background-color: ${p => p.theme.colors.backgroundMain};
      color: ${p => p.theme.colors.contentSecondary};
    }
  }
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 360px;
`;

export const Label = styled(FormControlLabel)`
  margin-bottom: 40px;
`;
