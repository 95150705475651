import { NotificationType } from "@common/store"
import styled from "styled-components"

interface PopupProps {
  readonly type: NotificationType
  isOpen: boolean
}

const colorSelector = ({ type, theme }) =>
  ({
    success: theme.colors.backgroundSuccess,
    error: theme.colors.backgroundError,
    info: theme.colors.lightBlue,
  }[type])

export const Popup = styled.div<PopupProps>`
  text-align: center;
  background-color: ${colorSelector};
  width: 100%;
  height: 76px;
  padding: 28px 0;
  box-sizing: border-box;
  position: fixed;
  z-index: 2000;
  left: 0;
  top: ${({ isOpen }) => (isOpen ? 0 : "-76px")};
  transition: top 1s ease;
`
