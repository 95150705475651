import { Loading } from "@common/components"
import { lazyLoad } from "@common/utils"

export const UnitUsersAsync = lazyLoad(
  () => import("./index"),
  module => module.UsersPage,
  {
    fallback: <Loading />,
  },
)
