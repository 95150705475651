import { CreateBillModal, CreateBillModalValues } from "@account/view/AccountsMain/Modals";
import BillsIcon from "@assets/icons/bills.svg";
import NewBillIcon from "@assets/icons/newbudget.svg";
import { useAsyncDispatch, useFlag, useTypedSelector } from "@common/hooks";
import { addAccountToListThunk, LayoutService } from "@common/services";
import { NAV_BAR_MENU, setActiveMenu } from "@common/store";
import { ReactElement, useCallback, useEffect, useMemo } from "react";
import { DropResult } from "react-beautiful-dnd";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { DropDownItem } from "../dropdown/DropDownItem";
import { Dropdown, DropdownNavItem } from "../dropdown/Dropdown";
import { MenuListItem } from "./styles";
import { clearSelectedTransactionIds } from "@account-detail/store";

function AddBillComponent({ turnOn }: { turnOn: () => void }): ReactElement {
  const item: DropdownNavItem = {
    title: "Добавить",
    onClick: turnOn,
    Icon: <NewBillIcon />,
    id: undefined,
  };

  return <DropDownItem item={item} />;
}

export function BillsComponent(): ReactElement {
  const dispatch = useDispatch();
  const asyncDispatch = useAsyncDispatch();

  const accounts = useTypedSelector(state => state.layout.accountsList);
  const currentBudget = useTypedSelector(state => state.budgets.currentBudget);
  const activeMenu = useTypedSelector(state => state.system.activeMenu);

  const [open, turnOn, close] = useFlag(false);

  const isActiveBill = activeMenu === NAV_BAR_MENU.BILLS;

  const accountList: DropdownNavItem[] = useMemo(
    () =>
      currentBudget?.id !== undefined
        ? accounts
            .filter(account => account.budget === currentBudget.id)
            .map<DropdownNavItem>(account => ({
              id: account?.id,
              link: `/accounts/${account?.id}/`,
              title: account?.name,
              node: account?.currentBalance?.toString(),
            }))
        : [],
    [currentBudget?.id, accounts],
  );

  const onDragEnd = useCallback(
    (result: DropResult) => {
      asyncDispatch(LayoutService.sortOrderCacheAccounts({ result }));
    },
    [asyncDispatch, LayoutService],
  );

  const createAccount = useCallback(
    (values: CreateBillModalValues) => {
      if (currentBudget?.id === undefined) return;

      asyncDispatch(
        addAccountToListThunk({
          name: values.name,
          balance: values.available,
          budget: currentBudget.id,
          isApproved: true,
          accountType: values?.type,
        }),
      )
        .then(close)
    },
    [asyncDispatch, LayoutService, close, currentBudget],
  );

  const openBill = () => {
    dispatch(setActiveMenu(isActiveBill ? NAV_BAR_MENU.NOT_SELECTED : NAV_BAR_MENU.BILLS));
    dispatch(clearSelectedTransactionIds());
  };

  useEffect(() => {
    asyncDispatch(LayoutService.loadAccounts());
  }, [currentBudget?.id]);

  return (
    <MenuListItem>
      <Dropdown
        active={isActiveBill}
        onDragEnd={onDragEnd}
        mainItem={{
          title: "Счета",
          Icon: (
            <StyledIcon>
              <BillsIcon />
            </StyledIcon>
          ),
          id: undefined,
          onClick: openBill,
        }}
        items={accountList}
        OtherItem={<AddBillComponent turnOn={turnOn} />}
      />
      <CreateBillModal open={open} close={close} onSubmit={createAccount} onCancel={close} />
    </MenuListItem>
  );
}

const StyledIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 24px;
    height: 24px;
  }
`;
