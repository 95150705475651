import ChevronBottom from "@assets/icons/chevron-bottom.svg";
import styled from "styled-components";

type Props = {
  onClick?: () => void
  collapsed?: boolean
}
export function BottomArrow(props: Props) {
  const { collapsed, onClick } = props

  return (
    <StyledArrowBottom onClick={onClick} $collapsed={collapsed}>
      <ChevronBottom />
    </StyledArrowBottom>
  )
}

const StyledArrowBottom = styled.div<{ $collapsed?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  cursor: pointer;
  transform: rotate(-180deg);
  transition: all 0.16s linear;

  ${p =>
    p.$collapsed &&
    `
    transition: all 0.16s linear;
    transform: rotate(0deg);
  `}
`
