import { authSelector, userData } from "@common/store";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { ReactElement } from "react";

export function RoleGuard({ children }: { children: ReactElement }): ReactElement {
  const isAuth = useSelector(authSelector)
  const user = useSelector(userData)

  if (!isAuth) {
    return <Navigate to="/auth/login" replace />
  }

  if (isAuth && user && user?.roleName !== "client") {
    return <Navigate to="/users/" replace />
  }
  return children
}
