import { loadUser, setNoShowUser } from "@common/services/user.service"
import { PayloadAction, createEntityAdapter, createSlice } from "@reduxjs/toolkit"
import { UserData, UserState } from "./user.types"

const userAdapter = createEntityAdapter()

const INIT_STATE: UserState = {
  loading: false,
  error: null,
  data: null,
}

const userSlice = createSlice({
  name: "user",
  initialState: userAdapter.getInitialState(INIT_STATE),
  reducers: {
    setUserData: (state, action: PayloadAction<UserData>) => {
      state.data = action.payload
      state.loading = false
    },
    setIsAutogeneratedPassword: state => {
      if (state.data === null) return
      state.data.isAutogeneratedPassword = false
      state.loading = false
    },
    setUserLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    setUserError: (state, action: PayloadAction<string | null>) => {
      state.loading = false
      // state.error = action.payload
    },
    setAutoRenewalAction: (state, action: PayloadAction<boolean>) => {
      if (state.data === null) return
      state.data.autoRenewal = action.payload
    },
  },
  extraReducers: builder => {
    builder
      .addCase(loadUser.pending, state => {
        state.loading = true
        state.error = null
      })
      .addCase(loadUser.fulfilled, (state, action) => {
        userAdapter.setOne(state, action.payload)
        state.loading = false
        state.error = null
      })
      .addCase(loadUser.rejected, (state, action) => {
        state.loading = false
        state.error = action.error
      })
      .addCase(setNoShowUser.pending, state => {
        state.loading = true
        state.error = null
      })
      .addCase(setNoShowUser.fulfilled, (state, action) => {
        userAdapter.setOne(state, action)
        state.loading = false
        state.error = null
      })
      .addCase(setNoShowUser.rejected, (state, action) => {
        state.loading = false
        state.error = action.error
      })
    // .addCase(setTrialPeriod.pending, state => {
    //   state.loading = true
    //   state.error = null
    // })
    // .addCase(setTrialPeriod.fulfilled, (state, action) => {
    //   // state = userAdapter.getInitialState(INIT_STATE)
    // })
    // .addCase(setTrialPeriod.rejected, (state, action) => {
    //   state.loading = false
    //   state.error = action.error
    // })
  },
})

export const {
  setIsAutogeneratedPassword,
  setUserError,
  setUserLoading,
  setUserData,
  setAutoRenewalAction,
} = userSlice.actions
export const userReducer = userSlice.reducer
