import { memo } from "react"
import type { ReactNode } from "react"
import { createPortal } from "react-dom"

export type Prettify<T> = { [K in keyof T]: T[K] } & {}

export function registerPortalContainer(container: HTMLElement | null): void {
  globalContainer = container
}

let globalContainer: HTMLElement | null = null

export type PortalProps = Prettify<{
  children: ReactNode
  container?: HTMLElement | null
  key?: null | string
}>

export const Portal = memo(function Portal(props: PortalProps) {
  const { container: propsContainer, key, children } = props
  const container = propsContainer || globalContainer
  if (container == null) {
    throw new Error(errorMessageNoContainer)
  }
  return createPortal(children, container, key)
})


const errorMessageNoContainer = `
<Portal/>: no container defined. To use <Portal/>, you should call either 'registerPortalContainer(container)' or pass 'container' prop at '<Portal container={container}/>'.
`.trim()
