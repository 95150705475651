import styled from 'styled-components'

export const Wrapper = styled.div<{ active: boolean }>`
  height: 360px;
  background-color: ${p => (p.active ? p.theme.colors.main : p.theme.colors.white)};
  padding: 0 0 18px 24px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-direction: column;
  align-self: flex-end;
  overflow: hidden;
  border-radius: 8px;
  cursor: ${p => (p.active ? 'auto' : 'pointer')};
`;

export const CostPeriod = styled.div`
  min-width: 177px;
  min-height: 177px;
  display: inline-flex;
  justify-content: flex-end;
  padding: 37px 30px 0 0;

  position: relative;
  &:before {
    content: '';
    width: 284px;
    height: 284px;
    border-radius: 50%;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: ${p => p.theme.colors.light};
  }
`;

export const Cost = styled.div<{ active: boolean }>`
  width: 100%;
  font-style: normal;
  font-weight: bold;
  font-size: ${p => p.theme.text.fontLarge};
  line-height: 44px;
  color: ${p => (p.active ? p.theme.colors.white : p.theme.colors.main)};
  margin-bottom: 4px;
`;

export const CostPeriodText = styled.div<{ active: boolean }>`
  font-style: normal;
  font-weight: normal;
  font-size: ${p => p.theme.text.fontSmall};
  line-height: 24px;
  color: ${p => (p.active ? p.theme.colors.white : p.theme.colors.contentMain)};
  margin-bottom: 24px;
`;

export const Name = styled.div<{ active: boolean }>`
  font-style: normal;
  font-weight: normal;
  font-size: ${p => p.theme.text.fontSmall};
  line-height: 24px;
  color: ${p => (p.active ? p.theme.colors.white : p.theme.colors.contentMain)};
`;

export const Top = styled.div`
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  width: 100%;
`;

export const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-right: 19px;
`;

export const Button = styled.button<{ active: boolean }>`
  margin-left: 20px;
  background: transparent;
  border: none;
  cursor: ${p => (p.active ? 'auto' : 'pointer')};
`;

export const Saving = styled.div`
  font-family: ${p => p.theme.fontFamily.main};
  font-style: normal;
  font-weight: 600;
  font-size: ${p => p.theme.text.fontSmall};
  line-height: 24px;
  color: ${p => p.theme.colors.gold};
  margin-bottom: 4px;
  min-height: 24px;
`;
