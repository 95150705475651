import { createSlice, PayloadAction, Slice, SliceCaseReducers } from "@reduxjs/toolkit";

export interface IExpense {
  id?: string;
  subcategory: string;
  amount: string;
  month: number;
  year: number;
  createdAt?: string;
  updatedUp?: string;
}

export interface IDateExpense {
  year: number;
  month: number;
}

interface AllBooleanOrNull {
  [key: string]: boolean | null;
}

export interface IExpensesState {
  loading: AllBooleanOrNull;
  error: AllBooleanOrNull;
  list: IExpense[];
  datesExpenses: IDateExpense[];
}

const initialState: IExpensesState = {
  loading: {
    list: null,
    datesExpenses: null,
  },
  error: {
    list: null,
    datesExpenses: null,
  },
  list: [],
  datesExpenses: [],
};

interface ExpensesActions extends SliceCaseReducers<IExpensesState> {
  getExpensesSuccess: (state: IExpensesState, action: PayloadAction<IExpense[]>) => void
  getExpensesLoading: (state: IExpensesState, action: PayloadAction<boolean | null>) => void
  getExpensesError: (state: IExpensesState, action: PayloadAction<boolean | null>) => void
  getDatesHasExpensesSuccess: (state: IExpensesState, action: PayloadAction<boolean | null>) => void
  getDatesHasExpensesLoading: (state: IExpensesState, action: PayloadAction<boolean | null>) => void
  getDatesHasExpensesError: (state: IExpensesState, action: PayloadAction<boolean | null>) => void
  createExpenseSuccess: (state: IExpensesState) => void
  createExpenseLoading: (state: IExpensesState, action: PayloadAction<boolean | null>) => void
  changeExpenseSuccess: (state: IExpensesState) => void
  changeExpenseLoading: (state: IExpensesState, action: PayloadAction<boolean | null>) => void
}

const expensesSlice: Slice<IExpensesState, ExpensesActions, "expenses"> = createSlice({
  name: "expenses",
  initialState: initialState,
  reducers: {
    getExpensesSuccess: (state, action: PayloadAction<IExpense[]>): void => {
      state.list = action.payload
    },
    getExpensesLoading: (state, action: PayloadAction<boolean | null>): void => {
      state.loading.list = action.payload
    },
    getExpensesError: (state, action: PayloadAction<boolean | null>): void => {
      state.error.list = action.payload
    },
    getDatesHasExpensesSuccess: (state, action: PayloadAction<boolean | null>): void => {
      state.error.datesExpenses = action.payload
    },
    getDatesHasExpensesLoading: (state, action: PayloadAction<boolean | null>): void => {
      state.loading.datesExpenses = action.payload
    },
    getDatesHasExpensesError: (state, action: PayloadAction<boolean | null>): void => {
      state.error.datesExpenses = action.payload
    },
    createExpenseSuccess: (state: IExpensesState): void => {
      // TODO временно, пока не сделаем нормальную систему обновления
    },
    createExpenseLoading: (state: IExpensesState, action: PayloadAction<boolean | null>) => {
      state.loading.createExpense = action.payload
    },
    changeExpenseSuccess: (state: IExpensesState): void => {
      // TODO временно, пока не сделаем нормальную систему обновления
    },
    changeExpenseLoading: (state: IExpensesState, action: PayloadAction<boolean | null>) => {
      state.loading.changeExpense = action.payload
    },
  },
  extraReducers: {},
})

export const {
  getExpensesSuccess,
  getExpensesLoading,
  getExpensesError,
  getDatesHasExpensesSuccess,
  changeExpenseSuccess,
  changeExpenseLoading,
  createExpenseSuccess,
  createExpenseLoading,
  getDatesHasExpensesError,
  getDatesHasExpensesLoading,
} = expensesSlice.actions

export const expensesReducer = expensesSlice.reducer
