import { TextField } from "@account/view/AccountsMain/styles"
import { ButtonMain } from "@common/components/ui/ButtonMain"
import { useAsyncDispatch } from "@common/hooks"
import { updateNotification } from "@common/store"
import { memo, useState } from "react"
import { Cell, SubCategory } from "./styles"

export interface IRow {
  id: number
  name: string
  title: string
  body: string
}

export const Row = memo((props: IRow) => {
  const asyncDispatch = useAsyncDispatch()

  const [title, setTitle] = useState<string>(props.title)
  const [body, setBody] = useState<string>(props.body)
  const [changed, setChanged] = useState<boolean>(false)

  const handleChangeTitle = e => {
    setTitle(e.target.value)
    setChanged(true)
  }

  const handleChangeBody = e => {
    setBody(e.target.value)
    setChanged(true)
  }

  const handleSave = () => {
    setChanged(false)
    asyncDispatch(updateNotification({ id: props.id, title, body }))
  }

  return (
    <SubCategory>
      <Cell>
        {props.id} -{props.name}
      </Cell>
      <Cell>
        <TextField label="Название" name="title" onChange={handleChangeTitle} value={title} />
      </Cell>
      <Cell>
        <TextField
          variant="outlined"
          name="body"
          hiddenLabel
          onChange={handleChangeBody}
          value={body}
          maxRows={3}
          multiline
        />
      </Cell>
      <Cell>
        <ButtonMain onClick={handleSave} disabled={!changed}>
          Сохранить
        </ButtonMain>
      </Cell>
    </SubCategory>
  )
})
