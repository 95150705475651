import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 360px;
`;

export const Title = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: ${p => p.theme.text.fontMedium};
  line-height: 32px;
  color: ${p => p.theme.colors.contentMain};
  margin-bottom: 40px;
`;

export const Text = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: ${p => p.theme.text.fontSmall};
  line-height: 24px;
  margin-bottom: 152px;
  color: ${p => p.theme.colors.contentMain};
  p {
    margin-bottom: 24px;
  }
`;
