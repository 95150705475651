import * as React from 'react'
import styled from 'styled-components'
import { Form as FinalForm } from 'react-final-form'
import { EditForm } from './EditForm'
import { Button as MuiButton } from '@material-ui/core'

export const ModalAccounts = ({ mode, onEdit, onDelete }) => {
  const initialValues = { name: mode.initialValue };
  return (
    <>
      {
        {
          edit: (
              <Stiker>
                <Box>
                  <FinalForm onSubmit={onEdit} render={EditForm} initialValues={initialValues} />
                  <Button onClick={onDelete}>Удалить бюджет навсегда</Button>
                </Box>
              </Stiker>
          ),
        }[mode.mode]
      }
    </>
  );
};

const Button = styled(MuiButton)`
  && {
    color: ${p => p.theme.colors.main};
    span {
      text-transform: none;
    }
    &:disabled {
      color: ${p => p.theme.colors.contentSecondary};
      font-weight: 400;
      svg {
        fill: ${p => p.theme.colors.contentSecondary};
      }
    }
  }
`;

const Box = styled.div`
  text-align: center;
`;

export const Stiker = styled.div`
  width: 360px;
  height: 500px;
  position: absolute;
  background-color: white;
  z-index: 110;
  top: 229px;
  padding: 15px 25px;
  box-shadow: 0 16px 64px rgb(0 0 0 / 20%);
  margin-left: 20px;
`;
