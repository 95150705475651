import { getHumanReadableMonth } from "@common/utils"
import { createSlice, PayloadAction, Slice, SliceCaseReducers } from "@reduxjs/toolkit"

export interface Incomes {
  average: string
  byDate: ByDate[]
  name: string
  total: string
}

export interface Expenses {
  average: string
  byDate: ByDate[]
  name: string
  total: string
}

export interface ByDate {
  year: number
  month: number
  value: string
}

export interface NetWorth {
  year: number | null
  month: number | null
  obligation: string
  availableCapital: string
}

export interface IncomesAndExpenses {
  income: Incomes[]
  incomeHeader: null | Incomes
  expenses: Expenses[]
  expensesHeader: null | Expenses
}

export interface NetWorthDate {
  startDate: {
    month: number
    year: number
  }
  endDate: {
    month: number
    year: number
  }
}

export interface NetWorthState {
  loading: boolean
  error: string | null
  netWorth: NetWorth[]
  incomesAndExpenses: IncomesAndExpenses
  netWorthDate: NetWorthDate
  incomesAndExpensesDate: {
    // Временно. Сделать тут также, как у netWorthDate
    month: number
    year: number
  }
  csv: string
}

const defaultDate = { month: getHumanReadableMonth(new Date()), year: new Date().getFullYear() }

const initialState: NetWorthState = {
  loading: false,
  error: null,
  netWorthDate: {
    startDate: defaultDate,
    endDate: defaultDate,
  },
  incomesAndExpensesDate: defaultDate,
  netWorth: [
    {
      year: null,
      month: null,
      obligation: "",
      availableCapital: "",
    },
  ],
  incomesAndExpenses: {
    income: [],
    incomeHeader: null,
    expenses: [],
    expensesHeader: null,
  },
  csv: "",
}

interface ReportsActions extends SliceCaseReducers<NetWorthState> {
  setReportsNetWorth: (state: NetWorthState, action: PayloadAction<NetWorth[]>) => void
  setReportsNetWorthDate: (state: NetWorthState, action: PayloadAction<NetWorthDate>) => void
  setReportsIncomesAndExpensesDate: (state: NetWorthState, action: PayloadAction<typeof defaultDate>) => void
  setReportsIncomesAndExpenses: (state: NetWorthState, action: PayloadAction<IncomesAndExpenses>) => void
  setReportsLoading: (state: NetWorthState, action: PayloadAction<boolean>) => void
  setReportsError: (state: NetWorthState, action: PayloadAction<string | null>) => void
  setIncomeAndExpensesCSV: (state: NetWorthState, action: PayloadAction<string>) => void
}

const reportsSlice: Slice<NetWorthState, ReportsActions, "reports"> = createSlice({
  name: "reports",
  initialState: initialState,
  reducers: {
    setReportsNetWorth: (state: NetWorthState, action: PayloadAction<NetWorth[]>) => {
      state.loading = false
      state.netWorth = action.payload
    },
    setReportsNetWorthDate: (state: NetWorthState, action: PayloadAction<NetWorthDate>) => {
      state.netWorthDate = action.payload
    },
    setReportsIncomesAndExpensesDate: (state: NetWorthState, action: PayloadAction<typeof defaultDate>) => {
      state.incomesAndExpensesDate = action.payload
      state.loading = false
    },
    setReportsIncomesAndExpenses: (state: NetWorthState, action: PayloadAction<IncomesAndExpenses>) => {
      state.incomesAndExpenses = action.payload
      state.loading = false
    },
    setReportsLoading: (state: NetWorthState, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    setReportsError: (state: NetWorthState, action: PayloadAction<string | null>) => {
      state.error = action.payload
      state.loading = false
    },
    setIncomeAndExpensesCSV: (state: NetWorthState, action: PayloadAction<string>) => {
      state.csv = action.payload
      state.loading = false
    },
  },
  extraReducers: {},
})

export const {
  setReportsError,
  setIncomeAndExpensesCSV,
  setReportsNetWorth,
  setReportsNetWorthDate,
  setReportsIncomesAndExpensesDate,
  setReportsLoading,
  setReportsIncomesAndExpenses,
} = reportsSlice.actions

export const reportsReducer = reportsSlice.reducer
