import { InputProps as StandardInputProps } from "@material-ui/core/Input/Input";
import { TextFieldProps } from "@material-ui/core/TextField/TextField";
import { TextField } from "./styles";
import { ReactElement } from "react";

type Props = TextFieldProps & {
  value: string | undefined;
  onChange?: StandardInputProps["onChange"];
  withMainBorder?: boolean;
};

export function InputText(props: Props): ReactElement {
  const { value, onChange, withMainBorder, ...other } = props;

  return <TextField {...other} value={value} onChange={onChange} type="text" $withMainBorder={withMainBorder}  />;
}
