import { StatusCode } from "@assets/index";
import { LoginRequest, LoginResponse } from "@auth/models";
import { API, APIFormData } from "@common/api";
import { loadUser } from "@common/services/user.service";
import { NotificationType, RootState, setAccountsError, setNotification, UserData } from "@common/store";
import { createAsyncThunk } from "@reduxjs/toolkit";

type LogoutNotificationType = {
  status: NotificationType;
  message: string;
};

class AuthService {
  login = createAsyncThunk<UserData & { token: string }, LoginRequest>(
    "users/login",
    async ({ email, password, saveRefreshToken }, { dispatch, rejectWithValue, fulfillWithValue }) => {
      try {
        const { data } = await API.post<LoginResponse>("/users/jwt/create/", { email, password });
        localStorage.setItem("session", data.access);
        if (saveRefreshToken) localStorage.setItem("refresh", data.refresh);
        const userData = await dispatch(loadUser()).unwrap();
        return { ...userData, token: data.access };
      } catch (error) {
        dispatch(setNotification({ type: "error", message: (error as any).response?.data?.error }));
        dispatch(setAccountsError((error as { message: string }).message));
        return rejectWithValue(error);
      }
    },
  );

  sendLinkChangePassword = createAsyncThunk<void, { email: string }>(
    "users/sendLinkChangePassword",
    async ({ email }, { dispatch }) => {
      try {
        await API.post("/users/password/change/", { email });
        dispatch(setNotification({ type: "success", message: "Ссылка на смену пароля отправлена Вам на почту" }));
      } catch (error) {
        const message =
          (error as any).response.status === StatusCode.BAD_REQUEST
            ? "Пользователя с таким адресом электронной почты не существует"
            : (error as any).message;
        dispatch(setNotification({ type: "error", message: message }));
      }
    },
  );

  signUpFromData = createAsyncThunk<void, { email: string; password: string; referralId: string | undefined }>(
    "users/signUp",
    async ({ email, password, referralId }, { dispatch }) => {
      try {
        const bodyFormData = new FormData();
        bodyFormData.append("email", email);
        bodyFormData.append("password", password);
        bodyFormData.append("referralId", referralId ?? "");
        await APIFormData.post("/users/", bodyFormData);
        dispatch(
          setNotification({
            type: "success",
            message: "Регистрация прошла успешно. Письмо с подтверждением отправлено на почту",
          }),
        );
      } catch (error) {
        const message =
          (error as any).response.status === StatusCode.BAD_REQUEST
            ? "Пользователь с таким адресом электронной почты уже существует"
            : (error as any).message;
        dispatch(setNotification({ type: "error", message: message }));
      }
    },
  );

  signUpJSON = createAsyncThunk<void, { email: string; password: string; referralId: string | undefined }>(
    "users/signUpJSON",
    async ({ email, password, referralId }, { dispatch }) => {
      try {
        await API.post("/users/", {
          email,
          password,
          ...(referralId !== undefined && { referralId }),
        });

        dispatch(
          setNotification({
            type: "success",
            message: "Регистрация прошла успешно. Письмо с подтверждением отправлено на почту",
          }),
        );
      } catch (error) {
        const message =
          (error as any).response.status === StatusCode.BAD_REQUEST
            ? "Пользователь с таким адресом электронной почты уже существует"
            : (error as any).message;
        dispatch(setNotification({ type: "error", message: message }));
      }
    },
  );

  changePassword = createAsyncThunk<void, { password: string; token: string | null }, { state: RootState }>(
    "users/changePassword",
    async ({ password, token }, { dispatch }) => {
      await API.post(`/users/password/change/${token}/`, { password });
      dispatch(setNotification({ type: "success", message: "Пароль успешно изменен" }));
    },
  );

  logout = createAsyncThunk<void, LogoutNotificationType | undefined, { state: RootState }>(
    "users/changePassword",
    async (notification, { dispatch }) => {
      localStorage.clear();
      notification && dispatch(setNotification({ type: notification.status, message: notification.message }));
    },
  );

  refreshToken = createAsyncThunk<string | undefined, void, { state: RootState }>(
    "users/changePassword",
    async (_, { dispatch }) => {
      const refresh = localStorage.getItem("refresh");

      try {
        if (refresh) {
          const { data } = await API.post<LoginResponse>("users/jwt/refresh/", { refresh });

          localStorage.setItem("session", data.access);
          localStorage.setItem("refresh", data.refresh);
          return data.access;
        }
      } catch {
        dispatch(this.logout());
      }
    },
  );

  constructor() {
  }
}

export default new AuthService();
