import { UniqueIdentifier } from "@dnd-kit/core"
import { getAvailable } from "./getAvailable"
import { FlattenedItem } from "./types"

export function getTreeItemMinDepth<T>(
  newItems: Array<FlattenedItem<T>>,
  overItemIndex: number,
  items: Array<FlattenedItem<T>>,
  activeIds: Array<UniqueIdentifier>,
  maxDeep?: number,
): number {
  const nextItem: FlattenedItem<T> = newItems[overItemIndex + 1]
  const available: number = getAvailable(items, activeIds, maxDeep)
  const nextItemDepth: number = nextItem?.depth ?? 0
  if (nextItemDepth > available) {
    return available
  }
  return nextItemDepth
}
