import React, { memo, MouseEventHandler, ReactNode } from 'react';

import { Source } from './Button.style';

export type Variant = 'primary' | 'secondary' | undefined;

export interface ButtonProps {
  variant?: Variant;
  onClick?: MouseEventHandler;
  children?: ReactNode;
  disabled?: boolean;
}

export const Button = memo(({ variant = 'primary', onClick, children, disabled = false }: ButtonProps) => (
  <Source onClick={onClick} variant={variant} disabled={disabled}>
    {children}
  </Source>
));

Button.displayName = 'Button';
