import { SchedulerCancellable } from "@common/core"
import { useCallback, useRef } from "react"

export function useScheduler<F extends SchedulerCancellable>(
  scheduler: F,
  ...args: Parameters<F>
): (...args: any) => void {
  const cancel = useRef<(...args: any) => void>()
  return useCallback(() => {
    cancel.current?.()
    // @ts-ignore
    cancel.current = scheduler(...args)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheduler, ...args])
}
