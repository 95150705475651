import { CREDIT } from "@assets/index";
import { useAsyncDispatch, useTypedSelector, useUnwrapAsyncDispatch } from "@common/hooks";
import { createContractorThunk, getContractorsThunk } from "@common/services";
import {
  accountsListOptions,
  addPeriodicTypes,
  contractorsList,
  currentBudgetDateSelector,
  currentBudgetSelector,
  getCategoriesByDate,
  getPeriodicTypesThunk,
  newContractorSelector,
  selectCategoriesOptions,
  selectPeriodicTypesOptions,
  setNewContractor,
  subcategoriesList,
} from "@common/store";
import ru from "date-fns/locale/ru";
import arrayMutators from "final-form-arrays";
import { useCallback, useEffect, useRef, useState } from "react";
import { registerLocale } from "react-datepicker";
import { Field, Form as FinalForm } from "react-final-form";
import { FieldArray } from "react-final-form-arrays";
import { useDispatch, useSelector } from "react-redux";
import { ModalForm } from "../../Modals";
import { SplitFormRow } from "./SplitFormRow";
import { ContractorOptionsType, getContractorOptions, OptionType } from "./helpers";
import {
  BoxSB,
  Calendar,
  Cancel,
  Cell,
  CreatableSelect,
  DropDownOptionLabel,
  DropDownOptionSubText,
  DropDownOptionWrapper,
  Select,
  SplitButton,
  SplitButtonLabel,
  Submit,
  Text,
  TextField,
  Wrapper,
} from "./styles";

registerLocale("ru", ru);

export const CreateForm = ({ onClose, onCreate, showAccountsOption }) => {
  const addNewAfterSaveRef = useRef(false);
  const refPeriod = useRef<HTMLInputElement | null>(null),
    contReference = useRef<HTMLInputElement | null>(null),
    subReference = useRef<HTMLInputElement | null>(null),
    walletReference = useRef<HTMLInputElement | null>(null),
    descReference = useRef<HTMLInputElement | null>(null);

  const initialValues = useRef({ date: new Date() });
  const [open, setOpen] = useState(false);
  const [newSubcategoryName, setNewSubcategoryName] = useState("");
  const [addedContractor, setAddedContractor] = useState<OptionType | null>(null);
  const [contractorOptions, setContractorOptions] = useState<ContractorOptionsType[]>([]);

  const subcategories = useSelector(subcategoriesList);
  const categoryOptions = useSelector(selectCategoriesOptions);
  const periodicOptions = useSelector(selectPeriodicTypesOptions);
  const walletOptions = useSelector(accountsListOptions);
  const contractors = useSelector(contractorsList);
  const currentBudget = useSelector(currentBudgetSelector);
  const newContractor = useSelector(newContractorSelector);
  const currentDate = useSelector(currentBudgetDateSelector);

  const currentAccount = useTypedSelector(state => state.accounts.currentAccount);
  const currentAccountName = currentAccount?.name;
  const currentAccountId = currentAccount?.id;

  const dispatch = useDispatch();
  const asyncDispatch = useAsyncDispatch();
  const asyncUnwrapDispatch = useUnwrapAsyncDispatch();

  useEffect(() => {
    setContractorOptions(getContractorOptions({ contractors, currentAccountId }));
  }, [contractors, currentAccountId]);

  useEffect(() => {
    if (!newContractor) {
      return setAddedContractor(null);
    }

    if (contractorOptions.length) {
      setAddedContractor(contractorOptions[0].options.find(option => option.value === newContractor.id) || null);
      dispatch(setNewContractor(null));
    }
  }, [newContractor, contractorOptions, dispatch]);

  useEffect(() => {
    void asyncDispatch(getCategoriesByDate(currentBudget?.id, currentDate.month, currentDate.year));
    if (currentBudget?.id !== undefined) {
      asyncDispatch(getContractorsThunk({ budget: currentBudget.id }));
    }
    asyncUnwrapDispatch(getPeriodicTypesThunk).then(data => dispatch(addPeriodicTypes(data)));
  }, [dispatch, asyncDispatch, currentBudget?.id, subcategories]);

  const handleCreateContactor = (inputValue: any) => {
    if (currentBudget?.id === undefined) return;
    asyncDispatch(
      createContractorThunk({
        name: inputValue,
        budget: currentBudget.id,
        templates: [],
      }),
    );
  };

  const handleCreateSubcategory = value => {
    setNewSubcategoryName(value);
    setOpen(true);
  };

  const onEnterPress = event => {
    if (event.key === "Enter") {
      event.preventDefault();

      const form = event.target.form;
      if (form) {
        const inputElemnts = [...form].filter(el => el.id);
        const index = inputElemnts.indexOf(event.target);
        const nextInput = inputElemnts[index + 1]?.disabled ? inputElemnts[index + 2] : inputElemnts[index + 1];
        nextInput && nextInput.focus();
      }
    }
  };

  const [partitions, setPartitions] = useState<Array<number>>([]);

  /**
   * @description action true = add; action false = remove
   */
  const splitUp = useCallback(
    (partitionKey: number) => (action: boolean) => () => {
      if (!action) {
        setPartitions(part => part.filter(p => p !== partitionKey));
      }
      setPartitions(part => [...part, partitionKey]);
    },
    [],
  );

  const validate = values => {
    const errors: any = {};

    if (!values.outgoing && !values.incoming) {
      errors.outgoing = "Required";
      errors.incoming = "Required";
    }

    return errors;
  };

  const formatOptionLabel = ({ label, availableFunds }) => {
    return (
      <DropDownOptionWrapper>
        <DropDownOptionLabel>{label}</DropDownOptionLabel>
        <DropDownOptionSubText value={availableFunds}>{availableFunds} ₽</DropDownOptionSubText>
      </DropDownOptionWrapper>
    );
  };

  const renderForm = ({
    handleSubmit,
    values,
    form: {
      submit,
      mutators: { push, changeValue },
    },
  }) => {
    const isTransfer = contractors.some(el => el.contractorType === "Переводы" && values.contractor?.label === el.name);

    if (addedContractor) {
      values["contractor"] = addedContractor;
    }

    const transactionType: "outgoing" | "incoming" | null = (() => {
      if (!!values.incoming || values.splitTransactions?.some(trc => !!trc?.incoming)) {
        return "incoming";
      } else if (!!values.outgoing || values.splitTransactions?.some(trc => !!trc?.outgoing)) {
        return "outgoing";
      }

      return null;
    })();

    return (
      <>
        <form onSubmit={handleSubmit} onKeyDown={onEnterPress}>
          <Wrapper>
            <Cell>
              <Field name="date" validate={value => !value && "Required"}>
                {({ input: { value, onChange, name }, meta: { error, pristine } }) => (
                  <Calendar
                    id={name}
                    selected={value}
                    onChange={date => {
                      onChange(date);
                      refPeriod && refPeriod.current?.focus();
                    }}
                    dateFormat="dd.MM.yyyy"
                    value={value}
                    name={name}
                    autoComplete="off"
                    locale="ru"
                    autoFocus
                    showPopperArrow={false}
                  />
                )}
              </Field>
            </Cell>
            <Cell>
              <Field name="periodicity">
                {({ input: { value, onChange, name } }) => (
                  <Select
                    id={name}
                    name={name}
                    value={value || periodicOptions[0]}
                    onChange={e => {
                      onChange(e);
                      contReference && contReference.current?.focus();
                    }}
                    options={periodicOptions}
                    classNamePrefix="select"
                    placeholder="Повтор"
                    className="select"
                    // components={{ DropdownIndicator: DropdownIndicator(BottomIcon) }}
                  />
                )}
              </Field>
            </Cell>
            <Cell>
              <Field name="contractor">
                {({ input: { value, onChange, name } }) => (
                  <>
                    {currentAccountName?.includes(CREDIT) ? (
                      <Select
                        id={name}
                        name={name}
                        value={value}
                        onChange={e => {
                          onChange(e);
                          values["comment"] = "hello";
                          const { autoSubcategory = null, autoSubcategoryName = null } = {
                            ...contractors.find(({ id }) => id === value.value),
                          };

                          if (autoSubcategory && autoSubcategoryName) {
                            changeValue("subcategory", {
                              label: autoSubcategoryName,
                              value: autoSubcategory,
                            });
                          }
                          subReference && subReference.current?.focus();
                        }}
                        options={[contractorOptions[1]]}
                        classNamePrefix="select"
                        placeholder="Контрагент"
                        className="select"
                        isSearchable
                        isClearable
                        components={
                          {
                            // DropdownIndicator: DropdownIndicator(BottomIcon),
                            // ClearIndicator: ClearIndicator(CloseIcon),
                          }
                        }
                      />
                    ) : (
                      <CreatableSelect
                        id={name}
                        name={name}
                        value={value}
                        onChange={e => {
                          onChange(e);

                          if (!e) return;

                          const { autoSubcategory = null, autoSubcategoryName = null } = {
                            ...contractors.find(({ id }) => id === (e as any).value),
                          };

                          if (autoSubcategory && autoSubcategoryName) {
                            changeValue("subcategory", {
                              label: autoSubcategoryName,
                              value: autoSubcategory,
                            });

                            descReference && descReference.current?.focus();
                            return;
                          } else {
                            changeValue("subcategory", undefined);
                          }
                          subReference && subReference.current?.focus();
                        }}
                        options={contractorOptions}
                        classNamePrefix="select"
                        placeholder="Контрагент"
                        className="select"
                        isSearchable
                        isClearable
                        onCreateOption={handleCreateContactor}
                        formatCreateLabel={(text: string) => <Text>Создать «{text}»</Text>}
                        components={
                          {
                            // DropdownIndicator: DropdownIndicator(BottomIcon),
                            // ClearIndicator: ClearIndicator(CloseIcon),
                          }
                        }
                        $error={false}
                      />
                    )}
                  </>
                )}
              </Field>
            </Cell>
            <Cell></Cell>
            <Cell>
              <Field name="comment">
                {({ input: { value, onChange, name }, meta: { error, pristine } }) => (
                  <TextField
                    name={name}
                    value={value}
                    onChange={onChange}
                    inputRef={descReference}
                    id={name}
                    label="Введите описание"
                    type="text"
                    variant="outlined"
                    autoComplete="off"
                    error={!!error && !pristine}
                  />
                )}
              </Field>
            </Cell>
          </Wrapper>
          <FieldArray name="splitTransactions">
            {({ fields }) =>
              fields.map((name, index) => (
                <SplitFormRow
                  key={partitions[index]}
                  index={index}
                  fields={fields}
                  remove={splitUp(index)(false)}
                  handleCreateSubcategory={handleCreateSubcategory}
                  values={values}
                  name={name}
                  {...{
                    transactionType,
                    setContractorOptions,
                    contractors,
                    currentAccountId,
                    isTransfer,
                    categoryOptions,
                  }}
                />
              ))
            }
          </FieldArray>
          <BoxSB>
            <SplitButton
              onClick={() => {
                push("splitTransactions", undefined);
                splitUp(partitions.length)(true)();
              }}
            >
              <SplitButtonLabel>Добавить разделение +</SplitButtonLabel>
            </SplitButton>
            <div>
              <Submit type="submit">Сохранить</Submit>
              <Submit
                type="submit"
                id="addAndSubmit"
                onKeyDown={event => {
                  if (event.key === "Enter") {
                    addNewAfterSaveRef.current = true;
                    submit();
                  }
                }}
                onClick={() => (addNewAfterSaveRef.current = true)}
              >
                Сохранить и добавить новую
              </Submit>
              <Cancel onClick={() => onClose()}>Отмена</Cancel>
            </div>
          </BoxSB>
        </form>
        <ModalForm
          open={open}
          setOpen={setOpen}
          mode={"createSubcategory"}
          value={newSubcategoryName}
          setActiveSubcategory={sub =>
            changeValue("subcategory", (({ name, id }) => ({ label: name, value: id }))(sub))
          }
        />
      </>
    );
  };
  return (
    <FinalForm
      onSubmit={values => onCreate(values, addNewAfterSaveRef.current)}
      validate={validate}
      initialValues={initialValues.current}
      // render={renderForm}
      mutators={{
        ...arrayMutators,
        changeValue: ([field, value], state, { changeValue }) => {
          changeValue(state, field, () => value);
        },
      }}
    />
  );
};
