import type { Article } from "@common/models"
import { createArticleThunk, deleteArticlesThunk, editArticleThunk, getArticlesThunk } from "@common/services"
import { ActionReducerMapBuilder, PayloadAction, Slice, SliceCaseReducers, createSlice } from "@reduxjs/toolkit"

// TODO: Описать контент
export type Content = any

interface ArticlesState {
  list: Article[]
  isLoading: boolean
  error: string | null
}

const initialState: ArticlesState = {
  list: [],
  isLoading: false,
  error: null,
}

interface ArticlesActions extends SliceCaseReducers<ArticlesState> {
  setArticlesList: (state: ArticlesState, action: PayloadAction<Article[]>) => void
}

const articlesSlice: Slice<ArticlesState, ArticlesActions, "articles"> = createSlice({
  name: "articles",
  initialState: initialState,
  reducers: {
    setArticlesList: (state, action: PayloadAction<Article[]>): void => {
      state.list = action.payload
    },
  },
  extraReducers: createExtraReducer,
})

export const { setArticlesList } = articlesSlice.actions

export const articlesReducer = articlesSlice.reducer

function createExtraReducer(builder: ActionReducerMapBuilder<ArticlesState>): void {
  builder
    .addCase(getArticlesThunk.pending, state => {
      state.isLoading = true
    })
    .addCase(getArticlesThunk.fulfilled, state => {
      state.isLoading = false
      state.error = null
    })
    .addCase<string, PayloadAction<string | null>>(getArticlesThunk.rejected.toString(), (state, action) => {
      state.isLoading = false
      state.error = action.payload
    })
    .addCase(createArticleThunk.pending, state => {
      state.isLoading = true
    })
    .addCase(createArticleThunk.fulfilled, state => {
      state.isLoading = false
      state.error = null
    })
    .addCase<string, PayloadAction<string | null>>(createArticleThunk.rejected.toString(), (state, action) => {
      state.isLoading = false
      state.error = action.payload
    })
    .addCase(editArticleThunk.pending, state => {
      state.isLoading = true
    })
    .addCase(editArticleThunk.fulfilled, state => {
      state.isLoading = false
      state.error = null
    })
    .addCase<string, PayloadAction<string | null>>(editArticleThunk.rejected.toString(), (state, action) => {
      state.isLoading = false
      state.error = action.payload
    })
    .addCase(deleteArticlesThunk.pending, state => {
      state.isLoading = true
    })
    .addCase(deleteArticlesThunk.fulfilled, state => {
      state.isLoading = false
      state.error = null
    })
    .addCase<string, PayloadAction<string | null>>(deleteArticlesThunk.rejected.toString(), (state, action) => {
      state.isLoading = false
      state.error = action.payload
    })
}
