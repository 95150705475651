import SettingsIcon from "@assets/icons/settingsIcon.svg";
import CloseIcon from "@assets/icons/close.svg";
import styled from "styled-components";
import { ReactElement } from "react";

type SettingsMenuItem = {
  onClick: () => void;
  title: string;
};

export interface SettingsMenuProps {
  menuItems: SettingsMenuItem[];
  show: boolean;
  toggle: () => void;
}

export function SettingsMenu(props: SettingsMenuProps): ReactElement {
  const { menuItems, show, toggle } = props;

  return (
    <MenuContainer>
      {show ? menuItems.map(({ title, onClick }) => <MenuItem onClick={onClick}>{title}</MenuItem>) : null}
      <StyledIcon onClick={toggle}>
        {!show ? <SettingsIcon /> : null}
        {show ? <CloseIcon /> : null}
      </StyledIcon>
    </MenuContainer>
  );
}

const MenuContainer = styled.div`
  display: flex;
  align-items: flex-start;
  column-gap: 6px;
  overflow: hidden;
`;

const MenuItem = styled.div`
  color: ${p => p.theme.colors.darkText};
  font-weight: 500;
  font-family: Manrope, Inter, sans-serif;
  font-size: 16px;
  line-height: 1;
  cursor: pointer;
  padding: 12px 8px;
  background: var(--color-white-100);
  width: 100%;
  border-radius: 4px;
  height: 100%;

  &:hover {
    background-color: ${p => p.theme.colors.lightnessGrey};
  }

  @media (max-width: 1200px) {
    padding: 8px;
  }
`;

const StyledIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 44px;
  min-width: 44px;
  height: 44px;
  background-color: var(--color-white-100);
  border-radius: 5px;

  transition: all 0.15s linear;
  cursor: pointer;

  &:hover {
    -webkit-box-shadow: 0 0 10px 0 rgba(34, 60, 80, 0.35) inset;
    -moz-box-shadow: 0 0 10px 0 rgba(34, 60, 80, 0.35) inset;
    box-shadow: 0 0 10px 0 rgba(34, 60, 80, 0.35) inset;
    transition: all 0.15s linear;
  }

  @media (max-width: 1200px) {
    width: 32px;
    min-width: 32px;
    height: 32px;
  }

  svg {
    width: 20px;
    height: 20px;

    path {
      fill: var(--color-gray-80);
    }

    @media (max-width: 1200px) {
      width: 16px;
      height: 16px;
    }
  }
`;
