import classNames from "classnames/bind";
import styles from "./SectionTitle.module.scss";

const cln = classNames.bind(styles);

type Props = {
  title: string;
  classNames?: string;
};
export function SectionTitle({ title, classNames }: Props) {
  return <h1 className={cln("title", classNames)}>{title}</h1>;
}
