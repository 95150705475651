import styled, { css } from 'styled-components'
import { ButtonVariants } from './ButtonMain'

export const Button = styled.button<{
  fullWidth?: boolean;
  size?: string;
  padX?: number;
  $indentTop?: string;
  $styleLink?: boolean;
  $indentBottom?: string;
  variant: ButtonVariants;
}>`
  padding: 8px ${({ padX }) => (padX ? `${padX}px` : '27px')};
  background-color: ${({ theme, variant }) =>
    variant === ButtonVariants.primary ? theme.colors.main : theme.colors.white};
  color: ${({ theme, variant }) => (variant === ButtonVariants.primary ? theme.colors.white : theme.colors.main)};
  border-radius: 4px;
  border: ${({ theme, variant }) => (variant === ButtonVariants.primary ? 'none' : `1px solid ${theme.colors.main}`)};
  font-style: normal;
  font-weight: 600;
  font-size: ${p => p.theme.text.fontSmall};
  line-height: 24px;
  transition: background 0.2s;
  text-transform: uppercase;
  cursor: pointer;
  width: ${p => (p.fullWidth ? '100%' : 'auto')};
  ${p => p.$indentTop && `margin-top: ${p.$indentTop};`}
  ${p => p.$indentBottom && `margin-bottom: ${p.$indentBottom};`}

  &:hover,
  &:focus,
  &:active {
    background-color: ${({ theme, variant }) =>
      variant === ButtonVariants.primary ? theme.colors.dark : theme.colors.lightGrey};
  }

  &:disabled {
    background-color: ${p => p.theme.colors.backgroundMain};
    color: ${p => p.theme.colors.contentSecondary};
    cursor: auto;
  }
  ${p => p.size === 'big' && 'padding: 16px 27px;'}
  ${p => p.$styleLink && link}
`;

const link = css`
  padding: 0;
  border: none;
  background-color: transparent;
  color: ${p => p.theme.colors.main};
  text-align: center;
  font-style: normal;
  font-weight: normal;
  font-size: ${p => p.theme.text.fontSmall};
  line-height: 24px;
  text-transform: none;

  &:hover,
  &:focus,
  &:active {
    background-color: transparent;
  }

  &:disabled {
    background-color: ${p => p.theme.colors.backgroundMain};
    color: ${p => p.theme.colors.contentSecondary};
    cursor: auto;
  }
`;

/* TODO: Добавить стили позже */
export const Link = styled.a``;
