class LocalStorageService {
  update(field: string, data: string | undefined): void {
    if (data === undefined) {
      return;
    }
    localStorage.setItem(field, data);
  }

  get(field: string): string | null {
    return localStorage.getItem(field);
  }

  delete(field: string): void {
    localStorage.removeItem(field);
  }

  clear(): void {
    localStorage.clear();
  }
}

export const localStorageService = new LocalStorageService();
