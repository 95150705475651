/**
 * Asynchronously loads the components for NotFoundPage
 */

import { Loading } from "@common/components";
import { lazyLoad } from "@common/utils";

export const NotFoundPage = lazyLoad(
  () => import("./index"),
  module => module.NotFoundPage,
  {
    fallback: <Loading />,
  },
);
