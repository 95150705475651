import CalcIcon from "@assets/icons/calc.svg";
import { useFlag } from "@common/hooks";
import { hexToRgb } from "@common/utils";
import { theme } from "@main/styles";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
import { FormHelperText } from "@mui/material";
import { useRef } from "react";
import { FieldInputProps, FieldMetaState } from "react-final-form";
import styled from "styled-components";
import { InputText } from "../Input/InputText";
import { Popover } from "../Popover/Popover";
import { InputButton } from "./styles";
import { CalculatorButtonGroup } from "./utils";

type Props<T> = {
  input: FieldInputProps<T>;
  placeholder?: string;
  disabled?: boolean;
  label?: string;
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void;
  meta?: FieldMetaState<T>;
  reg?: RegExp
  showError?: boolean
};

export function CalculatorInput<T extends string | undefined>(props: Props<T>) {
  const { input, label, placeholder, showError, disabled, setFieldValue, meta, reg } = props;
  const error = meta?.error !== undefined && ( meta?.touched || showError) ? meta?.error : undefined;

  const [isOpen, open, close, toggle] = useFlag(false);

  const inputRef = useRef<HTMLInputElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const focusInput = () => inputRef.current?.focus();

  const popoverRef = (ref: Popover | null) => {
    ref?.reference(containerRef.current);
  };

  return (
    <ClickAwayListener onClickAway={close}>
      <>
        <StyledContainer ref={containerRef} $error={error !== undefined} $disabled={disabled}>
          <InputText
            inputRef={inputRef}
            onChange={(e) => onInput(input, e, reg)}
            onBlur={input.onBlur}
            autoComplete="off"
            withMainBorder
            name={input.name}
            placeholder={placeholder}
            value={input.value}
            id={input.name}
            disabled={disabled}
            label={label}
            InputProps={{
              endAdornment: (
                <InputButton
                  disabled={disabled}
                  onMouseDown={event => {
                    event.preventDefault();
                  }}
                  aria-label="calculator"
                  onClick={toggle}
                >
                  <CalcIcon />
                </InputButton>
              ),
              inputProps: {
                pattern: "[0-9]+",
              },
            }}
          />
          <Popover
            popoverRef={popoverRef}
            closeOnEscape
            withBackdrop
            closeOnBackdrop
            placement="bottom-start"
            mainAxis={4}
            onClose={close}
            isShow={isOpen}
          >
            <CalculatorButtonGroup
              toAddSymbol={symbol => {
                input.onChange?.(`${input.value ?? ""}${symbol}`);
                focusInput();
              }}
              currentState={input.value}
            />
          </Popover>
          {error !== undefined ? <StyledError>{error}</StyledError> : null}
        </StyledContainer>
      </>
    </ClickAwayListener>
  );
}

const StyledContainer = styled.div<{
  $error?: boolean;
  $disabled?: boolean;
}>`
  height: 92px;
  width: 100%;
  padding-bottom: 16px;
  position: relative;

  .MuiFormControl-root {
    height: 100%;
  }

  .MuiInputBase-root {
    background-color: rgba(${hexToRgb(theme.colors.lightBlue)}, 0.2) !important;
    flex: 1;
    border-radius: 6px;
    border: 1px solid ${p => p.theme.colors.lightBlue};

    ${p =>
            p.$error &&
            `
          border: 1px solid #FF4646 !important;
        `}
    ${p =>
            p.$disabled &&
            `
          background: #e0e2e3;
        `}
    .MuiInputBase-input {
      height: 100%;
      border: 0 !important;
    }
  }
`;

const StyledError = styled(FormHelperText)`
  color: #ff4646 !important;

  position: absolute;
  bottom: -5px;
  left: 16px;
`;


function onInput<T>(input: FieldInputProps<T, HTMLElement>, e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, reg?: RegExp) {
  if (reg === undefined) {
    input.onChange(e);
    return;
  }

  if (e.target.value === undefined || e.target.value === "") {
    input.onChange(e.target.value);
    return;
  }

  if (new RegExp(reg).test(e.target.value)) {
    input.onChange(e);
  }
}
