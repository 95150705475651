import { Loading } from "@common/components";
import { lazyLoad } from "@common/utils";

export const UnitAnalyticsAsync = lazyLoad(
  () => import("./index"),
  module => module.AnalyticsPage,
  {
    fallback: <Loading />,
  },
);
