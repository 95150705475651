import { Loading } from "@common/components"
import { lazyLoad } from "@common/utils"

export const SubscriptionPage = lazyLoad(
  () => import("./index"),
  module => module.SubscriptionPage,
  {
    fallback: <Loading />,
  },
)
