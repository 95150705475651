import { media } from "@main/styles"
import { TextField as MuiTextField } from "@material-ui/core"
import styled from "styled-components"

export const FormTitle = styled.div`
  margin-bottom: 24px;
  font-style: normal;
  font-weight: 600;
  font-size: ${p => p.theme.text.fontSmall};
  line-height: 24px;
  color: ${p => p.theme.colors.contentMain};
`

export const FormRow = styled.div`
  display: flex;
  align-items: center;
`

export const TextField = styled(MuiTextField)<{ width?: string }>`
  &.MuiFormControl-root {
    min-height: 71px;
    margin-right: 40px;
    ${media.medium`
     margin-right: 50px;
  `}
  }
  .MuiFormLabel-root {
    font-family: Montserrat, sans-serif;

    &.Mui-focused {
      color: ${p => p.theme.colors.main};
    }
  }

  .MuiInput-underline {
    &:after {
      border-color: ${p => p.theme.colors.main};
    }

    &:before {
      border-color: ${p => p.theme.colors.backgroundSecondary};
    }
  }

  width: ${p => p.width || "196px"};
  ${media.medium`
     width: 243px;
  `}
`

export const Form = styled.form`
  padding: 0 40px 40px;
  ${media.medium`
     padding: 0 40px 26px;
  `}
`

export const Column = styled.div<{ grow?: string }>`
  flex-grow: ${p => p.grow || "0"};
`
