import type { Article, ArticleShortView } from "@common/models"
import { setNotification } from "@common/store"
import { ArticlesTransport } from "@common/transports"
import { normalizeError } from "@common/utils"
import { createAsyncThunk } from "@reduxjs/toolkit"

/**
 * @protected ADMIN
 */
export const getArticlesThunk = createAsyncThunk<Article[], void>(
  "GET_ARTICLES",
  async (_, { dispatch, fulfillWithValue, rejectWithValue }) => {
    try {
      const articles = await ArticlesTransport.getArticles()
      return fulfillWithValue(articles)
    } catch (err) {
      const error = normalizeError(err)
      dispatch(setNotification({ type: "error", message: error.message }))
      return rejectWithValue(error.message)
    }
  },
)

/**
 * @protected ADMIN
 */
export const createArticleThunk = createAsyncThunk<Article, ArticleShortView>(
  "articles/create-article",
  async (articleDto, { dispatch, fulfillWithValue, rejectWithValue }) => {
    try {
      const newArticle = await ArticlesTransport.createArticle(articleDto)
      return fulfillWithValue(newArticle)
    } catch (err) {
      const error = normalizeError(err)
      dispatch(setNotification({ type: "error", message: error.message }))
      return rejectWithValue(error.message)
    }
  },
)

/**
 * @protected ADMIN
 */
export const editArticleThunk = createAsyncThunk<Article, ArticleShortView & { id: string }>(
  "EDIT_ARTICLE",
  async ({ id, ...article }, { dispatch, fulfillWithValue, rejectWithValue }) => {
    try {
      const updatedArticle = await ArticlesTransport.editArticle(id, article)
      return fulfillWithValue(updatedArticle)
    } catch (err) {
      const error = normalizeError(err)
      dispatch(setNotification({ type: "error", message: error.message }))
      return rejectWithValue(error.message)
    }
  },
)

/**
 * @protected ADMIN
 */
export const deleteArticlesThunk = createAsyncThunk<void, { ids: string[] }>(
  "DELETE_ARTICLE",
  async ({ ids }, { dispatch, rejectWithValue }) => {
    try {
      for (let id of ids) await ArticlesTransport.deleteArticle(id)
    } catch (err) {
      const error = normalizeError(err)
      dispatch(setNotification({ type: "error", message: error.message }))
      return rejectWithValue(error.message)
    }
  },
)
