import { CheckboxProps, Checkbox as MuiCheckbox } from "@material-ui/core";
import { FieldInputProps } from "react-final-form";
import styled from "styled-components";

interface Props<T> {
  label?: string;
  input: FieldInputProps<T>;
  settings?: CheckboxProps;
}

export function FormCheckbox<T>(props: Props<T>) {
  const { label, input, settings } = props;
  return (
    <CheckboxLabel htmlFor={input.name}>
      <StyledCheckbox
        {...(settings !== undefined && { settings })}
        checked={input.checked}
        onChange={input.onChange}
        name={input.name}
        id={input.name}
      />
      {label !== undefined && <CheckboxText>{label}</CheckboxText>}
    </CheckboxLabel>
  );
}

const StyledCheckbox = styled(MuiCheckbox)`
  &&.Mui-checked {
    color: ${p => p.theme.colors.main};
  }

  &.MuiCheckbox-root {
    color: ${p => p.theme.colors.placeholder};
    padding: 0;

    input {
      width: 24px;
      min-width: 24px;
      height: 24px;
    }

    &.PrivateSwitchBase-input-4 {
      width: 24px;
      min-width: 24px;
    }

    &.PrivateSwitchBase-input-8 {
      width: 24px;
      min-width: 24px;
      height: 24px;
    }

    &.PrivateSwitchBase-input-5 {
      padding: 0;
    }
  }

  &.MuiCheckbox-colorSecondary.Mui-checked:hover,
  &.MuiIconButton-colorSecondary:hover {
    background-color: transparent;
  }
`;

export const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  width: fit-content;

  .PrivateSwitchBase-root-1 {
    padding: 0;
  }
`;
export const CheckboxText = styled.span`
  margin-left: 8px;
  font-family: Manrope, Inter, sans-serif;
  color: ${p => p.theme.colors.placeholder};
`;
