import { API } from "@common/api";
import { Budget, BudgetByDate, CurrentBudget } from "@common/models";
import { CreatedBudget } from "@common/store";
import { stringifyDate } from "@common/utils";

export type BudgetTransportType = {
  getBudgets: () => Promise<Budget[]>;
  getBudgetById: (id: string) => Promise<CurrentBudget>;
  getBudgetByDate: (budgetId: string, year: number, month: number) => Promise<BudgetByDate>;
  createBudget: (name: string) => Promise<CreatedBudget>;
  deleteBudget: (id: string) => Promise<void>;
  resetBudget: (id: string) => Promise<CurrentBudget>;
  updateBudget: ({ id, name }: { id: string; name?: string }) => Promise<CurrentBudget>;
};
export const BudgetTransport: BudgetTransportType = {
  getBudgets: async (): Promise<Budget[]> => {
    const { data } = await API.get<Budget[]>("/budgets/");
    return data;
  },
  getBudgetById: async (id: string): Promise<CurrentBudget> => {
    const { data } = await API.get<CurrentBudget>(`/budgets/${id}/`);
    return data;
  },
  getBudgetByDate: async (budgetId: string, year: number, month: number): Promise<BudgetByDate> => {
    const { data } = await API.get<BudgetByDate>(`/budgets/${stringifyDate(month)}/${year}/${budgetId}/`);
    return data;
  },
  createBudget: async (name: string): Promise<CreatedBudget> => {
    const { data } = await API.post<CreatedBudget>("/budgets/", { name: name });
    return data;
  },
  deleteBudget: async (id: string): Promise<void> => {
    return API.delete(`/budgets/${id}/`);
  },
  resetBudget: async (id: string): Promise<CurrentBudget> => {
    const { data } = await API.delete<CurrentBudget>(`/budgets/${id}/nullify/`);
    return data;
  },
  updateBudget: async ({ id, name }: { id: string; name?: string }): Promise<CurrentBudget> => {
    const { data } = await API.put<CurrentBudget>(`/budgets/${id}/`, { name });
    return data;
  },
};
