import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 1320px;
  margin: 0 auto;
`;

export const Title = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 60px;
  margin-top: 40px;
  margin-bottom: 40px;
  h1 {
    margin: 0;
  }

  margin-top: 24px;
`;
