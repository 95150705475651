import React from 'react'
import { ButtonIcon, StyledSelect } from "./styles"
import { SelectProps } from "@material-ui/core/Select/Select"

export function Select(props: SelectProps) {
  return (
    <StyledSelect
      {...props}
      MenuProps={{
        PaperProps: {
          style: {
            maxHeight: 200,
            borderRadius: 6,
            transform: "translateY(4px)",
          },
        },
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left',
        },
        getContentAnchorEl: null,
        placeholder: props.placeholder,
      }}
      IconComponent={ButtonIcon}
    >
      {props.children}
    </StyledSelect>
  )
}

