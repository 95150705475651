import { TextField as MuiTextField } from "@material-ui/core";
import styled from "styled-components";

export const TextField = styled(MuiTextField)<{ $withMainBorder?: boolean }>`
  &.MuiFormControl-root {
    width: 100%;
    color: ${p => p.theme.colors.placeholder};

    .MuiInputLabel-formControl {
      display: block;
      padding: 0 16px;
      height: 22px;
      font-size: 16px;
      line-height: 22px;
      position: static;
      font-weight: 600;
      font-family: Manrope, Inter, sans-serif;
      transform: none;
      color: ${p => p.theme.colors.darkText};
      margin-bottom: 11px;
      z-index: 0;
      width: fit-content;
    }

    .MuiInput-formControl {
      margin-top: 0;
      padding: 0;

      textarea {
        background: rgba(192, 216, 234, 0.2);
        border-radius: 6px;
      }

      .MuiInputBase-input {
        font-size: 16px;
        line-height: 22px;
        font-family: Manrope, Inter, sans-serif;

        padding: 20px 16px;
        height: 60px;

        font-weight: 500;
        color: #767676;
        border-radius: 6px;
        border: 1px solid ${p => p.theme.colors.lightBlue};
        box-sizing: border-box;

        ${p =>
          p.$withMainBorder &&
          `
            border: 1px solid #13BDCE;
            padding: 10px 12px 10px 16px;
            line-height: 26px;
            height: auto;
        `}

        &::placeholder {
          color: ${({ theme }) => theme.colors.placeholder} !important;
          font-size: 16px;
          line-height: 22px;
          font-family: Manrope, Inter, sans-serif;
          opacity: 1 !important;
        }
      }
    }
  }

  .MuiInput-underline:after,
  .MuiInput-underline:before {
    display: none;
  }

  .MuiOutlinedInput-notchedOutline {
    border: none transparent;
  }
`;
