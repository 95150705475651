import DeleteIcon from "@assets/icons/darkDelete24.svg";
import MinusIcon from "@assets/icons/darkMinus24.svg";
import MultiplyIcon from "@assets/icons/darkMultiply24.svg";
import PlusIcon from "@assets/icons/darkPlus24.svg";
import styled from "styled-components";

export function evaluateStringExpression(expression: string): string {
  try {
    const result = eval(expression);
    if (typeof result === "number") {
      return String(result);
    }
  } catch (error) {
    // В случае, если вычисление невозможно или возникла ошибка
    return "Error";
  }
  return "Invalid expression";
}
export const isValidNumber = (str: string): boolean => {
  const regex = /^-?\d+(\.\d+)?$/;
  return regex.test(str);
};

export const NumberExpressionRegExp = /^$|^-?\d+(\.\d+)?([+\-*/]\d+(\.\d+)?)*$/;
export const NumberActionRegExp = /^(?:\d+(?:\.\d+)?(?:[+\-*/]\d+(?:\.\d+)?)*)(?:(?:[+\-*/]?$)|(?:(?:[+\-*/]\d+(?:\.\d+)?)*[+\-*/]$))/;

type ButtonGroupProps = {
  toAddSymbol: (value: string) => void;
  currentState: string | undefined;
};

export function CalculatorButtonGroup(props: ButtonGroupProps) {
  const { toAddSymbol, currentState } = props;

  function onButtonClick(chart: string) {
    if (currentState === undefined) {
      return;
    }
    toAddSymbol(chart);
    // if (NumberActionRegExp.test(currentState.concat(chart))) {
    //   return toAddSymbol(chart);
    // }
  }

  return (
    <StyledButtonGroupWrapper>
      <StyledActionButton onClick={() => toAddSymbol("+")}>
        <PlusIcon />
      </StyledActionButton>
      <StyledActionButton onClick={() => toAddSymbol("-")}>
        <MinusIcon />
      </StyledActionButton>
      <StyledActionButton onClick={() => toAddSymbol("/")}>
        <DeleteIcon />
      </StyledActionButton>
      <StyledActionButton onClick={() => toAddSymbol("*")}>
        <MultiplyIcon />
      </StyledActionButton>
    </StyledButtonGroupWrapper>
  );
}

const StyledActionButton = styled.div`
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f6f7f9;
  border-radius: 2px;
  overflow: hidden;
  border: 1px solid transparent;

  &:hover {
    border: 1px solid ${p => p.theme.colors.main};
    background-color: rgba(19, 189, 206, 0.2);
  }
`;

const StyledButtonGroupWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  column-gap: 8px;
  row-gap: 8px;
  padding: 12px 24px;
  background-color: white;
  border: 1px solid #e5e6e9;
  border-radius: 4px;
`;
