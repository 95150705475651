import { z } from "zod"

export const BudgetZodSchema = z.object({
  id: z.string().uuid(),
  name: z.string(),
  createdAt: z.string(),
  updatedAt: z.string(),
})

export const CurrentBudgetZodSchema = z.object({
  id: z.string().uuid().nullable(),
  name: z.string().nullable(),
  cashReceipts: z.string().nullable(),
  planned: z.string().nullable(),
  spending: z.string().nullable(),
  available: z.string().nullable(),
})

export const BudgetByDateZodSchema = z.object({
  id: z.string().uuid().nullable(),
  name: z.string().nullable(),
  cashReceipts: z.string().nullable(),
  previousOverrun: z.string().nullable(),
  planned: z.string().nullable(),
  futureSpending: z.string().nullable(),
  lastMonthBudget: z.string().nullable(),
  lastMonthSpending: z.string().nullable(),
  avgBudgeted: z.string().nullable(),
  avgSpending: z.string().nullable(),
  notEnoughFunds: z.string().nullable(),
})

export const CreatedBudgetZodSchema = z.object({
  id: z.string().uuid(),
  name: z.string(),
  createdAt: z.string(),
  updatedAt: z.string(),
  available: z.number(),
})
