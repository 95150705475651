export const findAndReplaceId = (arr, id, newId, field = '') => {
  return arr.map(action => {
    if (field && action.props?.[field] === id) {
      return { ...action, props: { ...action.props, [field]: newId } };
    }
    if (action.id === id) {
      return { ...action, id: newId };
    }
    return action;
  });
};
