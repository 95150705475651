import authService from "@auth/service/auth-service"
import { useAsyncDispatch } from "@common/hooks"
import { comparePasswords } from "@common/utils"
import { getParam } from "@common/utils/searchParamsParser"
import { Form as FinalForm } from "react-final-form"
import { useLocation, useNavigate } from "react-router-dom"
import { ChangePasswordText, ChangePasswordTitle, Container, Link, Title, Wrapper } from "../common/styles"
import { UpdatePasswordForm } from "./UpdatePasswordForm"

export const UpdatePassword = () => {
  const asyncDispatch = useAsyncDispatch()
  const navigate = useNavigate()

  const search = useLocation().search
  const token = getParam(search, "token")

  const onSubmit = ({ password }) => {
    asyncDispatch(authService.changePassword({ password, token })).then(() => navigate("/auth/login"))
  }

  return (
    <Wrapper>
      <Container>
        <Title>Смена пароля</Title>
        <ChangePasswordTitle>Используйте сложный, уникальный пароль</ChangePasswordTitle>
        <FinalForm validate={comparePasswords} onSubmit={onSubmit} render={UpdatePasswordForm} />
        <ChangePasswordText>
          Или вернитесь к странице <Link to="/auth/login/">Входа</Link>
        </ChangePasswordText>
      </Container>
    </Wrapper>
  )
}
