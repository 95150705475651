import axios from "axios";

import { StatusCode } from "../../../assets";

export const savingerUrl = import.meta.env.VITE_APP_URI;
export const savingerUrlV2 = import.meta.env.VITE_APP_URI;

export type APIResponse<T> = {
  response: {
    data: T
  }
}

export const createConfig = (version?: "v1" | "v2", type?: "JSON" | "FORM_DATA") => (
  {
    timeout: 15000,
    baseURL: version === "v2" ? savingerUrlV2 : savingerUrl,
    withCredentials: true,
    headers: type === "FORM_DATA" ?
      {
        "Content-Type": "multipart/form-data",
      }
      : {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
  }
);

export const API = axios.create(createConfig());
export const API_V2 = axios.create(createConfig("v2"));
export const APIFormData = axios.create(createConfig("v1", "FORM_DATA"));

API.interceptors.request.use(config => {
  const token = localStorage.getItem("session")
  config.headers.Authorization = token ? `JWT ${localStorage.getItem("session")}` : null

  return config
})

API_V2.interceptors.request.use(config => {
  const token = localStorage.getItem("session")
  config.headers.Authorization = token ? `JWT ${localStorage.getItem("session")}` : null

  return config
})

API.interceptors.response.use(
  response => response,
  async error => {
    if (error.response.status === StatusCode.FORBIDDEN) {
      localStorage.clear();
      window.location.href = "/auth";
    }

    return Promise.reject(error);
  },
);

API_V2.interceptors.response.use(
  response => response,
  async error => {
    if (error.response.status === StatusCode.FORBIDDEN) {
      localStorage.clear();
      window.location.href = "/auth";
    }

    return Promise.reject(error);
  },
);
