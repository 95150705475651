import React, { Dispatch, FC, ReactNode, SetStateAction } from "react";
import { Backdrop } from "@material-ui/core";
import { Card, ChildrenContainer, Close, ModalSubtitle, ModalTitle, Paper, Wrapper } from "./styles";
import CloseIcon from "@assets/icons/close.svg";

interface IModal {
  open: boolean;
  setOpen?: Dispatch<SetStateAction<boolean>>;
  withCloseBtn?: boolean;
  children: ReactNode;
  onClose?: () => void;

  width?: number;
  minHeight?: number;
  minWidth?: number;
  title?: string;
  subtitle?: string;
  small?: boolean;
}

export const ModalContainer: FC<IModal> = props => {
  const {
    open,
    setOpen,
    children,
    onClose,
    withCloseBtn = true,
    title,
    subtitle,
    width,
    minHeight,
    minWidth,
    small,
  } = props;
  const handleClose = () => {
    if (onClose) onClose();
    else setOpen?.(false);
  };

  return (
    <Paper
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 150,
      }}
    >
      <Wrapper>
        <Card raised width={width} minHeight={minHeight} minWidth={minWidth} small={small}>
          {title !== undefined && <ModalTitle>{title}</ModalTitle>}
          {subtitle !== undefined && <ModalSubtitle>{subtitle}</ModalSubtitle>}
          <ChildrenContainer>{children}</ChildrenContainer>
        </Card>
        {withCloseBtn && (
          <Close onClick={handleClose}>
            <CloseIcon />
          </Close>
        )}
      </Wrapper>
    </Paper>
  );
};
