import styled, { css } from 'styled-components'
import { IconButton as MuiIconButton } from '@material-ui/core'
import { TextField } from "../Input/styles"

export const Wrapper = styled.div`
  position: absolute;
  top: 97px;
  right: 0;
  padding: 16px;
  background: ${p => p.theme.colors.white};

  border-radius: 6px;
  z-index: 10;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
`;

export const IconButton = styled(MuiIconButton)`
  width: 40px;
  height: 40px;

  &.MuiIconButton-root {
    border-radius: 50%;
    padding: 0;
    opacity: 1;
  }
`;

export const Input = styled(TextField)`
  opacity: 1;

  &.MuiFormControl-root {
    label[data-shrink="false"] ~ .MuiInputBase-root {
      .MuiInputBase-input {
        &::placeholder {
          opacity: 1 !important;
        }
      }
    }
  }

  .MuiInputBase-root {
    border: 1px solid #C0D8EA;
    border-radius: 6px;

    .MuiInputBase-input {
      font-family: Manrope, Inter, sans-serif;
      color: #767676;
      font-weight: 500;
      &[data-shrink=false] {
        opacity: 1;
      }

      &::placeholder {
        color: ${({ theme }) => theme.colors.placeholder} !important;
        font-size: 16px;
        font-weight: 500;
        line-height: 22px;
        font-family: Manrope, Inter, sans-serif;
        opacity: 1 !important;
      }
    }
    &.Mui-focused {
      
      .MuiOutlinedInput-notchedOutline {
        border-color: transparent;
        border-width: 0;
      }
    }
  }

  .PrivateNotchedOutline-legendLabelled-3,
  .PrivateNotchedOutline-root-1 {
    display: none;
  }
`;

export const InputButton = styled(MuiIconButton)`
  &.MuiIconButton-root {
    padding: 8px;
  }
`;

const stylesInputWrap = css`
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
  }
`;

export const InputWrap = styled.div<{ $onTop: boolean; $disabled: boolean }>`
  position: relative;
  width: 100%;
  ${p => p.$onTop && 'z-index: 10000'};
  ${p => p.$disabled && stylesInputWrap};
`;

export const Dimmer = styled.div`
  z-index: 900;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
`;
