import styled from 'styled-components'

interface TabProps {
  active?: boolean;
}

interface PointColors {
  color?: string;
  background?: string;
}

interface LeftStatsChartContainerProps {
  paddingBottom?: string | null;
}

interface ChartProps {
  paddingTop?: string | null;
}

interface RightActionProps {
  disabled?: boolean;
}

export const Wrapper = styled.div`
  max-width: 1320px;
  width: 100%;
  margin: 0 auto;
  padding-top: 40px;
`;

export const MainContainer = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  height: max-content;
  border-radius: 8px;
`;

export const Header = styled.div`
  width: 100%;
  height: 54px;
  display: flex;
  border-bottom: 1px solid ${({ theme }) => theme.colors.light};
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  height: calc(100% - 55px);
  box-sizing: border-box;
`;

export const Tab = styled.div<TabProps>`
  width: 200px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  color: ${({ active, theme }) => (active ? theme.colors.main : 'black')};
  border-bottom: ${({ active, theme }) => (active ? `2px solid ${theme.colors.main}` : null)};
`;

export const Container = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  width: 100%;
  height: 100%;
  padding: 48px 24px 24px 24px;

  & > * {
    overflow: hidden;
  }
`;

export const Left = styled.div`
  padding-right: 36px;
  box-sizing: border-box;
`;

export const LeftTopBarContainer = styled.div`
  width: 100%;
  margin-bottom: 32px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const LeftTopBarParams = styled.div`
  display: flex;

  > div {
    margin-right: 25px;
  }
`;

export const LeftStatsContainer = styled.div`
  width: 100%;
  height: calc(100% - 82px);
  border: 1px solid ${({ theme }) => theme.colors.backgroundSecondary};
  border-radius: 10px;
`;

export const LeftStatsTitle = styled.div`
  display: flex;
  align-items: center;
  font-size: 22px;
  line-height: 32px;
  font-weight: 700;
  box-sizing: border-box;
  padding: 40px 0 0 40px;
  cursor: pointer;
`;

export const LeftStatsChartContainer = styled.div<LeftStatsChartContainerProps>`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  width: 100%;
  padding: 0 40px 40px 40px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.backgroundSecondary};
  padding-bottom: ${({ paddingBottom }) => paddingBottom || '40px'};
`;

export const LeftStatsChart = styled.div<ChartProps>`
  box-sizing: border-box;
  width: 100%;
  max-width: 856px;
  height: 360px;
  max-height: 360px;
  padding-top: ${({ paddingTop }) => paddingTop || 0};
`;

export const LeftStatsPoints = styled.div`
  box-sizing: border-box;
  padding: 40px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

export const LeftStatsPoint = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 280px;
  margin-bottom: 25px;
`;

export const LeftStatsPointColoredPercents = styled.div<PointColors>`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  color: ${({ color }) => color || 'black'};
  background-color: ${({ background }) => background};
`;

export const LeftStatsPointInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const LeftStatsPointInfoTitle = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
  max-width: 180px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.contentSecondary};
`;

export const LeftStatsPointInfoValue = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
`;

export const Right = styled.div`
  min-width: 300px;
  padding-left: 36px;
  box-sizing: border-box;
`;

export const RightActionsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 32px;
`;

export const RightAction = styled.div<RightActionProps>`
  width: 100%;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  cursor: pointer;
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
  color: ${({ theme }) => theme.colors.main};
`;

export const RightInfoContainer = styled.div`
  width: 100%;
  margin-bottom: 16px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.backgroundSecondary};
`;

export const RightInfoTitle = styled.div`
  margin-bottom: 8px;
  color: ${({ theme }) => theme.colors.contentSecondary};
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
`;

export const RightInfoValue = styled.div`
  margin-bottom: 16px;
  font-size: 22px;
  font-weight: 700;
  line-height: 32px;
`;

export const Icon = styled.img`
  margin-right: 10px;
`;

export const LoaderContainer = styled.div`
  width: 100%;
  height: 840px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
