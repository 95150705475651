import styled from "styled-components"
import { LoadingIndicator } from "../LoadingIndicator"
import { ReactElement } from "react";

const LoadingWrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`

export function Loading(): ReactElement {
  return (
    <LoadingWrapper>
      <LoadingIndicator />
    </LoadingWrapper>
  )
}
