import styled, { css } from 'styled-components'
import {
  TextField as MuiTextField,
  Button as MuiButton,
  IconButton as MuiIconButton,
  Checkbox as MuiCheckbox,
  Paper,
} from '@material-ui/core'
import DatePicker from 'react-datepicker'
import ReactCreatableSelect from 'react-select/creatable'
import ReactSelect from 'react-select'
import CalendarIcon from '@assets/icons/calendar.svg'
import LeftIcon from '@assets/icons/left.svg'

export const Cell = styled.div<{ $hidden?: boolean }>`
  ${p => p.$hidden && { visibility: 'hidden', userSelect: 'none' }}
  padding: 4px 12px;
  line-height: 24px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  position: relative;
  width: 156px;
  min-width: 156px;
  height: 48px;

  &.minify {
    min-width: 70px;
    width: 73px;
  }

  &:nth-child(5) {
    width: 180px;
    min-width: 180px;
  }

  &:nth-child(1) {
    width: 120px;
    min-width: 120px;
  }

  &:nth-child(2) {
    width: 180px;
    min-width: 180px;
  }
  &:nth-child(3) {
    width: 180px;
    min-width: 180px;
  }
  &:nth-child(4) {
    width: 180px;
    min-width: 180px;
  }

  &:nth-child(6) {
    width: 120px;
    min-width: 120px;
    color: #ce1313;
    font-weight: 600;
  }

  &:nth-child(7) {
    width: 120px;
    min-width: 120px;
    color: #13bdce;
    font-weight: 600;
  }

  &:nth-child(8) {
    min-width: 70px;
  }

  .MuiSvgIcon-root {
    fill: ${p => p.theme.colors.backgroundSecondary};
  }

  .Mui-checked {
    .MuiSvgIcon-root {
      fill: ${p => p.theme.colors.main};
    }
  }
`;

export const CellText = styled.span`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const Checkbox = styled(MuiCheckbox)`
  &&.Mui-checked {
    color: ${p => p.theme.colors.main};
  }
`;

export const TextField = styled(MuiTextField)`
  &.MuiFormControl-root {
    position: relative;
    width: 100%;
  }
  && .MuiFormLabel-root {
    font-style: normal;
    font-weight: normal;
    font-size: ${p => p.theme.text.fontExtraSmall};
    line-height: 16px;
    color: ${p => p.theme.colors.contentSecondary};

    & .Mui-focused {
      color: ${p => p.theme.colors.main};
      border: 1px solid ${p => p.theme.colors.main};
    }
  }

  .MuiInput-underline:after {
    border-color: ${p => p.theme.colors.main};
  }
  .MuiFormHelperText-root.Mui-error {
    position: absolute;
    bottom: -20px;
  }
  .MuiOutlinedInput-input {
    padding: 8px;
    height: 40px;
    box-sizing: border-box;
    font-style: normal;
    font-weight: normal;
    font-size: ${p => p.theme.text.fontExtraSmall};
    line-height: 16px;
    color: ${p => p.theme.colors.contentSecondary};
  }
  .MuiInputLabel-outlined {
    transform: translate(14px, 8px) scale(1);
  }

  & .MuiOutlinedInput-root {
    width: 100%;
    &.Mui-focused {
      .MuiOutlinedInput-notchedOutline {
        border: 1px solid ${p => p.theme.colors.main};
      }
    }
  }
  & .MuiOutlinedInput-notchedOutline span {
    display: none;
  }
`;

export const Numbers = styled(TextField)`
  && .MuiOutlinedInput-input {
    padding: 8px;
    text-align: right;
    position: relative;
    height: 40px;
    box-sizing: border-box;
  }
`;

export const Submit = styled(MuiButton)`
  &.MuiButton-root {
    padding: 8px 16px;
    background-color: ${p => p.theme.colors.main};
    color: ${p => p.theme.colors.white};
    font-weight: bold;
    text-transform: none;
    margin-left: 16px;

    &:hover,
    &:focus,
    &:active {
      background-color: ${p => p.theme.colors.dark};
      color: ${p => p.theme.colors.white};
    }

    &:disabled {
      background-color: ${p => p.theme.colors.backgroundMain};
      color: ${p => p.theme.colors.contentSecondary};
    }
  }
`;

export const IconButton = styled(MuiIconButton)`
  &.MuiIconButton-root {
    position: absolute;
    left: 12px;
    padding: 4px;
  }
`;

export const SplitCrossIconButton = styled(MuiIconButton)({
  [`&.MuiIconButton-root`]: {
    position: 'absolute',
    transform: 'translateX(25%)',
  },
});

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 20px;
  color: ${p => p.theme.colors.contentMain};
  box-shadow: none;
  border-bottom: 1px ${p => p.theme.colors.backgroundSecondary} solid;

  & .react-datepicker .react-datepicker__header {
    background-color: #fff;
    border: none;
    padding: 16px 16px 4px;
    font-size: 16px;
    line-height: 24px;
    color: ${p => p.theme.colors.contentMain};
    font-family: 'Montserrat', sans-serif;
    text-transform: capitalize;
    font-weight: 600;
  }

  & .react-datepicker__current-month {
    font-size: 16px;
    line-height: 32px;
    color: ${p => p.theme.colors.contentMain};
    font-family: 'Montserrat', sans-serif;
    text-transform: capitalize;
    margin-bottom: 4px;
  }

  & .react-datepicker__navigation--previous {
    left: 16px;
    top: 16px;
  }

  & .react-datepicker__navigation--next {
    right: 16px;
    top: 16px;
  }

  & .react-datepicker__day--selected,
  & .react-datepicker__day--keyboard-selected {
    background-color: ${p => p.theme.colors.main};
    border-radius: 50%;
  }

  & .react-datepicker__day--today {
    font-weight: 400;
  }

  & .react-datepicker__month {
    font-size: 16px;
    line-height: 24px;
    font-family: 'Montserrat', sans-serif;
    margin: 0 16px 16px;
  }

  & .react-datepicker__day-name {
    line-height: 32px;
    margin: 4px;
  }

  & .react-datepicker__day {
    width: 32px;
    line-height: 32px;
    text-align: center;
    vertical-align: middle;
  }

  & .MuiInputLabel-outlined {
    transform: translate(10px, 12px) scale(1);
    font-size: 12px;
    line-height: 16px;
    color: ${p => p.theme.colors.contentMain};
  }
  & .MuiInputLabel-outlined.MuiInputLabel-shrink {
    display: none;
  }
  & .MuiOutlinedInput-notchedOutline {
    border-color: ${p => p.theme.colors.backgroundSecondary};
  }
`;

export const Box = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 20px 24px 20px 72px;
  border-bottom: 1px ${p => p.theme.colors.backgroundSecondary} solid;
`;

/**
 * @description Space Between Box
 */
export const BoxSB = styled(Box)({
  justifyContent: 'space-between',
});

export const Cancel = styled(Submit)`
  &.MuiButton-root {
    background-color: transparent;
    border: 1px ${p => p.theme.colors.main} solid;
    border-radius: 4px;
    color: ${p => p.theme.colors.main};
  }
`;

export const Label = styled.span`
  padding-left: 10px;
  text-transform: none;
  font-size: 12px;
  line-height: 16px;
  text-align: left;
`;

export const Button = styled(MuiButton)`
  && {
    color: ${p => p.theme.colors.main};
    padding: 0;
    margin-right: auto;
    span {
      text-transform: capitalize;
    }
    &:disabled {
      color: ${p => p.theme.colors.contentSecondary};
      font-weight: 400;
      svg {
        fill: ${p => p.theme.colors.contentSecondary};
      }
    }
  }
  & .MuiButton-label {
    align-items: flex-start;
  }
`;

export const SelectStyle = css`
  width: 100%;
  & .select__menu {
    width: 192px;
    padding: 16px;
    font-size: 12px;
    line-height: 16px;
    margin-top: 8px;
    z-index: 100;
    box-sizing: border-box;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.1);
  }
  & .select__control {
    font-size: 12px;
    line-height: 16px;
    box-shadow: none;
    border-color: ${p => p.theme.colors.backgroundSecondary};
  }
  & .css-1okebmr-indicatorSeparator {
    display: none;
  }

  & .select__clear-indicator {
    padding: 0;
  }
  & .select__dropdown-indicator {
    padding-left: 2px;
  }

  & .select__control:hover {
    border: 1px solid ${p => p.theme.colors.black};
    box-shadow: none;
  }

  && .select__control--is-focused,
  & .select__control--is-selected {
    border: 1px solid ${p => p.theme.colors.main};
    box-shadow: none;
  }

  & .select__option {
    cursor: pointer;
    background-color: transparent;
  }
  & .select__option:hover,
  & .select__option--is-focused {
    background: ${p => p.theme.colors.light};
  }
  & .select__option--is-selected {
    background: ${p => p.theme.colors.light};
    color: ${p => p.theme.colors.contentMain};
  }
  outline-color: ${p => p.theme.colors.main};
  outline-width: 1px;
`;

export const CreatableSelect = styled(ReactCreatableSelect)<{ $error: boolean; largeWidth?: boolean }>`
  ${SelectStyle}
  & .select__menu {
    ${({ largeWidth }) => largeWidth && `width: 300px`};
  }
  & .select__control {
    ${p => p.$error && `border: 1px solid ${p.theme.colors.negative}`};
    box-shadow: none;
  }
`;

export const Select = styled(ReactSelect)`
  ${SelectStyle}
`;

export const SubCategory = styled(Paper)<{ $checked: boolean; $highlighted: boolean }>`
  &.MuiPaper-root {
    display: flex;
    align-items: center;
    padding: 0 12px 0 20px;
    color: ${p => p.theme.colors.contentMain};
    box-shadow: none;
    border-bottom: 1px ${p => p.theme.colors.backgroundSecondary} solid;
    background-color: ${p => (p.$checked || p.$highlighted ? p.theme.colors.light : p.theme.colors.white)};
  }
`;

export const Calendar = styled(DatePicker)`
    width: 116px;
    padding: 8px;
    border: 1px solid ${p => p.theme.colors.backgroundSecondary};
    border-radius: 4px;
    color: ${p => p.theme.colors.contentMain};
    position: relative;
    background-image: url(${CalendarIcon.toString()});
    background-repeat: no-repeat;
    background-position: 84px 6px;
    box-sizing: border-box;
    font-size: 12px;
    line-height: 16px;
    height: 40px;
    outline: none;
    &:hover {
        border: 1px solid ${p => p.theme.colors.black};
    }
    &:focus {
        border-color: ${p => p.theme.colors.main};
    }
`;

export const InteractiveWrapper = styled.div`
  display: flex;
  flex-grow: 1;
`;

export const BottomIcon = styled(LeftIcon)`
  transform: rotate(-90deg);
  pointer-events: none;
`;

export const InnerWrap = styled.div`
  display: flex;
  ${Cell} {
    width: 156px;
    height: 40px;
  }
  & .MuiOutlinedInput-notchedOutline span {
    display: none;
  }
`;

export const IconWrap = styled.span`
  margin-right: 9px;
`;

export const Text = styled.span`
  font-weight: 600;
`;

export const SplitButton = styled(MuiButton)({});

export const SplitButtonLabel = styled.span(({ theme }) => ({
  textTransform: 'none',
  color: theme.colors.main,
}));

export const DropDownOptionWrapper = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  flexWrap: 'nowrap',
  marginLeft: 10,
}));

export const DropDownOptionSubText = styled.div<{ value: number }>(({ theme, value }) => ({
  color: value == 0.0 ? '#989898' : value < 0 ? theme.colors.negative : theme.colors.foundationPositive,
  whiteSpace: 'nowrap',
}));

export const DropDownOptionLabel = styled.div(({ theme }) => ({
  width: '180px',
  marginRight: '10px',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  color: '#333333',
}));
