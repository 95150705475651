import styled from 'styled-components'
import { TextField as MuiTextField } from '@material-ui/core'

export const Wrapper = styled.div`
  width: 360px;
`;

export const Title = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: ${p => p.theme.text.fontMedium};
  line-height: 32px;
  color: ${p => p.theme.colors.contentMain};
  margin-bottom: 40px;
`;

export const Text = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: ${p => p.theme.text.fontSmall};
  line-height: 24px;
  margin-bottom: 30px;
  color: ${p => p.theme.colors.contentMain};
  p {
    margin-bottom: 24px;
  }
`;

export const Form = styled.form``;

export const TextField = styled(MuiTextField)<{ width?: string }>`
  &.MuiFormControl-root {
    min-height: 71px;
    margin-right: 40px;
    margin-bottom: 123px;
  }
  .MuiFormLabel-root.Mui-focused {
    color: ${p => p.theme.colors.main};
  }
  .MuiInput-underline:after {
    border-color: ${p => p.theme.colors.main};
  }

  width: ${p => p.width || '196px'};
`;
