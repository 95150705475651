import authService from "@auth/service/auth-service";
import { tokenSelector } from "@common/store";
import jwtDecode from "jwt-decode";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useAsyncDispatch } from "./useAsyncDispatch";

// Запас времени до "смерти" токена в мс.
const SPARE_TIME = 120000;

type DecodeType = {
  exp: number;
};

export function useRefreshToken() {
  const asyncDispatch = useAsyncDispatch();
  const token = useSelector(tokenSelector);

  useEffect(() => {
    let timerId;

    if (token) {
      const { exp: tokenExp } = jwtDecode<DecodeType>(token);
      const tokenLifeTime = tokenExp * 1000 - Date.now() - SPARE_TIME;

      if (tokenLifeTime > 0) {
        timerId = setTimeout(() => {
          asyncDispatch(authService.refreshToken());
        }, tokenLifeTime);
      } else {
        asyncDispatch(authService.refreshToken());
      }
    }

    return () => clearTimeout(timerId);
  }, [token, asyncDispatch]);
}
