export const ONE_DAY = 1000 * 60 * 60 * 24;

export const CREDIT = 'Кредит';

export const LANDING_LINK = 'https://savinger.ru';

export enum RoleId {
  CLIENT = '1',
  ADMIN = '2',
  MANAGER = '3',
}

export enum StatusCode {
  BAD_REQUEST = 400,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  SERVER_ERROR = 500,
}
