import { validatePassword } from "@common/utils"
import { ReactElement, useState } from "react";
import { Field, FormRenderProps } from "react-final-form"
import { Form, Submit, TextField } from "../common/styles"
import { PasswordEndAdornment } from "./PasswordEndAdornment"

type UpdatePasswordFormType = {
  password: string
  repeat_password: string
}

export function UpdatePasswordForm(props: FormRenderProps<UpdatePasswordFormType>): ReactElement {
  const { handleSubmit, submitting, valid } = props

  const [passwordFieldType, setPasswordFieldType] = useState<"password" | "text">("password")
  const [repeatPasswordFieldType, setRepeatPasswordFieldType] = useState<"password" | "text">("password")

  const disabled = submitting || !valid

  return (
    <Form onSubmit={handleSubmit} style={{ width: "100%" }}>
      <Field name="password" validate={validatePassword}>
        {({ input, meta }) => (
          <TextField
            name={input.name}
            value={input.value}
            onChange={input.onChange}
            id="password"
            label="Пароль"
            type={passwordFieldType}
            error={!!meta.error && !meta.pristine}
            helperText={meta.pristine ? "" : meta.error}
            InputProps={{
              endAdornment: (
                <PasswordEndAdornment
                  fieldType={passwordFieldType}
                  onClick={() => setPasswordFieldType(prevState => (prevState === "password" ? "text" : "password"))}
                />
              ),
            }}
          />
        )}
      </Field>
      <Field name="repeat_password" validate={validatePassword}>
        {({ input, meta }) => (
          <TextField
            name={input.name}
            value={input.value}
            onChange={input.onChange}
            id="repeat_password"
            label="Повторите пароль"
            type={repeatPasswordFieldType}
            error={!!meta.error && !meta.pristine}
            helperText={meta.pristine ? "" : meta.error}
            InputProps={{
              endAdornment: (
                <PasswordEndAdornment
                  fieldType={repeatPasswordFieldType}
                  onClick={() =>
                    setRepeatPasswordFieldType(prevState => (prevState === "password" ? "text" : "password"))
                  }
                />
              ),
            }}
          />
        )}
      </Field>
      <Submit type="submit" disabled={disabled}>
        Сохранить
      </Submit>
    </Form>
  )
}
