import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 360px;
`;

export const Title = styled.div`
  font-style: normal;
  font-weight: bold;
  font-size: ${p => p.theme.text.fontMedium};
  line-height: 32px;
  color: ${p => p.theme.colors.contentMain};
  margin-bottom: 40px;
`;

export const Field = styled.div`
  width: 100%;
  height: 50px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
`;

export const FieldValue = styled.span`
  height: 25px;
  font-weight: 600;
  line-height: 24px;
`;

export const FieldLabel = styled(FieldValue)`
  color: ${({ theme }) => theme.colors.contentSecondary};
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
