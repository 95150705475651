import { ButtonMain } from "@common/components/ui/ButtonMain"
import { validatePassword } from "@common/utils"
import { Dispatch, FC, SetStateAction } from "react"
import { Field, Form as FinalForm } from "react-final-form"
import { Modal } from "../Modal/Modal"
import { Form, Text, TextField, Title, Wrapper } from "./styles"

interface IModalPassword {
  isOpen: boolean
  setOpen?: Dispatch<SetStateAction<boolean>>
  onSubmit: (password: string) => void
  passwordError: string | Error | null
  resetEmailFailure: () => void
  note: string
}

export const ModalPassword: FC<IModalPassword> = ({
  isOpen,
  setOpen,
  onSubmit,
  passwordError,
  resetEmailFailure,
  note,
}) => {
  const renderPasswordForm = ({ handleSubmit, submitting, valid }) => {
    const disabled = submitting || !valid
    return (
      <Form onSubmit={handleSubmit}>
        <Field name="password" validate={validatePassword}>
          {({ input: { name, value, onChange }, meta }) => (
            <TextField
              name={name}
              value={value}
              onChange={event => {
                onChange(event)
                if (passwordError !== null) {
                  resetEmailFailure()
                }
              }}
              id="password"
              label="Пароль"
              type="password"
              width="100%"
              error={(!!meta.error && !meta.pristine) || !!passwordError}
              helperText={(meta.pristine ? "" : meta.error) || (!!passwordError && passwordError)}
            />
          )}
        </Field>
        <ButtonMain fullWidth={true} type="submit" disabled={disabled}>
          Сохранить
        </ButtonMain>
      </Form>
    )
  }

  return (
    <Modal open={isOpen} setOpen={setOpen}>
      <Wrapper>
        <Title>Введите пароль</Title>
        <Text>
          <p>{note}</p>
        </Text>
        <FinalForm onSubmit={onSubmit} render={renderPasswordForm} />
      </Wrapper>
    </Modal>
  )
}
