import React from 'react'
import styled from 'styled-components'
import { FormControlLabel, Radio as MuiRadio, RadioGroup } from '@material-ui/core'
import OnIcon from '@assets/icons/on.svg'
import OffIcon from '@assets/icons/off.svg'
import { FieldInputProps } from "react-final-form"

export const Radio = ({ ...props }) => <StyledRadio {...props} checkedIcon={<OnIcon/>} icon={<OffIcon/>}/>;

type Props<T> = {
  input: FieldInputProps<T>
  labels: RadioLabelType[]
  row?: boolean
}
export type RadioLabelType = {
  label: React.ReactNode
  value?: unknown
}

export function StyledRadioGroup<T>(props: Props<T>) {
  const { input, labels, row = true } = props

  return (
    <StyledGroup
      name={input.name}
      id={input.name}
      value={input.value}
      onChange={input.onChange}
      row={row}
    >
      {labels.map(({ label, value }) => (
        <FormControlLabel value={value} control={<Radio/>} label={label}/>
      ))}
    </StyledGroup>
  )
}

const StyledGroup = styled(RadioGroup)<{ row?: boolean }>`
  ${p => p.row && `
      column-gap: 40px;
    `}
  .MuiFormControlLabel-root {
    margin: 0;
    color: ${p => p.theme.colors.placeholder};
    
    .MuiFormControlLabel-label {
      font-family: Manrope, Inter, sans-serif;
    }
    
    .MuiButtonBase-root {
      padding: 0;
      margin-right: 8px;

      .MuiIconButton-label {
        svg > path {
          fill: ${p => p.theme.colors.placeholder};
        }

        .PrivateSwitchBase-input-4 {
          padding: 0;
        }
      }

      &.Mui-checked ~ .MuiTypography-root {
        color: ${p => p.theme.colors.main};
      }

      &.Mui-checked > .MuiIconButton-label {
        svg > path {
          fill: ${p => p.theme.colors.main};
        }
      }

      .MuiTypography-root {
        font-weight: 500;
        font-family: Manrope, Inter, sans-serif;
        font-size: 16px;
        line-height: 22px;
        color: ${p => p.theme.colors.placeholder};
      }
    }
  }
`

const StyledRadio = styled(MuiRadio)`
  margin-right: 8px;

  &&.Mui-checked {
    color: ${p => p.theme.colors.main};

    label {
      color: ${p => p.theme.colors.main};
    }
  }

  &.MuiCheckbox-root {
    color: ${p => p.theme.colors.backgroundSecondary};
  }

  &.MuiCheckbox-colorSecondary.Mui-checked:hover,
  &.MuiIconButton-colorSecondary:hover {
    background-color: transparent;
  }
`;
