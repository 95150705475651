import { useCallback, useState } from "react"

export type AnyToVoidFunction = (...args: any) => void

export function useFlag(initial: boolean = false): [ boolean, AnyToVoidFunction, AnyToVoidFunction, AnyToVoidFunction ] {
  const [ value, setValue ] = useState(initial)
  const setTrue = useCallback(() => (setValue(true)), [])
  const setFalse = useCallback(() => (setValue(false)), [])
  const toggle = useCallback(() => (setValue((current) => !current)), [])
  return [ value, setTrue, setFalse, toggle ]
}
