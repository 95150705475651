import { createSlice, PayloadAction } from "@reduxjs/toolkit"

export type AnalyticsStore = {
  filter: {
    categories: string[]
    period: [Date | undefined, Date | undefined] | undefined
    accounts: string[]
  }
}
const initialState: AnalyticsStore = {
  filter: {
    categories: [],
    period: undefined,
    accounts: [],
  },
}

const analyticsSlice = createSlice({
  name: "analytics",
  initialState: initialState,
  reducers: {
    modify: <KEY extends keyof AnalyticsStore["filter"], VALUE extends AnalyticsStore["filter"][KEY]>(
      state,
      action: PayloadAction<{
        field: KEY
        value: VALUE
      }>,
    ) => {
      state.filter[action.payload.field] = action.payload.value
    },
  },
  extraReducers: {},
})

export const { modify } = analyticsSlice.actions
export const analyticsReducer = analyticsSlice.reducer
