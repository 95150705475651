import Logo from "@assets/icons/logo.svg";
import { useUnwrapAsyncDispatch } from "@common/hooks";
import { LayoutService } from "@common/index";
import { ReactElement, useEffect } from "react";
import styled from "styled-components";
import { Footer, MenuList } from "./components";
import { LogoOpened, Sidebar, Wrapper } from "./styles";

export function NavBar(): ReactElement {
  const asyncDispatch = useUnwrapAsyncDispatch()

  useEffect(() => {
    asyncDispatch(LayoutService.loadAccounts())
  }, [])

  return (
    <Sidebar>
      <Wrapper>
        <LogoOpened to="/">
          <StyledLogo />
        </LogoOpened>
        <MenuList />
      </Wrapper>
      <Footer />
    </Sidebar>
  )
}

const StyledLogo = styled(Logo as any)`
  width: 159px;
  height: 40px;
`
