import { FormFooter, ModalContainer } from "@common/components/ModalComponents"
import { CreateBudgetType } from "@common/services"
import { Field, Form as FinalForm } from "react-final-form"
import { FormInputText } from "../../../../../common/components/ui"
import { Form } from "./styles"
import { ReactElement } from "react";

type Props = {
  open: boolean
  close: () => void
  onSubmit?: (values: CreateBudgetType) => void
  onCancel?: () => void
}

export function CreateBudgetModal({ open, close, onCancel, onSubmit }: Props): ReactElement {
  return (
    <ModalContainer
      title="Создание бюджета"
      subtitle="Это смета расходов и доходов, которая поможет вам контролировать траты и достигать финансовых целей"
      width={400}
      open={open}
      onClose={close}
    >
      <FinalForm<CreateBudgetType>
        onSubmit={({ name }) => onSubmit?.({ name })}
        render={({ handleSubmit, submitting, valid, pristine }) => {
          const disabled = submitting || !valid || pristine

          return (
            <Form onSubmit={handleSubmit}>
              <div style={{ marginBottom: "24px" }}>
                <Field name="name">
                  {({ input }) => (
                    <FormInputText input={input} label="Наименование" placeholder="Введите наименование" />
                  )}
                </Field>
              </div>
              <FormFooter disabled={disabled} onRightButtonClick={onCancel} />
            </Form>
          )
        }}
      />
    </ModalContainer>
  )
}
