import { TreeItem } from "@common/components/ui";
import { Category, CategoryWithTag, GetCategoriesQuery, Subcategory, SubcategoryWithTag } from "@common/models";
import type { RootState } from "@common/store";
import { CategoryTransport } from "@common/transports";
import { normalizeError } from "@common/utils";
import { createAsyncThunk } from "@reduxjs/toolkit";
import type { AnyAction, Dispatch } from "redux";
import { setHiddenCategories, setTableCategoryItems, setTree } from "../store/budget";
import categoriesTableService from "./categories-table.service";

class CategoriesService {
  public async loadCategoriesTable(query: GetCategoriesQuery, dispatch: Dispatch<AnyAction>) {
    const categories = await CategoryTransport.getCategories(query);
    dispatch(setTableCategoryItems(categories));
  }

  public loadBudgetCategoriesByDate = createAsyncThunk<
    void,
    null | { budgetId: string | undefined; month: number | null; year: number | null },
    { state: RootState }
  >("LoadBudgetCategoriesByDate", async (body, { dispatch, getState, rejectWithValue }) => {
    const budgetId = body === null ? getState().budgets.currentBudget?.id : body.budgetId;
    const month = body === null ? getState().budgets.date.month : body.month;
    const year = body === null ? getState().budgets.date.year : body.year;

    if (budgetId === undefined || month === null || year === null) {
      return;
    }

    try {
      const categories = await CategoryTransport.getBudgetCategoriesByDate(budgetId, month, year);
      dispatch(setTableCategoryItems(categories));

      const { hidden, visible } = this.getCategoriesByTypeAlpha(categories);

      const tree = categoriesTableService.transformCategoriesToTree(visible);

      const hiddenCategories = categoriesTableService.transformCategoriesToTree(hidden);
      dispatch(setTree(tree));
      dispatch(setHiddenCategories(hiddenCategories));
    } catch (err) {
      const { message } = normalizeError(err);
      console.error(message);
      return rejectWithValue(message);
    }
  });

  private getCategoriesByType(categories: Category[]): { visible: Category[]; hidden: Category[] } {
    const visible: Category[] = [];
    const hidden: Category[] = [];

    for (let index = 0; index < categories.length; index++) {
      if (categories[index].isHidden) {
        hidden.push(categories[index]);
        continue;
      }
      visible.push(categories[index]);
    }

    return {
      visible,
      hidden,
    };
  }

  private getCategoriesByTypeAlpha(categories: Category[]): { visible: Category[]; hidden: Category[] } {
    const visible: Category[] = [];
    const hidden: Category[] = [];

    for (let categoryIndex = 0; categoryIndex < categories.length; categoryIndex++) {
      if (categories[categoryIndex]?.isHidden) {
        hidden.push(categories[categoryIndex]);
        continue;
      }

      if (
        categories[categoryIndex]?.subcategories === undefined ||
        categories[categoryIndex]?.subcategories?.length === 0
      ) {
        visible.push(categories[categoryIndex]);
        continue;
      }

      const { categoryWithVisibleSubcategories, categoryWithHiddenSubcategories } = this.getCategoryRows(
        categories[categoryIndex],
      );

      if (categoryWithVisibleSubcategories !== undefined) {
        visible.push(categoryWithVisibleSubcategories);
      }

      if (categoryWithHiddenSubcategories !== undefined) {
        hidden.push(categoryWithHiddenSubcategories);
      }
    }

    return {
      visible,
      hidden,
    };
  }

  private getCategoryRows(
    category: Category,
  ): { categoryWithVisibleSubcategories: Category | undefined; categoryWithHiddenSubcategories: Category | undefined } {
    const subcategories = category.subcategories || [];

    const visibleSubcategories: Subcategory[] = [];
    const hiddenSubcategories: Subcategory[] = [];

    for (let index = 0; index < subcategories.length; index++) {
      if (subcategories[index]?.isHidden) {
        hiddenSubcategories.push(subcategories[index]);
        continue;
      }
      visibleSubcategories.push(subcategories[index]);
    }

    return {
      categoryWithHiddenSubcategories: this.getCategoryWithSubcategories(category, hiddenSubcategories, true),
      categoryWithVisibleSubcategories: this.getCategoryWithSubcategories(category, visibleSubcategories, false),
    };
  }

  private getCategoryWithSubcategories(
    category: Category,
    subcategories?: Subcategory[],
    isHidden?: boolean,
  ): Category | undefined {
    return subcategories?.length !== 0 ? { ...category, subcategories: subcategories, isHidden } : undefined;
  }

  public getCategoriesTreeByType(
    categories: TreeItem<CategoryWithTag | SubcategoryWithTag>[],
  ): {
    visible: TreeItem<CategoryWithTag | SubcategoryWithTag>[];
    hidden: TreeItem<CategoryWithTag | SubcategoryWithTag>[];
  } {
    const visible: TreeItem<CategoryWithTag | SubcategoryWithTag>[] = [];
    const hidden: TreeItem<CategoryWithTag | SubcategoryWithTag>[] = [];

    for (let index = 0; index < categories.length; index++) {
      if (categories[index].data?.isHidden) {
        hidden.push(categories[index]);
        continue;
      }
      visible.push(categories[index]);
    }

    return {
      visible,
      hidden,
    };
  }

  constructor() {}
}

export default new CategoriesService();
