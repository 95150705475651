import { UniqueIdentifier } from "@dnd-kit/core"
import { arrayMove } from "@dnd-kit/sortable"
import { getTreeItemDepth, getTreeItemMaxDepth, getTreeItemMinDepth, getTreeItemParentId } from "./index"
import { FlattenedItem, ProjectionType } from "./types"

export function getProjection<T>(
  items: Array<FlattenedItem<T>>,
  activeIds: Array<UniqueIdentifier>,
  overId: UniqueIdentifier,
  dragOffset: number,
  indentationWidth: number,
  maxDeep?: number,
  currentId?: UniqueIdentifier,
  disableChangeDepth?: boolean,
): ProjectionType<T> {
  const overItemIndex: number = items.findIndex(({ id }): boolean => id === overId)

  const firstActiveItem: FlattenedItem<T> | undefined = items.find(item => item.id === currentId)

  const activeItemIndex: number = firstActiveItem !== undefined ? items.indexOf(firstActiveItem) : 0

  const newItems: Array<FlattenedItem<T>> = arrayMove(items, activeItemIndex, overItemIndex)

  const depth: number = getTreeItemDepth(
    items,
    newItems,
    activeIds,
    dragOffset,
    indentationWidth,
    overItemIndex,
    maxDeep,
    currentId,
    disableChangeDepth,
  )

  const parentId: UniqueIdentifier | undefined = getTreeItemParentId(depth, newItems, overItemIndex, activeIds)

  const maxDepth: number = getTreeItemMaxDepth(items, activeIds, overItemIndex, maxDeep)
  const minDepth: number = getTreeItemMinDepth(newItems, overItemIndex, items, activeIds, maxDeep)

  return { depth, maxDepth, minDepth, parentId }
}
