import { createSlice, PayloadAction, Slice, SliceCaseReducers } from "@reduxjs/toolkit";

const INIT_STATE: StateTypes = {
  loading: false,
  error: null,
  newContractor: null,
  list: [],
};

export interface ContractorEditType {
  name: string
  budget: string
  templates?: string[]
  isVisible?: boolean
  isAutocompletion?: boolean
  autoSubcategory?: string
}

export interface IContractor {
  id: string
  contractorType?: string
  name: string
  budget: string
  templates?: string[]
  isVisible?: boolean
  isAutocompletion?: boolean
  autoSubcategory?: string
  cacheAccountId?: string
  autoSubcategoryName?: string
  createdAt?: string
}

interface StateTypes {
  list: Array<IContractor>;
  loading: boolean;
  newContractor: IContractor | null;
  error: string | null;
}

interface ContractorsActions extends SliceCaseReducers<StateTypes> {
  setContractorsList: (state: StateTypes, action: PayloadAction<IContractor[]>) => void
  setContractorsLoading: (state: StateTypes, action: PayloadAction<boolean>) => void
  setContractorsError: (state: StateTypes, action: PayloadAction<string | null>) => void
  setNewContractor: (state: StateTypes, action: PayloadAction<IContractor | null>) => void
  setContractor: (state: StateTypes, action: PayloadAction<IContractor>) => void
}

const contractorsSlice: Slice<StateTypes, ContractorsActions, "contractors"> = createSlice({
  name: "contractors",
  initialState: INIT_STATE,
  reducers: {
    setContractor: (state, action: PayloadAction<IContractor>): void => {
      const contractors = state.list.filter(({ id }) => id !== action.payload.id);
      state.list = [ action.payload, ...contractors ]
    },
    setContractorsList: (state, action: PayloadAction<IContractor[]>): void => {
      state.list = action.payload
    },
    setContractorsLoading: (state, action: PayloadAction<boolean>): void => {
      state.loading = action.payload
    },
    setContractorsError: (state, action: PayloadAction<string | null>): void => {
      state.loading = false
      state.error = action.payload
    },
    setNewContractor: (state, action: PayloadAction<IContractor | null>): void => {
      state.newContractor = action.payload
    },
  },
  extraReducers: {},
})

export const {
  setContractor,
  setContractorsList,
  setContractorsLoading,
  setContractorsError,
  setNewContractor,
} = contractorsSlice.actions

export const contractorsReducer = contractorsSlice.reducer
