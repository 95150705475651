import styled from 'styled-components'

export const LeftTopBarActions = styled.div`
  display: flex;

  button:first-child {
    margin-right: 8px;
  }
`;

export const CustomTooltip = styled.div`
  min-width: 200px;
  padding: 24px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 4px 24px 0 ${({ theme }) => theme.colors.lightGrey};
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 30%;
    left: -10px;
    width: 30px;
    height: 30px;
    transform: rotate(45deg);
    background-color: white;
  }
  p {
    margin-bottom: 8px;
  }
  span {
    display: block;
  }
`;

export const Box = styled.div`
  width: 880px;
  height: 530px;
  * {
    box-sizing: border-box;
  }
`;

export const EmptyText = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: ${({ theme }) => theme.text.fontSmall};
  line-height: 24px;
  color: ${({ theme }) => theme.colors.contentMain};
  padding-left: 40px;
`;

export const Table = styled.div`
  height: 432px;
  width: 100%;
`;

export const TableHead = styled.div`
  width: 100%;
  height: 50px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.backgroundSecondary};
  border-top: 1px solid ${({ theme }) => theme.colors.backgroundSecondary};
  display: flex;
  padding: 0 12px;
`;

export const TableBody = styled.div`
  width: 100%;
  height: 418px;
  overflow-y: scroll;
`;

export const TableLine = styled.div`
  width: 100%;
  height: 50px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.backgroundSecondary};
  display: flex;
  justify-content: space-between;
  padding: 0 24px 0 12px;
`;

export const TableCell = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

export const Regular = styled(TableCell)`
  width: 180px;
  padding-left: 12px;

  &:first-child {
    width: 112px;
  }
  &:nth-last-child(2) {
    width: 160px;
  }
  &:last-child {
    width: 32px;
    padding-left: 0;
    justify-content: center;
  }
`;

export const Text = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const RegularMeta = styled(TableCell)`
  width: 180px;
  padding-left: 12px;
  font-weight: 600;
  &:first-child {
    width: 112px;
  }
  color: ${({ theme }) => theme.colors.contentSecondary};
`;

export const Title = styled.div`
  color: ${({ theme }) => theme.colors.contentMain};
  font-style: normal;
  font-weight: bold;
  font-size: ${({ theme }) => theme.text.fontMedium};
  line-height: 32px;
  padding: 40px;
`;

export const ExportLink = styled.a`
  display: flex;
  text-decoration: none;
  color: ${p => p.theme.colors.main};
  margin-right: 24px;
  align-items: center;
`;
